import React, { useEffect, useMemo, useState } from 'react'
import { Resources } from 'src/types/permissions-types'
import { Identifier } from 'src/types/request-entities-types'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import TableSectionContainer from 'src/components/table-section-container'
import { PageSectionTableMaxHeight } from 'src/components/ui-kit'
import { Order } from 'src/types/common-types'
import { useAction } from 'src/hooks/use-actions'
import { fetchAllMemberCoupons } from 'src/redux/member/member-actions'
import { useSelector } from 'react-redux'
import {
  getAllCouponsByUser,
  getAllCouponsByUserError,
  getIsAllCouponsByUserLoading,
} from 'src/redux/member/member-selectors'
import { Modal } from 'antd'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import ResourceCouponsModal from './resource-coupons-modal'
import { ActionCell } from '../../components/accessible-table/accessible-table-cell'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Coupon } from '../../constants/billing-v2-contants'
import api from '../../api'

type ResourceCouponsTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
}

function ResourceCouponsTable({
  recordId,
  resource,
  title,
  columns: baseColumns,
  emptyDataMessage,
}: ResourceCouponsTableProps) {
  const { isReadAllowed, isEditAllowed, isCreateAllowed, isDeleteAllowed } = useResourcePermissionHandler(resource)

  const onFetchMemberCoupons = useAction(fetchAllMemberCoupons)
  useEffect(() => {
    recordId && onFetchMemberCoupons(recordId)
  }, [recordId, onFetchMemberCoupons])

  const currentUserCoupons = useSelector(getAllCouponsByUser)
  const currentUserCouponsError = useSelector(getAllCouponsByUserError)
  const currentUserCouponsLoading = useSelector(getIsAllCouponsByUserLoading)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState<Coupon | null>(null)
  const [deleteSelected, setDeleteSelected] = useState<Coupon | null>(null)

  const columns = useMemo(
    () =>
      [
        ...baseColumns,
        isEditAllowed &&
          ({
            key: 'edit_coupon',
            render: (props) => (
              <ActionCell
                action={(rowData) => {
                  setSelected(rowData as Coupon)
                  setVisible(true)
                }}
                renderIcon={() => <EditOutlined />}
                {...props}
              />
            ),
          } as ColumnItem),
        isDeleteAllowed &&
          ({
            key: 'delete_coupon',
            render: (props) => (
              <ActionCell
                action={(rowData) => setDeleteSelected(rowData as Coupon)}
                renderIcon={() => <DeleteOutlined />}
                {...props}
              />
            ),
          } as ColumnItem),
      ].filter(Boolean) as ColumnItem[],
    [baseColumns, isDeleteAllowed, isEditAllowed],
  )

  const onCreate = async (coupon: Partial<Coupon>) => {
    await api.common.create(Resources.coupons, { data: { ...coupon, user_id: recordId } })
    setVisible(false)
    setSelected(null)
    onFetchMemberCoupons(recordId)
  }

  const onUpdate = async (coupon: Partial<Coupon>) => {
    if (!selected) return
    await api.common.update(Resources.coupons, {
      id: selected.id,
      data: { ...coupon, user_id: recordId },
      previousData: selected,
    })
    setVisible(false)
    setSelected(null)
    onFetchMemberCoupons(recordId)
  }

  const onDelete = async () => {
    if (!deleteSelected) return
    await api.common.delete(Resources.coupons, { id: deleteSelected.id })
    setDeleteSelected(null)
    onFetchMemberCoupons(recordId)
  }

  return isReadAllowed ? (
    <TableSectionContainer
      title={title}
      buttonTitle={isCreateAllowed ? 'Add' : undefined}
      onButtonClick={() => setVisible(true)}
    >
      <PageSectionTableMaxHeight
        resource={resource}
        id="coupons-table"
        columns={columns}
        errorOnDataLoad={currentUserCouponsError}
        isLoading={currentUserCouponsLoading}
        data={currentUserCoupons}
        sortBy="status"
        sortByDirection={Order.asc}
        emptyDataMessage={emptyDataMessage}
      />
      <ResourceCouponsModal
        selected={selected}
        visible={visible}
        onClose={() => {
          setVisible(false)
          setSelected(null)
        }}
        onCreate={onCreate}
        onUpdate={onUpdate}
      />
      <Modal
        visible={!!deleteSelected}
        title="Delete confirmation"
        onOk={onDelete}
        onCancel={() => setDeleteSelected(null)}
      >
        Are you sure that you want to delete this coupon?
      </Modal>
    </TableSectionContainer>
  ) : null
}

export default ResourceCouponsTable
