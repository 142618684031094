import styled from '@emotion/styled'
import { TableCell as MuiTableCell, Table as MuiTable } from '@mui/material'
import { titleCellBackground } from '../../constants/styles-constants'

export const Table = styled(MuiTable)`
  margin-bottom: 4px;
  border: 1px solid #757575;
  border-bottom: 1.5px solid #757575;
`

export const TableCell = styled(MuiTableCell)`
  &:first-of-type {
    width: 250px;
    background-color: ${titleCellBackground};
  }
`
