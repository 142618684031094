import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import FilterListIcon from '@mui/icons-material/FilterList'

import { AccessibleTableHeaderFilterProps } from './acessible-table-types'
import { Checkbox, Dropdown, Menu } from 'antd'
import { getCellValue } from './accessible-table-cell'

const StyledMenu = styled(Menu)`
  max-height: 400px;
  overflow: auto;
`

const MenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item {
    padding: 0;
  }
`

const StyledFilterListIcon = styled(FilterListIcon)`
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
`

function AccessibleTableHeaderFilter({
  tableDataList,
  filterValues,
  onFilterChange,
  column,
}: AccessibleTableHeaderFilterProps) {
  const { key, formatter } = column
  const values = Array.from(new Set(tableDataList.map((rowData) => getCellValue({ rowData, column }))))
  const dropdownMenu = useMemo(
    () => (
      <StyledMenu
        onClick={({ domEvent }) => {
          domEvent.preventDefault()
          domEvent.stopPropagation()
        }}
      >
        {values.map((value) => (
          <MenuItem key={value}>
            <div onClick={(e) => e.stopPropagation()}>
              <Checkbox
                style={{ width: '100%', padding: '4px 12px' }}
                checked={filterValues.includes(value)}
                onChange={(e) => {
                  const checked = e.target.checked
                  const newFilterValues = checked ? [...filterValues, value] : filterValues.filter((v) => v !== value)
                  onFilterChange(key, newFilterValues)
                }}
              >
                {formatter?.(value) || value}
              </Checkbox>
            </div>
          </MenuItem>
        ))}
      </StyledMenu>
    ),
    [filterValues, formatter, key, onFilterChange, values],
  )

  return (
    <Dropdown trigger={['click']} overlay={dropdownMenu}>
      <StyledFilterListIcon />
    </Dropdown>
  )
}

export default AccessibleTableHeaderFilter
