import React, { useCallback, useState } from 'react'
import { Alert, AlertTitle, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useDialog } from 'src/hooks/useDialog'
import { ILogisticResponse, OrderTypes } from 'src/api/services/orders/types'

import { Order } from '../../components/order'
import { OrderSearch } from '../../components/order-search'
import { PlaceNewOrderDialog } from './place-new-order-dialog'
import { DraftOrderCreationAlert } from './draft-order-creation-alert'
import { useOrderRequest } from './useOrderRequest'
import { useCreateOrderRequest } from './useOrderCreationRequest'
import { useFormState } from './useFormState'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

export function CreateOrder() {
  const [orderType, setOrderType] = useState(OrderTypes.DELIVERY)
  const [newOrderFormVisible, setNewOrderFormVisible] = useState<boolean>(false)
  const orderRequest = useOrderRequest()
  const { formData, setFormData, resetFormState, setFormDataPart } = useFormState()

  const requestOrder = useCallback(
    async (name: string) => {
      setNewOrderFormVisible(true)
      if (!orderRequest.fetch) return
      const data = await orderRequest.fetch(name, orderType)
      setFormData(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderRequest.fetch, orderType],
  )

  const [orderFormIsValid, setOrderFormIsValid] = useState(false)
  const adminCreationRequest = useCreateOrderRequest(orderRequest.data)
  const [logistic, setLogistic] = useState<ILogisticResponse | null>(null)

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const placeNewOrderDialog = useDialog(PlaceNewOrderDialog)

  const cleanFields = useCallback(() => {
    setLogistic(null)
    resetFormState()
  }, [resetFormState])

  const createOrder = useCallback(async () => {
    if (adminCreationRequest.fetch) {
      const newLogistic = await adminCreationRequest.fetch(orderType, formData)
      if (newLogistic && newLogistic.hasOwnProperty('response')) {
        setLogistic(null)
        onDisplayErrorNotification(newLogistic.response.__error__)
      } else if (newLogistic) {
        if (orderType === OrderTypes.DELIVERY) {
          setLogistic(newLogistic)
          placeNewOrderDialog.show({
            orderId: formData.name,
            onConfirm: cleanFields,
          })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminCreationRequest.fetch, cleanFields, onDisplaySuccessNotification, formData, orderType])

  const onOrderTypeChange = useCallback(
    (type: OrderTypes) => {
      cleanFields()
      setOrderType(type)
    },
    [cleanFields],
  )

  const onSearchUpdated = useCallback(() => {
    setNewOrderFormVisible(false)
    setLogistic(null)
  }, [setNewOrderFormVisible, setLogistic])

  return (
    <div style={{ marginTop: 24 }}>
      <OrderSearch
        orderType={orderType}
        onChangeType={onOrderTypeChange}
        loading={!orderRequest.fetch || orderRequest.isLoading}
        requestOrder={requestOrder}
        onSearchUpdated={onSearchUpdated}
      />
      {orderRequest.error && (
        <Box sx={{ marginTop: 2 }}>
          <Alert severity="error">
            {orderRequest.error.message}
            <AlertTitle> Shopify order not found. </AlertTitle>
          </Alert>
        </Box>
      )}
      {newOrderFormVisible && orderRequest.data && (
        <>
          <Order formData={formData} onFormDataChange={setFormDataPart} onValidationChange={setOrderFormIsValid} />
          <Box sx={{ marginTop: 2 }}>
            <LoadingButton
              variant="contained"
              onClick={createOrder}
              loading={adminCreationRequest.isLoading}
              disabled={!orderFormIsValid}
            >
              Create Order
            </LoadingButton>
          </Box>
          {logistic && (
            <Box sx={{ marginTop: 2 }}>
              <DraftOrderCreationAlert orderId={logistic.id} />
            </Box>
          )}
        </>
      )}
      {placeNewOrderDialog.node}
    </div>
  )
}
