import httpClient from 'src/api/http-client'
import { API } from 'src/config'

import { OrderTypes } from '../types'

export interface IOrderServiceCode {
  code: string
  description: string
  type: OrderTypes[]
  product: string[]
}

export const requestServiceCodes = async (): Promise<IOrderServiceCode[]> => httpClient(`${API.orders}/service-code`)
