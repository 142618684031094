import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PersonalWorkout } from '../../constants/personal-workout-constants'
import { RequestError } from '../../api/http-client'
import { PersonalTrainer, PersonalTrainerAssignedMembers } from '../../constants/personal-trainer-constants'

export interface PersonalTrainerState {
  isLoading: boolean
  error: null | RequestError
  record: PersonalTrainer | null
  isAssignedMembersLoading: boolean
  assignedMembersError: null | RequestError
  assignedMembers: PersonalTrainerAssignedMembers[]
  isSessionsLoading: boolean
  sessionsError: null | RequestError
  sessions: PersonalWorkout[]
}

const initialState: PersonalTrainerState = {
  isLoading: false,
  error: null,
  record: null,
  isAssignedMembersLoading: false,
  assignedMembersError: null,
  assignedMembers: [],
  isSessionsLoading: false,
  sessionsError: null,
  sessions: [],
}

export const personalTrainerSlice = createSlice({
  name: 'personalTrainer',
  initialState,
  reducers: {
    startFetchingPersonalTrainer: (state) => {
      state.isLoading = true
      state.error = null
    },
    setPersonalTrainerRecord: (state, action: PayloadAction<PersonalTrainer>) => {
      state.isLoading = false
      state.error = null
      state.record = action.payload
    },
    setPersonalTrainerError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.isLoading = false
      state.record = null
    },
    startFetchingAssignedMembers: (state) => {
      state.isAssignedMembersLoading = true
      state.assignedMembersError = null
    },
    setAssignedMembers: (state, action: PayloadAction<PersonalTrainerAssignedMembers[]>) => {
      state.isAssignedMembersLoading = false
      state.assignedMembersError = null
      state.assignedMembers = action.payload
    },
    setAssignedMembersError: (state, action: PayloadAction<RequestError>) => {
      state.assignedMembersError = action.payload
      state.isAssignedMembersLoading = false
      state.assignedMembers = []
    },
    startFetchingSessions: (state) => {
      state.isSessionsLoading = true
      state.sessionsError = null
    },
    setSessions: (state, action: PayloadAction<PersonalWorkout[]>) => {
      state.isSessionsLoading = false
      state.sessionsError = null
      state.sessions = action.payload
    },
    setSessionsError: (state, action: PayloadAction<RequestError>) => {
      state.sessionsError = action.payload
      state.isSessionsLoading = false
      state.sessions = []
    },
    clearRecordData: (state) => {
      state.isLoading = false
      state.error = null
      state.record = null
      state.isSessionsLoading = false
      state.sessionsError = null
      state.sessions = []
    },
  },
})

export const {
  startFetchingPersonalTrainer,
  setPersonalTrainerRecord,
  setPersonalTrainerError,
  startFetchingAssignedMembers,
  setAssignedMembers,
  setAssignedMembersError,
  startFetchingSessions,
  setSessions,
  setSessionsError,
  clearRecordData,
} = personalTrainerSlice.actions

export default personalTrainerSlice.reducer
