import React from 'react'
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { useRequest } from 'src/hooks/use-request'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'
import { requestLatestStudioMetadata } from 'src/api/services/cloud/studios/requestLatestStudioMetadata'
import { getUniformDateTime } from 'src/utils/uniformDateDisplay'
import styled from '@emotion/styled'
import MetadataIcon from 'src/theme/icons/metadata.png'
import { titleCellBackground } from 'src/constants/styles-constants'

const StyledTableCell = styled(TableCell)`
  &:first-of-type {
    width: 250px;
    background-color: ${titleCellBackground};
  }
`

const HeaderCellContent = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  gap: 8px;
`

export const MetadataCardContent = ({ studioId }: { studioId: string | undefined }) => {
  const { data, isLoading, error } = useRequest(requestLatestStudioMetadata, studioId ? [studioId] : undefined)
  useRequestErrorHandler(error)
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <HeaderCellContent>
                <img src={MetadataIcon} style={{ width: '30px', height: '30px' }} />
                Metadata
              </HeaderCellContent>
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>{data.id}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Device ID</StyledTableCell>
              <StyledTableCell>{data.device_id}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Base Snap</StyledTableCell>
              <StyledTableCell>{data.base_snap}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>BIOS version</StyledTableCell>
              <StyledTableCell>{data.bios_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Camera left version</StyledTableCell>
              <StyledTableCell>{data.camera_left_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Camera right version</StyledTableCell>
              <StyledTableCell>{data.camera_right_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Microphone version</StyledTableCell>
              <StyledTableCell>{data.microphone_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Mirror services version</StyledTableCell>
              <StyledTableCell>{data.mirror_services_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Motor firmware left</StyledTableCell>
              <StyledTableCell>{data.motor_firmware_left}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Motor firmware right</StyledTableCell>
              <StyledTableCell>{data.motor_firmware_right}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Motor hardware</StyledTableCell>
              <StyledTableCell>{data.motor_hardware}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Snap revision</StyledTableCell>
              <StyledTableCell>{data.snap_revision}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Snap version</StyledTableCell>
              <StyledTableCell>{data.snap_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Station firmware</StyledTableCell>
              <StyledTableCell>{data.station_firmware}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Station hardware</StyledTableCell>
              <StyledTableCell>{data.station_hardware}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Station name</StyledTableCell>
              <StyledTableCell>{data.station_name}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Station serial</StyledTableCell>
              <StyledTableCell>{data.station_serial}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Touchscreen version</StyledTableCell>
              <StyledTableCell>{data.touchscreen_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>UI version</StyledTableCell>
              <StyledTableCell>{data.ui_version}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Created date</StyledTableCell>
              <StyledTableCell>{getUniformDateTime(data.created_date)}</StyledTableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Stack alignItems="center" style={{ paddingTop: 20 }}>
        {isLoading && <CircularProgress />}
        {!isLoading && !data && <div>No metadata</div>}
      </Stack>
    </div>
  )
}
