import { RequestQueryBuilder } from '@nestjsx/crud-request'

import { Resources } from '../../types/permissions-types'
import { HttpClient, RequestMethods } from '../http-client'
import { transformDataList } from '../../utils/data-transformation-utils'
import { composeSearch, mergeEncodedQueries, paramsSerializer } from '../utils'
import { GetListParams } from '../../types/request-entities-types'
import { TpiWorkflow, TpiWorkflowDetailsDto, TpiWorkflowState } from 'src/constants/tpi-constants'
import api from 'src/api'
import { Member } from 'src/constants/member-constants'

type TPIWorkflowSateProps = {
  workflow_state: TpiWorkflowState
}
const tpiApi = (baseUrl: string, httpClient: HttpClient) => ({
  getList: async (
    resource: Resources,
    params?: Partial<GetListParams>,
  ): Promise<{ total: number; data: TpiWorkflowDetailsDto[] }> => {
    const { q: queryParams, ...filter } = params?.filter || {}

    const encodedQueryParams = paramsSerializer(queryParams)
    const queryBuilder = RequestQueryBuilder.create(filter ? { search: composeSearch(filter) } : {})

    const query = mergeEncodedQueries(encodedQueryParams, queryBuilder.query())
    const requestUrl = `${baseUrl}/v1/tpi/admin/workflows?${query}`

    const response = (await httpClient(requestUrl)) as { total: number; data: TpiWorkflow[] }
    const { data, total } = response
    const usersIds = data.map((w) => w.user_id)
    const userDetails = usersIds.length
      ? ((await api.common.getList(Resources.members, { filter: { ['id||$in']: usersIds } })) as Member[])
      : []

    const lookup = userDetails.reduce<Map<string, Member>>((res, u) => res.set(u.id, u), new Map<string, Member>())
    return {
      data: transformDataList(
        resource,
        data.map((t) => {
          const u = lookup.get(t.user_id)
          return {
            ...t,
            first_name: u?.first_name,
            last_name: u?.last_name,
            email: u?.email,
            image: u?.image,
          }
        }),
      ),
      total: total,
    }
  },
  updateTPIWorkflow: (id: string, data: TPIWorkflowSateProps) => {
    return httpClient(`${baseUrl}/v1/tpi/admin/workflows/${id}`, {
      method: RequestMethods.patch,
      body: JSON.stringify(data),
    })
  },
})

export default tpiApi
