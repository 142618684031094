import * as React from 'react'
import { Modal } from 'src/antd-components'
import { FormeDialog } from 'src/hooks/useDialog'
import { ModalProps } from 'antd/lib/modal/Modal'

type Params = Omit<ModalProps, 'open' | 'onCancel'>

const ConfirmationDialog: FormeDialog<Params> = ({ params, open, onClose }) => (
  <Modal okText="Agree" open={open} onCancel={onClose} {...params} />
)

export default ConfirmationDialog
