import React, { useEffect } from 'react'

import { Divider, Form, Modal, Select, Space, Typography, useWatchAll } from 'src/antd-components'

import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { useAction } from '../../../hooks/use-actions'
import { BillingGroup } from '../../../types/billing-groups'
import { RequestError } from '../../../api/http-client'
import { PersonalTrainer, PersonalTrainerAssignedMembers } from '../../../constants/personal-trainer-constants'
import { getErrorMessage } from '../../../utils/errors'
import { setAssignedMembers } from '../../../redux/personal-trainer/personal-trainer-reducer'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import Loader from '../../../components/loader'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import { FormeDialog } from '../../../hooks/useDialog'

type AssignClientToPersonalTrainer = {
  record?: PersonalTrainer
  billingGroups: BillingGroup[]
  personalTrainerId: string
  assignedClient?: PersonalTrainerAssignedMembers
  assignedMembers: PersonalTrainerAssignedMembers[]
}

const defaultRecord = { id: '', billing_groups: [], clients: [] }

type FormData = { billingGroupId?: string }
const initialValues: FormData = {}

const EditAssignedClientBillingGroupDialog: FormeDialog<AssignClientToPersonalTrainer> = (props) => {
  const {
    params: { personalTrainerId, assignedClient, billingGroups, record = defaultRecord, assignedMembers },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)

  const onUpdateAssignedMembersRecord = useAction(setAssignedMembers)

  const billingGroupsChoices = billingGroups.map((item) => ({
    value: item.id,
    label: `${item.name} ($${item.rate_30m} / $${item.rate_60m})`,
  }))
  const clientBillingGroupId = assignedClient?.personal_trainer_billing_group_id ?? undefined

  const [form] = Form.useForm<FormData>()
  const { billingGroupId } = useWatchAll(form, initialValues)

  const isFormValid = billingGroupId && billingGroupId !== clientBillingGroupId

  useEffect(() => {
    if (clientBillingGroupId) {
      form.setFieldValue('billingGroupId', clientBillingGroupId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientBillingGroupId])

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed || !assignedClient?.user_id) {
    return null
  }

  const onEditClientBillingGroup = () => {
    const commonData = { id: personalTrainerId, user_id: assignedClient.user_id }
    const params = {
      id: personalTrainerId,
      previousData: { ...commonData, billing_group_id: clientBillingGroupId },
      data: { ...commonData, billing_group_id: billingGroupId },
      subUrl: `clients/${assignedClient.user_id}`,
    }

    api.common
      .update(Resources.personalTrainers, params)
      .then(() => {
        const newBillingGroup = record.billing_groups.find((item: { id: string }) => billingGroupId === item.id)
        const updatedMembers = assignedMembers.map((member) =>
          member.user_id === assignedClient.user_id && newBillingGroup
            ? {
                ...member,
                personal_trainer_billing_group_id: newBillingGroup.id,
                billing_group_name: newBillingGroup.name,
              }
            : member,
        )
        onUpdateAssignedMembersRecord(updatedMembers)
        onDisplaySuccessNotification('Billing group was successfully updated')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
    onClose()
  }

  return (
    <Modal
      title="Change assigned client billing group"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid }}
      onOk={onEditClientBillingGroup}
      onCancel={onClose}
    >
      <Typography.Title level={5}>Assigned Client:</Typography.Title>
      <Space direction="vertical">
        <Typography.Text copyable>{`${assignedClient.first_name} ${assignedClient.last_name}`}</Typography.Text>
        <Typography.Link copyable href={`mailto:${assignedClient.email}`}>
          {assignedClient.email}
        </Typography.Link>
      </Space>
      <Divider />
      <Form form={form} initialValues={initialValues} labelAlign="right" layout="vertical">
        <Form.Item label="Billing Group" name="billingGroupId">
          <Select options={billingGroupsChoices} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditAssignedClientBillingGroupDialog
