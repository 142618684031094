import { HttpClient } from '../http-client'

export type Get1on1InvoicesParams = {
  member_id: string
  starting_after?: string
  ending_before?: string
  limit?: string
}

export type FailedPaymentsParams = {
  member_id?: string
  starting_after?: string
  ending_before?: string
  limit?: string
}

const invoicesApi = (baseUrl: string, httpClient: HttpClient) => ({
  getFailedPayments: (params?: FailedPaymentsParams) => {
    const urlSearch = new URLSearchParams(params).toString()
    return httpClient(`${baseUrl}/v1/invoices/failedPayments?${urlSearch}`)
  },
})

export default invoicesApi
