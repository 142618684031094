import React, { useCallback, useMemo } from 'react'
import { Stack } from '@mui/material'

import { Resources } from 'src/types/permissions-types'
import { getButtonDataQa } from 'src/utils/entities-data-qa-utils'
import { snakeCaseToText } from 'src/utils/snakeCaseToText'
import {
  AvailableDeviceMessages,
  requestAvailableDeviceMessages,
} from 'src/api/services/cloud/studio-assets/requestAvailableDeviceMessages'
import { requestSendingDeviceMessages } from 'src/api/services/cloud/firebase-messages/requestSendingDeviceMessages'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { Query, useRequest } from 'src/hooks/use-request'
import ActionsDropdownMenu from 'src/components/actions-dropdown'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import { MembershipProfile } from 'src/types/member-profile'

export const Content = ({
  membership,
  availableDeviceMessages,
}: {
  membership: MembershipProfile
  availableDeviceMessages: AvailableDeviceMessages
}) => {
  const { onDisplaySuccessNotification } = useSnackbarNotifications()
  const { fetch: fetchSendingDeviceMessagesRequest, error: sendDeviceMessagesRequestError } =
    useRequest(requestSendingDeviceMessages)
  useRequestErrorHandler(sendDeviceMessagesRequestError)

  const sendMessage = useCallback(
    async (message: string) => {
      await fetchSendingDeviceMessagesRequest([
        {
          userId: membership.userId,
          deviceId: `STUDIO-${membership.id}`,
          message,
        },
      ])
      onDisplaySuccessNotification(`Message "${message}" has been sent to studio`)
    },
    [fetchSendingDeviceMessagesRequest, membership, onDisplaySuccessNotification],
  )

  const actionsConfig = useMemo(
    () => [
      availableDeviceMessages['STUDIO'].map((message) => ({
        title: snakeCaseToText(message),
        onClick: () => sendMessage(message),
        dataQa: getButtonDataQa(Resources.studios, `sendMessageToStudio/${message}`),
      })),
    ],
    [availableDeviceMessages, sendMessage],
  )

  return (
    <Stack style={{ marginTop: 20 }} direction="row" justifyContent="space-between" alignItems="center">
      <ActionsDropdownMenu title="Send message to studio" actionsConfig={actionsConfig} />
    </Stack>
  )
}

export const DeviceMessagesMenu = ({
  membershipQuery,
}: {
  membershipQuery: Query<[id: string], MembershipProfile>
}) => {
  const { isEditAllowed } = useResourcePermissionHandler(Resources.deviceMessage)
  const { data: availableDeviceMessages } = useRequest(requestAvailableDeviceMessages, [])
  if (!(isEditAllowed && availableDeviceMessages && membershipQuery.data)) return null
  return <Content membership={membershipQuery.data} availableDeviceMessages={availableDeviceMessages} />
}
