import React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import styled from '@emotion/styled'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SxProps } from '@mui/system'

export type ChoiceItem = {
  id: string
  label: string
}

type SelectInputProps = {
  id: string
  value: string | undefined
  label?: string
  sx?: SxProps
  placeholder?: string
  withLabel?: boolean
  disabled?: boolean
  choices: ChoiceItem[]
  onSelect?: (itemId: string) => void
}

const Placeholder = styled.span`
  color: rgba(0, 0, 0, 0.35);
`

const StyledBox = styled(Box)`
  width: 100%;
`

const convertBoldString = (value: string) => {
  if (value.indexOf('<b>') >= 0) {
    const firstPart = value.substring(0, value.indexOf('<b>'))
    const secondPart = value.substring(value.indexOf('<b>') + 3, value.indexOf('</b>'))
    const thirdPart = value.substring(value.indexOf('</b>') + 4, value.length)
    const result = (
      <span>
        {firstPart}
        <b>{secondPart}</b>
        {thirdPart}
      </span>
    )
    return result
  }
  return value
}

function SelectInput(props: SelectInputProps) {
  const { id, value, label, sx, placeholder, choices, disabled = false, onSelect } = props
  const labelId = `${id}/label`
  const selectedItem = choices.find((item) => item.id === value)

  const onChange = (event: SelectChangeEvent<unknown>) => {
    if ((event.target.value as string) !== value) {
      onSelect?.(event.target.value as string)
    }
  }

  return (
    <StyledBox>
      <FormControl fullWidth>
        {label && <InputLabel id={labelId}>{label}</InputLabel>}

        <Select
          displayEmpty
          disabled={disabled}
          id={id}
          data-qa={id}
          labelId={labelId}
          sx={sx}
          value={value}
          label={label}
          size="small"
          input={<OutlinedInput />}
          renderValue={() => {
            if (selectedItem) {
              return convertBoldString(selectedItem.label)
            }
            return <Placeholder>{placeholder}</Placeholder>
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={onSelect ? onChange : undefined}
        >
          {choices.map((item) => {
            const labelToShow = convertBoldString(item.label)
            return (
              <MenuItem key={item.id} value={item.id}>
                {labelToShow}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </StyledBox>
  )
}

export default SelectInput
