import React, { ChangeEvent, memo, ReactElement, useState } from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { OrderTypes } from 'src/api/services/orders/types'
import { useRequest } from 'src/hooks/use-request'
import api from 'src/api'
import { RelatedOrders } from './related-orders'

interface IOrderSearch {
  orderType: OrderTypes
  onChangeType: (type: OrderTypes) => void
  loading: boolean
  requestOrder: (orderName: string) => Promise<any>
  onSearchUpdated: () => void
}

function OrderSearch({
  orderType,
  onChangeType,
  loading,
  requestOrder,
  onSearchUpdated,
}: IOrderSearch): ReactElement<IOrderSearch> {
  const [searchValue, setSearchValue] = useState('')
  const [showRelatedOrders, setShowRelatedOrders] = useState(false)

  const relatedOrders = useRequest(api.orders.getOrders)
  const loadOrder = async () => {
    try {
      await requestOrder(searchValue)
      setShowRelatedOrders(false)
    } catch (e) {}
  }

  const lookUp = async () => {
    onSearchUpdated()
    await relatedOrders.fetch({ shopify_order_name: searchValue })
    setShowRelatedOrders(true)
  }

  return (
    <Box component="form">
      <FormControl>
        <InputLabel id="order-type-label">Order Type</InputLabel>
        <Select
          labelId="order-type-label"
          label="Order Type"
          sx={{
            textTransform: 'capitalize',
          }}
          value={orderType}
          onChange={(ev: SelectChangeEvent) => onChangeType(ev.target.value as OrderTypes)}
          autoWidth
          disabled={loading}
        >
          {Object.values(OrderTypes).map((key) => (
            <MenuItem key={key} value={key} sx={{ textTransform: 'capitalize' }}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ marginLeft: 2 }} required>
        <TextField
          onChange={(ev: ChangeEvent<HTMLInputElement>) => setSearchValue(ev.target.value)}
          id="demo-helper-text-misaligned"
          label="Shopify order"
          disabled={loading}
          value={searchValue}
        />
      </FormControl>
      <FormControl sx={{ marginLeft: 2 }}>
        <LoadingButton variant="contained" onClick={lookUp} loading={relatedOrders.isLoading}>
          Look Up
        </LoadingButton>
      </FormControl>
      {showRelatedOrders && relatedOrders.data && (
        <RelatedOrders
          orders={relatedOrders.data}
          handleCreateButtonClick={loadOrder}
          orderCreationIsInProgress={loading}
        />
      )}
    </Box>
  )
}

export default memo(OrderSearch)
