import React from 'react'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'

import { NOOP } from '../constants/common-constants'
import TextButton from './buttons/text-button'

const Container = styled(Grid)`
  width: 100%;
`

const HeaderSection = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0 8px 0;
`

const Title = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
`

const SectionContent = styled(Grid)`
  display: flex;
  flex-direction: column;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #000;
  opacity: 0.5;
  margin: 0;
`

const DividerWithOffset = styled(Divider)`
  margin-top: 0;
  margin-bottom: 15px;
`

const ShowAllButton = styled.span`
  margin-left: 12px;
`

type TableSectionProps = {
  title?: string
  buttonTitle?: string
  className?: string
  withAction?: boolean
  withShowAllToggleButton?: boolean
  showAll?: boolean
  children: React.ReactNode
  onShowAllClick?: () => void
  onButtonClick?: () => void
  withDivider?: boolean
  withDividerThin?: boolean
}

function TableSectionContainer(props: TableSectionProps) {
  const {
    title,
    buttonTitle,
    className,
    children,
    withAction = true,
    withShowAllToggleButton = false,
    showAll = false,
    onShowAllClick = NOOP,
    onButtonClick = NOOP,
    withDivider,
    withDividerThin,
  } = props
  return (
    <Container container className={className}>
      <HeaderSection item xs={12}>
        <div>
          {title && <Title>{title}</Title>}
          {withShowAllToggleButton && (
            <ShowAllButton>
              <TextButton onClick={onShowAllClick}>{showAll ? 'Show Less' : 'Show All'}</TextButton>
            </ShowAllButton>
          )}
        </div>
        {withAction && <TextButton onClick={onButtonClick}>{buttonTitle}</TextButton>}
      </HeaderSection>
      {withDivider && <DividerWithOffset />}
      {withDividerThin && <Divider />}
      <SectionContent item xs={12}>
        {children}
      </SectionContent>
    </Container>
  )
}

export default TableSectionContainer
