import { MemberProfile } from 'src/types/member-profile'
import { BillingPromotion } from './billing-promotion-constants'
import { PersonalTrainerLevel } from './personal-trainer-constants'

export enum UserRole {
  admin = 'admin',
  contentManager = 'contentManager',
  trainer = 'trainer',
  technician = 'technician',
  user = 'user',
  oneToOneClient = 'oneToOneClient',
  contentPreviewer = 'contentPreviewer',
  concierge = 'concierge',
  mobileOneToOneClient = 'mobileOneToOneClient',
  tpiClient = 'tpiClient',
}

export enum UserGender {
  female = 'female',
  intersex = 'intersex',
  male = 'male',
  preferNotToSay = 'prefer not to say',
}

export const userGenderChoicesList = [
  { id: UserGender.female, name: 'Female' },
  { id: UserGender.intersex, name: 'Intersex' },
  { id: UserGender.male, name: 'Male' },
  { id: UserGender.preferNotToSay, name: 'Prefer not to say' },
]

export const emergencyContactRelationshipChoices = [
  { id: 'parent', label: 'Parent' },
  { id: 'child', label: 'Child' },
  { id: 'partner', label: 'Partner' },
  { id: 'spouse', label: 'Spouse' },
  { id: 'friend', label: 'Friend' },
  { id: 'caregiver', label: 'Caregiver' },
]

export enum MemberTabKeys {
  memberOverviewContentTab = 'memberOverviewContentTab',
  memberOneOnOneContentTab = 'memberOneOnOneContentTab',
  memberCPSTab = 'memberCPSTab',
  memberUserChargesTab = 'memberUserChargesTab',
}

export enum MemberPageSubRoutes {
  overview = 'overview',
  '1-on-1' = '1-on-1',
  cps = 'cps',
  'user-charges' = 'user-charges',
}

export const memberTabSubRoutesToKeysMap: any = {
  [MemberPageSubRoutes.overview]: MemberTabKeys.memberOverviewContentTab,
  [MemberPageSubRoutes['1-on-1']]: MemberTabKeys.memberOneOnOneContentTab,
  [MemberPageSubRoutes['cps']]: MemberTabKeys.memberCPSTab,
  [MemberPageSubRoutes['user-charges']]: MemberTabKeys.memberUserChargesTab,
}

export const memberTabKeysToSubRoutesMap: any = {
  [MemberTabKeys.memberOverviewContentTab]: MemberPageSubRoutes.overview,
  [MemberTabKeys.memberOneOnOneContentTab]: MemberPageSubRoutes['1-on-1'],
  [MemberTabKeys.memberCPSTab]: MemberPageSubRoutes['cps'],
  [MemberTabKeys.memberUserChargesTab]: MemberPageSubRoutes['user-charges'],
}

type Note = {
  created_date: string
  note: string
  updated_by: string
  updated_date: string
  user_id: string
}

export type Member = {
  id: string
  email: string
  first_name: string
  last_name: string
  roles: UserRole[]
  zip_code: string
  timezone: string
  date_of_birth: string | null
  gender: UserGender
  max_heart_rate: number | null
  resting_heart_rate: number | null
  vo2_max: number | null
  height: number | null
  weight: number | null
  avatar?: string | null
  image?: string | null
  membershipProfiles: MemberProfile[]
  msa_note: Note | null
  personal_trainer_billing_group?: Record<string, any>
  personal_trainer_billing_groups: Record<string, any>[]
  emergency_address: string
  emergency_contact_first_name: string
  emergency_contact_last_name: string
  emergency_contact_relationship: string
  emergency_contact_phone: string
  concierge_id: string | null | undefined
  stripe_customer_id: string
  promotion: BillingPromotion[]
  level: PersonalTrainerLevel
  created_date: string
  updated_date: string
  agora_int_uid: any
  require_cc: boolean
}

export const ALLOWED_ASSIGN_PROFILE_MEMBERSHIP_NAME = ['digital', 'digital bridge']
