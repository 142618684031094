import { HttpClient, RequestMethods } from 'src/api/http-client'

type TrainerPayRateProps = {
  pay_rate: number
  start_date?: string
}

const trainerApi = (baseUrl: string, httpClient: HttpClient) => ({
  getPayRatesDates: () => {
    return httpClient(`${baseUrl}/personal-trainers/pay-rate-dates?count=2`)
  },

  getTrainerPayRate: (trainerId: string) => {
    return httpClient(`${baseUrl}/personal-trainers/${trainerId}/pay-rate`, {
      method: RequestMethods.get,
    })
  },
  updateTrainerPayRate: (trainerId: string, data: TrainerPayRateProps) => {
    return httpClient(`${baseUrl}/personal-trainers/${trainerId}/pay-rate`, {
      method: RequestMethods.patch,
      body: JSON.stringify(data),
    })
  },
})

export default trainerApi
