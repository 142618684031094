import React from 'react'
import styled from '@emotion/styled'
import { Card, CardContent, CardActions, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

type StubPageProps = {
  mainMessage: string
  secondaryMessage?: string
  buttonMessage?: string
  buttonTitle?: string
  onClick?: () => void
}

const Root = styled(Card)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${(_) => _.theme.spacing(16)};
  padding-bottom: ${(_) => _.theme.spacing(16)};
`

const Main = styled(Container)<{ component: 'main' }>`
  text-align: center;
  margin-top: ${(_) => _.theme.spacing(8)};
  margin-bottom: ${(_) => _.theme.spacing(2)};
`

const CentredSection = styled(CardActions)`
  display: flex;
  justify-content: center;
`

export default function StubPage(props: StubPageProps) {
  const { mainMessage, secondaryMessage, buttonMessage, buttonTitle, onClick } = props
  return (
    <Main component="main" maxWidth="md">
      <Root>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            {mainMessage}
          </Typography>
        </CardContent>

        <CardContent>
          {secondaryMessage && (
            <Typography variant="h5" component="h2" gutterBottom>
              {secondaryMessage}
            </Typography>
          )}
          {buttonMessage && (
            <Typography variant="h5" component="h2" gutterBottom>
              {buttonMessage}
            </Typography>
          )}
        </CardContent>

        <CentredSection>
          {buttonTitle && (
            <Button disabled={!onClick} variant="outlined" color="primary" onClick={onClick}>
              {buttonTitle}
            </Button>
          )}
        </CentredSection>
      </Root>
    </Main>
  )
}
