import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Resources } from '../../types/permissions-types'
import { useAction } from '../../hooks/use-actions'
import { getErrorMessage } from '../../utils/errors'
import { usePreviousValue } from '../../hooks/use-previous-value'
import { getPersonalTrainerError } from '../../redux/personal-trainer/personal-trainer-selectors'
import { clearRecordData } from '../../redux/personal-trainer/personal-trainer-reducer'
import {
  fetchPersonalTrainerAssignedMembers,
  fetchPersonalTrainerRecord,
  fetchPersonalTrainerSessions,
} from '../../redux/personal-trainer/personal-trainer-actions'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'

/**
 *
 */
export const usePersonalTrainerData = (recordId?: string) => {
  const onClearRecordData = useAction(clearRecordData)
  const onFetchPersonalTrainerRecord = useAction(fetchPersonalTrainerRecord)
  const onFetchPersonalTrainerSessions = useAction(fetchPersonalTrainerSessions)
  const onFetchPersonalTrainerAssignedMembers = useAction(fetchPersonalTrainerAssignedMembers)

  const personalTrainerError = useSelector(getPersonalTrainerError)
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const { isReadAllowed } = useResourcePermissionHandler(Resources.personalTrainers)

  const prevIsReadAllowed = usePreviousValue(isReadAllowed)

  useEffect(() => {
    const isReadAllowedChanged = isReadAllowed !== prevIsReadAllowed

    if (recordId && isReadAllowed && isReadAllowedChanged) {
      onFetchPersonalTrainerRecord(recordId)
      onFetchPersonalTrainerSessions(recordId)
      onFetchPersonalTrainerAssignedMembers(recordId)
    }

    return () => onClearRecordData()
  }, [
    recordId,
    isReadAllowed,
    prevIsReadAllowed,
    onFetchPersonalTrainerRecord,
    onFetchPersonalTrainerSessions,
    onFetchPersonalTrainerAssignedMembers,
    onClearRecordData,
  ])

  useEffect(() => {
    if (!!personalTrainerError) {
      onDisplayErrorNotification(getErrorMessage(personalTrainerError))
    }
  }, [personalTrainerError, onDisplayErrorNotification])
}
