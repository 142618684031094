import httpClient from 'src/api/http-client'
import { API } from '../../../config'
import { PartialExcept } from '../../../utils/advanced-types'
import { IOrder } from 'shopify-api-node'
import { AddressType, OrderAddress } from './types'

export type ShopifyOrder = Omit<PartialExcept<IOrder, 'name' | 'email' | 'line_items'>, 'shipping_address'> & {
  address_type: AddressType
  shipping_address: OrderAddress
}

export const requestShopifyOrder = async (name: string): Promise<ShopifyOrder> => {
  const order: ShopifyOrder = await httpClient(`${API.orders}/order/shopify`, { params: { name } })
  if (!order.address_type) {
    order.address_type = 'Residence'
  }
  return order
}
