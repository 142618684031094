import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCPSSchedule, getCPSScheduleError, getIsCPSScheduleLoading } from 'src/redux/member/member-selectors'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { PageSectionTableMaxHeight } from '../components/ui-kit'
import TableSectionContainer from '../components/table-section-container'
import { Order } from 'src/types/common-types'

type ResourceCPSTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
}

function ResourceCPSScheduleTable(props: ResourceCPSTableProps) {
  const { title, resource, columns, emptyDataMessage } = props
  const tableId = `${resource}-table`

  const cpsSchedule = useSelector(getCPSSchedule)
  const cpsScheduleError = useSelector(getCPSScheduleError)
  const isCPSScheduleLoading = useSelector(getIsCPSScheduleLoading)
  const [showAllWorkouts, setshowAllWorkouts] = useState(false)
  const buttonText = showAllWorkouts ? 'Show Less' : 'Show All'
  const cpsScheduleData = useMemo(
    () => (showAllWorkouts ? cpsSchedule : [...cpsSchedule.slice(0, 5)]),
    [cpsSchedule, showAllWorkouts],
  )

  const updateshowAllWorkouts = () => setshowAllWorkouts(!showAllWorkouts)

  return (
    <TableSectionContainer
      title={title}
      buttonTitle={cpsSchedule!.length > 5 ? buttonText : undefined}
      onButtonClick={updateshowAllWorkouts}
    >
      <PageSectionTableMaxHeight
        id={tableId}
        columns={columns}
        resource={resource}
        errorOnDataLoad={cpsScheduleError}
        isLoading={isCPSScheduleLoading}
        data={cpsScheduleData}
        sortBy="start_datetime"
        sortByDirection={Order.desc}
        emptyDataMessage={emptyDataMessage}
      />
    </TableSectionContainer>
  )
}

export default ResourceCPSScheduleTable
