import React, { useMemo } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'

import { Resources } from 'src/types/permissions-types'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { routing } from 'src/boot/routing'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { getButtonDataQa } from 'src/utils/entities-data-qa-utils'

import { DialogsController } from './useDialogsController'
import { ActionCell, ComponentCell, DataCell, DateCell } from '../../components/accessible-table/accessible-table-cell'
import AdditionalActionsMenu, { AdditionalActionsMenuConfig } from 'src/components/table-row-additional-actions-menu'
import { getClientTimezone } from 'src/api/utils'

export const useAssignedMembersTableColumns = (dialogsController: DialogsController): ColumnItem[] => {
  const { onEditAssignedClient, onOpenRemoveAssignedMemberDialog } = dialogsController
  const { isEditAllowed: isAssignedMembersEditAllowed, isDeleteAllowed: isAssignedMembersDeleteAllowed } =
    useResourcePermissionHandler(Resources.trainerMember)
  const { isReadAllowed: isMemberReadAllowed } = useResourcePermissionHandler(Resources.members)

  return useMemo(() => {
    const additionalActionsConfig: AdditionalActionsMenuConfig[] = [
      {
        icon: <EditIcon />,
        title: 'Edit Assigned Member',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'editAssignedMember'),
        onClick: (rowData?: { [key: string]: any }) => onEditAssignedClient(rowData ?? {}),
      },
      {
        icon: <CancelPresentationIcon />,
        title: 'Remove Assigned Member',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'removeAssignedMember'),
        onClick: (rowData?: { [key: string]: any }) => onOpenRemoveAssignedMemberDialog(rowData ?? {}),
      },
    ]

    const assignedMembersTableColumns: ColumnItem[] = [
      {
        key: 'name',
        title: 'Name',
        fields: ['first_name', 'last_name'],
        render: DataCell,
        orderByField: 'first_name',
        link: isMemberReadAllowed
          ? (row: { user_id: string }) => routing.member.generatePath({ id: row.user_id })
          : undefined,
      },
      { key: 'email', title: 'Email', fields: ['email'], render: DataCell, orderByField: 'email' },
      {
        key: 'billing_group_name',
        title: 'Billing Group',
        fields: ['billing_group_name'],
        render: DataCell,
        orderByField: 'billing_group_name',
      },
      {
        key: 'assigned_date',
        title: 'Assigned Date',
        fields: ['assigned_date'],
        render: (props) => (
          <DateCell
            options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
            {...props}
          />
        ),
        orderByField: 'assigned_date',
      },
    ]

    if (isAssignedMembersEditAllowed && isAssignedMembersDeleteAllowed) {
      assignedMembersTableColumns.push({
        key: 'threeDotActionsMenu',
        render: (props) => {
          return (
            <ComponentCell {...props}>
              <AdditionalActionsMenu rowData={props?.rowData} actionsConfig={additionalActionsConfig} />
            </ComponentCell>
          )
        },
      })
    } else if (isAssignedMembersEditAllowed) {
      assignedMembersTableColumns.push({
        key: 'editAssignedClient',
        render: (props) => <ActionCell action={onEditAssignedClient} renderIcon={() => <EditIcon />} {...props} />,
      })
    } else if (isAssignedMembersDeleteAllowed) {
      assignedMembersTableColumns.push({
        key: 'removeAssignedClient',
        render: (props) => (
          <ActionCell
            action={onOpenRemoveAssignedMemberDialog}
            renderIcon={() => <CancelPresentationIcon />}
            {...props}
          />
        ),
      })
    }

    return assignedMembersTableColumns
  }, [
    isAssignedMembersDeleteAllowed,
    isAssignedMembersEditAllowed,
    isMemberReadAllowed,
    onEditAssignedClient,
    onOpenRemoveAssignedMemberDialog,
  ])
}
