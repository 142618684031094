import React, { useEffect, useMemo, useState } from 'react'
import { createStructuredSelector } from 'reselect'
import { useSelector } from 'react-redux'

import {
  getIsMemberPromotionsLoading,
  getIsMemberStudiosLoading,
  getIsPromotionsError,
  getPromotions,
  getStudiosError,
  getStudios,
} from 'src/redux/member/member-selectors'
import { Resources } from 'src/types/permissions-types'
import TableSectionContainer from 'src/components/table-section-container'
import { getFieldDataQa } from 'src/utils/entities-data-qa-utils'
import AccessibleTable from 'src/components/accessible-table/accessible-table'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { PROMOTIONS_EMPTY_DATA_MESSAGE } from 'src/constants/messages'
import { formatPromotionDate } from 'src/utils/date-utils'
import { BillingPromotion } from '../constants/billing-promotion-constants'
import { Member } from 'src/constants/member-constants'
import useIsMounted from 'src/hooks/use-is-mounted'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { IOrder } from 'src/api/services/orders/types'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import api from 'src/api'
import extractInstallDate from 'src/utils/extract-install-date'

type PromotionInfoContainerProps = {
  record: Member | null
  promotionsTableColumns: ColumnItem[]
  isPromotionsCreateAllowed: boolean
  showCreatePromotionDialog: () => void
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const promotionsState = createStructuredSelector({
  isPromotionsLoading: getIsMemberPromotionsLoading,
  promotions: getPromotions,
  promotionsError: getIsPromotionsError,
  isStudiosLoading: getIsMemberStudiosLoading,
  studios: getStudios,
  studiosError: getStudiosError,
})

const ResourcePromotionInfoContainer = (props: PromotionInfoContainerProps) => {
  const { record, promotionsTableColumns, showCreatePromotionDialog, setIsLoading } = props
  const { isPromotionsLoading, promotionsError, promotions, isStudiosLoading, studiosError } =
    useSelector(promotionsState)

  useEffect(() => {
    setIsLoading(isPromotionsLoading || isStudiosLoading)
  }, [setIsLoading, isPromotionsLoading, isStudiosLoading])

  const isMounted = useIsMounted()
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const [installDate, setInstallDate] = useState<string>('')

  useEffect(() => {
    if (isMounted && record?.email) {
      api.orders
        .getOrdersByEmail(record?.email)
        .then((res: IOrder[]) => setInstallDate(extractInstallDate(res)))
        .catch((e: RequestError) => {
          const errorMessage = getErrorMessage(e)
          onDisplayErrorNotification(errorMessage)
          setInstallDate('Error fetching install date')
        })
    }
  }, [record?.email, isMounted, onDisplayErrorNotification])

  const decoratedPromotionsList: BillingPromotion[] = useMemo(
    () =>
      promotions?.length
        ? promotions.map((promotionItem) => ({
            id: promotionItem.id,
            name: promotionItem.promotion?.name,
            date_added: formatPromotionDate(promotionItem.created_date || ''),
            install_date: installDate,
            term:
              promotionItem.promotion?.length_in_months && promotionItem.promotion?.length_in_months > 0
                ? `${promotionItem.promotion?.length_in_months} ${
                    promotionItem.promotion?.length_in_months === 1 ? 'month' : 'months'
                  }`
                : null,
            consumed: promotionItem.consumed,
          }))
        : [],
    [promotions, installDate],
  )

  return promotions.length ? (
    <TableSectionContainer title="Promotions">
      <AccessibleTable
        id={getFieldDataQa(Resources.billingPromotions, '/promotions-list-table')}
        resource={Resources.billingPromotions}
        data={decoratedPromotionsList}
        sortBy="id"
        columns={promotionsTableColumns}
        errorOnDataLoad={promotionsError || studiosError}
        isLoading={isPromotionsLoading || isStudiosLoading}
        emptyDataMessage={PROMOTIONS_EMPTY_DATA_MESSAGE}
        withEmptyDataActionButton={false}
        onEmptyDataActionButtonClick={showCreatePromotionDialog}
      />
    </TableSectionContainer>
  ) : null
}

export default ResourcePromotionInfoContainer
