import React, { MutableRefObject, useEffect } from 'react'

/**
 *
 */
const useIsMounted = (): MutableRefObject<any> => {
  const isMounted = React.useRef(true)

  useEffect(
    () => (): void => {
      isMounted.current = false
    },
    [],
  )

  return isMounted
}

export default useIsMounted
