import React, { useCallback, useMemo, useState, VFC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
type ParamsBase = {} | void

export type FormeDialog<Params extends ParamsBase = void> = VFC<{
  open: boolean
  onClose: () => void
  params: Params
}>

export const useDialog = <Params extends ParamsBase = void>(Component: FormeDialog<Params>) => {
  const [state, setState] = useState<{ open: boolean; params: Params }>()
  const show = useCallback((params: Params) => setState({ open: true, params }), [])
  const hide = useCallback(() => setState((s) => ({ ...s!, open: false })), [])
  const node = useMemo(
    () => (state ? <Component open={state.open} onClose={hide} params={state.params} /> : undefined),
    [state, Component, hide],
  )
  return { node, show, hide }
}
