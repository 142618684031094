import { styled, Button } from '@mui/material'
import { mainColor, secondaryColor } from '../../constants/styles-constants'

const CancelButton = styled(Button)({
  padding: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 8,
  marginRight: 4,
  marginLeft: 4,
  color: mainColor,
  backgroundColor: secondaryColor,
  textTransform: 'capitalize',
  '&:hover, &:active, &:focus': {
    color: mainColor,
    backgroundColor: secondaryColor,
  },
})

export default CancelButton
