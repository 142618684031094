import React from 'react'
import { Container, Typography } from '@mui/material'

export const ErrorFallback = ({ error }: { error: Error }) => (
  <Container>
    <Typography sx={{ paddingTop: 2 }} variant="h3">
      Something went wrong
    </Typography>
    <Typography sx={{ paddingTop: 4 }} variant="h5">
      {error.message}
    </Typography>
    <pre>{error.stack}</pre>
  </Container>
)
