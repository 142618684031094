import React, { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'

import { Modal, Search, Table } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'

import api from 'src/api'
import { RequestError } from 'src/api/http-client'
import Loader from 'src/components/loader'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from 'src/types/permissions-types'
import { getErrorMessage } from 'src/utils/errors'

import { requestLinkAccount } from 'src/api/services/cloud/users/requestLinkAccount'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { FormeDialog } from 'src/hooks/useDialog'
import { ColumnsType, RowSelectionType } from 'antd/lib/table/interface'
import {
  combineSearchFilters,
  createFullNameSearchFilter,
  createRegularSearchFilter,
} from 'src/containers/resource-list-container/filters'

const StyledLabel = styled.h6`
  margin: 0;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`

const StyledTableWrapp = styled.div`
  margin-top: 15px;
`
const scroll = { y: 480 }

const clientsTableColumns: ColumnsType<Member> = [
  {
    key: 'name',
    title: 'Name',
    render: (_, member) => `${member.first_name} ${member.last_name}`,
    sorter: (first, second) => first.first_name.localeCompare(second.first_name),
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    sorter: (first, second) => first.email.localeCompare(second.email),
  },
]

const searchFilter = combineSearchFilters(
  createFullNameSearchFilter('first_name', 'last_name'),
  createRegularSearchFilter(['first_name', 'last_name', 'email']),
)

export const LinkAccountDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading } = useResourcePermissionHandler(Resources.personalData)
  const [searchInputValue, setSearchInput] = React.useState('')
  const [selectedClientId, setClientId] = React.useState<string>('')
  const [clientsList, setClientsList] = React.useState<Member[]>([])

  const isFormDataValid = selectedClientId

  const rowSelection = useMemo(
    () => ({
      type: 'radio' as RowSelectionType,
      onSelect: ({ id }: Member) => setClientId(id),
    }),
    [],
  )
  const dataSource = useMemo(() => clientsList.map((client) => ({ ...client, key: client.id })), [clientsList])

  useEffect(() => {
    api.common
      .getList(Resources.members, {
        pagination: { page: 1, perPage: 100 },
        filter: searchFilter(searchInputValue),
        sort: { field: 'email', order: 'ASC' },
      })
      .then(({ data: clients }: any) => setClientsList(clients))
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
  }, [searchInputValue, onDisplayErrorNotification])

  const linkAccounts = async () => {
    try {
      await requestLinkAccount(record.id, selectedClientId)

      onDisplaySuccessNotification('Session was successfully updated')
      onClose()
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
    }
  }

  const { fetch: onLinkAccounts, isLoading: isSubmitting } = useRequest(linkAccounts)

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal
      title="Link Account"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormDataValid }}
      onOk={onLinkAccounts}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <StyledLabel>Member List</StyledLabel>
      <Search
        id="searchInput"
        allowClear
        placeholder="Search member by name or email..."
        value={searchInputValue}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <StyledTableWrapp>
        <Table<Member>
          id="clientsList"
          rowSelection={rowSelection}
          columns={clientsTableColumns}
          dataSource={dataSource}
          pagination={false}
          size="middle"
          scroll={scroll}
        />
      </StyledTableWrapp>
    </Modal>
  )
}
