import React, { useState } from 'react'
import { Modal } from 'src/antd-components'
import { Resources } from 'src/types/permissions-types'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { FormeDialog } from 'src/hooks/useDialog'
import { notification, DatePicker, DatePickerProps, Typography } from 'antd'
import { requestRestoreMembership } from 'src/api/services/cloud/memberships/requestMembershipStateChanges'
import { MembershipStatus } from 'src/api/services/cloud/memberships/types'
import { addDays, startOfDay } from 'date-fns'

const errorNotification = (text = 'Please try again later') =>
  notification.error({
    message: 'Something went wrong',
    description: text,
  })

export const RestoreMembershipDialog: FormeDialog<{ record: Member; current: MembershipStatus }> = ({
  params: { record, current },
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(false)
  const [billingStartDate, setBillingStartDate] = useState<Date | null>(startOfDay(addDays(new Date(), 1)))
  const { isDeleteAllowed } = useResourcePermissionHandler(Resources.membership)

  const showDateSelector = current === 'expired'
  const [isFormValid, setIsFormValid] = useState(!showDateSelector)

  const onBillingDateOk = (value: DatePickerProps['value']) => {
    const date = value?.toISOString() ?? billingStartDate
    if (date) {
      setBillingStartDate(new Date(date))
      setIsFormValid(true)
    }
  }

  const onBillingDateChange = (value: DatePickerProps['value']) => {
    setIsFormValid(false)
    if (value) {
      onBillingDateOk(value)
    } else {
      setBillingStartDate(null)
    }
  }

  return (
    <Modal
      title="Restore Membership"
      open={open}
      onCancel={() => !loading && onClose()}
      onOk={async () => {
        try {
          if (!billingStartDate) return

          setLoading(true)
          const response = await requestRestoreMembership(record.id, { monthlyBillingDate: billingStartDate })

          if (response.success) {
            notification.success({
              message: 'Membership restored',
              description: 'It will take some time for system to update',
            })
          } else {
            errorNotification()
          }
        } catch (err: any) {
          errorNotification(err?.response?.__error__)
        } finally {
          setLoading(false)
          onClose()
        }
      }}
      cancelText="Cancel"
      okText="Restore Membership"
      okButtonProps={{ danger: true, disabled: !isDeleteAllowed || !isFormValid, loading }}
    >
      {showDateSelector && (
        <Typography.Paragraph>
          Select billing start date:
          <br />
          <DatePicker onChange={onBillingDateChange} onOk={onBillingDateOk} placeholder="Billing Date " />
        </Typography.Paragraph>
      )}
      {!showDateSelector && <>Are you sure you want to reactivate membership?</>}
    </Modal>
  )
}
