import { Resources } from '../types/permissions-types'

enum EntityPrefixes {
  menu = 'menu',
  button = 'button',
  filter = 'filter',
}

export type FieldDataQa = `${Resources}/${string}`
export type MenuDataQa = `${EntityPrefixes}/${string}`
export type FilterDataQa = `${EntityPrefixes}/${string}`
export type ButtonDataQa = `${EntityPrefixes}/${Resources}/${string}`

export const getMenuDataQa = (field: string): MenuDataQa => `${EntityPrefixes.menu}/${field}`
export const getFilterDataQa = (field: string): FilterDataQa => `${EntityPrefixes.filter}/${field}`
export const getFieldDataQa = (resource: Resources, field: string): FieldDataQa => `${resource}/${field}`
export const getButtonDataQa = (resource: Resources, actionLabel: string): ButtonDataQa =>
  `${EntityPrefixes.button}/${resource}/${actionLabel}`
