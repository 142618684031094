import { ICustomerAddress } from 'shopify-api-node'
import { PartialExcept } from 'src/utils/advanced-types'

export type AddressType = 'Residence' | 'Business'

export type OrderAddress = PartialExcept<
  ICustomerAddress,
  'first_name' | 'last_name' | 'address1' | 'address2' | 'city' | 'province_code' | 'zip' | 'country_code' | 'phone'
>

export interface IOrderPart {
  sku: string
  part: string
}

export interface ISurveyQuestion {
  question: string
  answer: string
  // default true
  sendTo3PL?: boolean
}
export interface IOrderInfo {
  survey?: ISurveyQuestion
  additionalInfo?: string
  phone?: string
}
export interface IOrderCode {
  code: string
}

export interface IProduct {
  id: number
  sku: string
  part: string
  description: string
  dimUOM: number
  length: number
  width: number
  height: number
  weightUOM: number
  weight: number
}

export interface IOrder {
  id: number
  email: string
  shopify_id: string
  name: string
  orderInfo: IOrderInfo
  type: string
  over75: boolean
  created_at: string
  address: ICustomerAddress
  status: string
  scheduled_date?: string
  parts?: IOrderPart[]
  serviceCodes?: IOrderCode[]
}

interface ILogisticTime {
  endTime: string
  startTime: string
}

interface ILogisticDate {
  availDate: string
  timeWindows: ILogisticTime[]
}

interface ILogistic {
  availableDates: ILogisticDate[]
  xpoId: number
}

export interface ILogisticResponse {
  id: number
  logistics: ILogistic[]
}

export enum OrderTypes {
  DELIVERY = 'delivery',
  SERVICE = 'service',
  RETURN = 'return',
}

export interface IOrderItemPart {
  id: number
  sku: string
  part: string
  description: string
}
