import { Drawer, DrawerProps } from '@mui/material'
import React, { memo, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useResourcesPermissionsMap } from 'src/hooks/use-permissions-handler'
import { ResourceConfig } from 'src/constants/resources-constants'

import { useHistory } from 'react-router-dom'
import { useAuthHandler } from 'src/hooks/use-auth-handler'
import { ExpandedDrawerContent } from './ExpandedDrawerContent'
import { CollapsedDrawerContent } from './CollapsedDrawerContent'
import { mapMenuItems } from './utils'

export const DRAWER_WIDTH = 240
export const COLLAPSED_DRAWER_WIDTH = 70

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ collapsed }: DrawerProps & { collapsed: boolean }) => ({
  '.MuiPaper-root': {
    width: collapsed ? COLLAPSED_DRAWER_WIDTH : DRAWER_WIDTH,
    display: 'flex',
    'justify-content': 'space-between',
  },
}))

function NavigationDrawer({ collapsed, onCollapsedChange }: { collapsed: boolean; onCollapsedChange: () => void }) {
  const history = useHistory()
  const { resourcesPermissionsMap } = useResourcesPermissionsMap()
  const allowedResources: ResourceConfig[] = Object.values(resourcesPermissionsMap)

  const menuItems = useMemo(() => mapMenuItems(allowedResources), [allowedResources])

  const { isLoading, isAuthenticated } = useAuthHandler()

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) history.push('/login')
    }
  }, [isAuthenticated, isLoading, history])

  const Component = useMemo(() => (collapsed ? CollapsedDrawerContent : ExpandedDrawerContent), [collapsed])

  return (
    <StyledDrawer variant="persistent" open collapsed={collapsed}>
      <Component menuItems={menuItems} onCollapsedChange={onCollapsedChange} />
    </StyledDrawer>
  )
}

export default memo(NavigationDrawer)
