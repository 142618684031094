import React from 'react'
import { Box, Button, Card, CardContent, Chip, Grid, Typography, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useHistory } from 'react-router-dom'
import { IOrder } from 'src/api/services/orders/types'
import { routing } from 'src/boot/routing'

const Order = ({ order }: { order: IOrder }) => {
  const { id, email, status, created_at, name } = order
  const history = useHistory()
  const goToOrderEditor = () =>
    history.push(routing.editOrder.generatePath({ id: String(id), shopify_order_name: name }))
  const theme = useTheme()
  return (
    <Grid
      container
      alignItems="center"
      spacing={4}
      justifyContent="space-between"
      sx={{ paddingTop: 2, paddingBottom: 2, borderBottom: `1px solid ${theme.palette.divider}` }}
    >
      <Grid item>
        <div>Order name: {name}</div>
        <div>{email}</div>
        <Box sx={{ display: 'flex', paddingTop: 1 }}>
          <Typography color="text.secondary">{new Date(created_at).toLocaleDateString()}</Typography>
          <Chip
            label={status}
            color={status === 'Commit' ? 'success' : 'default'}
            size="small"
            sx={{ marginLeft: 2 }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={goToOrderEditor}>
          {status === 'Draft' && 'Edit'}
          {status === 'Commit' && 'View'}
        </Button>
      </Grid>
    </Grid>
  )
}

export const RelatedOrders = ({
  orders,
  handleCreateButtonClick,
  orderCreationIsInProgress,
}: {
  orders: IOrder[]
  handleCreateButtonClick: () => void
  orderCreationIsInProgress: boolean
}) => {
  const title = orders.length ? 'Next orders has been found upon your request' : 'No existing orders has been found'

  return (
    <Card variant="outlined" sx={{ marginTop: 2, width: 'fit-content' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Box>
          {orders.map((order) => (
            <Order key={order.name} order={order} />
          ))}
          <LoadingButton
            sx={{ marginTop: 2 }}
            variant="contained"
            onClick={handleCreateButtonClick}
            loading={orderCreationIsInProgress}
          >
            Create New
          </LoadingButton>
        </Box>
      </CardContent>
    </Card>
  )
}
