import { QuerySort, RequestQueryBuilder } from '@nestjsx/crud-request'
import { HttpClient } from '../http-client'
import { Resources } from '../../types/permissions-types'
import { GetListParams, GetListWithFilterOnlyParams, Identifier } from '../../types/request-entities-types'
import { composeSearch, mergeEncodedQueries, paramsSerializer } from '../utils'
import { API } from '../../config'

export enum EntitiesSubUrls {
  memberSessions = 'sessions',
  personalTrainerSessions = 'sessions',
  memberGuests = 'guests',
  memberGuestsOf = 'guests-of',
  conciergeClients = 'clients',
  byUser = 'by-user',
  incurredCharges = 'incurred-charges',
  memberships = 'memberships',
  callSearch = 'callSearch',
  profiles = 'profiles',
  trainers = 'trainers',
  user = 'user',
}

export enum SubEntities {
  personalWorkouts = 'personal-workouts',
  iframe = 'iframe',
  assignedMembers = 'assigned-members',
  assignedTrainers = 'trainers',
  memberAllCharges = 'charges',
  billingSchedule = 'billing-schedule',
  coupons = 'coupons',
}

export enum SubUrlPlacements {
  beforeId = 'beforeId',
  afterId = 'afterId',
}

const entitiesApi = (baseUrl: string, httpClient: HttpClient) => ({
  getResourceEntity: (
    resource: Resources,
    recordId: Identifier,
    entitySubUrl: EntitiesSubUrls,
    subUrlPlacement: SubUrlPlacements = SubUrlPlacements.afterId,
  ) => {
    const urlPartOne = `${baseUrl}/${resource}`
    const urlPartTwo =
      subUrlPlacement === SubUrlPlacements.afterId ? `${recordId}/${entitySubUrl}` : `${entitySubUrl}/${recordId}`
    return httpClient(`${urlPartOne}/${urlPartTwo}`)
  },

  getSubEntityListWithFilterOnly: (subEntity: SubEntities, params: GetListWithFilterOnlyParams) => {
    const { q: queryParams, ...filter } = params.filter || {}
    const encodedQueryParams = paramsSerializer(queryParams)
    const encodedQueryFilter = RequestQueryBuilder.create({ search: composeSearch(filter) }).query()

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
    const requestUrl = `${baseUrl}/${subEntity}?${query}`

    return httpClient(requestUrl)
  },

  getSubEntityList: (subEntity: SubEntities, params: GetListParams) => {
    const { sort } = params
    const { q: queryParams, ...filter } = params.filter || {}

    const encodedQueryParams = paramsSerializer(queryParams)
    const encodedQueryFilter = RequestQueryBuilder.create({ search: composeSearch(filter) })
      .sortBy(sort as QuerySort)
      .query()

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
    const requestUrl = `${baseUrl}/${subEntity}?${query}`

    return httpClient(requestUrl)
  },

  getAgoraIframeLinkForSession: (sessionId: any): Promise<{ url: string }> => {
    const agoraBaseUrl = API.agora

    const requestUrl = `${agoraBaseUrl}/${SubEntities.iframe}/${EntitiesSubUrls.callSearch}?cname=${sessionId}`
    return httpClient(requestUrl)
  },
})

export default entitiesApi
