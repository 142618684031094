import styled from '@emotion/styled'
import { IconButton } from '@mui/material'
import React from 'react'
import { mainColor } from 'src/constants/styles-constants'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { MenuItem } from './types'
import { useHistory, useLocation } from 'react-router-dom'
import UserMenu from './UserMenu'

const Menu = styled.div`
  padding: 8px 0;
  background-color: #f5f5f5;
`

const LinkBlock = styled.div`
  &:not(:last-child) {
    border-bottom: 2px solid #bababa;
  }
  padding: 8px 0;
`

const BlockTitle = styled.div`
  font-weight: 600;
  padding: 8px 0;
  padding-left: 16px;

  > svg {
    vertical-align: bottom;
    margin-right: 8px;
    fill: ${mainColor};
  }

  &:last-child {
    cursor: pointer;

    &.selected-item,
    &:hover {
      text-decoration: underline;
    }
  }
`

const Link = styled.div`
  padding: 8px 0;
  margin-left: 48px;
  cursor: pointer;

  &:hover,
  &.selected-item {
    text-decoration: underline;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 60px;
  margin-top: 12px;

  svg {
    fill: ${mainColor};
  }
`

const AppTitle = styled.div`
  line-height: 40px;
  font-weight: 600;
  font-size: 24px;
  padding-left: 16px;
  color: ${mainColor};
`

const ButtonWrapper = styled.div`
  padding: 20px;
  padding-left: 16px;
  button {
    background-color: #f5f5f5;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.25);
  }
`

export function ExpandedDrawerContent({
  menuItems,
  onCollapsedChange,
}: {
  menuItems: MenuItem[]
  onCollapsedChange: () => void
}) {
  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <>
      <div>
        <Header>
          <AppTitle>Dash</AppTitle>
          <IconButton onClick={onCollapsedChange}>
            <ArrowBackIcon />
          </IconButton>
        </Header>
        <Menu>
          {menuItems.map((item) => {
            const Icon = item.icon

            const handleClick = (_item: MenuItem) => history.push(`/${_item.to || _item.name}`)
            const isSelectedItem = pathname.includes(item.to || item.name)

            return (
              <LinkBlock key={item.label}>
                <BlockTitle
                  onClick={() => !item.children && handleClick(item)}
                  className={isSelectedItem ? 'selected-item' : undefined}
                >
                  <Icon />
                  {item.label}
                </BlockTitle>
                {item.children?.map((childItem: MenuItem) => {
                  const isSelected = pathname.slice(1) === (childItem.to || childItem.name)

                  return (
                    <Link
                      className={isSelected ? 'selected-item' : undefined}
                      key={childItem.label}
                      onClick={() => handleClick(childItem)}
                    >
                      {childItem.label}
                    </Link>
                  )
                })}
              </LinkBlock>
            )
          })}
        </Menu>
      </div>
      <ButtonWrapper>
        <UserMenu />
      </ButtonWrapper>
    </>
  )
}
