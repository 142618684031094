import * as React from 'react'
import styled from '@emotion/styled'

export enum PillColors {
  green = 'green',
  red = 'red',
  blue = 'blue',
  grey = 'grey',
  ghost = 'ghost',
  darkBlue = 'darkBlue',
  pink = 'pink',
  orange = 'orange',
}

type TextPillProps = {
  pillText: string
  pillColor: PillColors
}

enum PillBorder {
  green = 'none',
  red = 'none',
  blue = 'none',
  grey = 'none',
  ghost = '1px solid #808080',
  darkBlue = 'none',
  pink = 'none',
  orange = 'none',
}

enum PillBackgroundColors {
  green = '#D5F2CE',
  red = '#F2CECE',
  blue = '#B3E2FB',
  grey = '#F4F4F4',
  ghost = 'transparent',
  darkBlue = '#50BFEF',
  pink = '#DB8BEF',
  orange = '#FAC069',
}

enum PillTextColors {
  green = '#41684A',
  red = '#684141',
  blue = '#053B58',
  grey = '#000000',
  ghost = '#808080',
  darkBlue = '#FFFFFF',
  pink = '#FFFFFF',
  orange = '#FFFFFF',
}

const Root = styled.span<{ pillColor: PillColors }>`
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.04em;
  padding: 3px 5px;
  border-radius: 4px;
  font-weight: 500;
  box-sizing: border-box;
  border: ${({ pillColor }) => PillBorder[pillColor]};
  background-color: ${({ pillColor }) => PillBackgroundColors[pillColor]};
  color: ${({ pillColor }) => PillTextColors[pillColor]};
`

const TextPill = ({ pillColor, pillText }: TextPillProps) => <Root pillColor={pillColor}>{pillText}</Root>

export default TextPill
