import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConciergeClient } from 'src/types/member-types'
import { RequestError } from '../../api/http-client'
import { Member } from '../../constants/member-constants'

export interface ConciergeState {
  isRecordLoading: boolean
  recordError: null | RequestError
  record: Member | null
  isAssignedMembersLoading: boolean
  assignedMembersError: null | RequestError
  assignedMembers: ConciergeClient[]
}

const initialState: ConciergeState = {
  isRecordLoading: false,
  recordError: null,
  record: null,
  isAssignedMembersLoading: false,
  assignedMembersError: null,
  assignedMembers: [],
}

export const conciergeSlice = createSlice({
  name: 'concierge',
  initialState,
  reducers: {
    startFetchingConcierge: (state) => {
      state.isRecordLoading = true
      state.recordError = null
    },
    setConciergeRecord: (state, action: PayloadAction<Member>) => {
      state.isRecordLoading = false
      state.recordError = null
      state.record = action.payload
    },
    setConciergeError: (state, action: PayloadAction<RequestError>) => {
      state.recordError = action.payload
      state.isRecordLoading = false
      state.record = null
    },
    startFetchingAssignedMembers: (state) => {
      state.isAssignedMembersLoading = true
      state.assignedMembersError = null
    },
    setAssignedMembers: (state, action: PayloadAction<ConciergeClient[]>) => {
      state.isAssignedMembersLoading = false
      state.assignedMembersError = null
      state.assignedMembers = action.payload
    },
    addNewAssignedMember: (state, action: PayloadAction<ConciergeClient>) => {
      state.assignedMembers = [...state.assignedMembers, action.payload]
    },
    removeAssignedMember: (state, action: PayloadAction<string>) => {
      state.assignedMembers = state.assignedMembers.filter((member) => member.id !== action.payload)
    },
    setAssignedMembersError: (state, action: PayloadAction<RequestError>) => {
      state.assignedMembersError = action.payload
      state.isAssignedMembersLoading = false
      state.assignedMembers = []
    },
    clearRecordData: (state) => {
      state.isRecordLoading = false
      state.recordError = null
      state.record = null
      state.isAssignedMembersLoading = false
      state.assignedMembersError = null
      state.assignedMembers = []
    },
  },
})

export const {
  startFetchingConcierge,
  setConciergeRecord,
  setConciergeError,
  startFetchingAssignedMembers,
  setAssignedMembers,
  addNewAssignedMember,
  removeAssignedMember,
  setAssignedMembersError,
  clearRecordData,
} = conciergeSlice.actions

export default conciergeSlice.reducer
