import { useEffect, useMemo } from 'react'
import { uniqBy } from 'lodash'
import { nanoid } from 'nanoid'

import { IProduct } from 'src/api/services/orders/types'
import { useRequest } from 'src/hooks/use-request'
import api from 'src/api'

const cache: { value?: IProduct[] } = {}

export const useCachedProducts = () => {
  const { data = cache.value, fetch } = useRequest(api.orders.getProducts)
  useEffect(() => {
    if (!data) {
      fetch().then((response) => (cache.value = response))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return useMemo(
    () => (data ? uniqBy(data, (_) => [_.part, _.sku].join()).map((item) => ({ ...item, key: nanoid() })) : undefined),
    [data],
  )
}
