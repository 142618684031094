import React, { useCallback } from 'react'
import { useAction } from '../../hooks/use-actions'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import { setMemberRecord } from '../../redux/member/member-reducer'
import { Resources } from '../../types/permissions-types'
import { RequestError } from '../../api/http-client'
import api from '../../api'
import { useDebouncedCallback } from '../../hooks/use-debounced-callback'
import { getErrorMessage } from '../../utils/errors'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { TableRow } from '@mui/material'
import { TableCell } from './styles'
import { TextArea } from '../../antd-components'
import { format } from 'date-fns'
import { getMemberRecord } from '../../redux/member/member-selectors'
import { useSelector } from 'react-redux'

export function Note() {
  const memberRecord = useSelector(getMemberRecord)
  const onUpdateMemberRecord = useAction(setMemberRecord)
  const { isEditAllowed: isNoteAllowed } = useResourcePermissionHandler(Resources.members)
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const onNoteChange = useCallback(
    (value) => {
      if (!memberRecord?.id) return

      const params = { id: memberRecord.id, data: { msa_note: { note: value } }, previousData: { id: '' } }
      api.common
        .update(Resources.members, params)
        .then(({ data }) => {
          onUpdateMemberRecord(data)
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    },
    [memberRecord?.id, onDisplayErrorNotification, onUpdateMemberRecord],
  )
  const onChangeDebounced = useDebouncedCallback(onNoteChange, 400)

  const msa_note = memberRecord?.msa_note

  return isNoteAllowed ? (
    <TableRow>
      <TableCell>
        <div>Note</div>
        {msa_note && (
          <div style={{ fontSize: 12, color: '#4c4c4c' }}>
            last edited on {format(new Date(msa_note.updated_date), 'dd MMM')} by {msa_note.updated_by}
          </div>
        )}
      </TableCell>
      <TableCell>
        <TextArea
          onChange={(e) => onChangeDebounced(e.target.value)}
          defaultValue={msa_note?.note || ''}
          autoSize={{ minRows: 2, maxRows: 5 }}
        />
      </TableCell>
    </TableRow>
  ) : null
}
