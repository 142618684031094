import * as React from 'react'
import MemberAdditionalActions from './components/members-list-additional-actions'
import { useMemo } from 'react'
import { useDialogsController } from './useDialogsController'
import { PageContainer } from 'src/components/ui-kit'
import { MembersTable } from './components/members-table'

export type MembersMode = 'members' | 'employees'

function MembersList({ mode }: { mode: MembersMode }) {
  const dialogsController = useDialogsController(null)
  const { dialogNodes, showSendMembershipInviteDialog } = dialogsController

  const additionalActions = useMemo(
    () => <MemberAdditionalActions onOpenSendInvite={showSendMembershipInviteDialog} />,
    [showSendMembershipInviteDialog],
  )

  const filterFunc = (data: any[]) =>
    data.filter((rowData) => {
      const employeeClasses = ['hardware unlimited', 'hardware employee']
      const className = rowData?.membershipProfiles?.[0]?.membership?.class?.name
      const isEmployeeClass = employeeClasses.includes(className)

      return mode === 'employees' ? isEmployeeClass : !isEmployeeClass
    })

  return (
    <PageContainer>
      <MembersTable sortField="email" additionalActions={additionalActions} filterFunc={filterFunc} />
      {dialogNodes}
    </PageContainer>
  )
}

export default MembersList
