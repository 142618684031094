import React from 'react'
import styled from '@emotion/styled'
import { Resources } from 'src/types/permissions-types'
import { HEADER_HEIGHT } from 'src/constants/styles-constants'
import { withPermissions } from 'src/hocs/withPermissions'
import TPIWorkflowsList from './tpi-workflows-list'
import { NavList } from './tpi-tabs-list'

const PageContainer = styled.div`
  height: 100%;
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-left: -24px;
`

const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-left: 24px;
  position: relative;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`

const TPIsPage = ({ filterParams }: { filterParams: any }) => {
  return (
    <PageContainer>
      <NavList />
      <ContentContainer>
        <TPIWorkflowsList filterParams={filterParams} />
      </ContentContainer>
    </PageContainer>
  )
}

export default withPermissions(TPIsPage, Resources.tpiWorkflows)
