import httpClient, { RequestMethods } from 'src/api/http-client'
import { API } from 'src/config'

import { ShopifyOrder } from './requestShopifyOrder'
import { ILogisticResponse, IOrderInfo, IOrderItemPart, OrderTypes } from './types'
import { IOrderServiceCode } from './service-codes/requestServiceCodes'

export const requestAdminCreation = async (
  order: ShopifyOrder,
  order_info: IOrderInfo,
  type: OrderTypes,
  parts: IOrderItemPart[],
  serviceCodes: IOrderServiceCode[],
): Promise<ILogisticResponse | any> =>
  httpClient(`${API.orders}/v2/order/create_admin`, {
    method: RequestMethods.post,
    body: JSON.stringify({
      order,
      orderInfo: order_info,
      type,
      service_codes: serviceCodes,
      parts,
    }),
  })
