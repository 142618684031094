import React, { useCallback } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { nanoid } from 'nanoid'

import { FormeDialog } from 'src/hooks/useDialog'

const titleId = nanoid()
const descriptionId = nanoid()

export const PlaceNewOrderDialog: FormeDialog<{
  orderId: string
  onConfirm: () => void
}> = ({ open, onClose, params: { orderId, onConfirm } }) => {
  const onPlaceNewOrderClick = useCallback(() => {
    onConfirm()
    onClose()
  }, [onConfirm, onClose])

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={titleId} aria-describedby={descriptionId}>
      <DialogTitle id={titleId}>Success</DialogTitle>
      <DialogContent>
        <DialogContentText id={descriptionId}>Order #{orderId} has been successfully committed</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Back to order</Button>
        <Button onClick={onPlaceNewOrderClick} autoFocus>
          Place a new order
        </Button>
      </DialogActions>
    </Dialog>
  )
}
