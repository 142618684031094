import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestError } from '../../api/http-client'

export interface ResourceListState {
  isLoading: boolean
  error: null | RequestError
  resourceList: Record<string, any>[]
  totalRows: number
}

const initialState: ResourceListState = {
  isLoading: false,
  error: null,
  resourceList: [],
  totalRows: 0,
}

export const resourceListSlice = createSlice({
  name: 'resourceList',
  initialState,
  reducers: {
    startFetchingResourceList: (state) => {
      state.isLoading = true
      state.error = null
    },
    setResourceList: (state, action: PayloadAction<{ resourceList: Record<string, any>[]; totalRows: number }>) => {
      const { resourceList, totalRows } = action.payload
      state.isLoading = false
      state.error = null
      state.resourceList = resourceList
      state.totalRows = totalRows
    },
    setResourceListError: (state, action: PayloadAction<RequestError>) => {
      state.error = action.payload
      state.isLoading = false
      state.resourceList = []
      state.totalRows = 0
    },
    clearResourceListData: (state) => {
      state.isLoading = false
      state.error = null
      state.resourceList = []
      state.totalRows = 0
    },
  },
})

export const { startFetchingResourceList, setResourceList, setResourceListError, clearResourceListData } =
  resourceListSlice.actions

export default resourceListSlice.reducer
