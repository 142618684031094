import React, { useEffect, useState } from 'react'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { useAction } from 'src/hooks/use-actions'
import { Member } from 'src/constants/member-constants'
import { setPromotions } from 'src/redux/member/member-reducer'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { CREATE_PROMOTION_INSTALL_DATE_MESSAGE } from 'src/constants/messages'
import { Alert, Form, Modal, Select, useWatchAll } from 'src/antd-components'

import { FormeDialog } from 'src/hooks/useDialog'

type Coupon = {
  value: number
  label: React.ReactNode
}

type FormData = { couponId?: number }

const initialValues = { couponId: undefined }

export const CreatePromotionDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const onUpdatePromotions = useAction(setPromotions)

  const [couponOptions, setCouponOptions] = useState<Coupon[]>([])

  const [form] = Form.useForm<FormData>()
  const { couponId } = useWatchAll(form, initialValues)

  useEffect(() => {
    if (isOpened) {
      const displayCouponError = () =>
        onDisplayErrorNotification('Error loading available coupon names. Please refresh and try again.')
      api.common
        .getList(Resources.promotions, { filter: { 'promotion_class||$eq': 'membership' } })
        .then(
          (
            allCoupons: {
              id: number
              name: string
              amount_off: string
              length_in_months: number
              percent_off: string
            }[],
          ) => {
            const allCouponOptions = allCoupons.map((coupon) => {
              const { id, name, amount_off, percent_off, length_in_months } = coupon ?? {}
              const discount = (() => {
                if (amount_off) return `$${parseInt(amount_off) / 100} off`
                if (percent_off) return `${percent_off}% off`
                return ''
              })()
              const duration =
                discount && length_in_months >= 1
                  ? length_in_months > 1
                    ? `for ${length_in_months} months`
                    : `for ${length_in_months} month`
                  : ''
              return {
                value: id,
                label: (
                  <>
                    <b>{name}</b> {discount} {duration}
                  </>
                ),
              }
            })

            if (!allCouponOptions?.length) {
              displayCouponError()
            } else {
              setCouponOptions(allCouponOptions)
            }
          },
        )
        .catch(() => {
          displayCouponError()
        })
    }
  }, [isOpened, setCouponOptions, onDisplayErrorNotification])

  const isFormValid = !!couponId
  const clearFormData = () => form.resetFields()

  const onCreate = () => {
    const params = {
      data: { user_id: record.id, promotion_id: couponId },
    }

    api.common
      .create(Resources.billingPromotions, params)
      .then(({ data: nextRecord }) => {
        onUpdatePromotions([nextRecord])
        onDisplaySuccessNotification('Promotion successfully created')
        clearFormData()
      })
      .catch(() => {
        onDisplayErrorNotification('Error creating promotion. Please refresh and try again.')
      })

    onClose()
  }

  return (
    <Modal
      title="Add new promotion"
      okText="Add"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid }}
      onOk={onCreate}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialValues} labelAlign="right" layout="vertical">
        <Form.Item label="Coupon" name="couponId">
          <Select options={couponOptions} />
        </Form.Item>
      </Form>
      <Alert type="info" message={CREATE_PROMOTION_INSTALL_DATE_MESSAGE} />
    </Modal>
  )
}
