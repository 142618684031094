import React, { memo } from 'react'
import { Grid, TextField } from '@mui/material'

import { OrderForm } from '../order-form'
import { CreateOrderFormData } from '../../containers/create-order/useFormState'

function Order({
  formData,
  onFormDataChange,
  onValidationChange,
}: {
  formData: CreateOrderFormData
  onFormDataChange: (newValue: Partial<CreateOrderFormData>) => void
  onValidationChange: (isValid: boolean) => void
}) {
  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item xs={8}>
        <TextField
          onChange={(ev) => onFormDataChange({ name: ev.target.value })}
          label="Order name"
          value={formData.name}
          error={formData.name.trim().length === 0}
          required
          fullWidth
          size="small"
          sx={{ marginBottom: 2 }}
        />
        <OrderForm data={formData} onChange={onFormDataChange} onValidationChange={onValidationChange} />
      </Grid>
    </Grid>
  )
}

export default memo(Order)
