export const LOCK_CONTAINER_ID = 'auth0lock'
export const USER_PERMISSIONS_KEY = 'https://formelife.com/permissions'
export const AUTH0_REDIRECT_URL = window.location.origin
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'dash-auth.ci.formelife.net'
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || 'swM4FZazf0AjqoerS1iUYHL2YPJcLBhp'
export const ENV_NAME = process.env.REACT_APP_ENV_NAME || 'develop'
export const AMOUNT_DEFAULT = 10 //amount for CLDA-158 (probabaly will be recieved from backend config)
export const AGORA_SESSION_CUT_OFF_DAYS = 10

const getMSLink = (name: string, env_link: string) =>
  process.env[env_link] || `https://${name}.${ENV_NAME}.formelife.net`

export const API = {
  agora: (process.env.REACT_APP_ADMIN_API_URL || '').replace('admin', 'agora'),
  cloud: process.env.REACT_APP_ADMIN_API_URL || '',
  cloudv2: process.env.REACT_APP_ADMIN_API_URL?.replace('v1', 'v2') || '',

  // MS
  orders: getMSLink('ms-order-management', 'REACT_APP_MS_ORDERS_API_URL'),
  invoices: getMSLink('ms-invoices', 'REACT_APP_MS_INVOICES_API_URL'),
  billing: getMSLink('ms-billing', 'REACT_APP_MS_BILLING_API_URL'),
  bundles: getMSLink('ms-bundles', 'REACT_APP_MS_BUNDLES_API_URL'),
  tpi: getMSLink('ms-tpi', 'REACT_APP_MS_TPI_API_URL'),
}

export const getStripeLink =
  ENV_NAME === 'production' ? process.env.REACT_APP_STRIPE_URL_PROD : process.env.REACT_APP_STRIPE_URL_TEST

export const getAuth0Link = process.env.REACT_APP_AUTH0_URL
