import React from 'react'
import styled from '@emotion/styled'
import { Resources } from 'src/types/permissions-types'
import { withPermissions } from 'src/hocs/withPermissions'
import { Products } from './containers/products'
import { CreateOrder } from './containers/create-order'
import { EditOrder } from './containers/edit-order'
import { Orders } from './containers/orders'
import { HEADER_HEIGHT } from 'src/constants/styles-constants'
import { Route } from 'react-router-dom'
import { routing } from 'src/boot/routing'

const PageContainer = styled.div`
  height: 100%;
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-left: -24px;
`

const contentTopOffset = 24
const ContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-left: 24px;
  margin-top: ${contentTopOffset}px;
  position: relative;
  height: calc(100vh - ${HEADER_HEIGHT + contentTopOffset}px);
`

const routes = [
  {
    path: routing.createOrder.pattern,
    label: 'Orders',
    component: CreateOrder,
  },
  {
    path: routing.ordersList.pattern,
    label: 'Order History',
    component: Orders,
  },
  {
    path: routing.productsList.pattern,
    label: 'Products',
    component: Products,
  },
]

const OrderPage = () => (
  <PageContainer>
    <ContentContainer>
      {routes.map((route) => (
        <Route key={route.label} exact path={route.path} component={route.component} />
      ))}
      <Route exact path={routing.editOrder.pattern} component={EditOrder} />
    </ContentContainer>
  </PageContainer>
)

export default withPermissions(OrderPage, Resources.orderManagement)
