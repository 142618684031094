import React, { useCallback, useMemo, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link, TableCell } from '@mui/material'
import styled from '@emotion/styled'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { TabItemConfig } from 'src/containers/page-tab-section-container'
import { Member, MemberTabKeys } from 'src/constants/member-constants'
import TextPill, { PillColors } from 'src/components/text-pill'
import { isPromotionDeleteAvailable } from 'src/utils/edit-utils'
import { routing } from 'src/boot/routing'
import { PersonalWorkout } from 'src/constants/personal-workout-constants'
import { BillingPromotion } from 'src/constants/billing-promotion-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from 'src/types/permissions-types'

import { DialogsController } from './useDialogsController'
import MemberOneOnOneTab from './components/member-one-on-one-tab'
import MemberOverviewTab from './components/member-overview-tab'
import MemberCPSTab from './components/member-cps-tab'
import MemberChargesTab from './components/member-user-charges-tab'
import { CheckCircleTwoTone, WalletTwoTone, EditOutlined } from '@ant-design/icons'

import {
  ABSENT_VALUE_PLACEHOLDER,
  ActionCell,
  ComponentCell,
  CurrencyCell,
  DataCell,
  DateCell,
  NestedDataCell,
  BooleanYesNoCell,
  SessionIdCell,
} from '../../components/accessible-table/accessible-table-cell'
import { getClientTimezone } from 'src/api/utils'
import { Charge, BillingSchedule } from 'src/constants/billing-v2-contants'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import { DatePicker } from 'antd'
import { getUniformDateTime } from 'src/utils/uniformDateDisplay'
import moment from 'moment'
import { MembershipProfile } from 'src/types/member-profile'
import { requestProfileUpdate } from 'src/api/services/cloud/memberships/requestProfileUpdate'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

const StyledEmail = styled.span`
  margin-right: 8px;
`

const useTabsConfig = (member: Member | null, dialogsController: DialogsController): TabItemConfig[] => {
  const recordId = member?.id ?? ''
  const {
    showEditMemberSessionDetailsDialog,
    showCreatePromotionDialog,
    showDeletePromotionDialog,
    showEditBillingScheduleDialog,
    showEditUserChargesDialog,
    showEditAssignedTrainersDialog,
  } = dialogsController
  const { isReadAllowed: isIncurredChargesReadAllowed } = useResourcePermissionHandler(
    Resources.oneOnOneIncurredCharges,
  )
  const { isReadAllowed: isChargesReadAllowed, isEditAllowed: isChargesEditAllowed } = useResourcePermissionHandler(
    Resources.charge,
  )
  const { isReadAllowed: isInvoicesReadAllowed } = useResourcePermissionHandler(Resources.oneOnOneInvoices)
  const { isReadAllowed: isSessionsReadAllowed, isEditAllowed: isSessionsEditAllowed } = useResourcePermissionHandler(
    Resources.memberSessions,
  )
  const { isReadAllowed: isPromotionsReadAllowed, isCreateAllowed: isPromotionsCreateAllowed } =
    useResourcePermissionHandler(Resources.promotions)
  const { isReadAllowed: isStudiosReadAllowed } = useResourcePermissionHandler(Resources.studios)
  const { isReadAllowed: isProfilesReadAllowed } = useResourcePermissionHandler(Resources.members)
  const { isReadAllowed: isTrainerReadAllowed, isEditAllowed: isTrainerEditAllowed } = useResourcePermissionHandler(
    Resources.personalTrainers,
  )

  const { isReadAllowed: isBillingScheduleReadAllowed, isEditAllowed: isBillingScheduleEditAllowed } =
    useResourcePermissionHandler(Resources.billingSchedule)

  const [isOverviewLoading, setIsOverviewLoading] = useState(false)
  const [isOneOnOneLoading, setIsOneOnOneLoading] = useState(false)

  const { isReadAllowed: isCPSReadAllowed } = useResourcePermissionHandler(Resources.members)
  const { isEditAllowed: isExpiryEditAllowed } = useResourcePermissionHandler(Resources.membershipExpiry)

  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const onProfileChange = useCallback(
    (data: Partial<MembershipProfile>) => {
      data.id &&
        requestProfileUpdate(data.id, data).catch(() => {
          onDisplayErrorNotification('Error changing field. Please refresh and try again.')
        })
    },
    [onDisplayErrorNotification],
  )

  return useMemo(() => {
    const incurredChargesTableColumns: ColumnItem[] = [
      {
        key: 'amount',
        title: 'Amount (USD)',
        fields: ['charge'],
        render: (props) => {
          return <NestedDataCell {...props} nestedFields={['price']} />
        },
        orderByField: 'charge.price',
      },
      {
        key: 'session_id',
        title: 'Session #',
        fields: ['personal_session_id'],
        render: (props) => {
          const sessionId = props.rowData?.charge?.source_id as string
          const usedCoupon = (props.rowData?.charge?.source === 'personalTraining' &&
            props.rowData?.coupons[0]?.charge_kind === 'personalTraining') as boolean
          return (
            <ComponentCell {...props}>
              <StyledEmail>{sessionId || ABSENT_VALUE_PLACEHOLDER}</StyledEmail>
              {usedCoupon && <WalletTwoTone style={{ color: '#388e3c' }} />}
            </ComponentCell>
          )
        },
        orderByField: 'personal_session_id',
      },
      {
        key: 'title',
        title: 'Title',
        fields: ['title'],
        render: (props) => {
          const title = props.rowData?.title

          return (
            <ComponentCell {...props}>
              <StyledEmail {...props}>{title || ABSENT_VALUE_PLACEHOLDER}</StyledEmail>
            </ComponentCell>
          )
        },
        orderByField: 'title',
      },
      {
        key: 'session_date',
        title: 'Session Date',
        fields: ['date'],
        render: (props) => {
          const date = props.rowData?.description?.split(': ')[1] as string

          return (
            <ComponentCell {...props}>
              <StyledEmail {...props}>{date || ABSENT_VALUE_PLACEHOLDER}</StyledEmail>
            </ComponentCell>
          )
        },
        orderByField: 'date',
      },
    ]

    const sessionsTableColumns: ColumnItem[] = [
      { key: 'date', title: 'Date', fields: ['date'], render: DateCell, orderByField: 'date' },

      {
        key: 'workout_category',
        title: 'Category',
        fields: ['workout_category'],
        render: DataCell,
        orderByField: 'workout_category',
      },
      { key: 'workout_type', title: 'Type', fields: ['workout_type'], render: DataCell, orderByField: 'workout_type' },
      {
        key: 'trainer_session_id',
        title: 'Session #',
        fields: ['id'],
        render: SessionIdCell,
        orderByField: 'id',
      },
      { key: 'duration', title: 'Duration', fields: ['duration'], render: DataCell, orderByField: 'duration' },
      {
        key: 'session_status',
        title: 'Ses. Status',
        fields: ['workout_status'],
        render: DataCell,
        orderByField: 'workout_status',
      },

      {
        key: 'billing_status',
        title: 'Bil. Status',
        fields: ['billing_status'],
        render: DataCell,
        orderByField: 'billing_status',
      },
      {
        key: 'trainer',
        title: 'Trainer',
        fields: ['trainer_name'],
        render: DataCell,
        orderByField: 'trainer_name',
        link: isTrainerReadAllowed
          ? (row: { trainer_id: string }) => routing.personalTrainer.generatePath({ id: row.trainer_id })
          : undefined,
      },
      {
        key: 'billing_rate',
        title: 'Bill Rate',
        fields: ['bill_rate'],
        render: (props) => <CurrencyCell {...props} />,
        orderByField: 'bill_rate',
      },
    ]

    if (isSessionsEditAllowed) {
      sessionsTableColumns.push({
        key: 'edit_session',
        render: (props) => (
          <ActionCell
            displayActionIconArgs={['workout_status', 'billing_status', 'payment_status']}
            action={(rowData) => showEditMemberSessionDetailsDialog(rowData as PersonalWorkout)}
            renderIcon={() => <EditIcon />}
            {...props}
          />
        ),
      })
    }

    const promotionsTableColumns: ColumnItem[] = [
      { key: 'name', title: 'Name', fields: ['name'], render: DataCell, orderByField: 'name' },
      { key: 'date_added', title: 'Date Added', fields: ['date_added'], render: DataCell, orderByField: 'date_added' },
      {
        key: 'install_date',
        title: 'Install Date',
        fields: ['install_date'],
        render: DataCell,
        orderByField: 'install_date',
      },
      { key: 'term', title: 'Term', fields: ['term'], render: DataCell, orderByField: 'term' },
    ]

    // to reduce the size of JWT token, delete promotions permission does not exist. instead, if you have the create permission, you are also allowed to delete
    if (isPromotionsCreateAllowed) {
      promotionsTableColumns.push({
        key: 'delete_promotion',
        render: (props) => (
          <ActionCell
            isDisplayActionIcon={isPromotionDeleteAvailable}
            displayActionIconArgs={['consumed']}
            action={(rowData) => showDeletePromotionDialog(rowData as BillingPromotion)}
            renderIcon={() => <DeleteIcon />}
            {...props}
          />
        ),
      })
    }
    const profileTableColumns: ColumnItem[] = [
      {
        key: 'email',
        title: 'Email',
        render: (props) => {
          const email = props.rowData?.user?.email as string
          const isTest = props.rowData?.test as boolean
          const link = routing.member.generatePath({ id: props.rowData?.userId })
          return (
            <ComponentCell {...props}>
              <StyledEmail>
                {props.rowData?.userId !== member?.id && isProfilesReadAllowed ? (
                  <Link style={{ textDecoration: 'none' }} href={link}>
                    {email || ABSENT_VALUE_PLACEHOLDER}
                  </Link>
                ) : (
                  email || ABSENT_VALUE_PLACEHOLDER
                )}
              </StyledEmail>
              {isTest && <TextPill pillColor={PillColors.ghost} pillText="Test" />}
            </ComponentCell>
          )
        },
      },
      {
        key: 'first_name',
        title: 'Name',
        fields: ['user'],
        render: (props) => <NestedDataCell {...props} nestedFields={['first_name', 'last_name']} />,
        orderByField: 'user.first_name',
      },
      {
        key: 'owner',
        title: 'Owner',
        render: BooleanYesNoCell,
        orderByField: 'owner',
      },
      {
        key: 'name',
        title: 'Membership Name',
        render: (props) => {
          const cellValue = props.rowData['membership']?.['class']?.['name'] || '-'
          return <TableCell style={{ wordBreak: 'break-all' }}>{cellValue}</TableCell>
        },
        orderByField: 'membership.class.name',
      },
      {
        key: 'updated_date',
        title: 'Added',
        fields: ['updated_date'],
        render: (props) => (
          <DateCell
            options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
            {...props}
          />
        ),
        orderByField: 'updated_date',
      },
      {
        key: 'detach_date',
        title: 'Detach Date',
        fields: ['detach_date'],
        render: (props) => {
          return (
            <TableCell>
              {isExpiryEditAllowed ? (
                <DatePicker
                  defaultValue={props.rowData?.detach_date ? moment(props.rowData?.detach_date) : undefined}
                  onChange={(date) => {
                    const value = date?.toDate().toISOString() || undefined

                    onProfileChange({ id: props.rowData?.id, detach_date: value || null })
                  }}
                />
              ) : props.rowData?.detach_date2 ? (
                getUniformDateTime(props.rowData?.detach_date)
              ) : (
                ABSENT_VALUE_PLACEHOLDER
              )}
            </TableCell>
          )
        },
      },
    ]

    const trainerTableColumns: ColumnItem[] = [
      {
        key: 'email',
        title: 'Email',
        render: (props) => {
          const email = props.rowData?.trainer_email as string
          const link = routing.personalTrainer.generatePath({ id: props.rowData?.trainer_id })
          return (
            <ComponentCell {...props}>
              <StyledEmail>
                {isTrainerReadAllowed ? (
                  <Link style={{ textDecoration: 'none' }} href={link}>
                    {email || ABSENT_VALUE_PLACEHOLDER}
                  </Link>
                ) : (
                  email || ABSENT_VALUE_PLACEHOLDER
                )}
              </StyledEmail>
            </ComponentCell>
          )
        },
      },
      {
        key: 'first_name',
        title: 'Name',
        fields: ['trainer_first_name', 'trainer_last_name'],
        render: DataCell,
        orderByField: 'trainer_first_name',
      },
      {
        key: 'assigned_date',
        title: 'Assigned Date',
        fields: ['assigned_date'],
        render: (props) => (
          <DateCell
            options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
            {...props}
          />
        ),
        orderByField: 'assigned_date',
      },
      {
        key: 'trainer_feature',
        title: 'Trainer Feature',
        fields: ['feature'],
        render: (props) => {
          const feature = props?.rowData?.feature ? props?.rowData?.feature : '-'
          return <TableCell>{feature}</TableCell>
        },
        orderByField: 'feature',
      },
      {
        key: 'status',
        title: 'Status',
        render: (props) => <ComponentCell {...props}>{props.rowData.active ? 'Active' : 'Not Active'}</ComponentCell>,
      },
    ]

    if (isTrainerEditAllowed) {
      trainerTableColumns.push({
        key: 'edit_trainer',
        render: (props) => (
          <ActionCell
            displayActionIconArgs={['is_trainer_feature']}
            action={(rowData) => showEditAssignedTrainersDialog(rowData as PersonalTrainer)}
            renderIcon={() => <EditIcon />}
            {...props}
          />
        ),
      })
    }

    const hardwareTableColumns: ColumnItem[] = [
      {
        key: 'id',
        fields: ['id'],
        title: 'ID',
        render: DataCell,
        link: isStudiosReadAllowed ? (row: { id: string }) => routing.studio.generatePath({ id: row.id }) : undefined,
      },
      { key: 'lift_id', fields: ['lift_id'], title: 'Lift ID', render: DataCell },
      {
        key: 'lift_registration_date',
        fields: ['lift_registration_date'],
        title: 'Lift registration date',
        render: DateCell,
      },
      { key: 'product_type', fields: ['product_type'], title: 'Product Type', render: DataCell },
      { key: 'created_date', fields: ['created_date'], title: 'Create Date', render: DateCell },
      { key: 'install_date', fields: ['install_date'], title: 'Install Date', render: DateCell },
    ]

    const billingScheduleTableColumns: ColumnItem[] = [
      {
        key: 'period',
        fields: ['period'],
        title: 'Monthly / Biweekly',
        render: DataCell,
        orderByField: 'period',
      },
      {
        key: 'status',
        fields: ['status'],
        title: 'Status',
        render: DataCell,
        orderByField: 'status',
      },
      {
        key: 'monthly_anchor_day',
        fields: ['monthly_anchor_day'],
        title: 'Monthly Anchor Day',
        render: DataCell,
        orderByField: 'monthly_anchor_day',
      },
      {
        key: 'due_date',
        fields: ['due_date'],
        title: 'Due date',
        render: DateCell,
        orderByField: 'due_date',
      },
    ]

    if (isBillingScheduleEditAllowed) {
      billingScheduleTableColumns.push({
        key: 'edit_schedule',
        render: (props) => (
          <ActionCell
            displayActionIconArgs={['period', 'due_date', 'force_billing_period']}
            action={(rowData) => showEditBillingScheduleDialog(rowData as BillingSchedule)}
            renderIcon={() => {
              return props.rowData.status !== 'cancelled' ? <EditIcon /> : null
            }}
            {...props}
          />
        ),
      })
    }

    const cpsHistoryTableColumns: ColumnItem[] = [
      {
        key: 'session_name',
        title: 'Session Name',
        fields: ['plan_workout'],
        render: (props) => <NestedDataCell {...props} nestedFields={['name']} />,
        orderByField: 'plan_workout.name',
      },
      {
        key: 'trainer_name',
        title: 'Trainer Name',
        fields: ['owner_first_name', 'owner_last_name'],
        render: (props) => {
          const name = `${props.rowData?.plan_workout?.owner?.first_name} ${props.rowData?.plan_workout?.owner?.last_name}`
          return <TableCell style={{ wordBreak: 'break-all' }}>{name}</TableCell>
        },
        orderByField: 'owner_first_name',
      },
      {
        key: 'start_time',
        fields: ['start_time'],
        title: 'Session Assigned Date',
        render: DateCell,
        orderByField: 'start_time',
      },
      {
        key: 'end_time',
        fields: ['end_time'],
        title: 'Session End Date',
        render: DateCell,
        orderByField: 'end_time',
      },
      {
        key: 'workout_status',
        fields: ['workout_status'],
        title: 'Status',
        render: DataCell,
        orderByField: 'workout_status',
      },
      {
        key: 'elapsed_time',
        fields: ['elapsed_time'],
        title: 'Time Spent (in mins.)',
        render: (props) => {
          const mins = Math.round(props.rowData?.elapsed_time / 60000)
          return <TableCell style={{ wordBreak: 'break-all' }}>{mins}</TableCell>
        },
        orderByField: 'elapsed_time',
      },
      {
        key: 'calories_used',
        fields: ['calories_used'],
        title: 'Calories Burned',
        render: (props) => {
          const mins = Math.round(props.rowData?.calories_used)
          return <TableCell style={{ wordBreak: 'break-all' }}>{mins}</TableCell>
        },
        orderByField: 'calories_used',
      },
      {
        key: 'weight_lifted',
        fields: ['weight_lifted'],
        title: 'Total Weight',
        render: DataCell,
        orderByField: 'weight_lifted',
      },
    ]

    const cpsScheduleTableColumns: ColumnItem[] = [
      {
        key: 'message',
        title: 'Workout Message',
        fields: ['scheduled_workout'],
        render: (props) => <NestedDataCell {...props} nestedFields={['message']} />,
        orderByField: 'scheduled_workout.message',
      },
      {
        key: 'first_name',
        title: 'Trainer Name',
        fields: ['creator'],
        render: (props) => <NestedDataCell {...props} nestedFields={['first_name', 'last_name']} />,
        orderByField: 'creator.first_name',
      },
      {
        key: 'start_datetime',
        fields: ['start_datetime'],
        title: 'Session Assigned Date',
        render: DateCell,
        orderByField: 'start_datetime',
      },
    ]

    const allUserChargesTableColumns: ColumnItem[] = [
      {
        key: 'title',
        title: 'Title',
        fields: ['line_item_title'],
        render: DataCell,
        orderByField: 'line_item_title',
      },
      {
        key: 'amount',
        title: 'Amount (USD)',
        fields: ['price'],
        render: DataCell,
        orderByField: 'price',
      },
      {
        key: 'due_date',
        title: 'Due Date',
        fields: ['due_date'],
        render: (props) => (
          <DateCell
            options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
            {...props}
          />
        ),
        orderByField: 'due_date',
      },
      {
        key: 'paid_date',
        title: 'Paid Date',
        fields: ['paid_date'],
        render: (props) => (
          <DateCell
            options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
            {...props}
          />
        ),
        orderByField: 'paid_date',
      },
      {
        key: 'type',
        title: 'Type',
        fields: ['type'],
        render: DataCell,
        orderByField: 'type',
      },
      {
        key: 'status',
        title: 'Status',
        fields: ['status'],
        render: DataCell,
        orderByField: 'status',
      },
    ]

    const allUserCouponsTableColumns: ColumnItem[] = [
      {
        key: 'type',
        title: 'Type',
        fields: ['charge_kind'],
        render: DataCell,
        orderByField: 'charge_kind',
      },
      {
        key: 'status',
        title: 'Status',
        fields: ['status'],
        render: DataCell,
        orderByField: 'status',
      },
      {
        key: 'price',
        title: 'Price',
        fields: ['fixed_price'],
        render: DataCell,
        orderByField: 'fixed_price',
      },
      {
        key: 'discount_percent',
        title: 'Discount (%)',
        fields: ['discount_percent'],
        render: DataCell,
        orderByField: 'discount_percent',
      },
      {
        key: 'expiry',
        title: 'Expiry',
        fields: ['expire_date'],
        render: DateCell,
        orderByField: 'expire_date',
      },
    ]

    if (isChargesEditAllowed) {
      allUserChargesTableColumns.push({
        key: 'edit_charge',
        render: (props) => {
          return props.rowData.status !== 'paid' ? (
            <ActionCell
              displayActionIconArgs={['period', 'due_date']}
              action={(rowData) => {
                showEditUserChargesDialog(rowData as Charge)
              }}
              renderIcon={() => <EditOutlined />}
              {...props}
            />
          ) : (
            <ActionCell renderIcon={() => <CheckCircleTwoTone />} {...props} />
          )
        },
      })
    }

    const memberInvitesTableColumns: ColumnItem[] = [
      {
        key: 'initiating_app',
        title: 'Initiating App',
        fields: ['initiating_app'],
        render: DataCell,
        orderByField: 'initiating_app',
      },
      {
        key: 'initiating_user_id',
        title: 'Initiated By',
        fields: ['initiating_user_id'],
        render: DataCell,
        orderByField: 'initiating_user_id',
      },
      {
        key: 'created_date',
        title: 'Created Date',
        fields: ['created_date'],
        render: DateCell,
        orderByField: 'created_date',
      },
      {
        key: 'status',
        title: 'Status',
        fields: ['status'],
        render: DataCell,
        orderByField: 'status',
      },
      {
        key: 'error_text',
        title: 'Error Text',
        fields: ['error_text'],
        render: DataCell,
        orderByField: 'error_text',
      },
      {
        key: 'finish_date',
        title: 'Finish Date',
        fields: ['finish_date'],
        render: DateCell,
        orderByField: 'finish_date',
      },
    ]

    const tabsContentConfig = [
      {
        key: MemberTabKeys.memberOverviewContentTab,
        title: 'Overview',
        disabled: isOneOnOneLoading,
        tabComponent: (
          <MemberOverviewTab
            record={member ?? null}
            setIsOverviewLoading={setIsOverviewLoading}
            promotionsTableColumns={promotionsTableColumns}
            hardwareTableColumns={hardwareTableColumns}
            profileTableColumns={profileTableColumns}
            trainerTableColumns={trainerTableColumns}
            billingScheduleTableColumns={billingScheduleTableColumns}
            personalTrainersTableColumns={profileTableColumns}
            memberInvitesTableColumns={memberInvitesTableColumns}
            isPromotionsReadAllowed={isPromotionsReadAllowed}
            isStudiosReadAllowed={isStudiosReadAllowed}
            isProfilesReadAllowed={isProfilesReadAllowed}
            isBillingScheduleReadAllowed={isBillingScheduleReadAllowed}
            isPersonalTrainersReadAllowed={isTrainerReadAllowed}
            isPromotionsCreateAllowed={isPromotionsCreateAllowed}
            showCreatePromotionDialog={showCreatePromotionDialog}
          />
        ),
      },
    ]

    if (
      isIncurredChargesReadAllowed ||
      isInvoicesReadAllowed ||
      isSessionsReadAllowed ||
      isBillingScheduleReadAllowed
    ) {
      tabsContentConfig.push({
        key: MemberTabKeys.memberOneOnOneContentTab,
        title: '1-on-1',
        disabled: isOverviewLoading,
        tabComponent: (
          <MemberOneOnOneTab
            memberId={member?.stripe_customer_id as string}
            recordId={recordId}
            setIsOneOnOneLoading={setIsOneOnOneLoading}
            sessionsTableColumns={sessionsTableColumns}
            incurredChargesTableColumns={incurredChargesTableColumns}
          />
        ),
      })
    }

    if (isCPSReadAllowed) {
      tabsContentConfig.push({
        key: MemberTabKeys.memberCPSTab,
        title: 'CPS',
        tabComponent: (
          <MemberCPSTab
            recordId={recordId}
            cpsHistoryTableColumns={cpsHistoryTableColumns}
            cpsScheduleTableColumns={cpsScheduleTableColumns}
          />
        ),
        disabled: false,
      })
    }

    if (isChargesReadAllowed) {
      tabsContentConfig.push({
        key: MemberTabKeys.memberUserChargesTab,
        title: 'Charges',
        tabComponent: (
          <MemberChargesTab
            recordId={recordId}
            allUserChargesTableColumns={allUserChargesTableColumns}
            allUserCouponsTableColumns={allUserCouponsTableColumns}
          />
        ),
        disabled: false,
      })
    }

    return tabsContentConfig
  }, [
    isTrainerReadAllowed,
    isSessionsEditAllowed,
    isPromotionsCreateAllowed,
    isTrainerEditAllowed,
    isStudiosReadAllowed,
    isBillingScheduleEditAllowed,
    isChargesEditAllowed,
    isOneOnOneLoading,
    member,
    isPromotionsReadAllowed,
    isProfilesReadAllowed,
    isBillingScheduleReadAllowed,
    showCreatePromotionDialog,
    isIncurredChargesReadAllowed,
    isInvoicesReadAllowed,
    isSessionsReadAllowed,
    isCPSReadAllowed,
    isChargesReadAllowed,
    showEditMemberSessionDetailsDialog,
    showDeletePromotionDialog,
    isExpiryEditAllowed,
    onProfileChange,
    showEditAssignedTrainersDialog,
    showEditBillingScheduleDialog,
    showEditUserChargesDialog,
    isOverviewLoading,
    recordId,
  ])
}

export default useTabsConfig
