import styled from '@emotion/styled'
import { IconButton, Menu } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import { buttonStyles } from './styles'
import { ButtonWithMenuProps } from './types'

export const GrayButtonWithMenu = styled(ButtonWithMenu)`
  ${buttonStyles}
` as (arg: ButtonWithMenuProps) => ReactElement

export function ButtonWithMenu({ icon: Icon, children, className }: ButtonWithMenuProps) {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const open = Boolean(anchorEl)

  const onClick = (e: React.MouseEvent) => setAnchorEl(e.currentTarget)
  const onClose = () => setAnchorEl(null)

  return (
    <>
      <IconButton className={className} onClick={onClick}>
        <Icon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ horizontal: 'right', vertical: 0 }}
        onClick={onClose}
      >
        {children}
      </Menu>
    </>
  )
}
