import { configureStore } from '@reduxjs/toolkit'
import resourceList from './resource-list/resource-list-reducer'
import personalTrainer from './personal-trainer/personal-trainer-reducer'
import concierge from './concierge/concierge-reducer'
import member from './member/member-reducer'
import { useDispatch as useReduxDispatch } from 'react-redux'

export const store = configureStore({
  reducer: {
    resourceList,
    personalTrainer,
    concierge,
    member,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useDispatch = () => useReduxDispatch<AppDispatch>()
