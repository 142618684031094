import React from 'react'

import { Modal, Typography } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'
import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import Loader from 'src/components/loader'

import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { FormeDialog } from 'src/hooks/useDialog'
import { Charge } from 'src/constants/billing-v2-contants'
import { DatePicker, Divider, Form, Input, InputNumber, Select } from 'antd'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { Identifier } from 'src/types/request-entities-types'
import styled from '@emotion/styled'

const statusOptions = ['not_paid', 'pending', 'paid', 'failed', 'cancelled'].map((value) => ({ value, label: value }))
const typeOptions = ['recurring', 'per_item', 'one_time'].map((value) => ({ value, label: value }))
const periodOptions = ['monthly', 'biweekly', 'annual'].map((value) => ({ value, label: value }))
const sourceOptions = ['monthlyMembership', 'sessionsBundle', 'personalTraining'].map((value) => ({
  value,
  label: value,
}))

const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    width: 80px;
  }
`

export const EditUserChargesDialog: FormeDialog<{ charge?: Charge; user_id?: Identifier }> = ({
  params: { charge, user_id },
  open: isOpened,
  onClose,
}) => {
  const [form] = Form.useForm()

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.charge)

  const updateCharge = async () => {
    await form.validateFields()

    try {
      const data = form.getFieldsValue()

      if (charge) {
        await api.billingV2.updateUserCharges(charge.id, data)
      } else {
        const id = uuidv4()
        await api.billingV2.createUserCharges({
          id,
          ...data,
          paid_date: null,
          features: [],
          user_id,
          taxable: true,
          // ST087651 for any monthlyMembership (even if it's manual), else assume it's PT of some flavor
          tax_code: data.price === 49 || data.price === 39 ? 'ST087651' : 'SP036858',
        })
      }

      setTimeout(() => window.location.reload(), 300)
      onDisplaySuccessNotification(charge ? 'Charge was successfully updated' : 'Charge was successfully created')
      onClose()
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
    }
  }

  const { fetch: onEditUserCharge, isLoading: isSubmitting } = useRequest(updateCharge)

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed || charge?.status === 'paid') {
    return null
  }

  return (
    <Modal
      style={{ zIndex: '1 !important' }}
      title="Edit Charge"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      onOk={onEditUserCharge}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <StyledForm form={form} initialValues={charge}>
        {charge ? (
          <>
            <Typography.Paragraph>
              User id:{' '}
              <Typography.Text code copyable>
                {charge?.user_id}
              </Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph>
              Charge id:{' '}
              <Typography.Text code copyable>
                {charge?.id}
              </Typography.Text>
            </Typography.Paragraph>
            <Divider />
          </>
        ) : null}
        <Form.Item rules={[{ required: true }]} label="Title" name="line_item_title">
          <Input />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Price" name="price">
          <InputNumber />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Status" name="status">
          <Select options={statusOptions} />
        </Form.Item>
        <Divider />
        <Form.Item rules={[{ required: true }]} label="Source" name="source">
          <Select options={sourceOptions} />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} label="Source ID" name="source_id">
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item rules={[{ required: true }]} label="Type" name="type">
          <Select options={typeOptions} />
        </Form.Item>
        <Divider />
        <Form.Item rules={[{ required: true }]} label="Period" name="period">
          <Select options={periodOptions} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="Due Date"
          name="due_date"
          getValueFromEvent={(value: Date) => value?.toISOString()}
          getValueProps={(value?: string) => ({ value: value ? moment(value) : value })}
        >
          <DatePicker showTime />
        </Form.Item>
      </StyledForm>
    </Modal>
  )
}
