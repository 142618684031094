import { omitBy } from 'lodash'
import { stringify } from 'query-string'
import { PREFERRED_TIMEZONE } from 'src/constants/common-constants'
import { CondOperator, SFields, SCondition } from '../types/cond-operators'

export const countDiff = (o1: Record<string, any>, o2: Record<string, any>): Record<string, any> =>
  omitBy(o1, (v, k) => o2[k] === v)

export const paramsSerializer = <TParams extends Record<string, any>>(params: TParams): string => stringify(params)

export const mergeEncodedQueries = (...encodedQueries: string[]) => encodedQueries.map((query) => query).join('&')

export const composeSearch = (paramsFilter: any): SCondition => {
  const result: SCondition = { $and: [] }
  for (const key of Object.keys(paramsFilter)) {
    const value = paramsFilter[key]
    let [field, ops] = key.split('||')

    if (field === 'searchBy') {
      const searchBy = ops.split(',')
      const filter = {
        $or: searchBy.map((f) => {
          if (!value && ops.includes('user.email')) return { ['user.email']: { $neL: '' } }
          else if (!value && ops.includes('email')) return { ['email']: { $neL: '' } }
          else if (!value && ops.includes('id')) return { ['id']: { $neL: '' } }
          else return { [f]: { $contL: value } }
        }),
      }
      result.$and?.push(filter)
      continue
    }
    if (field === 'rawFilter') {
      result.$and!.push(value)
      continue
    }

    if (!ops) {
      if (typeof paramsFilter[key] === 'number' || paramsFilter[key].match(/^\d+$/)) {
        ops = CondOperator.EQUALS
      } else {
        ops = CondOperator.CONTAINS
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1]
    }

    const filter: SFields = { [field]: { [ops]: value } }
    result.$and?.push(filter)
  }
  return result
}

export const getClientTimezone = () => localStorage.getItem(PREFERRED_TIMEZONE)
