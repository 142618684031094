import { styled, Button } from '@mui/material'
import { disabledButtonColor, mainColor, secondaryColor } from '../../constants/styles-constants'

const SuccessButton = styled(Button)({
  padding: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 8,
  marginRight: 4,
  marginLeft: 4,
  color: secondaryColor,
  backgroundColor: mainColor,
  textTransform: 'capitalize',
  '&:hover, &:active, &:focus': {
    color: secondaryColor,
    backgroundColor: mainColor,
  },
  '&:disabled': {
    color: secondaryColor,
    backgroundColor: disabledButtonColor,
  },
})

export default SuccessButton
