import { Member } from 'src/constants/member-constants'

export type TpiWorkflow = {
  id: string
  user_id: string
  workflow_state: TpiWorkflowState
}

export enum TpiWorkflowState {
  created = 'created',
  contacted = 'contacted',
  activating = 'activating',
  confirming = 'waitingForEmailConfirmation',
  active = 'active',
  rejected = 'rejected',
  trainerAssigned = 'trainerAssigned',
}

export type TpiWorkflowDetailsDto = TpiWorkflow & Pick<Member, 'first_name' | 'last_name' | 'email' | 'image'>
