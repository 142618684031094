import { format } from 'date-fns'
import { isNumber } from 'lodash'
import { BundleState, UserBundle } from 'src/api/services/bundles'
import { CurrencyFormats, formatCurrencyNumber } from 'src/utils/data-transformation-utils'

const DATE_FORMAT = 'MM-dd-yyyy'

type GetBundleData = {
  name: string
  sku: string | null
  unitPrice: string | null
  bundlePrice: string | null
  consumedAt: string | null
  sessionsCount: string | null
  consumedSessions: string | null
  installmentPayed: string | null
  sessionsCountInt: number | null
  consumedSessionsInt: number | null
  availableSessionsint: number | null
  status: string
}

const statusByStateMap: Record<BundleState, string> = {
  active: 'Active',
  future: 'Pending (Future)',
  activating: 'Pending (Activating)',
  suspended: 'Suspended',
  sentToBilling: 'In V2 Billing',
}

export const getBundleData = ({
  display_name,
  sku,
  unit_price,
  bundle_price,
  consumed_at,
  sessions_count,
  consumed_sessions,
  available_sessions,
  installment_payed,
  state,
}: UserBundle): GetBundleData => {
  const formattedUnitPrice = isNumber(unit_price)
    ? formatCurrencyNumber(unit_price, CurrencyFormats.enUs, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
    : null

  const formattedBundlePrice = isNumber(bundle_price)
    ? formatCurrencyNumber(bundle_price, CurrencyFormats.enUs, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      })
    : null

  const formattedConsumedAt = consumed_at ? format(new Date(consumed_at), DATE_FORMAT) : null

  return {
    name: display_name || 'bundle(no name)',
    sku: sku ? `SKU: ${sku}` : null,
    unitPrice: formattedUnitPrice ? `Unit Price: ${formattedUnitPrice}` : null,
    bundlePrice: formattedBundlePrice ? `Bundle Price: ${formattedBundlePrice}` : null,
    consumedAt: formattedConsumedAt ? `Consumed At: ${formattedConsumedAt}` : null,
    sessionsCount: isNumber(sessions_count) ? `Sessions: ${sessions_count}` : null,
    sessionsCountInt: sessions_count || null,
    consumedSessions: isNumber(consumed_sessions) ? `Consumed Sessions: ${consumed_sessions}` : null,
    consumedSessionsInt: isNumber(consumed_sessions) ? consumed_sessions : null,
    availableSessionsint: isNumber(available_sessions) ? available_sessions : null,

    installmentPayed: installment_payed ? `Installment Payed: ${installment_payed}` : null,
    status: `Status: ${statusByStateMap[state]}`,
  }
}
