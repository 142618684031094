export const buttonStyles = `
  background: #d9d9d9;

  &:hover {
    background: transparent;
    border: 2px solid #808080;
    border-radius: 50%;
  }

  svg {
    fill: #808080;
  }

  height: 48px;
  width: 48px;
`
