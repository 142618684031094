import React from 'react'
import { TpiWorkflowState } from 'src/constants/tpi-constants'
import TPIsPage from '../tpis'

const TPIWorkflowsActiveList = () => {
  const filterParams = {
    [`workflow_state||$in`]: [TpiWorkflowState.active],
  }
  return <TPIsPage filterParams={filterParams} />
}

export default TPIWorkflowsActiveList
