import React, { FC, VFC } from 'react'
import { Avatar, Link, TableCell as MUITableCell } from '@mui/material'
import Radio from '@mui/material/Radio'
import styled from '@emotion/styled'
import { AGORA_SESSION_CUT_OFF_DAYS } from 'src/config'

import { useRequest } from 'src/hooks/use-request'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'
import api from 'src/api'

import { NOOP } from '../../constants/common-constants'
import { getFormattedDate } from '../../utils/date-utils'
import { mainColor } from '../../constants/styles-constants'
import { getButtonDataQa, getFieldDataQa } from '../../utils/entities-data-qa-utils'
import { AccessibleTableColumnProps, getCellValueArgs } from './acessible-table-types'
import { CurrencyFormats, formatCurrencyNumber } from '../../utils/data-transformation-utils'
import { WorkoutStatus } from '../../constants/personal-workout-constants'
import { toPercentage } from '../../utils/percentage'
import { Resources } from 'src/types/permissions-types'
import SelectInput from '../inputs/select-input'
import moment from 'moment-timezone'

export const ABSENT_VALUE_PLACEHOLDER = '-'

const TableCell = styled(MUITableCell)`
  padding: 11px 16px;
`

const ActionButtonTableCell = styled(TableCell)`
  cursor: pointer;
  padding: 11px 16px;
`

const CurrencyContainer = styled.div`
  display: flex;
`

const CurrencyPostfix = styled.div`
  padding-left: 10px;
  color: #969696;
`

const getValue = (path: string, data: any) => {
  return path.split('.').reduce((acc, pathPart) => {
    const pathPartParsed = isNaN(Number(pathPart)) ? pathPart : Number(pathPart)
    return acc ? acc[pathPartParsed] : acc
  }, data)
}

export const getCellValue = ({ column, rowData }: getCellValueArgs) => {
  const { fields = [] } = column
  return fields.reduce((acc, field) => `${acc} ${getValue(field, rowData)} \n`, '').trim() || ABSENT_VALUE_PLACEHOLDER
}

const withAbsentValuePlaceholder =
  <T extends AccessibleTableColumnProps>(Component: VFC<T>): VFC<T> =>
  (props) => {
    const cellValue = getCellValue(props)
    if (cellValue === 'null' || cellValue === 'undefined') {
      return <TableCell>{ABSENT_VALUE_PLACEHOLDER}</TableCell>
    }
    return <Component {...props} />
  }

export const RadioButtonCell = withAbsentValuePlaceholder(({ isSelected, onRowClick }) => (
  <TableCell>
    <Radio
      value="a"
      size="small"
      name="radio-buttons"
      inputProps={{ 'aria-label': 'A' }}
      checked={isSelected}
      onChange={onRowClick}
    />
  </TableCell>
))

export const DataCell = withAbsentValuePlaceholder((props) => {
  const { rowData, column } = props
  const cellLink = column.link?.(rowData)
  const cellValue = column.getCellValue ? column.getCellValue(props) : getCellValue(props)
  const cellValueFormatted = column.formatter ? column.formatter(cellValue) : cellValue

  return (
    <TableCell style={{ wordBreak: 'break-all' }}>
      {cellLink ? <Link href={cellLink}>{cellValueFormatted}</Link> : cellValueFormatted}
    </TableCell>
  )
})
export const BooleanYesNoCell = withAbsentValuePlaceholder((props) => {
  const { rowData, column } = props
  const cellValue = rowData[column.key]
  return <TableCell style={{ wordBreak: 'break-all' }}>{cellValue ? 'Yes' : 'No'}</TableCell>
})

export const DataUserCell = withAbsentValuePlaceholder<AccessibleTableColumnProps & { data: string }>((props) => {
  const { rowData, column, data } = props
  const cellLink = column.link?.(rowData)
  const cellValue = getCellValue(props)
  return (
    <TableCell style={{ wordBreak: 'break-all' }}>
      {data ? data : cellLink ? <Link href={cellLink}>{cellValue}</Link> : cellValue}
    </TableCell>
  )
})

export const DataDropCell = withAbsentValuePlaceholder<
  AccessibleTableColumnProps & { dropData: { id: string; label: string }[]; onSelect: (value: any) => void }
>((props) => {
  const { dropData, onSelect } = props
  const cellValue = getCellValue(props)
  return (
    <TableCell style={{ wordBreak: 'break-all' }}>
      <SelectInput
        id={getFieldDataQa(Resources.tpiWorkflows, '/workflows')}
        value={cellValue}
        placeholder="Select workflow state"
        sx={{ width: '350px', maxWidth: '100%', height: 40, maxHeight: 40, marginBottom: 2 }}
        choices={dropData}
        onSelect={onSelect}
      />
    </TableCell>
  )
})

export const SessionIdCell = withAbsentValuePlaceholder((props) => {
  const { rowData } = props
  const cellValue = getCellValue(props)
  const msSinceSession = moment(new Date()).diff(moment(new Date(rowData.date)))
  const daysSinceSession = Math.ceil(moment.duration(msSinceSession).asDays())

  const agoraRequest =
    daysSinceSession < AGORA_SESSION_CUT_OFF_DAYS
      ? useRequest(
          api.entities.getAgoraIframeLinkForSession,
          [WorkoutStatus.Completed, WorkoutStatus.UnableToComplete].includes(rowData.workout_status)
            ? [rowData.id]
            : undefined,
        )
      : null

  useRequestErrorHandler(agoraRequest?.error)

  const link = agoraRequest?.data?.url
  return (
    <TableCell>
      {link ? (
        <Link href={link} target="_blank">
          {cellValue}
        </Link>
      ) : (
        cellValue
      )}
    </TableCell>
  )
})

export const CurrencyCell = withAbsentValuePlaceholder<
  AccessibleTableColumnProps & {
    postfix?: string
    format?: CurrencyFormats
    options?: Intl.NumberFormatOptions
  }
>((props) => {
  const { rowData, postfix, format, options } = props
  const cellValue = getCellValue(props)
  return (
    <TableCell>
      {rowData?.currency || postfix ? (
        <CurrencyContainer>
          <div>{formatCurrencyNumber(+cellValue, format, options)}</div>
          <CurrencyPostfix>{rowData?.currency || postfix}</CurrencyPostfix>
        </CurrencyContainer>
      ) : (
        formatCurrencyNumber(+cellValue, format, options)
      )}
    </TableCell>
  )
})

export const NestedDataCell = withAbsentValuePlaceholder<AccessibleTableColumnProps & { nestedFields: string[] }>(
  (props) => {
    const { rowData, column, nestedFields } = props
    const cellLink = column.link?.(rowData)
    const { fields = [] } = column
    const [rootCellField] = fields
    const cellDataItem = rowData[rootCellField] || ({} as Record<string, any>)
    const nestedCellValue = nestedFields.reduce((acc, filed) => `${acc} ${cellDataItem[filed]}`, '')
    return <TableCell>{cellLink ? <Link href={cellLink}>{nestedCellValue}</Link> : nestedCellValue}</TableCell>
  },
)

export const PercentCell = withAbsentValuePlaceholder((props) => {
  const { rowData, column } = props
  const cellLink = column.link?.(rowData)
  const cellValue = getCellValue(props)
  const data = toPercentage(Number(cellValue))
  return <TableCell>{cellLink ? <Link href={cellLink}>{data}</Link> : data}</TableCell>
})

export const DataListCell = withAbsentValuePlaceholder<AccessibleTableColumnProps & { itemFields?: string[] }>(
  ({ rowData, column, itemFields = [] }) => {
    const { fields = [] } = column
    const [rootCellField] = fields
    const fieldList = rowData[rootCellField] as Record<string, any>[]
    return (
      <TableCell>
        {fieldList.map((item: Record<string, any>) => {
          const itemValue = itemFields.reduce((acc, filed) => `${acc} ${item[filed]}`, '')
          return <span key={item.id}>{itemValue}</span>
        })}
      </TableCell>
    )
  },
)

export const DateCell = withAbsentValuePlaceholder<
  AccessibleTableColumnProps & { options?: Intl.DateTimeFormatOptions }
>(({ rowData, column, options }) => {
  const { fields = [] } = column
  return <TableCell>{getFormattedDate(rowData[fields[0]], undefined, options)}</TableCell>
})

export const ActionCell: VFC<
  AccessibleTableColumnProps & {
    renderIcon: VFC<any>
    displayActionIconArgs?: string[]
    isDisplayActionIcon?: (...args: any[]) => boolean
    action?: (rowData: Record<string, any>) => void
  }
> = ({
  rowData,
  resource,
  tableId,
  column,
  isActive,
  renderIcon,
  displayActionIconArgs = [],
  isDisplayActionIcon,
  action = NOOP,
}) => {
  const { title: actionTitle = '' } = column
  const displayActionIconArgsExtracted = displayActionIconArgs.map((arg) => rowData[arg])
  if (!isDisplayActionIcon || isDisplayActionIcon(...displayActionIconArgsExtracted)) {
    return (
      <ActionButtonTableCell
        align="right"
        data-qa={getButtonDataQa(resource, `${tableId}/${rowData.id}`)}
        onClick={() => action(rowData)}
      >
        {renderIcon && renderIcon({ isActive })} {actionTitle}
      </ActionButtonTableCell>
    )
  } else {
    return <TableCell align="right">{null}</TableCell>
  }
}

export const AvatarCell: VFC<AccessibleTableColumnProps & { action?: (rowData: Record<string, any>) => void }> = ({
  resource,
  tableId,
  rowData,
  column,
  action = NOOP,
}) => {
  const { fields = [] } = column
  const [rootCellField] = fields
  const { first_name, last_name } = rowData
  return (
    <ActionButtonTableCell
      align="right"
      data-qa={getButtonDataQa(resource, `${tableId}/${rowData.id}`)}
      onClick={() => action(rowData)}
    >
      <Avatar
        sx={{ bgcolor: mainColor }}
        alt={`${first_name} ${last_name} avatar`}
        src={rowData[rootCellField]}
        variant="circular"
        style={{ width: 50, height: 50 }}
      >
        {first_name?.[0]?.toUpperCase()}
        {last_name?.[0]?.toUpperCase()}
      </Avatar>
    </ActionButtonTableCell>
  )
}

export const ComponentCell: FC<AccessibleTableColumnProps> = ({ resource, tableId, rowData, children }) => (
  <TableCell align="left" data-qa={getButtonDataQa(resource, `${tableId}/${rowData.id}`)}>
    {children}
  </TableCell>
)
