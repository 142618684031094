import React from 'react'
import styled from '@emotion/styled'
import { TableRow } from '@mui/material'
import Typography from '@mui/material/Typography'

import { NOOP } from '../../constants/common-constants'
import { AccessibleTableRowProps } from './acessible-table-types'
import NoMaxWidthTooltip from '../no-max-width-tooltip'

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (propName) => !['highlighted', 'clickable'].includes(propName as string),
})<{ highlighted: boolean; clickable: boolean }>(({ highlighted, clickable }) => {
  const highlightedStyles = {
    background: 'rgba(0, 0, 0, 0.12)',
  }
  const clickableStyles = {
    cursor: 'pointer',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.12)',
      transform: 'scale(0.995)',
    },
  }
  return { ...(highlighted ? highlightedStyles : {}), ...(clickable ? clickableStyles : {}) }
})

function AccessibleTableRow(props: AccessibleTableRowProps) {
  const {
    tableId,
    resource,
    rowData,
    withClickableRows,
    columns,
    isSelected,
    isHighlighted,
    isActive,
    tooltipTitle = '',
    onRowClick = NOOP,
    onRowHover = NOOP,
  } = props

  const renderTooltipTitle = tooltipTitle ? <Typography color="inherit">{tooltipTitle}</Typography> : ''

  return (
    <NoMaxWidthTooltip arrow placement="top-end" title={renderTooltipTitle}>
      <StyledTableRow
        highlighted={isHighlighted}
        clickable={withClickableRows}
        onClick={onRowClick}
        onMouseOver={onRowHover}
      >
        {columns.map((column) => {
          const Component = column.render
          return (
            <Component
              key={column.key}
              column={column}
              onRowClick={onRowClick}
              rowData={rowData}
              tableId={tableId}
              resource={resource}
              isSelected={isSelected}
              isActive={isActive}
            />
          )
        })}
      </StyledTableRow>
    </NoMaxWidthTooltip>
  )
}

export default AccessibleTableRow
