import styled from '@emotion/styled'
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

interface DragRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
}

const type = 'DragRow'

const StyledRow = styled.tr`
  &.drop-over-downward td {
    border-bottom: 2px dashed #1890ff;
  }

  &.drop-over-upward td {
    border-top: 2px dashed #1890ff;
  }
`

export const DragRow = ({ index, moveRow, className, style, ...restProps }: DragRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: { index: number }) => {
      item.index !== index && moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))

  return (
    <StyledRow
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}
