import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getHistoryWorkout,
  getHistoryWorkoutError,
  getIsHistoryWorkoutLoading,
} from 'src/redux/member/member-selectors'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { PageSectionTableMaxHeight } from '../components/ui-kit'
import TableSectionContainer from '../components/table-section-container'
import { Order } from 'src/types/common-types'

type ResourceCPSTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
}

function ResourceCPSHistoryTable(props: ResourceCPSTableProps) {
  const { title, resource, columns, emptyDataMessage } = props
  const tableId = `${resource}-table`

  const cpsHistory = useSelector(getHistoryWorkout)
  const cpsHistoryError = useSelector(getHistoryWorkoutError)
  const isCPSHistoryLoading = useSelector(getIsHistoryWorkoutLoading)
  const [showAllWorkouts, setshowAllWorkouts] = useState(false)
  const buttonText = showAllWorkouts ? 'Show Less' : 'Show All'
  const cpsHistoryData = useMemo(
    () => (showAllWorkouts ? cpsHistory : [...cpsHistory.slice(0, 5)]),
    [cpsHistory, showAllWorkouts],
  )
  const completedSessions = cpsHistory.filter((cp) => cp.workout_status.toLowerCase() === 'completed')

  const updateshowAllWorkouts = () => setshowAllWorkouts(!showAllWorkouts)

  return (
    <TableSectionContainer
      title={title}
      buttonTitle={cpsHistory!.length > 5 ? buttonText : undefined}
      onButtonClick={updateshowAllWorkouts}
    >
      <PageSectionTableMaxHeight
        id={tableId}
        columns={columns}
        resource={resource}
        errorOnDataLoad={cpsHistoryError}
        isLoading={isCPSHistoryLoading}
        data={cpsHistoryData}
        sortBy="start_time"
        sortByDirection={Order.desc}
        emptyDataMessage={emptyDataMessage}
      />
      {completedSessions.length > 0 && (
        <h4 style={{ opacity: 0.8, paddingLeft: '10px' }}>{completedSessions.length} session(s) completed!</h4>
      )}
    </TableSectionContainer>
  )
}

export default ResourceCPSHistoryTable
