import * as React from 'react'
import { Avatar } from '@mui/material'

enum AvatarVariant {
  circular = 'circular',
  rounded = 'rounded',
  square = 'square',
}

const DEFAULT_IMAGE_SOURCE = '/broken-image.jpg'

const getImageSource = (source: string, record?: string | { [key: string]: any }) => {
  if (typeof record === 'string') return record
  const sourceValue = record ? record[source] : null
  return sourceValue || DEFAULT_IMAGE_SOURCE
}

const PreviewImage = (props: {
  record?: string | { [key: string]: any }
  source: string
  label: string
  size?: number
  variant?: AvatarVariant
}) => {
  const { record, source, label, size = 50, variant = AvatarVariant.square } = props
  const imgSource = getImageSource(source, record)

  if (!record) {
    return null
  }

  return <Avatar alt={label} src={imgSource} variant={variant} style={{ width: size, height: size }} />
}

export default PreviewImage
