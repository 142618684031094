import React, { useEffect, useState } from 'react'
import { Modal, TableColumnsType } from 'antd'
import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { DragTable } from '../../../components/drag-table/drag-table'
import { PersonalTrainer } from '../../../constants/personal-trainer-constants'
import { Link } from 'react-router-dom'
import { routing } from 'src/boot/routing'

const columns: TableColumnsType<PersonalTrainer> = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Rank',
    dataIndex: 'sort_rank',
  },
  {
    key: 'edit',
    title: '',
    render: (props) => {
      const link = props?.id ? routing.personalTrainer.generatePath({ id: props?.id }) : ''
      return (
        <Link style={{ textDecoration: 'none', color: '#5868CE' }} to={link}>
          Edit
        </Link>
      )
    },
  },
]

export function EditTrainerRankingModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [trainers, setTrainers] = useState<PersonalTrainer[]>([])
  const [loading, setLoading] = useState(false)
  const fetchTrainers = async () => {
    setLoading(true)
    try {
      const response = await api.common.getList(Resources.personalTrainers)
      const data = response.data as PersonalTrainer[]

      const activeTrainers = data
        .filter(({ email, active, test }) => active && !test && Boolean(email))
        .sort((a, b) => a.sort_rank - b.sort_rank)

      setTrainers(activeTrainers)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTrainers()
  }, [])

  return (
    <Modal
      style={{ marginLeft: '23%', top: '55px' }}
      width="70vw"
      title="Trainer Ranking"
      open={open}
      onCancel={onClose}
      cancelText="Close"
      okButtonProps={{ style: { display: 'none' } }}
    >
      <DragTable
        style={{ height: '60vh', marginBottom: 30 }}
        scroll={{ y: '60vh' }}
        dataSource={trainers}
        columns={columns}
        rowKey="id"
        loading={loading}
        moveRow={async (from, to) => {
          const fromRow = trainers[from]
          const toRow = trainers[to]

          setLoading(true)

          await api.common.update(Resources.personalTrainers, {
            id: fromRow.id,
            data: { sort_rank: toRow.sort_rank },
            previousData: fromRow,
          })

          await fetchTrainers()
        }}
      />
    </Modal>
  )
}
