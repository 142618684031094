import React, { useCallback, useMemo, useState } from 'react'

import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { useAction } from 'src/hooks/use-actions'
import { setPersonalTrainerRecord } from 'src/redux/personal-trainer/personal-trainer-reducer'
import { Resources } from 'src/types/permissions-types'
import { FormeDialog } from 'src/hooks/useDialog'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import api from 'src/api'

import EditEntityMediaDialog from '../../../components/dialogs/edit-entity-media-dialog'

export const EditTrainerMediaDialog: FormeDialog<{ record: PersonalTrainer; isHeadImage: boolean }> = ({
  params: { record, isHeadImage },
  open,
  onClose,
}) => {
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const onUpdateTrainerRecord = useAction(setPersonalTrainerRecord)
  const [currentTrainerRecord, setCurrentTrainerRecord] = useState<PersonalTrainer | null>(record ?? null)

  const getTrainerRecord = useCallback(() => {
    if (!record.id) return
    return api.common
      .getOne(Resources.personalTrainers, { id: record.id })
      .catch(() => onDisplayErrorNotification('Sorry, something went wrong, please reload the page'))
  }, [record.id, onDisplayErrorNotification])

  const onUploadFileCallBack = useCallback(async () => {
    const { data: nextTrainerRecord } = await getTrainerRecord()
    onUpdateTrainerRecord(nextTrainerRecord)
    setCurrentTrainerRecord(nextTrainerRecord)
  }, [getTrainerRecord, onUpdateTrainerRecord])

  const onDeleteFileCallBack = useCallback(() => {
    const update: Partial<PersonalTrainer> = isHeadImage ? { avatar_head_image: null } : { avatar: null }
    const newRecord = { ...record, ...update }
    onUpdateTrainerRecord(newRecord)
    setCurrentTrainerRecord(newRecord)
  }, [record, isHeadImage, onUpdateTrainerRecord])

  const params = useMemo(
    () => ({
      title: 'Edit Trainer Media',
      resource: Resources.personalTrainers,
      resourceItemId: record.id,
      avatar: !isHeadImage ? currentTrainerRecord?.avatar : currentTrainerRecord?.avatar_head_image,
      query: { headImage: isHeadImage ? 'true' : 'false' },
      onDeleteFileCallBack,
      onUploadFileCallBack,
    }),
    [record, currentTrainerRecord, isHeadImage, onDeleteFileCallBack, onUploadFileCallBack],
  )

  return <EditEntityMediaDialog params={params} open={open} onClose={onClose} />
}
