import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import styled from '@emotion/styled'

import { ActionItemConfig } from './actions-dropdown'
import { mainColor } from 'src/constants/styles-constants'

export type AdditionalActionsMenuConfig = ActionItemConfig & {
  icon: React.ReactNode
}

interface AdditionalActionsMenuProps {
  rowData: { [key: string]: any }
  actionsConfig?: AdditionalActionsMenuConfig[]
}

const ThreeDotIcon = styled(MoreVertIcon)`
  &:hover {
    fill: ${mainColor};
  }
`

const MenuItemIcon = styled.div`
  margin-right: 15px;
`

const AdditionalActionsMenu = (props: AdditionalActionsMenuProps) => {
  const { rowData, actionsConfig } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const onHover = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget)
    }
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  if (!rowData || !actionsConfig || !actionsConfig.length) {
    return null
  }

  return (
    <div>
      <ThreeDotIcon
        aria-owns="pop-up-menu"
        aria-haspopup="true"
        //@ts-expect-error: typescript won't allow this to be corretly typed without throwing an event type error
        onMouseOver={(e: React.MouseEvent<HTMLElement>) => onHover(e)}
      />
      <Menu
        id="pop-up-menu"
        disableAutoFocusItem
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onClose}
        MenuListProps={{ onMouseLeave: onClose }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {actionsConfig.map(({ dataQa, title, icon, onClick }) => (
          <MenuItem
            key={dataQa}
            data-qa={dataQa}
            onClick={() => {
              onClick(rowData)
              onClose()
            }}
          >
            {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
            {title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default AdditionalActionsMenu
