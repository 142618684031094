import React, { useCallback } from 'react'
import { TableRow } from '@mui/material'
import { TableCell } from './styles'
import { CommercialDetail, CommercialMembershipBusinessType } from '../../api/services/cloud/memberships/types'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { Input, Select } from '../../antd-components'
import { requestCommercialUpdate } from '../../api/services/cloud/memberships/requestCommercialUpdate'
import { useDebouncedCallback } from '../../hooks/use-debounced-callback'
import { useSelector } from 'react-redux'
import { getIsOwner, getMembership } from '../../redux/member/member-selectors'

const businessTypeOptions = Object.keys(CommercialMembershipBusinessType).map((value) => ({
  value,
  label: value.charAt(0).toLocaleUpperCase() + value.slice(1),
}))

export function CommercialSection() {
  const membership = useSelector(getMembership)
  const isOwner = useSelector(getIsOwner)
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const isCommercial = membership?.class.type === 'commercial'
  const commercial_detail = membership?.commercial_detail

  const onCommercialChange = useCallback(
    (data: Partial<CommercialDetail>) => {
      membership?.id &&
        requestCommercialUpdate(membership.id, data).catch(() => {
          onDisplayErrorNotification('Error changing field. Please refresh and try again.')
        })
    },
    [membership?.id, onDisplayErrorNotification],
  )

  const onCommercialChangeDebounced = useDebouncedCallback(onCommercialChange, 400)

  if (!isCommercial) return null

  return (
    <>
      <TableRow>
        <TableCell>Business Name</TableCell>
        <TableCell>
          <Input
            disabled={!isOwner}
            defaultValue={commercial_detail?.business_name}
            onChange={(value) => onCommercialChangeDebounced({ business_name: value })}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Business Type</TableCell>
        <TableCell>
          <Select
            disabled={!isOwner}
            options={businessTypeOptions}
            defaultValue={commercial_detail?.business_type}
            onChange={(value) => onCommercialChange({ business_type: value })}
          />
        </TableCell>
      </TableRow>
    </>
  )
}
