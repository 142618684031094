import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Resources } from '../../types/permissions-types'
import ResourceListContainer from '../../containers/resource-list-container'
import { DataCell, DateCell } from '../../components/accessible-table/accessible-table-cell'
import { ColumnItem } from '../../components/accessible-table/acessible-table-types'
import { PageContainer } from 'src/components/ui-kit'
import { createRegularSearchFilter } from '../../containers/resource-list-container/filters'
import { Button } from '@mui/material'
import { getButtonDataQa } from '../../utils/entities-data-qa-utils'
import { KnownDevicesAddModal } from './known-devices-add-modal'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'

const columns: ColumnItem[] = [
  { key: 'id', title: 'ID', fields: ['id'], render: DataCell },
  { key: 'created_date', title: 'Created Date', fields: ['created_date'], render: DateCell },
  {
    key: 'updated_date',
    title: 'Updated Date',
    fields: ['updated_date'],
    render: DateCell,
  },
]

function KnownDevicesList() {
  const [open, setOpen] = useState(false)
  const { isEditAllowed } = useResourcePermissionHandler(Resources.studios)
  const searchFilter = createRegularSearchFilter(['id'])

  return (
    <PageContainer>
      <ResourceListContainer
        additionalActions={
          isEditAllowed ? (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              data-qa={getButtonDataQa(Resources.knownDevice, 'add')}
            >
              Add Known Device
            </Button>
          ) : null
        }
        searchFilter={searchFilter}
        searchFilterPlaceholder="Search by name, email or auth0..."
        columns={columns}
        resource={Resources.knownDevice}
      />
      <KnownDevicesAddModal open={open} onClose={() => setOpen(false)} />
    </PageContainer>
  )
}

export default KnownDevicesList
