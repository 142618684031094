import React from 'react'
import { Identifier } from '../../../types/request-entities-types'
import { Resources } from '../../../types/permissions-types'
import { ColumnItem } from '../../../components/accessible-table/acessible-table-types'
import ResourceChargesTable from 'src/containers/resource-charges-table'
import ResourceCouponsTable from '../../../containers/resource-coupons-table'

type MemberChargesTabProps = {
  recordId: Identifier
  allUserChargesTableColumns: ColumnItem[]
  allUserCouponsTableColumns: ColumnItem[]
}

function MemberChargesTab(props: MemberChargesTabProps) {
  const { recordId, allUserChargesTableColumns, allUserCouponsTableColumns } = props

  return (
    <>
      <ResourceChargesTable
        title="User Charges"
        columns={allUserChargesTableColumns}
        recordId={recordId ?? ''}
        resource={Resources.charge}
        emptyDataMessage="Not available"
      />
      <ResourceCouponsTable
        title="Coupons"
        columns={allUserCouponsTableColumns}
        recordId={recordId ?? ''}
        resource={Resources.coupons}
        emptyDataMessage="Not available"
      />
    </>
  )
}

export default MemberChargesTab
