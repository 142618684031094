import { Resources } from '../../types/permissions-types'
import { CreateParams } from '../../types/request-entities-types'
import { checkStatus, getRequestParams, HttpClient, RequestMethods } from '../http-client'

const filesApi = (baseUrl: string, httpClient: HttpClient) => ({
  uploadImage: async (
    resource: Resources,
    params: CreateParams & { id?: string; subUrl?: string; withReload?: boolean },
  ) => {
    const { data, subUrl } = params
    const url = `${baseUrl}/${resource}`
    const requestUrl = subUrl ? `${url}/${subUrl}` : url

    const s3Response = await httpClient(requestUrl, { method: RequestMethods.post, isFile: true })
    const signedUrl = s3Response?.signedUrl

    const requestParams = getRequestParams({ method: RequestMethods.put, body: data, isFile: true })
    return fetch(signedUrl, requestParams).then(checkStatus)
  },
})

export default filesApi
