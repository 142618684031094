import React from 'react'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Grid } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

import { routing, MemberRouteParams } from 'src/boot/routing'

import { PageSectionHeader } from '../components/ui-kit'
import { MemberTabKeys, memberTabSubRoutesToKeysMap, memberTabKeysToSubRoutesMap } from '../constants/member-constants'

export type TabItemConfig = {
  key: string
  title: string
  tabComponent: React.ReactNode
  disabled?: boolean
}

type MemberTabContentSectionProps = {
  tabsConfig: TabItemConfig[]
  headerAdditionalActions?: React.ReactNode
}

const TabContent = styled(PageSectionHeader.withComponent(Box))`
  border-bottom: 1px solid ${(_) => _.theme.palette.divider};
`

function PageTabSectionContainer(props: MemberTabContentSectionProps) {
  const { tabsConfig, headerAdditionalActions } = props
  const { id, tab } = useParams<MemberRouteParams>()
  const history = useHistory()

  const onSelectContentTab = (event: React.SyntheticEvent, newValue: MemberTabKeys) => {
    history.push(routing.member.generatePath({ id, tab: memberTabKeysToSubRoutesMap[newValue] }))
  }

  return (
    <Grid item xs={9}>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={memberTabSubRoutesToKeysMap[tab]}>
          <TabContent>
            <TabList aria-label="members content tabs" onChange={onSelectContentTab}>
              {tabsConfig.map((tabConfig) => (
                <Tab
                  key={tabConfig.key}
                  value={tabConfig.key}
                  label={tabConfig.title}
                  disabled={!!tabConfig.disabled}
                />
              ))}
            </TabList>
            {headerAdditionalActions}
          </TabContent>
          {tabsConfig.map((tabConfig) => (
            <TabPanel key={tabConfig.key} value={tabConfig.key}>
              {tabConfig.tabComponent}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Grid>
  )
}

export default PageTabSectionContainer
