import React, { useEffect, useMemo } from 'react'
import { TableRow } from '@mui/material'
import { TableCell } from './styles'
import { useAction } from '../../hooks/use-actions'
import { fetchEstimatedBill } from '../../redux/member/member-actions'
import { useRequest } from '../../hooks/use-request'
import { requestProfileExt } from '../../api/services/cloud/profiles/requestProfileExt'
import { useSelector } from 'react-redux'
import { getEstimatedBill, getMemberRecord } from '../../redux/member/member-selectors'

export function UpcomingCharge() {
  const memberRecord = useSelector(getMemberRecord)

  const { data: profileExt, fetch: fetchProfileOneExt } = useRequest(requestProfileExt)
  const profileExtDetails = useMemo(() => profileExt, [profileExt])

  useEffect(() => {
    memberRecord?.id &&
      memberRecord?.membershipProfiles &&
      memberRecord?.membershipProfiles[0]?.id &&
      fetchProfileOneExt(memberRecord.membershipProfiles[0].id)
  }, [memberRecord?.id, fetchProfileOneExt, memberRecord?.membershipProfiles])

  const onFetchEstimatedBill = useAction(fetchEstimatedBill)

  useEffect(() => {
    memberRecord?.id && profileExtDetails?.nextBillingDate && onFetchEstimatedBill(memberRecord.id, 'monthly')
  }, [profileExtDetails?.nextBillingDate, memberRecord, onFetchEstimatedBill])

  const estimatedBill = useSelector(getEstimatedBill)

  return (
    <TableRow>
      <TableCell>Upcoming charge</TableCell>
      <TableCell>{estimatedBill}</TableCell>
    </TableRow>
  )
}
