import { useCallback, useEffect, useRef, useState } from 'react'
import { makePromiseCancelable, RequestCancellationError } from 'src/utils/make-promise-cancelable'

export type Query<Params extends any[], Response> = {
  data: Response | undefined
  isLoading: boolean
  error: Error | undefined
  fetch: (...p: Params) => Promise<Response>
}

export const useRequest = <Params extends any[], Response>(
  request: (...params: Params) => Promise<Response>,
  params?: Params,
): Query<Params, Response> => {
  const [data, setData] = useState<Response>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const cancelRequestRef = useRef<() => void>()

  const fetch = useCallback(
    (...p: Params) => {
      cancelRequestRef.current?.()
      const { promise, cancel } = makePromiseCancelable<Response>(request(...p))
      cancelRequestRef.current = cancel
      setError(undefined)
      setIsLoading(true)
      return promise
        .then((response) => {
          setData(response)
          return response
        })
        .catch((e) => {
          if (!(e instanceof RequestCancellationError)) setError(e)
          return e
        })
        .finally(() => setIsLoading(false))
    },
    [request, setData, setError, setIsLoading],
  )

  useEffect(() => {
    if (params) fetch(...params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)])

  useEffect(() => () => cancelRequestRef.current?.(), [])

  return { data, isLoading, error, fetch }
}
