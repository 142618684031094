import React from 'react'
import { Resources } from '../../types/permissions-types'
import ResourceListContainer from '../../containers/resource-list-container'
import { membershipsColumns } from './columns'
import { routing } from '../../boot/routing'
import { useHistory } from 'react-router-dom'

const filterApiResource = {
  [`class.type||$eq`]: 'commercial',
}

function MembershipsList() {
  const history = useHistory()

  return (
    <ResourceListContainer
      columns={membershipsColumns}
      resource={Resources.membership}
      join={['profiles', 'profiles.fullUser']}
      filterApiResource={filterApiResource}
      sortField="commercial_detail.business_name"
      withClickableRows
      onRowClick={(record) => {
        const activeProfile = record.profiles.filter(({ role }: { role: string }) => role === 'owner')?.[0]

        history.push(routing.member.generatePath({ id: activeProfile.userId }))
      }}
    />
  )
}

export default MembershipsList
