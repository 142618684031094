import { useCallback } from 'react'
import { intersection } from 'lodash'
import { nanoid } from 'nanoid'

import { useRequest } from 'src/hooks/use-request'
import { OrderTypes } from 'src/api/services/orders/types'
import { requestShopifyOrder } from 'src/api/services/orders/requestShopifyOrder'

import { useCachedProducts } from '../../useCachedProducts'
import { useCachedServiceCodes } from '../../useCachedServiceCodes'
import { CreateOrderFormData } from './useFormState'

export const useOrderRequest = () => {
  const { fetch: originalFetch, ...rest } = useRequest(requestShopifyOrder)
  const allParts = useCachedProducts()
  const allCodes = useCachedServiceCodes()

  const fetch = useCallback(
    async (name: string, orderType: OrderTypes): Promise<CreateOrderFormData> => {
      const data = await originalFetch(name)
      const skuList = data.line_items.map((item) => item.sku)
      return {
        name: data.name,
        email: data.email,
        address_type: data.address_type,
        address: data.shipping_address,
        orderInfo: {},
        type: '',
        service_codes: allCodes!
          .filter(
            (code) =>
              code.type.includes(orderType) &&
              (orderType === OrderTypes.DELIVERY ? intersection(code.product, skuList).length : true),
          )
          .map((c) => ({ key: nanoid(), code: c.code })),
        parts: allParts!
          .filter((p) => skuList.includes(p.sku))
          .map((p) => ({ key: nanoid(), sku: p.sku, part: p.part })),
      }
    },
    [allCodes, allParts, originalFetch],
  )

  return { fetch: allCodes && allParts ? fetch : undefined, ...rest }
}
