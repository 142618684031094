import React, { useCallback, useMemo, useState } from 'react'

import api from '../../api'
import { NOOP } from '../../constants/common-constants'
import { Resources } from '../../types/permissions-types'
import { RequestError } from '../../api/http-client'
import { getErrorMessage } from '../../utils/errors'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { FormeDialog } from '../../hooks/useDialog'
import { Modal, Button, Tooltip } from 'src/antd-components'
import { DeleteFilled } from '@ant-design/icons'
import { UploadFile } from 'antd'
import styled from '@emotion/styled'
import { mainOppositeTextColor } from 'src/constants/styles-constants'
import FileInput from '../upload-images'

type EditEntityMediaDialogProps = {
  title: string
  resource: Resources
  resourceItemId: string
  avatar?: string | null
  query?: Record<string, string>
  onUploadFileCallBack?: () => void
  onDeleteFileCallBack?: () => void
}

const maxFileSize = 25000000
const supportedFileTypes = ['image/png', 'image/jpeg']

const Container = styled.div`
  width: 100%;

  .ant-upload.ant-upload-drag {
    hieght: 160px;
  }
  .ant-upload-list-picture-card-container {
    width: 500px;
  }
`

const Wrapper = styled.div`
  width: 500px;
  margin: auto;
`

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const TooltipMessage = styled.span`
  line-height: 30px;
  margin-right: 10px;
  font-size: 14px;
`

const StyledDeleteIcon = styled(DeleteFilled)`
  &.anticon {
    color: ${mainOppositeTextColor};
  }
`

const EditEntityMediaDialog: FormeDialog<EditEntityMediaDialogProps> = (props) => {
  const {
    params: {
      title,
      resourceItemId,
      avatar: fileUrl,
      resource,
      query,
      onUploadFileCallBack = NOOP,
      onDeleteFileCallBack = NOOP,
    },
    open: isOpened,
    onClose,
  } = props

  const { isEditAllowed } = useResourcePermissionHandler(resource)
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const [selectedFile, setSelectedFile] = useState<UploadFile | null>(null)
  const [isDeleteTooltipOpen, setIsDeleteTooltipOpen] = useState(false)

  const onCloseMediaDialog = useCallback(() => {
    setSelectedFile(null)
    onClose()
  }, [onClose])

  const isFileSelected = !!selectedFile
  const isFileTypeAvailable = selectedFile && supportedFileTypes.includes(selectedFile.type!)
  const isFileSizeAvailable = selectedFile && selectedFile.size! <= maxFileSize

  const isUploadAvailable = useMemo(() => {
    const isSelectedFileAvailable = isFileSelected && isFileTypeAvailable && isFileSizeAvailable
    if (!fileUrl) return isSelectedFileAvailable
    return isSelectedFileAvailable && selectedFile && !fileUrl.includes(selectedFile.name)
  }, [fileUrl, selectedFile, isFileSelected, isFileSizeAvailable, isFileTypeAvailable])

  const onUploadImage = () => {
    if (!selectedFile) return onDisplayErrorNotification('File expected')
    if (!isFileTypeAvailable) return onDisplayErrorNotification('Unsupported image format')
    if (!isFileSizeAvailable) return onDisplayErrorNotification('Maximum file size 25MB')

    const params = {
      id: resourceItemId,
      data: selectedFile.originFileObj,
      subUrl: addQueryParams(`${resourceItemId}/avatar`, query),
    }

    api.files
      .uploadImage(resource, params)
      .then(() => {
        onDisplaySuccessNotification('Image was successfully uploaded.')
        setTimeout(() => onUploadFileCallBack(), 3000)
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })

    onCloseMediaDialog()
  }

  const onDeleteAvatar = () => {
    if (!fileUrl) return
    const params = { id: resourceItemId, subUrl: addQueryParams(`avatar`, query), previousData: { id: resourceItemId } }

    api.common
      .delete(resource, params)
      .then(() => {
        onDisplaySuccessNotification('Image was successfully deleted')
        onDeleteFileCallBack()
      })
      .catch((error: RequestError) => onDisplayErrorNotification(getErrorMessage(error)))
  }

  const addQueryParams = (subUrl: string, params?: Record<string, string>) =>
    `${subUrl}?${params ? new URLSearchParams(params).toString() : ''}`

  if (!isEditAllowed) {
    return null
  }

  return (
    <Modal
      width={550}
      title={title}
      okText="Upload"
      cancelText="Cancel"
      open={isOpened}
      okButtonProps={{
        disabled: !isUploadAvailable,
      }}
      onOk={onUploadImage}
      onCancel={onCloseMediaDialog}
      footer={
        <>
          <Button onClick={onCloseMediaDialog}>Cancel</Button>
          <Tooltip
            open={isDeleteTooltipOpen}
            onOpenChange={setIsDeleteTooltipOpen}
            placement="topLeft"
            trigger="click"
            title={
              <TooltipContainer>
                <TooltipMessage>Are you sure you want to delete this image?</TooltipMessage>
                <Button
                  type="text"
                  icon={<StyledDeleteIcon />}
                  onClick={() => {
                    onDeleteAvatar()
                    setIsDeleteTooltipOpen(false)
                  }}
                />
              </TooltipContainer>
            }
          >
            <Button danger disabled={!fileUrl}>
              Delete
            </Button>
          </Tooltip>

          <Button onClick={onUploadImage} disabled={!isUploadAvailable} type="primary">
            Upload
          </Button>
        </>
      }
    >
      <Container>
        <Wrapper>
          <FileInput
            fileUrl={fileUrl}
            maxFileSize={maxFileSize}
            supportedFileTypes={supportedFileTypes}
            selectedFile={selectedFile}
            onSelectFiles={(files: UploadFile[]) => setSelectedFile(files[0])}
            onDelete={() => {
              onDisplaySuccessNotification('File has been removed')
              setSelectedFile(null)
            }}
          />
        </Wrapper>
      </Container>
    </Modal>
  )
}

export default EditEntityMediaDialog
