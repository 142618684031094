import React, { MutableRefObject, memo } from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import styled from '@emotion/styled'

import { NOOP } from '../../constants/common-constants'
import { Resources } from '../../types/permissions-types'
import { ColumnItem } from '../../components/accessible-table/acessible-table-types'
import { useResourceListData } from '../../hooks/use-resource-list-data'
import { createStructuredSelector } from 'reselect'
import {
  getIsResourceListLoading,
  getResourceList,
  getResourceListError,
  getResourceListTotalRows,
} from '../../redux/resource-list/resource-list-selectors'
import SearchInput from '../../components/inputs/search-input'
import AccessibleTable from '../../components/accessible-table/accessible-table'
import { getErrorMessage } from '../../utils/errors'
import { ResourceListContainerSearchFilter } from './filters'
import { HEADER_HEIGHT } from '../../constants/styles-constants'
import { GetListParams } from 'src/types/request-entities-types'
import { Order } from '../../types/common-types'

export type ResourceListContentProps = {
  resource: Resources
  columns?: ColumnItem[]
  withCreate?: boolean
  withRefresh?: boolean
  children?: React.ReactElement
  sortField?: string
  sortDirection?: Order
  searchFilter?: ResourceListContainerSearchFilter | null
  withClickableRows?: boolean
  searchFilterPlaceholder?: string
  additionalActions?: React.ReactElement | null
  onCreate?: () => void
  apiResource?: (resource: Resources, params?: Partial<GetListParams> | undefined) => Promise<any>
  filterApiResource?: { [key: string]: string }
  filterFunc?: (data: any[]) => any[]
  onRowClick?: (data: any) => void
  apiRef?: MutableRefObject<{ fetch: () => void } | undefined>
  join?: string[]
}

const Container = styled(Paper)`
  width: 100%;
  overflow: hidden;
  margin: 0;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 0px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`

const Actions = styled.div`
  display: flex;
  padding: 30px 20px 20px;
  justify-content: space-between;
`

const LeftActions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const selectResourceListState = createStructuredSelector({
  isResourceListLoading: getIsResourceListLoading,
  resourceListError: getResourceListError,
  resourceList: getResourceList,
  totalRows: getResourceListTotalRows,
})

function ResourceListContent(props: ResourceListContentProps) {
  const {
    resource,
    columns = [],
    sortField = 'id',
    sortDirection = Order.asc,
    withCreate = false,
    withRefresh = true,
    withClickableRows = false,
    searchFilterPlaceholder,
    searchFilter,
    children,
    additionalActions,
    onCreate = NOOP,
    apiResource,
    filterApiResource,
    filterFunc,
    onRowClick,
    apiRef,
    join,
  } = props

  const { isResourceListLoading, resourceListError, resourceList, totalRows } = useSelector(selectResourceListState)
  const errorMessage = resourceListError ? getErrorMessage(resourceListError) : ''

  const {
    searchInputValue,
    page,
    rowsPerPage,
    sortBy,
    sortByDirection,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick: defaultOnRowClick,
    onChangeSearchInput,
    onChangeSortBy,
    onChangeSortByDirection,
    fetch,
  } = useResourceListData({
    resource,
    sortField,
    sortDirection,
    searchFilter,
    error: resourceListError,
    apiResource,
    filterApiResource,
    join,
  })

  if (apiRef) {
    apiRef.current = { fetch }
  }

  const filteredResourceList = filterFunc?.(resourceList) || resourceList

  return (
    <Container>
      <Actions>
        {searchFilter && (
          <SearchInput
            autoFocus
            id={`${resource}-searchFilter`}
            placeholder={searchFilterPlaceholder}
            value={searchInputValue}
            onChange={onChangeSearchInput}
          />
        )}
        <LeftActions>
          {additionalActions}
          {withCreate && (
            <Button startIcon={<AddIcon />} onClick={onCreate}>
              Create
            </Button>
          )}
          {withRefresh ? (
            <Button startIcon={<RefreshIcon />} onClick={fetch}>
              Refresh
            </Button>
          ) : null}
        </LeftActions>
      </Actions>
      <AccessibleTable
        withPagination
        useHandlersOnSort
        withClickableRows={withClickableRows}
        id={resource}
        page={page}
        errorMessage={errorMessage}
        columns={columns}
        resource={resource}
        rowsPerPage={rowsPerPage}
        sortBy={sortBy}
        sortByDirection={sortByDirection}
        isLoading={isResourceListLoading}
        data={filteredResourceList}
        totalRows={totalRows}
        onRowClick={withClickableRows ? onRowClick || defaultOnRowClick : NOOP}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangeSortBy={onChangeSortBy}
        onChangeSortByDirection={onChangeSortByDirection}
      />
      {children}
    </Container>
  )
}

export default memo(ResourceListContent)
