export enum Verbs {
  read = 'read',
  update = 'update',
  create = 'create',
  delete = 'delete',
}

export enum Resources {
  personalTrainers = 'personal-trainers',
  paymentPeriods = 'payment-periods',
  personalWorkouts = 'personal-workouts',
  members = 'members',
  profile = 'profile',
  memberships = 'memberships',
  membership = 'membership',
  contentPlans = 'content-plans',
  studios = 'studios',
  knownDevice = 'known-device',
  billingPromotions = 'billing-promotions',
  promotions = 'promotions',
  concierges = 'concierges',
  failedPayments = 'failed-payments',
  trainerBillingGroup = 'trainer-billing-group',
  trainerMember = 'trainer-member',
  trainerSession = 'trainer-session',
  memberEmergencyInfo = 'member-emergency-info',
  oneOnOneIncurredCharges = '1-1-incur-charges',
  oneOnOneInvoices = '1-1-invoices',
  memberSessions = '1-1-sessions',
  conciergeMember = 'concierges-member',
  triggerInvoicing = 'trigger-invoice-payment',
  addBundle = 'bundle-order',
  orderManagement = 'order-management',
  trainerPayRate = 'trainer-pay-rate',
  trainerLevel = 'trainer-level',
  userRoles = 'user-roles',
  tpiWorkflows = 'tpi-workflows',
  deviceMessage = 'device-message',
  personalData = 'personal-data',
  memberInvite = 'member-invite',
  trainers = 'trainers',
  bundles = 'bundles',
  billingSchedule = 'billing-schedule',
  bills = 'bills',
  cpsHistory = 'workout-cps-history',
  charge = 'charges',
  cpsSchedule = 'schedule-cps',
  coupons = 'coupons',
  invites = 'invite',
  linking = 'linking',
  membershipSwitch = 'membership-switch',
  membershipStatus = 'membership-status',
  trainerFeatures = 'trainer-features',
  membershipClass = 'membership-class',
  membershipExpiry = 'membership-expiry',
  password = 'password',
}

export type Permission = `${Verbs}:${Resources}`
export type PagePermissions = Record<string, Permission>
export type PagesPermissions = Record<Resources, PagePermissions>
