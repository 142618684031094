export enum CondOperator {
  EQUALS = '$eq',
  NOT_EQUALS = '$ne',
  GREATER_THAN = '$gt',
  LOWER_THAN = '$lt',
  GREATER_THAN_EQUALS = '$gte',
  LOWER_THAN_EQUALS = '$lte',
  STARTS = '$starts',
  ENDS = '$ends',
  CONTAINS = '$cont',
  EXCLUDES = '$excl',
  IN = '$in',
  NOT_IN = '$notin',
  IS_NULL = '$isnull',
  NOT_NULL = '$notnull',
  BETWEEN = '$between',
  EQUALS_LOW = '$eqL',
  NOT_EQUALS_LOW = '$neL',
  STARTS_LOW = '$startsL',
  ENDS_LOW = '$endsL',
  CONTAINS_LOW = '$contL',
  EXCLUDES_LOW = '$exclL',
  IN_LOW = '$inL',
  NOT_IN_LOW = '$notinL',
}

export type SPrimitivesVal = string | number | boolean
export type SFiledValues = SPrimitivesVal | SPrimitivesVal[]

export type SFieldOperator = {
  $eq?: SFiledValues
  $ne?: SFiledValues
  $gt?: SFiledValues
  $lt?: SFiledValues
  $gte?: SFiledValues
  $lte?: SFiledValues
  $starts?: SFiledValues
  $ends?: SFiledValues
  $cont?: SFiledValues
  $excl?: SFiledValues
  $in?: SFiledValues
  $notin?: SFiledValues
  $between?: SFiledValues
  $isnull?: SFiledValues
  $notnull?: SFiledValues
  $eqL?: SFiledValues
  $neL?: SFiledValues
  $startsL?: SFiledValues
  $endsL?: SFiledValues
  $contL?: SFiledValues
  $exclL?: SFiledValues
  $inL?: SFiledValues
  $notinL?: SFiledValues
  $or?: SFieldOperator
  $and?: never
}

export type SField = SPrimitivesVal | SFieldOperator

export type SConditionAND = {
  $and?: (SFields | SConditionAND)[]
  $or?: never
}

export type SFields = {
  [key: string]: SField | (SFields | SConditionAND)[] | undefined
  $or?: (SFields | SConditionAND)[]
  $and?: never
}

export type SCondition = SFields | SConditionAND
