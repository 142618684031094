import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import ActionsDropdownMenu from 'src/components/actions-dropdown'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from 'src/types/permissions-types'
import { getButtonDataQa } from 'src/utils/entities-data-qa-utils'

type MemberActionsDropdownMenuType = {
  onOpenSendInvite?: () => void
}

function MemberAdditionalActions(props: MemberActionsDropdownMenuType) {
  const actionsConfig = []

  const { isEditAllowed: isSendInviteAllowed } = useResourcePermissionHandler(Resources.memberInvite)

  if (isSendInviteAllowed) {
    actionsConfig.push({
      icon: <AddIcon />,
      title: 'Send Invite',
      dataQa: getButtonDataQa(Resources.memberInvite, 'create'),
      onClick: () => props.onOpenSendInvite?.(),
    })
  }

  if (actionsConfig.length === 1) {
    const [actionConfig] = actionsConfig

    return (
      <Button startIcon={actionConfig.icon} onClick={actionConfig?.onClick} data-qa={actionConfig.dataQa}>
        {actionConfig.title}
      </Button>
    )
  }

  return <ActionsDropdownMenu actionsConfig={[actionsConfig]} />
}

export default MemberAdditionalActions
