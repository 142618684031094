import React from 'react'
import styled from '@emotion/styled'
import { Button, TableBody } from '@mui/material'

import { NOOP } from '../../constants/common-constants'
import { AccessibleTableBodyProps } from './acessible-table-types'
import Loader from '../loader'
import AccessibleTableRow from './accessible-table-row'

const StyledTableBody = styled(TableBody)`
  position: relative;
`

const WideTableCell = styled.td`
  text-align: center;
`
const CellContent = styled.div`
  padding: 11px 16px;
`

const MainMessage = styled.span`
  font-weight: 400;
  white-space: nowrap;
`
const EmptyDataActions = styled.div`
  margin-top: 12px;
`

function AccessibleTableBody(props: AccessibleTableBodyProps) {
  const {
    id,
    resource,
    tableDataList,
    withClickableRows = false,
    columns,
    activeRowId,
    selectedRows = [],
    errorOnDataLoad,
    errorMessage,
    getRowTooltipTitle = () => '',
    highlightedRows = [],
    emptyDataMessage,
    withEmptyDataActionButton,
    emptyDataActionButtonTitle,
    onEmptyDataActionButtonClick,
    isLoading,
    onRowClick = NOOP,
    onRowHover = NOOP,
  } = props

  if (isLoading) {
    return (
      <StyledTableBody>
        <tr>
          <td colSpan={columns.length}>
            <Loader />
          </td>
        </tr>
      </StyledTableBody>
    )
  }

  if (errorOnDataLoad) {
    return (
      <StyledTableBody>
        <tr>
          <WideTableCell colSpan={columns.length}>
            <CellContent>
              <MainMessage>{errorMessage}</MainMessage>
            </CellContent>
          </WideTableCell>
        </tr>
      </StyledTableBody>
    )
  }

  if (!tableDataList.length) {
    return (
      <StyledTableBody>
        <tr>
          <WideTableCell colSpan={columns.length}>
            <CellContent>
              <MainMessage>{emptyDataMessage}</MainMessage>
              {withEmptyDataActionButton && (
                <EmptyDataActions>
                  <Button variant="outlined" onClick={onEmptyDataActionButtonClick}>
                    {emptyDataActionButtonTitle}
                  </Button>
                </EmptyDataActions>
              )}
            </CellContent>
          </WideTableCell>
        </tr>
      </StyledTableBody>
    )
  }

  return (
    <TableBody>
      {tableDataList.map((rowData, index) => (
        <AccessibleTableRow
          key={`${id}/${rowData.id || index}`}
          tableId={id}
          resource={resource}
          rowData={rowData}
          withClickableRows={withClickableRows}
          columns={columns}
          isActive={activeRowId === rowData.id}
          isSelected={selectedRows.includes(rowData.id as string)}
          isHighlighted={highlightedRows.includes(rowData.id as string)}
          tooltipTitle={getRowTooltipTitle(rowData)}
          onRowClick={() => onRowClick(rowData)}
          onRowHover={() => onRowHover(rowData)}
        />
      ))}
    </TableBody>
  )
}

export default AccessibleTableBody
