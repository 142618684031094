import React, { FC } from 'react'

type Props = { ErrorFallback: FC<{ error: Error }> }
type State = { error?: Error }

// eslint-disable-next-line react/require-optimization
export class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line react/sort-comp,react/state-in-constructor
  state = {} as State

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  render() {
    const { ErrorFallback, children } = this.props
    const { error } = this.state
    return error ? <ErrorFallback error={error} /> : children
  }
}
