import StayPrimaryPortraitOutlinedIcon from '@mui/icons-material/StayPrimaryPortraitOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'

import { Resources } from 'src/types/permissions-types'
import { MenuItem } from './types'
import { ResourceConfig } from 'src/constants/resources-constants'
import { FC } from 'react'

type NavMenuItemType =
  | Resources
  | {
      label: string
      icon?: FC
      children?: NavMenuItemType[]
      name?: Resources
      to?: string
    }

export const NAV_MENU_ITEMS: NavMenuItemType[] = [
  {
    label: 'People at FORME',
    icon: GroupOutlinedIcon,
    children: [
      Resources.personalTrainers,
      Resources.members,
      {
        label: 'Employees',
        name: Resources.members,
        to: 'employees',
      },
      {
        name: Resources.membership,
        label: 'Commercial',
      },
    ],
  },
  {
    label: 'Administration',
    icon: FolderOutlinedIcon,
    children: [Resources.membershipClass],
  },
  {
    label: 'Active Hardware',
    icon: StayPrimaryPortraitOutlinedIcon,
    children: [Resources.studios, Resources.knownDevice],
  },
  Resources.tpiWorkflows,
  {
    label: 'Third Party Logistics',
    icon: LocalShippingOutlinedIcon,
    children: [
      {
        name: Resources.orderManagement,
        label: 'Orders',
        to: `${Resources.orderManagement}/create`,
      },
      {
        name: Resources.orderManagement,
        label: 'Order History',
        to: `${Resources.orderManagement}/orders`,
      },
    ],
  },
  {
    label: "FORME's Catalogue",
    icon: LocalOfferOutlinedIcon,
    children: [
      {
        name: Resources.orderManagement,
        label: 'Products',
        to: `${Resources.orderManagement}/products`,
      },
    ],
  },
]

const resourceToMenuItem = (resource: Resources, allowedResources: ResourceConfig[]) =>
  allowedResources.find(({ name }) => name === resource)

export function mapMenuItems(allowedResources: ResourceConfig[], items = NAV_MENU_ITEMS): MenuItem[] {
  return items
    .map((item) => {
      if (typeof item === 'string') {
        return resourceToMenuItem(item, allowedResources)
      } else if (item.name) {
        const menuItem = resourceToMenuItem(item.name, allowedResources)
        return { ...menuItem, ...item }
      } else {
        const childMenuItems = item.children && mapMenuItems(allowedResources, item.children)

        return childMenuItems?.length ? ({ ...item, children: childMenuItems } as unknown as MenuItem) : null
      }
    })
    .filter(Boolean) as MenuItem[]
}
