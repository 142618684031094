import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { getButtonDataQa } from 'src/utils/entities-data-qa-utils'
import { ActionItemConfig } from 'src/components/actions-dropdown'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { getErrorMessage } from 'src/utils/errors'
import { resourcesNamesMap } from 'src/constants/resources-constants'
import { MemberRouteParams } from 'src/boot/routing'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { useRequest } from 'src/hooks/use-request'

import { DialogsController } from './useDialogsController'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { MembershipStatus } from 'src/api/services/cloud/memberships/types'
import { sendCompleteInvite } from 'src/api/services/cloud/memberships/sendCompleteInvite'

const RESTORABLE_MEMBERSHIP_STATUSES: MembershipStatus[] = ['suspended', 'paused', 'expired']

export const useActionsConfig = (dialogsController: DialogsController): ActionItemConfig[][] => {
  const { id: memberId } = useParams<MemberRouteParams>()
  const member = useSelector((state: RootState) => state.member.record)
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isEditAllowed } = useResourcePermissionHandler(Resources.members)
  const { isEditAllowed: isEmergencyContactEditAllowed } = useResourcePermissionHandler(Resources.memberEmergencyInfo)
  const { isReadAllowed: isViewTriggerInvoicingAllowed } = useResourcePermissionHandler(Resources.triggerInvoicing)
  const { isReadAllowed: isAddBundleAllowed } = useResourcePermissionHandler(Resources.addBundle)
  const { isEditAllowed: isLinkAllowed } = useResourcePermissionHandler(Resources.linking)
  const { isDeleteAllowed: isDeleteMembershipAllowed } = useResourcePermissionHandler(Resources.membership)
  const { isEditAllowed: isSwitchMembershipAllowed } = useResourcePermissionHandler(Resources.membershipSwitch)
  const { isEditAllowed: isInviteAllowed } = useResourcePermissionHandler(Resources.memberInvite)
  const { isEditAllowed: isPasswordAllowed } = useResourcePermissionHandler(Resources.password)

  const {
    showEditMemberDetailsDialog,
    showEditMemberMediaDialog,
    showEditEmergencyContactInfoDialog,
    showAddBundlesDialog,
    showLinkAccountDialog,
    showCancelMembershipDialog,
    showPauseMembershipDialog,
    showRestoreMembershipDialog,
    showUncancelMembershipDialog,
    showSwitchMembershipDialog,
    showSetPasswordDialog,
    showInviteGuestDialog,
  } = dialogsController

  const { fetch: triggerUserBiWeeklyBilling, isLoading: invoicingTriggerRequestIsLoading } = useRequest(
    api.billingV2.triggerUserBiWeeklyBilling,
  )
  const { fetch: sendComplete } = useRequest(sendCompleteInvite)

  const sendTriggerInvoicing = useCallback(async () => {
    if (!memberId) return
    try {
      await triggerUserBiWeeklyBilling(memberId)
      onDisplaySuccessNotification('Invoicing has been triggered successfully')
    } catch (error: any) {
      onDisplayErrorNotification(getErrorMessage(error))
    }
  }, [triggerUserBiWeeklyBilling, memberId, onDisplayErrorNotification, onDisplaySuccessNotification])

  const sendCompleteInv = useCallback(async () => {
    try {
      await sendComplete({ userId: memberId })
      onDisplaySuccessNotification('Sent invitation completion')
    } catch (error: any) {
      onDisplayErrorNotification(getErrorMessage(error))
    }
  }, [sendComplete, memberId, onDisplayErrorNotification, onDisplaySuccessNotification])

  return useMemo(() => {
    const actionsConfig: ActionItemConfig[] = []

    if (isEditAllowed) {
      actionsConfig.push({
        title: 'Edit Member Details',
        dataQa: getButtonDataQa(Resources.members, 'editMemberDetails'),
        onClick: showEditMemberDetailsDialog,
      })
      actionsConfig.push({
        title: 'Edit Media',
        dataQa: getButtonDataQa(Resources.members, 'editMedia'),
        onClick: showEditMemberMediaDialog,
      })
    }

    if (isEmergencyContactEditAllowed) {
      actionsConfig.push({
        title: 'Edit Emergency Information',
        dataQa: getButtonDataQa(Resources.members, 'editEmergencyInformation'),
        onClick: showEditEmergencyContactInfoDialog,
      })
    }

    if (isViewTriggerInvoicingAllowed) {
      actionsConfig.push({
        title: resourcesNamesMap[Resources.triggerInvoicing],
        dataQa: getButtonDataQa(Resources.members, 'sendTriggerInvoicing'),
        onClick: sendTriggerInvoicing,
        disabled: invoicingTriggerRequestIsLoading || !memberId,
      })
    }

    if (isAddBundleAllowed) {
      actionsConfig.push({
        title: resourcesNamesMap[Resources.addBundle],
        dataQa: getButtonDataQa(Resources.members, 'addBundle'),
        onClick: showAddBundlesDialog,
      })
    }

    if (isLinkAllowed) {
      actionsConfig.push({
        title: 'Link Accounts',
        dataQa: getButtonDataQa(Resources.personalData, 'linkAccounts'),
        onClick: showLinkAccountDialog,
      })
    }

    isInviteAllowed &&
      actionsConfig.push({
        title: 'Complete Invite',
        dataQa: getButtonDataQa(Resources.memberInvite, 'sendCompleteInvite'),
        onClick: sendCompleteInv,
      })

    isPasswordAllowed &&
      actionsConfig.push({
        title: 'Set Password',
        dataQa: getButtonDataQa(Resources.memberInvite, 'setPassword'),
        onClick: showSetPasswordDialog,
      })

    const membership = member?.membershipProfiles?.find((profile) => profile.owner)?.membership

    if (membership) {
      if (isDeleteMembershipAllowed) {
        const cancelAvailable = membership.status === 'active'
        const uncancelAvailable = membership.status === 'will_expire'
        const restoreMembership = RESTORABLE_MEMBERSHIP_STATUSES.includes(membership.status)

        cancelAvailable &&
          actionsConfig.push(
            {
              title: 'Cancel Membership',
              dataQa: getButtonDataQa(Resources.membership, 'cancelMembership'),
              onClick: showCancelMembershipDialog,
            },
            {
              title: 'Pause Membership',
              dataQa: getButtonDataQa(Resources.membership, 'pauseMembership'),
              onClick: showPauseMembershipDialog,
            },
          )

        uncancelAvailable &&
          actionsConfig.push({
            title: 'Uncancel Membership',
            dataQa: getButtonDataQa(Resources.membership, 'uncancelMembership'),
            onClick: showUncancelMembershipDialog,
          })

        restoreMembership &&
          actionsConfig.push({
            title: 'Reactivate Membership',
            dataQa: getButtonDataQa(Resources.membership, 'restoreMembership'),
            onClick: () => showRestoreMembershipDialog(membership!.status),
          })
      }

      isInviteAllowed &&
        actionsConfig.push({
          title: 'Invite Guest',
          dataQa: getButtonDataQa(Resources.memberInvite, 'inviteGuest'),
          onClick: () => showInviteGuestDialog(membership.id),
        })

      isSwitchMembershipAllowed &&
        actionsConfig.push({
          title: 'Switch Membership',
          dataQa: getButtonDataQa(Resources.membershipSwitch, 'switchMembership'),
          onClick: showSwitchMembershipDialog,
        })
    }

    return [actionsConfig]
  }, [
    isEditAllowed,
    isEmergencyContactEditAllowed,
    isViewTriggerInvoicingAllowed,
    isAddBundleAllowed,
    isLinkAllowed,
    isInviteAllowed,
    sendCompleteInv,
    isPasswordAllowed,
    showSetPasswordDialog,
    member?.membershipProfiles,
    showEditMemberDetailsDialog,
    showEditMemberMediaDialog,
    showEditEmergencyContactInfoDialog,
    sendTriggerInvoicing,
    invoicingTriggerRequestIsLoading,
    memberId,
    showAddBundlesDialog,
    showLinkAccountDialog,
    isDeleteMembershipAllowed,
    isSwitchMembershipAllowed,
    showSwitchMembershipDialog,
    showCancelMembershipDialog,
    showPauseMembershipDialog,
    showUncancelMembershipDialog,
    showRestoreMembershipDialog,
    showInviteGuestDialog,
  ])
}
