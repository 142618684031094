import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Upload } from 'src/antd-components'
import { InboxOutlined } from '@ant-design/icons'

import { NOOP } from '../constants/common-constants'
import useSnackbarNotifications from '../hooks/use-snackbar-notifications'
import { UploadFile } from 'antd'

const { Dragger } = Upload

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 500px;
`

const Image = styled.img`
  max-height: 400px;
  max-width: 400px;
  border-radius: 5px;
`

const defaultMaxFileSize = 2500000
const defaultDropzoneText = 'Drag and drop a file here or click'
const defaultSecondaryDropZoneText = 'Accepted file types: png/jpeg, max file size: 23 MB'
const defaultSupportedFileTypes = ['image/png', 'image/jpeg']

type FileInputProps = {
  maxFileSize?: number
  fileUrl?: string | null
  dropzoneText?: string
  secondaryDropZoneText?: string
  supportedFileTypes?: string[]
  selectedFile: UploadFile | null
  onSelectFiles: (file: UploadFile[]) => void
  onDelete?: () => void
}

const FileInput = (props: FileInputProps) => {
  const {
    fileUrl,
    selectedFile,
    supportedFileTypes = defaultSupportedFileTypes,
    dropzoneText = defaultDropzoneText,
    secondaryDropZoneText = defaultSecondaryDropZoneText,
    maxFileSize = defaultMaxFileSize,
    onSelectFiles,
    onDelete = NOOP,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const isAvatarDisplay = fileUrl && !selectedFile
  const fileList = useMemo(() => (selectedFile ? [selectedFile] : []), [selectedFile])

  const maxFileSizeMB = Math.floor(maxFileSize / 1024 / 1024)

  return (
    <div>
      {!fileUrl && (
        <Dragger
          fileList={fileList}
          listType="picture-card"
          multiple={false}
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: true,
          }}
          beforeUpload={(file) => {
            if (file.size >= maxFileSize) {
              onDisplayErrorNotification(`File ${file.name} was rejected. Maximum file size ${maxFileSizeMB}MB`)

              return Upload.LIST_IGNORE
            }

            if (!supportedFileTypes.includes(file.type)) {
              onDisplayErrorNotification(`File ${file.name} was rejected. Unsupported image format`)

              return Upload.LIST_IGNORE
            }

            return true
          }}
          onChange={({ file }) => {
            if (file.status === 'removed') {
              onDelete()

              return
            }

            onSelectFiles([file])
            if (file.status === 'done') {
              onDisplaySuccessNotification(`File ${file.name} was successfully added`)
            }
          }}
          // By default upload tries to upload a selected file.
          // We do not need this, so it is necessary to pass this fake request function.
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess?.('ok')
            }, 0)
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{dropzoneText}</p>
          <p className="ant-upload-hint">{secondaryDropZoneText}</p>
        </Dragger>
      )}
      {isAvatarDisplay && (
        <ImageContainer>
          <Image src={fileUrl!} />
        </ImageContainer>
      )}
    </div>
  )
}

export default FileInput
