import React, { useEffect, useMemo, useState } from 'react'

import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { useAction } from '../../../hooks/use-actions'
import { fromPercentage, toPercentage } from '../../../utils/percentage'
import { RequestError } from '../../../api/http-client'
import {
  levelsList,
  PersonalTrainer,
  PersonalTrainerFeatures,
  PersonalTrainerLevel,
} from '../../../constants/personal-trainer-constants'
import { getErrorMessage } from '../../../utils/errors'
import { setPersonalTrainerRecord } from '../../../redux/personal-trainer/personal-trainer-reducer'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import Loader from '../../../components/loader'
import { FormeDialog } from '../../../hooks/useDialog'
import _ from 'lodash'
import { Input, Form, Radio, Select, InputNumber, Modal, useWatchAll } from 'src/antd-components'

export type PayRates = {
  oldPayRate: number
  currentPayRate: number
  newPayRate: number
  newPayRateStartDate: string
  newPayRateStartDateFormatted: string
}

type EditPersonalTrainerDetailsProps = {
  payRates: PayRates
  record: PersonalTrainer
  onSuccess?: (id: string) => void
}

type FormData = {
  test: boolean
  first_name: string
  last_name: string
  level: PersonalTrainerLevel | string
  rate_value: number
  start_value: string | undefined
  features: PersonalTrainerFeatures[]
}

// const dataQa = {
//   firstName: getFieldDataQa(Resources.personalTrainers, '/edit_details/first_name'),
//   lastName: getFieldDataQa(Resources.personalTrainers, '/edit_details/last_name'),
//   testTrainer: getFieldDataQa(Resources.personalTrainers, '/edit_details/test_trainer'),
//   payRate: getFieldDataQa(Resources.personalTrainers, '/edit_details/pay_rate'),
//   rateValue: getFieldDataQa(Resources.personalTrainers, '/edit_details/rate_value'),
// }

type Period = {
  id: string
  startDate: string
  formattedDate: string
}

const valueLabelLevelsList = levelsList.map(({ id, label }) => ({ value: id, label }))
const featuresOptions = Object.values(PersonalTrainerFeatures).map((value) => ({ value, label: value }))

const EditPersonalTrainerDetailsDialog: FormeDialog<EditPersonalTrainerDetailsProps> = ({
  params: { record, onSuccess, payRates },
  open: isOpened,
  onClose,
}) => {
  const [form] = Form.useForm()

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)
  const { isEditAllowed: isTrainerPayDataEditAllowed } = useResourcePermissionHandler(Resources.trainerPayRate)
  const { isEditAllowed: isTrainerLevelEditAllowed } = useResourcePermissionHandler(Resources.trainerLevel)

  const onUpdatePersonalTrainerRecord = useAction(setPersonalTrainerRecord)

  const initialTrainerDetails = {
    test: record?.test ?? false,
    first_name: record?.user?.first_name ?? '',
    last_name: record?.user?.last_name ?? '',
    level: record?.level ?? undefined,
    features: record.features ?? [],
    rate_value: Number(payRates?.newPayRate) ?? 0,
    start_value: '',
  }

  const [periodAsIsList, setPeriodAsIsList] = useState<any>([])
  const { test, first_name, last_name, level, rate_value, start_value, features }: FormData = useWatchAll(
    form,
    initialTrainerDetails,
  )

  const clearFormData = () => {
    form.resetFields()
  }

  const trainerDetailsChanged =
    test !== record?.test || level !== record?.level || !_.isEqual(features, record?.features)
  const userDetailsChanged =
    !!first_name.trim() &&
    !!last_name.trim() &&
    (first_name.trim() !== record?.user?.first_name || last_name.trim() !== record?.user?.last_name)
  const trainerPayRateChanged =
    rate_value &&
    !!start_value &&
    (rate_value !== Number(payRates?.newPayRate) ||
      periodAsIsList[start_value]?.formattedDate !== payRates?.newPayRateStartDateFormatted)

  useEffect(
    () => {
      api.cloud.trainers
        .getPayRatesDates()
        .then((resp: any) => {
          const result = resp?.map((periodData: { date: string; formattedDate: string }, index: number) => {
            if (periodData.date === payRates?.newPayRateStartDate) {
              form.setFieldsValue({ start_value: `${index}` })
            }
            return { id: index.toString(), startDate: periodData.date, formattedDate: periodData.formattedDate }
          })
          setPeriodAsIsList(result)
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPeriodAsIsList, onDisplayErrorNotification, payRates],
  )

  const periodsList = useMemo(
    () =>
      periodAsIsList?.map((item: Period) => ({
        value: item.id,
        label: item.formattedDate,
      })),
    [periodAsIsList],
  )

  useEffect(() => {
    const newValues = {
      pay_rate: Number(payRates.currentPayRate),
      rate_value: Number(payRates.newPayRate),
      start_value: payRates.newPayRateStartDate,
    }
    form.setFieldsValue(newValues)
  }, [payRates, form])

  const isFormValid = userDetailsChanged || trainerDetailsChanged || trainerPayRateChanged

  const onEdit = () => {
    if (!record?.user?.id || !isFormValid) return

    if (userDetailsChanged || trainerDetailsChanged) {
      const dataToSend = {
        test,
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        level,
        features,
      }
      const params = { id: record?.id, data: dataToSend, previousData: { id: record?.id } }
      api.common
        .update(Resources.personalTrainers, params)
        .then(({ data: trainer }) => {
          onUpdatePersonalTrainerRecord({ ...trainer })
          form.setFieldsValue({ ...dataToSend })
          onDisplaySuccessNotification('Personal trainer was successfully edited')
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    }
    if (trainerPayRateChanged && record?.id) {
      const rec = periodAsIsList.find((item: Period) => item.id === start_value)
      const dataToSend: {
        pay_rate: number
        start_date?: string
      } = {
        pay_rate: rate_value,
        start_date: rec?.startDate,
      }
      api.cloud.trainers
        .updateTrainerPayRate(record?.id, dataToSend)
        .then(() => {
          onSuccess?.(record?.id)
          form.setFieldsValue({ ...dataToSend })
          onDisplaySuccessNotification('Personal trainer rates have been updated')
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    }
    clearFormData()
    onClose()
  }

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed) {
    return null
  }

  return (
    <Modal
      title="Edit trainer details"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{
        disabled: !isFormValid,
      }}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialTrainerDetails} labelAlign="right" layout="vertical">
        <Form.Item name="test" label="Test Trainer">
          <Radio.Group>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="first_name" label="First Name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item name="last_name" label="Last Name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        {isTrainerLevelEditAllowed && (
          <>
            <Form.Item name="level" label="Level">
              <Select options={valueLabelLevelsList} />
            </Form.Item>
            <Form.Item name="features" label="Features">
              <Select mode="multiple" options={featuresOptions} allowClear />
            </Form.Item>
          </>
        )}
        {isTrainerPayDataEditAllowed && (
          <>
            <Form.Item name="pay_rate" label="Current Pay Rate">
              <InputNumber
                disabled
                formatter={(value) => toPercentage((value as number) ?? 0)}
                parser={(value) => fromPercentage(value ?? '')}
              />
            </Form.Item>
            <Form.Item name="rate_value" label="New Pay Rate">
              <InputNumber
                formatter={(value) => toPercentage((value as number) ?? 0)}
                parser={(value) => fromPercentage(value ?? '')}
                min={0}
                max={1}
              />
            </Form.Item>
            <Form.Item name="start_value" label="Billing Period">
              <Select options={periodsList} placeholder="Select start billing period" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default EditPersonalTrainerDetailsDialog
