import { ArrayElement } from 'src/utils/advanced-types'

export const studioTypes = ['studio', 'studio_lift'] as const

export const studioRoles = ['consumer'] as const

export type StudioType = ArrayElement<typeof studioTypes>

export type StudioRole = ArrayElement<typeof studioRoles>

export type Studio = {
  id: string
  product_type: StudioType
  install_date: string | null
  created_date: string
  updated_date: string
  public_key: string
  profile_id: string | null
  roles: StudioRole[]
  lift_id: string | null
  lift_only_mode: boolean
  lift_registration_date: string | null
  has_actual_keys: boolean
}

export type StudioMetadata = {
  id: string
  device_id: string
  base_snap: string | null
  bios_version: string | null
  camera_left_version: string | null
  camera_right_version: string | null
  microphone_version: string | null
  mirror_services_version: string | null
  motor_firmware_left: string | null
  motor_firmware_right: string | null
  motor_hardware: string | null
  snap_revision: string | null
  snap_version: string | null
  station_firmware: string | null
  station_hardware: string | null
  station_name: string | null
  station_serial: string | null
  touchscreen_version: string | null
  ui_version: string | null
  created_date: string
}
