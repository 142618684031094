import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Order } from '../types/common-types'
import { Resources } from '../types/permissions-types'
import { useAction } from './use-actions'
import { RequestError } from '../api/http-client'
import { getErrorMessage } from '../utils/errors'
import { fetchResourceList } from '../redux/resource-list/resource-list-actions'
import { clearResourceListData } from '../redux/resource-list/resource-list-reducer'
import useSnackbarNotifications from './use-snackbar-notifications'
import { ResourceListContainerSearchFilter } from '../containers/resource-list-container/filters'
import { GetListParams } from 'src/types/request-entities-types'

/**
 *
 */
type UseResourceListDataProps = {
  resource: Resources
  sortField?: string
  sortDirection?: Order
  error: null | RequestError
  searchFilter?: ResourceListContainerSearchFilter | null
  apiResource?: (resource: Resources, params?: Partial<GetListParams> | undefined) => Promise<any>
  filterApiResource?: { [key: string]: string }
  join?: string[]
}

/**
 *
 */
export type ResourceListState = {
  totalRows: number
  resourceList: any[]
  isLoading: boolean
  error: null | Error
}

/**
 *
 */
type UseResourceListData = {
  searchInputValue: string
  page: number
  rowsPerPage: number
  sortBy: string
  sortByDirection: Order
  onChangePage: (nextPage: number) => void
  onChangeRowsPerPage: (nextRowsPerPage: number) => void
  onRowClick: ({ id }: Record<string, any>) => void
  onChangeSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeSortBy: (nextSortBy: string) => void
  onChangeSortByDirection: (nextSortByDirection: Order) => void
  fetch: () => void
}

/**
 *
 */
export const useResourceListData = ({
  resource,
  sortField = 'id',
  sortDirection = Order.asc,
  searchFilter,
  error,
  apiResource,
  filterApiResource,
  join,
}: UseResourceListDataProps): UseResourceListData => {
  const history = useHistory()
  const onFetchResourceList = useAction(fetchResourceList)
  const onClearResourceListData = useAction(clearResourceListData)

  const [page, onChangePage] = useState(0)
  const [rowsPerPage, onChangeRowsPerPage] = useState(50)
  const [sortBy, onChangeSortBy] = useState(sortField)
  const [sortByDirection, onChangeSortByDirection] = useState<Order>(sortDirection)
  const [searchInputValue, setSearchInput] = useState('')

  const filter = useMemo(() => {
    let result = searchFilter?.(searchInputValue) ?? {}
    if (filterApiResource) {
      result = { ...filterApiResource, ...result }
    }

    return result
  }, [searchFilter, searchInputValue, filterApiResource])
  const sort = useMemo(
    () => ({
      field: sortBy,
      order: sortByDirection.toUpperCase(),
    }),
    [sortBy, sortByDirection],
  )
  const requestParams = useMemo(() => ({ filter, sort, join }), [filter, sort, join])

  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const fetch = useCallback(
    () => onFetchResourceList({ resource, params: requestParams, apiResource }),
    [apiResource, onFetchResourceList, requestParams, resource],
  )

  useEffect(() => {
    fetch()

    return () => onClearResourceListData()
  }, [onClearResourceListData, fetch])

  useEffect(() => {
    if (error) onDisplayErrorNotification(getErrorMessage(error))
  }, [error, onDisplayErrorNotification])

  const onRowClick = ({ id: resourceItemId }: Record<string, any>) => {
    if (resource === Resources.members) {
      history.push(`/${resource}/${resourceItemId}/overview`)
    } else {
      history.push(`/${resource}/${resourceItemId}`)
    }
  }

  const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangePage(0)
    setSearchInput(e.target.value)
  }

  return {
    searchInputValue,
    page,
    rowsPerPage,
    sortBy,
    sortByDirection,
    onChangePage,
    onChangeRowsPerPage,
    onRowClick,
    onChangeSearchInput,
    onChangeSortBy,
    onChangeSortByDirection,
    fetch,
  }
}
