import { useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { ActionCreator, ActionCreatorsMapObject, bindActionCreators } from 'redux'

export function useActions<A, M extends ActionCreatorsMapObject<A>, D extends any[]>(actions: M, deps?: D) {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators<A, M>(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch],
  )
}

export function useAction<T, TAction extends ActionCreator<T>, D extends any[]>(action: TAction, deps?: D) {
  const dispatch = useDispatch()
  return useCallback(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (...args: Parameters<TAction>) => {
      dispatch(action.apply(action, args))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ? [dispatch, ...deps] : [dispatch],
  )
}

export function makeUseActions<A, M extends ActionCreatorsMapObject<A>>(actions: M) {
  return () => useActions(actions)
}
