import React, { ChangeEvent } from 'react'
import { TextField } from '@mui/material'
import styled from '@emotion/styled'

import { NOOP } from '../../constants/common-constants'
import { InputSize, InputVariant } from './inputs-types'
import { Typography } from 'src/antd-components'

type TextInputProps = {
  id: string
  value: string
  label?: string
  topLabel?: string
  readOnly?: boolean
  disabled?: boolean
  fullWidth?: boolean
  size?: InputSize
  allowedChars?: RegExp
  maxLength?: number
  variant?: InputVariant
  className?: string
  onChange?: (inputValue: string) => void
  thin?: boolean
  copyable?: boolean
  link?: string
}

const SimpleTextField = styled(TextField, {
  shouldForwardProp: (propName) => propName !== 'thin',
})<{ thin?: boolean }>(({ thin }) => ({
  '> .MuiInput-underline:before': {
    display: 'none',
  },
  '> .MuiInput-underline:after': {
    display: 'none',
  },
  '> .MuiInputBase-root': {
    marginBottom: thin ? 0 : 12,
    // padding: thin ? '5px 9px' : undefined,
    fontSize: thin ? '0.875rem' : undefined,
  },
  '.MuiOutlinedInput-notchedOutline': { borderWidth: thin ? '1px !important' : undefined },
}))

function TextInput(props: TextInputProps) {
  const {
    id,
    value,
    label,
    topLabel,
    className,
    variant = InputVariant.labeled,
    size = InputSize.small,
    fullWidth = true,
    disabled,
    readOnly,
    allowedChars,
    maxLength,
    onChange = NOOP,
    thin = true,
    copyable = false,
    link = '',
  } = props
  const isLabeledInput = variant === InputVariant.labeled
  const labelId = `${id}/label`

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if ((allowedChars && !e.target.value.match(allowedChars)) || (maxLength && e.target.value.length > maxLength))
      return
    onChange(e.target.value)
  }

  return (
    <>
      {topLabel && (
        <Typography.Text id={labelId} className="secondary">
          {topLabel}
        </Typography.Text>
      )}
      {link && (
        <Typography.Link copyable href={link} target="_blank" className="primary">
          {value}
        </Typography.Link>
      )}
      {!link && copyable && (
        <Typography.Text copyable className="primary">
          {value}
        </Typography.Text>
      )}
      {!copyable && !link && (
        <SimpleTextField
          multiline
          disabled={disabled}
          label={label}
          fullWidth={fullWidth}
          variant={isLabeledInput ? InputVariant.standard : variant}
          value={value}
          data-qa={id}
          size={size}
          InputProps={{ readOnly }}
          className={isLabeledInput ? '' : className}
          onChange={onChangeInput}
          thin={thin}
        />
      )}
    </>
  )
}

export default TextInput
