import React, { ChangeEvent, memo, ReactElement } from 'react'
import { Grid, TextField } from '@mui/material'
import { OrderItemLayout } from '../order-item-layout'
import { useDebouncedCallback } from 'src/hooks/use-debounced-callback'

interface IInfoProps {
  defaultValue: string
  onChange: (value: string) => void
}

export const Info = memo(({ defaultValue, onChange }: IInfoProps): ReactElement<IInfoProps> => {
  const onChangeDebounced = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value), 300)
  return (
    <OrderItemLayout title="Additional Info">
      <Grid container spacing={1} rowSpacing={2} sx={{ paddingTop: '20px' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Delivery instructions"
            multiline
            rows={4}
            defaultValue={defaultValue}
            onChange={onChangeDebounced}
          />
        </Grid>
      </Grid>
    </OrderItemLayout>
  )
})
