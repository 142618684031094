export enum BundleState {
  future = 'future',
  activating = 'activating',
  active = 'active',
  suspended = 'suspended',
  sentToBilling = 'sentToBilling',
}

export type UserBundle = {
  id: number
  display_name: string
  sku: string
  unit_price: number
  bundle_price: number
  consumed_at?: number
  sessions_count: number
  consumed_sessions: number
  available_sessions: number
  installment_payed: number
  state: BundleState
  is_recurring: boolean
}

export type UserBundleShort = {
  bundle_price: number
  display_name: string
  sessions_count: number
  unit_price: number
  sku: string
  is_recurring: boolean
}
export type ItemProps = {
  amount: number
  name: string
  sku: string
}
export type BundleOrderResponceProps = {
  customer: {
    name?: string
    email?: string
  }
  bundles: UserBundle[]
  id: string
  items: ItemProps[]
}
