import React from 'react'
import ResourceListContainer from '../../../containers/resource-list-container'
import { ColumnItem } from '../../../components/accessible-table/acessible-table-types'
import { AvatarCell, DataCell } from '../../../components/accessible-table/accessible-table-cell'
import { ResourceListContentProps } from '../../../containers/resource-list-container/resource-list-content'
import { Resources } from '../../../types/permissions-types'
import {
  combineSearchFilters,
  createFullNameSearchFilter,
  createRegularSearchFilter,
} from '../../../containers/resource-list-container/filters'

export const memberBaseColumns: ColumnItem[] = [
  { key: 'email', title: 'Email', fields: ['email'], render: DataCell, orderByField: 'email' },
  {
    key: 'first_name',
    title: 'First Name',
    fields: ['first_name'],
    render: DataCell,
    orderByField: 'first_name',
  },
  { key: 'last_name', title: 'Last Name', fields: ['last_name'], render: DataCell, orderByField: 'last_name' },
]

const membersColumns: ColumnItem[] = [
  { key: 'image', title: 'Avatar', fields: ['image'], render: AvatarCell },
  ...memberBaseColumns,
  {
    key: 'membership_class',
    title: 'Membership Class',
    fields: ['membershipProfiles.0.membership.class.name'],
    render: DataCell,
    filter: true,
  },
  {
    key: 'membership_status',
    title: 'Membership Status',
    fields: ['membershipProfiles.0.membership.status'],
    render: DataCell,
    filter: true,
  },
  {
    key: 'owner',
    title: 'Is Owner',
    fields: ['membershipProfiles.0.owner'],
    formatter: (value) => (value === 'true' ? 'Yes' : value === 'false' ? 'No' : '-'),
    render: DataCell,
    filter: true,
  },
]

export const searchFilter = combineSearchFilters(
  createFullNameSearchFilter('first_name', 'last_name'),
  createRegularSearchFilter(['first_name', 'last_name', 'email', 'id']),
)

type MembersTableProps = Partial<Omit<ResourceListContentProps, 'resource'>>

export function MembersTable(props: MembersTableProps) {
  return (
    <ResourceListContainer
      withClickableRows
      searchFilter={searchFilter}
      searchFilterPlaceholder="Search by name, email or auth0..."
      columns={membersColumns}
      {...props}
      resource={Resources.members}
    />
  )
}
