import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, CircularProgress, Grid } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { useRequest } from 'src/hooks/use-request'
import api from 'src/api'
import { AddressType, IOrder } from 'src/api/services/orders/types'
import { OrderForm } from '../../components/order-form'
import { OrderFormData } from '../../components/order-form/types'
import { Overwrite } from 'src/utils/advanced-types'
import { EventsLog } from './EventsLog'
import { Button } from 'src/antd-components'
import { routing } from 'src/boot/routing'

const address_type = 'Residence' as AddressType //TODO-eb: to take from "order" when available

export const EditOrder = () => {
  const { shopify_order_name, id } = useParams<{ shopify_order_name: string; id: string }>()
  const { data, isLoading } = useRequest(api.orders.getOrderById, [shopify_order_name, id])
  const [order, setOrder] = useState<Overwrite<IOrder, OrderFormData>>()
  const isCommit = order?.status === 'Commit'
  const [enableSave, setEnableSave] = useState(false)
  const [enableSend, setEnableSend] = useState(true)
  const [orderSaved, setOrderSaved] = useState(false)
  const [orderSent, setOrderSent] = useState(false)

  useEffect(() => {
    if (data) {
      setOrder({
        ...data,
        address_type,
        orderInfo: data?.orderInfo ?? {},
        parts: data.parts?.map((item) => ({ ...item, key: nanoid() })) ?? [],
        service_codes: data.serviceCodes?.map((item) => ({ ...item, key: nanoid() })) ?? [],
      })
    }
  }, [data])

  const { isLoading: isSaving, fetch: updateOrder } = useRequest(api.orders.updateOrder)
  const { isLoading: isSending, fetch: sendOrder } = useRequest(api.orders.sendOrder)

  if (isLoading) return <CircularProgress />
  if (!order) return null

  const handleSaveClick = () => {
    setEnableSave(false)
    updateOrder(order.id, {
      parts: order.parts,
      service_codes: order.service_codes,
      orderInfo: { survey: order.orderInfo.survey, additionalInfo: order.orderInfo.additionalInfo },
    }).then(() => {
      setOrderSaved(true)
    })
  }

  const handleSendClick = () => {
    setEnableSend(false)
    sendOrder(order.id).then(() => {
      setOrderSent(true)
      setEnableSend(true)
    })
  }

  const onFormChange = (formData: Partial<OrderFormData>) => {
    setEnableSave(true)
    const newState = { ...order, ...formData }
    setOrder(newState)
  }

  return (
    <div style={{ position: 'relative' }}>
      {(isSaving || isSending) && (
        <CircularProgress size="small" sx={{ position: 'absolute', top: 0, right: 10, width: 20, height: 20 }} />
      )}
      <Grid container spacing={6} sx={{ marginTop: 2 }}>
        <Grid item xs={8}>
          <Button style={{ marginBottom: 30 }}>
            <Link to={routing.createOrder.generatePath()}>Back</Link>
          </Button>
          <OrderForm
            data={order}
            onChange={onFormChange}
            userBioEditable={false}
            partItemsEditable={!isCommit}
            serviceCodesEditable={!isCommit}
          />
        </Grid>
        <Grid item xs={4}>
          <EventsLog shopifyOrderName={order.name} />
        </Grid>
        <Box sx={{ marginLeft: 6 }}>
          <LoadingButton variant="contained" onClick={handleSaveClick} loading={isSaving} disabled={!enableSave}>
            Save
          </LoadingButton>
          <LoadingButton variant="contained" onClick={handleSendClick} loading={isSaving} disabled={!enableSend}>
            Send to RXO
          </LoadingButton>
          {orderSaved && (
            <Box sx={{ marginTop: 2 }}>
              <Alert severity="success">
                <AlertTitle>Delivery order {order.name} successfully saved!</AlertTitle>
              </Alert>
            </Box>
          )}
          {orderSent && (
            <Box sx={{ marginTop: 2 }}>
              <Alert severity="success">
                <AlertTitle>Delivery order {order.name} sent to RXO!</AlertTitle>
              </Alert>
            </Box>
          )}
        </Box>
      </Grid>
    </div>
  )
}
