import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/login'
import Layout from '../containers/base-layout'
import StubPage from '../components/stub-page'
import PersonalTrainerList from '../pages/personal-trainer/personal-trainer-list'
import PersonalTrainerView from '../pages/personal-trainer/personal-trainer-view'
import { Employees } from '../pages/members/employees'
import { Members } from '../pages/members/members'
import MemberView from '../pages/members/member-view'
// import ConciergesList from '../pages/concierge/concierges-list'
// import ConciergeView from '../pages/concierge/concierge-view'
import { Orders } from 'src/pages/orders'
import { Dashboard } from 'src/pages/dashboard'
import { routing } from './routing'
import TPIWorkflowsCreatedList from 'src/pages/tpis/components/tpi-workflows-list-created'
import TPIWorkflowsContactedList from 'src/pages/tpis/components/tpi-workflows-list-contacted'
import TPIWorkflowsRejectedList from 'src/pages/tpis/components/tpi-workflows-list-rejected'
import TPIWorkflowsActivatingList from 'src/pages/tpis/components/tpi-workflows-list-activating'
import TPIWorkflowsActiveList from 'src/pages/tpis/components/tpi-workflows-active-list'
import TPIWorkflowsConfirmingList from 'src/pages/tpis/components/tpi-workflows-list-confirming'
import TPIWorkflowsTrainersAssignedList from 'src/pages/tpis/components/tpi-workflows-list-trainersAssigned'
import StudiosListPage from 'src/pages/studios/StudiosListPage/StudiosListPage'
import StudioPage from 'src/pages/studios/StudioPage/StudioPage'
import KnownDevicesListPage from 'src/pages/known-devices/known-devices-list'
import MembershipClassList from 'src/pages/membership-class/membership-class-list'
import MembershipsList from '../pages/memberships/memberships-list'

const Routes = () => (
  <Switch>
    <Layout>
      <Route exact path={routing.dashboard.pattern} component={Dashboard} />

      <Route exact path={routing.login.pattern} component={Login} />
      <Route
        exact
        path={routing.accessRestricted.pattern}
        component={() => <StubPage mainMessage="Looks like you don't have any allowed resources" />}
      />

      <Route exact path={routing.studiosList.pattern} component={StudiosListPage} />
      <Route exact path={routing.studio.pattern} component={StudioPage} />

      <Route exact path={routing.knownDevice.pattern} component={KnownDevicesListPage} />

      <Route exact path={routing.personalTrainers.pattern} component={PersonalTrainerList} />
      <Route exact path={routing.personalTrainer.pattern} component={PersonalTrainerView} />

      <Route exact path={routing.members.pattern} component={Members} />
      <Route exact path={routing.employees.pattern} component={Employees} />
      <Route path={routing.member.pattern} component={MemberView} />

      {/* <Route exact path={routing.concierges.pattern} component={ConciergesList} /> */}
      {/* <Route exact path={routing.concierge.pattern} component={ConciergeView} /> */}

      <Route exact path={routing.memberships.pattern} component={MembershipsList} />

      <Route exact path={routing.membershipClass.pattern} component={MembershipClassList} />

      <Route path={routing.ordersManagementWrapper.pattern} component={Orders} />

      <Route path={routing.tpiworkflows.pattern} component={TPIWorkflowsCreatedList} />

      <Route path={`${routing.createdTPIs.pattern}`} component={TPIWorkflowsCreatedList} />
      <Route path={`${routing.contactedTPIs.pattern}`} component={TPIWorkflowsContactedList} />
      <Route path={`${routing.activeTPIs.pattern}`} component={TPIWorkflowsActiveList} />
      <Route path={`${routing.rejectedTPIs.pattern}`} component={TPIWorkflowsRejectedList} />
      <Route path={`${routing.activatingTPIs.pattern}`} component={TPIWorkflowsActivatingList} />
      <Route path={`${routing.confirmingTPIs.pattern}`} component={TPIWorkflowsConfirmingList} />
      <Route path={`${routing.trainersAssignedTPIs.pattern}`} component={TPIWorkflowsTrainersAssignedList} />
    </Layout>
  </Switch>
)

export default Routes
