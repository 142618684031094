import React, { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'
import { Form, Input, Modal, Select, useWatchAll } from 'src/antd-components'
import { Resources } from 'src/types/permissions-types'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import Loader from 'src/components/loader'
import { FormeDialog } from 'src/hooks/useDialog'
import { useRequest } from 'src/hooks/use-request'
import { requestMembershipClassList } from 'src/api/services/cloud/memberships/requestMembershipClassList'
import { requestMembershipOne } from 'src/api/services/cloud/memberships/requestMembershipOne'
import { useSelector } from 'react-redux'
import { getMemberProfiles } from 'src/redux/member/member-selectors'
import { requestMembershipUpdate } from 'src/api/services/cloud/memberships/requestMembershipUpdate'

type FormData = {
  first_name: string
  last_name: string
  membershipClass: string
}

export const EditMembershipsDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const profiles = useSelector(getMemberProfiles)

  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.memberships)

  const { isLoading: isMembershipUpdateLoading, fetch: fetchMembershipUpdate } = useRequest(requestMembershipUpdate)
  const { data: membershipClassList, fetch: fetchMembershipClassList } = useRequest(requestMembershipClassList)
  const {
    data: membership,
    isLoading: isMembershipLoading,
    fetch: fetchMembershipOne,
  } = useRequest(requestMembershipOne)

  const initialFormData = useMemo(
    () => ({
      first_name: record.first_name,
      last_name: record.last_name,
      membershipClass: membership?.membershipClassId || '',
    }),
    [record, membership],
  )

  const membershipId = useMemo(() => {
    return profiles?.length && profiles[0].membership?.id
  }, [profiles])

  const [form] = Form.useForm<FormData>()
  const { first_name, last_name, membershipClass } = useWatchAll(form, initialFormData)

  const clearFormData = () => {
    form.resetFields()
  }

  const isFormValid = !!first_name.trim() && !!last_name.trim() && membershipClass
  const hasChanges = useMemo(() => {
    return !isEqual(initialFormData, { first_name: first_name.trim(), last_name: last_name.trim(), membershipClass })
  }, [initialFormData, first_name, last_name, membershipClass])

  const onEdit = () => {
    membershipId && fetchMembershipUpdate(membershipId, { membershipClassId: membershipClass })
    onClose()
  }

  const membershipClassOptions = useMemo(() => {
    return membershipClassList?.data?.map(({ id, name }) => ({ value: id, label: name }))
  }, [membershipClassList])

  useEffect(() => {
    fetchMembershipClassList()
  }, [fetchMembershipClassList])

  useEffect(() => {
    membershipId && fetchMembershipOne(membershipId)
  }, [membershipId, fetchMembershipOne])

  if (isLoading || isMembershipLoading) {
    return <Loader />
  }

  return (
    <Modal
      title="Edit memberships details"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid || !hasChanges }}
      confirmLoading={isMembershipUpdateLoading}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item label="First Name" name="first_name">
          <Input disabled allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input disabled allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Membership" name="membershipClass" hidden={isEditAllowed}>
          <Select allowClear options={membershipClassOptions} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
