import React, { useMemo, useState } from 'react'

import { Resources } from '../../types/permissions-types'
import { ColumnItem } from '../../components/accessible-table/acessible-table-types'
import ResourceListContainer from '../../containers/resource-list-container'
import CreatePersonalTrainerDialog from './components/create-personal-trainer-dialog'
import TrainersAdditionalActions from './components/trainer-list-additional-actions'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import {
  combineSearchFilters,
  createFullNameSearchFilter,
  createRegularSearchFilter,
} from '../../containers/resource-list-container/filters'
import {
  ComponentCell,
  DataCell,
  AvatarCell,
  NestedDataCell,
  PercentCell,
  ABSENT_VALUE_PLACEHOLDER,
} from '../../components/accessible-table/accessible-table-cell'
import TextPill, { PillColors } from 'src/components/text-pill'
import styled from '@emotion/styled'
import { WorkoutCategories, workoutCategories } from 'src/constants/personal-workout-constants'
import { EditTrainerRankingModal } from './components/edit-trainer-ranking-modal'

const StyledEmail = styled.span`
  margin-right: 8px;
`

const personalTrainersColumns: ColumnItem[] = [
  { key: 'avatar', title: 'Avatar', fields: ['avatar'], render: AvatarCell },
  {
    key: 'email',
    title: 'Email',
    render: (props) => {
      const email = props.rowData?.user?.email as string
      const isTest = props.rowData?.test as boolean

      return (
        <ComponentCell {...props}>
          <StyledEmail>{email || ABSENT_VALUE_PLACEHOLDER}</StyledEmail>
          {isTest && <TextPill pillColor={PillColors.ghost} pillText="Test" />}
        </ComponentCell>
      )
    },
    orderByField: 'user.email',
  },
  {
    key: 'first_name',
    title: 'First Name',
    fields: ['user'],
    render: (props) => <NestedDataCell {...props} nestedFields={['first_name']} />,
    orderByField: 'user.first_name',
  },
  {
    key: 'last_name',
    title: 'Last Name',
    fields: ['user'],
    render: (props) => <NestedDataCell {...props} nestedFields={['last_name']} />,
    orderByField: 'user.last_name',
  },
  {
    key: 'features',
    title: 'Category',
    render: (props) => {
      const categories: WorkoutCategories[] = props.rowData?.features?.map((category: string) => {
        return workoutCategories[category] || { caption: category, color: PillColors.orange }
      })

      return (
        <ComponentCell {...props}>
          {categories?.map(({ caption, color }) => (
            <TextPill key={caption} pillColor={color} pillText={caption} />
          ))}
        </ComponentCell>
      )
    },
  },
  {
    key: 'level',
    title: 'Level',
    fields: ['level'],
    render: DataCell,
    orderByField: 'level',
  },
  {
    key: 'status',
    title: 'Status',
    render: (props) => <ComponentCell {...props}>{props.rowData.active ? 'Active' : 'Not Active'}</ComponentCell>,
  },
]

const searchFilter = combineSearchFilters(
  createFullNameSearchFilter('user.first_name', 'user.last_name'),
  createRegularSearchFilter(['user.first_name', 'user.last_name', 'user.email']),
)

function PersonalTrainerList() {
  const [isCreatePersonalTrainerDialogOpened, setIsCreatePersonalTrainerDialogOpened] = useState(false)
  const onOpenCreatePersonalTrainerDialog = () => setIsCreatePersonalTrainerDialogOpened(true)
  const onCloseCreatePersonalTrainerDialog = () => setIsCreatePersonalTrainerDialogOpened(false)

  const [rankingEditModalOpen, setRankingEditModalOpen] = useState(false)

  const { isReadAllowed: isTrainerPayRateReadAllowed } = useResourcePermissionHandler(Resources.trainerPayRate)

  const payRateColumnExists = personalTrainersColumns.some((column) => column.key === 'pay_rate')

  if (isTrainerPayRateReadAllowed && !payRateColumnExists) {
    const payRateColumn = {
      key: 'pay_rate',
      title: 'Pay Rate',
      fields: ['current_pay_rate'],
      render: PercentCell,
    }
    personalTrainersColumns.splice(personalTrainersColumns.length - 1, 0, payRateColumn)
  }

  const additionalActions = useMemo(
    () => (
      <TrainersAdditionalActions
        onOpenCreate={onOpenCreatePersonalTrainerDialog}
        onRankingEdit={() => setRankingEditModalOpen(true)}
      />
    ),
    [],
  )

  return (
    <ResourceListContainer
      withClickableRows
      searchFilter={searchFilter}
      searchFilterPlaceholder="Search by name or email..."
      columns={personalTrainersColumns}
      resource={Resources.personalTrainers}
      additionalActions={additionalActions}
      sortField="user.email"
    >
      <div>
        <CreatePersonalTrainerDialog
          isOpened={isCreatePersonalTrainerDialogOpened}
          onClose={onCloseCreatePersonalTrainerDialog}
        />
        <EditTrainerRankingModal open={rankingEditModalOpen} onClose={() => setRankingEditModalOpen(false)} />
      </div>
    </ResourceListContainer>
  )
}

export default PersonalTrainerList
