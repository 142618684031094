import React from 'react'

import { Form, Input, Modal, useWatchAll } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'

import { RequestError } from 'src/api/http-client'
import Loader from 'src/components/loader'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from 'src/types/permissions-types'
import { getErrorMessage } from 'src/utils/errors'

import { sendInviteGuest } from 'src/api/services/cloud/memberships/sendInviteGuest'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { FormeDialog } from 'src/hooks/useDialog'
import { Checkbox } from 'antd'

type FormData = {
  email: string
  firstName: string
  lastName: string
  owner: boolean
}

export const InviteGuestDialog: FormeDialog<{ record: Member; membershipId: string }> = (props) => {
  const {
    params: { membershipId },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading } = useResourcePermissionHandler(Resources.personalData)

  const [form] = Form.useForm<FormData>()
  const inputValues = useWatchAll(form, { email: '', firstName: '', lastName: '', owner: false })
  const { email, firstName, lastName, owner } = inputValues

  const isFormDataValid = !!email && !!firstName && !!lastName

  const inviteGuest = async () => {
    try {
      await sendInviteGuest(membershipId, {
        email: email.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        owner,
      })

      onDisplaySuccessNotification('Invite sent successfully')
      onClose()
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
    }
  }

  const { fetch: onInviteGuest, isLoading: isSubmitting } = useRequest(inviteGuest)

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal
      title="Invite Guest"
      cancelText="Cancel"
      okText="Send"
      open={isOpened}
      okButtonProps={{ disabled: !isFormDataValid }}
      onOk={onInviteGuest}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <Form form={form} labelAlign="right" layout="vertical">
        <Form.Item label="email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="first name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="last name" name="lastName">
          <Input />
        </Form.Item>
        <Form.Item label="owner" name="owner">
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  )
}
