import { Member } from './member-constants'
import { BillingGroup } from '../types/billing-groups'

export enum PersonalTrainerCategory {
  Barre = 'Barre',
  Dance = 'Dance',
  Pilates = 'Pilates',
  Recovery = 'Recovery',
  Specialty = 'Specialty',
  Strength = 'Strength',
  Yoga = 'Yoga',
  Mind = 'Mind',
}

export enum PersonalTrainerLevel {
  NoLevel = 'NoLevel',
  HC = 'HC',
  PT1 = 'PT1',
  PT2 = 'PT2',
}

export enum PersonalTrainerFeatures {
  TPI = 'tpi',
}

export const levelsList = [
  { id: PersonalTrainerLevel.NoLevel, label: 'No level assigned' },
  { id: PersonalTrainerLevel.HC, label: 'Health Coach - HC' },
  { id: PersonalTrainerLevel.PT1, label: 'Personal Trainer 1 - PT1' },
  { id: PersonalTrainerLevel.PT2, label: 'Personal Trainer 2 - PT2' },
]

export const personalTrainerCategoryChoicesList = [
  { id: PersonalTrainerCategory.Barre, name: 'Barre' },
  { id: PersonalTrainerCategory.Dance, name: 'Dance' },
  { id: PersonalTrainerCategory.Pilates, name: 'Pilates' },
  { id: PersonalTrainerCategory.Recovery, name: 'Recovery' },
  { id: PersonalTrainerCategory.Specialty, name: 'Specialty' },
  { id: PersonalTrainerCategory.Strength, name: 'Strength' },
  { id: PersonalTrainerCategory.Yoga, name: 'Yoga' },
  { id: PersonalTrainerCategory.Mind, name: 'Mind' },
]

export const personalTrainerFieldsAllowedForEdit = ['first_name', 'last_name']

export type PersonalTrainerRate = {
  id: string
  is_default: boolean
  description: string
  rate: number
  duration: number
  created_date: Date
  archived_date: Date
  clients: Member[]
}

export type PersonalTrainer = {
  id: string
  active: boolean
  calendly_id: string
  first_name: string
  last_name: string
  email: string
  avatar?: string | null
  avatar_head_image?: string | null
  user: Member | null
  billing_groups: BillingGroup[]
  clients: Member[]
  concierge_clients: Member[]
  default_billing_group_id?: string
  test: boolean
  pay_rate: number
  pay_rates: any[]
  current_pay_rate?: string
  level: PersonalTrainerLevel
  features: PersonalTrainerFeatures[]
  biography: string
  certifications: []
  coaching_styles: []
  specializations: []
  sort_rank: number
}

export type PersonalTrainerAssignedMembers = {
  user_id: string
  first_name: string | null
  last_name: string | null
  email: string | null
  personal_trainer_billing_group_id: string | null
  billing_group_name: string | null
  assigned_date: Date
}

export const DEFAULT_TRAINER_PAY_RATE = 0.7

export type PayRateResponse = {
  pay_rate: number
  start_date: string
  end_date: string
  start_date_formatted: string
  end_date_formatted: string
}
