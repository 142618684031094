import React, { ReactElement } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { MenuItem } from '@mui/material'
import styled from '@emotion/styled'
import LogoutIcon from '@mui/icons-material/Logout'
import Typography from '@mui/material/Typography'
import ListItemIcon from '@mui/material/ListItemIcon'
import PunchClock from '@mui/icons-material/PunchClock'
import { mainColor } from '../../constants/styles-constants'
import { clearAuthData } from '../../utils/auth-utils'
import { useDialog } from 'src/hooks/useDialog'
import SelectTimezoneDialog from 'src/components/dialogs/select-timezone-dialog'
import SettingsIcon from '@mui/icons-material/Settings'
import { ButtonWithMenu } from './ButtonWithMenu'
import { ButtonWithMenuProps } from './types'

const StyledSettingsIcon = styled(SettingsIcon)`
  fill: ${mainColor};
`

function UserMenu({ Button = ButtonWithMenu }: { Button?: (arg: ButtonWithMenuProps) => ReactElement }) {
  const { logout } = useAuth0()

  const onLogout = () => {
    logout({ returnTo: window.location.origin })
    clearAuthData()
  }

  const editTimezoneDialog = useDialog(SelectTimezoneDialog)

  const onChangeTimezone = () => {
    // TODO: remove after migration to antd
    // !!! This is a temporary fix !!!
    // MUI menu adds overflow: hidden to body. When menu is closed, antd's modal is opened, but overflow; hidden is still present on body
    // Modal stores overflow: hidden somwhere in its state and when the modal is closed, is sets overflow: hidden to body
    // Result: we cannot scroll a page because body has overflow: hidden
    // Fix: remove all of the styles from body before we open a modal.
    // We can remove this fix after we migrate menu to antd
    const body = document.body
    body.removeAttribute('style')
    /////////////////////////////

    editTimezoneDialog.show({ onClose: () => editTimezoneDialog.hide() })
  }

  return (
    <>
      {editTimezoneDialog.node}
      <Button icon={StyledSettingsIcon}>
        <MenuItem onClick={onChangeTimezone}>
          <ListItemIcon>
            <PunchClock fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Select Time Zone</Typography>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Logout</Typography>
        </MenuItem>
      </Button>
    </>
  )
}

export default UserMenu
