import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { orderBy } from 'lodash'

import { PersonalTrainerRouteParams } from 'src/boot/routing'
import { formatBillRateSession } from 'src/utils/format-bill-rate'

import { Resources } from '../../types/permissions-types'
import { PageContainer, PageSectionHeader, PageSectionTable, PageTitle } from '../../components/ui-kit'
import { getFieldDataQa } from '../../utils/entities-data-qa-utils'
import { usePersonalTrainerData } from './use-personal-trainer-data'
import { useBillingGroupsTableColumns } from './useBillingGroupsTableColumns'
import { useSessionTableColumns } from './useSessionTableColumns'
import { useAssignedMembersTableColumns } from './useAssignedMembersTableColumns'
import { useActionsConfig } from './useActionsConfig'
import Loader from '../../components/loader'
import TextInput from '../../components/inputs/text-input'
import PreviewImage from '../../components/preview-image'
import AccessibleTable from '../../components/accessible-table/accessible-table'
import ActionsDropdownMenu from '../../components/actions-dropdown'
import TableSectionContainer from '../../components/table-section-container'
import ResourceRecordContainer from '../../containers/resource-record-container'
import { Order } from '../../types/common-types'
import {
  getPersonalTrainerRecord,
  getIsPersonalTrainerLoading,
  getPersonalTrainerSessions,
  getIsPersonalTrainerSessionsLoading,
  getIsPersonalTrainerAssignedMembersLoading,
  getPersonalTrainerAssignedMembers,
} from '../../redux/personal-trainer/personal-trainer-selectors'
import { getComparator, stableSort } from '../../utils/sort-utils'
import { withPermissions } from 'src/hocs/withPermissions'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import TextPill, { PillColors } from '../../components/text-pill'
import { toPercentage } from '../../utils/percentage'
import api from '../../api'
import { RequestError } from '../../api/http-client'
import { getErrorMessage } from '../../utils/errors'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { useDialogsController } from './useDialogsController'
import { getUniformDateTime } from '../../utils/uniformDateDisplay'
import { transformPayRateResponse } from 'src/utils/data-transformation-utils'
import { PayRateResponse } from 'src/constants/personal-trainer-constants'
import Button from 'antd/lib/button'
import CopyTwoTone from '@ant-design/icons/lib/icons/CopyTwoTone'

const RatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const TestTrainerContainer = styled.div`
  margin-bottom: 25px;
`

const selectPersonalTrainerState = createStructuredSelector({
  isLoading: getIsPersonalTrainerLoading,
  record: getPersonalTrainerRecord,
  isAssignedMembersLoading: getIsPersonalTrainerAssignedMembersLoading,
  assignedMembers: getPersonalTrainerAssignedMembers,
  isSessionsLoading: getIsPersonalTrainerSessionsLoading,
  sessions: getPersonalTrainerSessions,
})

export type PayRateProps = {
  oldPayRate: number
  currentPayRate: number
  newPayRate: number
  newPayRateStartDate: string
  newPayRateStartDateFormatted: string
}

function PersonalTrainerView() {
  const { isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)
  const { isReadAllowed: isBillingGroupsReadAllowed, isCreateAllowed: isBillingGroupsCreateAllowed } =
    useResourcePermissionHandler(Resources.trainerBillingGroup)
  const { isReadAllowed: isAssignedMembersReadAllowed, isCreateAllowed: isAssignedMembersCreateAllowed } =
    useResourcePermissionHandler(Resources.trainerMember)
  const { isReadAllowed: isSessionsReadAllowed } = useResourcePermissionHandler(Resources.trainerSession)
  const { isReadAllowed: isTrainerPayRateReadAllowed } = useResourcePermissionHandler(Resources.trainerPayRate)
  const { isEditAllowed: isTrainerFeaturesEditAllowed } = useResourcePermissionHandler(Resources.trainerFeatures)

  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const { id: recordId } = useParams<PersonalTrainerRouteParams>()
  const { isLoading, record, sessions, assignedMembers } = useSelector(selectPersonalTrainerState)
  const [sessionsList, setSessionsList] = useState(sessions)
  const [showAllSessions, setShowAllSessions] = useState(false)

  const [newRateData, setNewRateData] = useState<PayRateProps>({
    oldPayRate: 0,
    currentPayRate: 0,
    newPayRate: 0,
    newPayRateStartDate: '',
    newPayRateStartDateFormatted: '',
  })

  usePersonalTrainerData(recordId)

  const onSuccess = useCallback(
    (id: string) => {
      if (isTrainerPayRateReadAllowed) {
        api.cloud.trainers
          .getTrainerPayRate(id)
          .then((resp: PayRateResponse[]) => {
            setNewRateData(transformPayRateResponse(resp))
          })
          .catch((error: RequestError) => {
            const errorMessage = getErrorMessage(error)
            onDisplayErrorNotification(errorMessage)
          })
      }
    },
    [onDisplayErrorNotification, isTrainerPayRateReadAllowed],
  )

  const dialogsController = useDialogsController(record, assignedMembers, newRateData, onSuccess)

  const {
    dialogNodes,
    onOpenAssignMemberDialog,
    onOpenCreatePersonalTrainerBillingGroup,
    onOpenEditPersonalTrainerDetailsDialog,
    onOpenEditPersonalTrainerSpecsDialog,
    onOpenEditTrainerMediaDialog,
  } = dialogsController

  const sessionsTableColumns = useSessionTableColumns()
  const assignedMembersTableColumns = useAssignedMembersTableColumns(dialogsController)
  const actionsConfig = useActionsConfig(dialogsController)
  const billingGroupsTableColumns = useBillingGroupsTableColumns(record)

  const updateShowAllSessions = () => setShowAllSessions(!showAllSessions)

  const sortList = (list: any, sortBy: string, sortDirection: Order): any[] =>
    stableSort(list || [], getComparator(sortDirection, sortBy))

  useEffect(() => {
    const sortedSessions = sortList(formatBillRateSession(sessions), 'date', Order.desc)
    setSessionsList(sortedSessions)
  }, [sessions, setSessionsList])

  useEffect(() => {
    if (recordId) {
      onSuccess(recordId)
    }
  }, [recordId, onSuccess])

  const billingGroups = useMemo(
    () => (record ? orderBy(record.billing_groups, (_) => _.id === record.default_billing_group_id, 'desc') : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(record?.billing_groups), record?.default_billing_group_id],
  )

  if (!record) {
    return null
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <PageContainer>
      <ResourceRecordContainer resource={Resources.personalTrainers} record={record}>
        <PageSectionHeader>
          <PageTitle>
            {record?.user?.email} {!record?.active && <TextPill pillText="Inactive" pillColor={PillColors.orange} />}
            {record?.active && <TextPill pillText="Active" pillColor={PillColors.green} />}
          </PageTitle>
          <ActionsDropdownMenu actionsConfig={actionsConfig} />
        </PageSectionHeader>
        <Grid container spacing={4}>
          <Grid item xs={2}>
            <TableSectionContainer
              withDivider
              title="Trainer Details"
              buttonTitle={isEditAllowed ? 'Edit' : undefined}
              onButtonClick={onOpenEditPersonalTrainerDetailsDialog}
            >
              {record?.test && (
                <TestTrainerContainer>
                  <TextPill pillText="Test Trainer" pillColor={PillColors.grey} />
                </TestTrainerContainer>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    readOnly
                    label="Name"
                    value={`${record?.user?.first_name} ${record?.user?.last_name}`}
                    id={getFieldDataQa(Resources.personalTrainers, 'name')}
                  />
                  <div style={{ display: 'flex' }}>
                    <TextInput
                      readOnly
                      label="Email"
                      value={record?.user?.email ?? ''}
                      id={getFieldDataQa(Resources.personalTrainers, 'email')}
                    />
                    <Button
                      type="text"
                      icon={<CopyTwoTone />}
                      onClick={() => navigator.clipboard.writeText(record!.user!.email)}
                    />
                  </div>
                  <TextInput
                    readOnly
                    label="ID"
                    value={record?.id ?? ''}
                    id={getFieldDataQa(Resources.personalTrainers, 'id')}
                  />
                  <TextInput
                    readOnly
                    label="Rank"
                    value={record?.sort_rank?.toString() ?? 'N/A'}
                    id={getFieldDataQa(Resources.personalTrainers, 'sort_rank')}
                  />
                  <TextInput
                    readOnly
                    label="Level"
                    value={record?.level ?? ''}
                    id={getFieldDataQa(Resources.personalTrainers, 'level')}
                  />
                  <TextInput
                    readOnly
                    label="Agora ID"
                    value={record?.user?.agora_int_uid ?? ''}
                    id={getFieldDataQa(Resources.personalTrainers, 'agora_int_uid')}
                  />
                  <RatesContainer>
                    {isTrainerPayRateReadAllowed && newRateData.newPayRateStartDate && newRateData.newPayRate && (
                      <TextInput
                        readOnly
                        label={`Starting ${newRateData.newPayRateStartDateFormatted}`}
                        value={toPercentage(Number(newRateData.newPayRate))}
                        id={getFieldDataQa(Resources.personalTrainers, 'new_rate')}
                      />
                    )}
                    {isTrainerPayRateReadAllowed && (
                      <TextInput
                        readOnly
                        label="Current Pay Rate"
                        value={newRateData.currentPayRate ? toPercentage(newRateData.currentPayRate) : 'NA'}
                        id={getFieldDataQa(Resources.personalTrainers, 'pay_rate')}
                      />
                    )}
                    {isTrainerPayRateReadAllowed && (
                      <TextInput
                        readOnly
                        label="Previous Pay Rate"
                        value={newRateData.oldPayRate ? toPercentage(newRateData.oldPayRate) : 'NA'}
                        id={getFieldDataQa(Resources.personalTrainers, 'previous_rate')}
                      />
                    )}
                  </RatesContainer>
                  <TextInput
                    id={getFieldDataQa(Resources.personalTrainers, 'created_date')}
                    readOnly
                    label="Created"
                    value={record?.user?.created_date ? getUniformDateTime(record.user.created_date) : ''}
                  />
                  <TextInput
                    id={getFieldDataQa(Resources.personalTrainers, 'updated_date')}
                    readOnly
                    label="Updated"
                    value={record?.user?.updated_date ? getUniformDateTime(record.user.updated_date) : ''}
                  />
                  {record?.features?.length > 0 && (
                    <TextInput
                      id={getFieldDataQa(Resources.personalTrainers, 'features')}
                      readOnly
                      label="Features"
                      value={record?.features?.join(', ')}
                    />
                  )}
                </Grid>
                {/* <Grid item xs={6}>

                </Grid> */}
              </Grid>
            </TableSectionContainer>
            <TableSectionContainer
              withDivider
              title="Media"
              buttonTitle="Edit Avatar"
              withAction={isEditAllowed && record?.active}
              onButtonClick={() => onOpenEditTrainerMediaDialog(false)}
            >
              <PreviewImage
                size={150}
                label="Avatar"
                source="avatar"
                record={record}
                data-qa={getFieldDataQa(Resources.personalTrainers, 'avatar')}
              />
            </TableSectionContainer>
            <TableSectionContainer
              withDivider
              title="Media"
              buttonTitle="Edit Close-Up Avatar"
              withAction={isEditAllowed && record?.active}
              onButtonClick={() => onOpenEditTrainerMediaDialog(true)}
            >
              <PreviewImage
                size={150}
                label="Close-Up Avatar"
                source="avatar_head_image"
                record={record}
                data-qa={getFieldDataQa(Resources.personalTrainers, 'avatar_head_image')}
              />
            </TableSectionContainer>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TableSectionContainer
                  withDivider
                  title="Trainer Specs."
                  buttonTitle={isTrainerFeaturesEditAllowed ? 'Edit' : undefined}
                  onButtonClick={onOpenEditPersonalTrainerSpecsDialog}
                >
                  <TextInput
                    readOnly
                    label="Active"
                    value={record?.active ? 'True' : 'False'}
                    id={getFieldDataQa(Resources.personalTrainers, 'active')}
                  />
                  {record?.calendly_id && (
                    <TextInput
                      readOnly
                      label="Calendly ID"
                      value={record?.calendly_id}
                      id={getFieldDataQa(Resources.personalTrainers, 'calendly_id')}
                    />
                  )}
                  {record?.biography && (
                    <TextInput
                      readOnly
                      label="Bio"
                      value={record?.biography}
                      id={getFieldDataQa(Resources.personalTrainers, 'biography')}
                    />
                  )}
                  {record?.certifications?.length > 0 && (
                    <TextInput
                      readOnly
                      label="Certifications"
                      value={record?.certifications?.join(', ') ?? '-'}
                      id={getFieldDataQa(Resources.personalTrainers, 'certifications')}
                    />
                  )}
                  {record?.coaching_styles?.length > 0 && (
                    <TextInput
                      readOnly
                      label="Coaching Styles"
                      value={record?.coaching_styles?.join(', ')}
                      id={getFieldDataQa(Resources.personalTrainers, 'coaching_styles')}
                    />
                  )}
                  {record?.specializations?.length > 0 && (
                    <TextInput
                      readOnly
                      label="Specializations"
                      value={record?.specializations?.join(', ')}
                      id={getFieldDataQa(Resources.personalTrainers, 'specializations')}
                    />
                  )}
                </TableSectionContainer>
              </Grid>
              <Grid item xs={8}>
                {isBillingGroupsReadAllowed && (
                  <TableSectionContainer
                    title="Billing Groups"
                    buttonTitle={isBillingGroupsCreateAllowed && record?.active ? 'Add' : undefined}
                    onButtonClick={onOpenCreatePersonalTrainerBillingGroup}
                  >
                    <PageSectionTable
                      id="billing_groups"
                      resource={Resources.personalTrainers}
                      data={billingGroups}
                      activeRowId={record.default_billing_group_id}
                      columns={billingGroupsTableColumns}
                    />
                  </TableSectionContainer>
                )}
              </Grid>
            </Grid>
            {isAssignedMembersReadAllowed && (
              <TableSectionContainer
                title="Assigned Members"
                buttonTitle={isAssignedMembersCreateAllowed && record?.active ? 'Add' : undefined}
                onButtonClick={onOpenAssignMemberDialog}
              >
                <PageSectionTable
                  id="clients"
                  resource={Resources.personalTrainers}
                  data={assignedMembers}
                  sortBy="id"
                  columns={assignedMembersTableColumns}
                />
              </TableSectionContainer>
            )}
            {isSessionsReadAllowed && (
              <TableSectionContainer
                title="Sessions"
                withShowAllToggleButton
                showAll={showAllSessions}
                onShowAllClick={updateShowAllSessions}
              >
                <AccessibleTable
                  id={getFieldDataQa(Resources.personalTrainers, 'trainer_sessions')}
                  sortBy="date"
                  sortByDirection={Order.desc}
                  resource={Resources.personalTrainers}
                  columns={sessionsTableColumns}
                  data={showAllSessions ? sessionsList : [...sessionsList.slice(0, 5)]}
                />
              </TableSectionContainer>
            )}
          </Grid>
        </Grid>
      </ResourceRecordContainer>

      {dialogNodes}
    </PageContainer>
  )
}

export default withPermissions(PersonalTrainerView, Resources.personalTrainers)
