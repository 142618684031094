import React, { useCallback, useMemo } from 'react'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'
import { IconButton, Tooltip } from '@mui/material'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { useAction } from 'src/hooks/use-actions'
import { RequestError } from 'src/api/http-client'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import { getErrorMessage } from 'src/utils/errors'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { setPersonalTrainerRecord } from 'src/redux/personal-trainer/personal-trainer-reducer'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from 'src/constants/messages'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { ActionCell, CurrencyCell, DataCell } from '../../components/accessible-table/accessible-table-cell'

export const useBillingGroupsTableColumns = (record: PersonalTrainer | null) => {
  const { isCreateAllowed: isBillingGroupsCreateAllowed } = useResourcePermissionHandler(Resources.trainerBillingGroup)
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const onUpdatePersonalTrainerRecord = useAction(setPersonalTrainerRecord)

  const onMakeDefaultBillingGroup = useCallback(
    (rowData: { [key: string]: any }) => {
      if (!record) return

      if (!isBillingGroupsCreateAllowed) {
        onDisplayErrorNotification(`${LIMITED_PERMISSIONS_MAIN_MESSAGE}. ${LIMITED_PERMISSIONS_SECONDARY_MESSAGE}.`)
        return
      }

      const { id: billingGroupId } = rowData
      const data = { default_billing_group_id: billingGroupId }
      const params = { id: record.id, data, previousData: { id: record.id } }

      api.common
        .update(Resources.personalTrainers, params)
        .then(({ data: recordData }) => {
          onUpdatePersonalTrainerRecord(recordData)
          onDisplaySuccessNotification('The billing group was successfully marked as default')
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    },
    [
      isBillingGroupsCreateAllowed,
      onDisplayErrorNotification,
      onDisplaySuccessNotification,
      onUpdatePersonalTrainerRecord,
      record,
    ],
  )

  return useMemo(
    (): ColumnItem[] => [
      { key: 'name', title: 'Name', fields: ['name'], render: DataCell, orderByField: 'name' },
      { key: 'rate_30m', title: '30 Mins', fields: ['rate_30m'], render: CurrencyCell, orderByField: 'rate_30m' },
      { key: 'rate_60m', title: '60 Mins', fields: ['rate_60m'], render: CurrencyCell, orderByField: 'rate_60m' },
      {
        key: 'makeDefaultBillingGroup',
        render: (props) => (
          <ActionCell
            action={onMakeDefaultBillingGroup}
            renderIcon={({ isActive }: { isActive: boolean }) => (
              <Tooltip arrow placement="top" title="Default Billing Group">
                <IconButton>
                  {isActive ? (
                    <StarIcon color="primary" />
                  ) : (
                    <StarBorderOutlinedIcon
                      sx={{
                        fill: 'white',
                        'tr:hover &': { fill: (_) => _.palette.action.disabled },
                        'button:hover > &': { fill: (_) => _.palette.primary.main },
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {...props}
          />
        ),
      },
    ],
    [onMakeDefaultBillingGroup],
  )
}
