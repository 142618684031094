import React, { ReactNode, useCallback } from 'react'

import {
  EditMemberDetailsDialog,
  EditMemberMediaDialog,
  EditEmergencyContactInfoDialog,
  EditMemberSessionDetailsDialog,
  CreatePromotionDialog,
  DeletePromotionConfirmationDialog,
  AddBundlesDialog,
  EditMembershipsDialog,
  SendMembershipInviteDialog,
  EditBillingScheduleDialog,
  EditUserChargesDialog,
  EditAssignedTrainersDialog,
  LinkAccountDialog,
  CancelMembershipDialog,
  UncancelMembershipDialog,
  PauseMembershipDialog,
  RestoreMembershipDialog,
  SwitchMembershipDialog,
} from './components/dialogs'
import { MembershipStatus } from 'src/api/services/cloud/memberships/types'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import { BillingSchedule, Charge } from 'src/constants/billing-v2-contants'
import { BillingPromotion } from 'src/constants/billing-promotion-constants'
import { useDialog } from 'src/hooks/useDialog'
import { Member } from 'src/constants/member-constants'
import { PersonalWorkout } from 'src/constants/personal-workout-constants'
import { SetPasswordDialog } from './components/dialogs/SetPasswordDialog'
import { InviteGuestDialog } from './components/dialogs/InviteGuestDialog'

export type DialogsController = {
  showEditMemberDetailsDialog: () => void
  showEditMembershipsDialog: () => void
  showSendMembershipInviteDialog: () => void
  showEditMemberMediaDialog: () => void
  showEditEmergencyContactInfoDialog: () => void
  showEditMemberSessionDetailsDialog: (session: PersonalWorkout) => void
  showCreatePromotionDialog: () => void
  showDeletePromotionDialog: (promotion: BillingPromotion) => void
  showAddBundlesDialog: () => void
  showEditBillingScheduleDialog: (schedule: BillingSchedule) => void
  showEditUserChargesDialog: (charge: Charge) => void
  showLinkAccountDialog: () => void
  showEditAssignedTrainersDialog: (trainer: PersonalTrainer) => void
  showCancelMembershipDialog: () => void
  showUncancelMembershipDialog: () => void
  showPauseMembershipDialog: () => void
  showRestoreMembershipDialog: (currentState: MembershipStatus) => void
  showSwitchMembershipDialog: () => void
  showSetPasswordDialog: () => void
  showInviteGuestDialog: (membershipId: string) => void
  dialogNodes: ReactNode
}

export const useDialogsController = (record: Member | null): DialogsController => {
  const editMemberDetailsDialog = useDialog(EditMemberDetailsDialog)
  const editMemberMediaDialog = useDialog(EditMemberMediaDialog)
  const editEmergencyContactInfoDialog = useDialog(EditEmergencyContactInfoDialog)
  const editMemberSessionDetailsDialog = useDialog(EditMemberSessionDetailsDialog)
  const createPromotionDialog = useDialog(CreatePromotionDialog)
  const deletePromotionDialog = useDialog(DeletePromotionConfirmationDialog)
  const addBundlesDialog = useDialog(AddBundlesDialog)
  const editMembershipsDialog = useDialog(EditMembershipsDialog)
  const sendMembershipInviteDialog = useDialog(SendMembershipInviteDialog)
  const editBillingScheduleDialog = useDialog(EditBillingScheduleDialog)
  const editUserChargesDialog = useDialog(EditUserChargesDialog)
  const editAssignedTrainersDialog = useDialog(EditAssignedTrainersDialog)
  const linkAccountDialog = useDialog(LinkAccountDialog)
  const cancelMembershipDialog = useDialog(CancelMembershipDialog)
  const pauseMembershipDialog = useDialog(PauseMembershipDialog)
  const uncancelMembershipDialog = useDialog(UncancelMembershipDialog)
  const restoreMembershipDialog = useDialog(RestoreMembershipDialog)
  const switchMembershipDialog = useDialog(SwitchMembershipDialog)
  const setPasswordDialog = useDialog(SetPasswordDialog)
  const inviteGuestDialog = useDialog(InviteGuestDialog)

  const showEditMemberDetailsDialog = useCallback(
    () => record && editMemberDetailsDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMemberDetailsDialog.show, record],
  )
  const showEditMembershipsDialog = useCallback(
    () => record && editMembershipsDialog.show({ record }),
    [editMembershipsDialog, record],
  )
  const showSendMembershipInviteDialog = useCallback(
    () => sendMembershipInviteDialog.show(),
    [sendMembershipInviteDialog],
  )

  const showEditMemberMediaDialog = useCallback(
    () => record && editMemberMediaDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMemberMediaDialog.show, record],
  )
  const showEditEmergencyContactInfoDialog = useCallback(
    () => record && editEmergencyContactInfoDialog.show({ record, phoneLength: 10 }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editEmergencyContactInfoDialog.show, record],
  )
  const showEditMemberSessionDetailsDialog = useCallback(
    (session: PersonalWorkout) => record && editMemberSessionDetailsDialog.show({ record, session }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editMemberSessionDetailsDialog.show, record],
  )
  const showCreatePromotionDialog = useCallback(
    () => record && createPromotionDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPromotionDialog.show, record],
  )
  const showDeletePromotionDialog = useCallback(
    (promotion: BillingPromotion) => record && deletePromotionDialog.show({ record, promotion }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletePromotionDialog.show, record],
  )
  const showAddBundlesDialog = useCallback(
    () => record && addBundlesDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addBundlesDialog.show, record],
  )
  const showEditBillingScheduleDialog = useCallback(
    (schedule: BillingSchedule) => editBillingScheduleDialog.show({ schedule }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editBillingScheduleDialog.show],
  )
  const showLinkAccountDialog = useCallback(
    () => record && linkAccountDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkAccountDialog.show, record],
  )
  const showEditUserChargesDialog = useCallback(
    (charge: Charge) => editUserChargesDialog.show({ charge }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editUserChargesDialog.show],
  )
  const showEditAssignedTrainersDialog = useCallback(
    (trainer: PersonalTrainer) => record && editAssignedTrainersDialog.show({ record, trainer }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editAssignedTrainersDialog.show, record],
  )

  const showCancelMembershipDialog = useCallback(
    () => record && cancelMembershipDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelMembershipDialog.show, record],
  )

  const showUncancelMembershipDialog = useCallback(
    () => record && uncancelMembershipDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uncancelMembershipDialog.show, record],
  )

  const showPauseMembershipDialog = useCallback(
    () => record && pauseMembershipDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pauseMembershipDialog.show, record],
  )

  const showRestoreMembershipDialog = useCallback(
    (currentState: MembershipStatus) => record && restoreMembershipDialog.show({ record, current: currentState }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restoreMembershipDialog.show, record],
  )

  const showSwitchMembershipDialog = useCallback(
    () => record && switchMembershipDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pauseMembershipDialog.show, record],
  )

  const showSetPasswordDialog = useCallback(
    () => record && setPasswordDialog.show({ record }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPasswordDialog.show, record],
  )

  const showInviteGuestDialog = useCallback(
    (membershipId: string) => record && inviteGuestDialog.show({ record, membershipId }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inviteGuestDialog.show, record],
  )

  const dialogNodes = (
    <>
      {editMemberDetailsDialog.node}
      {editMembershipsDialog.node}
      {sendMembershipInviteDialog.node}
      {editMemberMediaDialog.node}
      {editEmergencyContactInfoDialog.node}
      {editMemberSessionDetailsDialog.node}
      {createPromotionDialog.node}
      {deletePromotionDialog.node}
      {addBundlesDialog.node}
      {editBillingScheduleDialog.node}
      {linkAccountDialog.node}
      {editUserChargesDialog.node}
      {editAssignedTrainersDialog.node}
      {cancelMembershipDialog.node}
      {uncancelMembershipDialog.node}
      {pauseMembershipDialog.node}
      {restoreMembershipDialog.node}
      {switchMembershipDialog.node}
      {setPasswordDialog.node}
      {inviteGuestDialog.node}
    </>
  )

  return {
    showEditMemberDetailsDialog,
    showEditMembershipsDialog,
    showSendMembershipInviteDialog,
    showEditMemberMediaDialog,
    showEditEmergencyContactInfoDialog,
    showEditMemberSessionDetailsDialog,
    showCreatePromotionDialog,
    showDeletePromotionDialog,
    showAddBundlesDialog,
    showEditBillingScheduleDialog,
    showLinkAccountDialog,
    showEditUserChargesDialog,
    showEditAssignedTrainersDialog,
    showCancelMembershipDialog,
    showUncancelMembershipDialog,
    showPauseMembershipDialog,
    showRestoreMembershipDialog,
    showSwitchMembershipDialog,
    showSetPasswordDialog,
    showInviteGuestDialog,
    dialogNodes,
  }
}
