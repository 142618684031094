import { IOrder } from 'src/api/services/orders/types'
import { OrderStatus } from 'src/constants/order-constants'
import { formatPromotionDate } from './date-utils'

// install date is extracted from a list of orders and their scheduled dates obtained from a call to OMS:
// - if only 1 "Commit" order, use that scheduled date as the install date
// - if multiple, use the earliest date
// - if none, return placeholder to inform user that no install date found

const extractInstallDate = (ordersList: IOrder[]): string => {
  let installDate = ''
  for (const order of ordersList) {
    if (
      order?.status === OrderStatus.commit &&
      order?.scheduled_date &&
      (!installDate || order?.scheduled_date < installDate)
    ) {
      installDate = order.scheduled_date
    }
  }

  return installDate ? formatPromotionDate(installDate) : 'Install date not found'
}

export default extractInstallDate
