import React from 'react'
import { IconButton, MenuItem as MuiMenuItem } from '@mui/material'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined'
import styled from '@emotion/styled'
import { MenuItem } from './types'
import UserMenu from './UserMenu'
import { GrayButtonWithMenu } from './ButtonWithMenu'
import { buttonStyles } from './styles'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  background: #bababa;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Header = styled.div`
  padding-top: 8px;
`

const Logo = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.022em;
  text-align: center;
  color: #000000;
`

const Bottom = styled.div`
  margin: auto;
  margin-bottom: 20px;
`

const StyledUserMenu = styled(UserMenu)``

const StyledIconButton = styled(IconButton)`
  ${buttonStyles}
  margin: 8px;
`

const LogoContainer = styled.div`
  margin-bottom: 52px;
`

const StyledExpandButton = styled(IconButton)`
  margin: 8px;
`

const StyledGrayButtonWithMenu = styled(GrayButtonWithMenu)`
  margin: 8px;
`

const MenuTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding: 8px 16px;
`

export function CollapsedDrawerContent({
  menuItems: _menuItems,
  onCollapsedChange,
}: {
  menuItems: MenuItem[]
  onCollapsedChange: () => void
}) {
  const history = useHistory()

  const menuItems = _menuItems.map((item) => {
    const Icon = item.icon

    if (!item.children) {
      return (
        <StyledIconButton key={item.name} onClick={() => history.push(`/${item.to || item.name}`)}>
          <Icon />
        </StyledIconButton>
      )
    }

    const children = [
      <MenuTitle key="title">{item.label}</MenuTitle>,
      ...item.children.map((child) => {
        return (
          <MuiMenuItem key={child.name} onClick={() => history.push(`/${child.name}`)}>
            {child.label}
          </MuiMenuItem>
        )
      }),
    ]

    return (
      <StyledGrayButtonWithMenu key={item.name} icon={Icon}>
        {children}
      </StyledGrayButtonWithMenu>
    )
  })

  return (
    <Wrapper>
      <Header>
        <StyledExpandButton onClick={onCollapsedChange}>
          <EastOutlinedIcon fontSize="large" />
        </StyledExpandButton>
        <LogoContainer>
          <StyledIconButton>
            <Logo>D</Logo>
          </StyledIconButton>
        </LogoContainer>
        {menuItems}
      </Header>
      <Bottom>
        <StyledUserMenu Button={GrayButtonWithMenu} />
      </Bottom>
    </Wrapper>
  )
}
