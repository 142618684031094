import * as React from 'react'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import styled from '@emotion/styled'

import { Dialog, DialogActions, DialogTitle } from '@mui/material'
import CancelButton from 'src/components/buttons/cancel-buttons'
import DeleteButton from 'src/components/buttons/delete-button'
import { FormeDialog } from 'src/hooks/useDialog'
import { BillingPromotion } from 'src/constants/billing-promotion-constants'
import { Member } from 'src/constants/member-constants'
import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { useAction } from 'src/hooks/use-actions'
import { fetchMemberPromotions } from 'src/redux/member/member-actions'

const StyledDialogTitle = styled(DialogTitle)`
  font-weight: 600;
  border-bottom: solid 1px #f2f2f2;
`

const StyledDialogContext = styled(DialogContentText)`
  margin: 15px;
  padding-top: 10px;
  font-weight: 400;
  color: #000;
  font-size: 14px;
  letter-spacing: 0.03em;
`

const StyledDialogActions = styled(DialogActions)`
  background: #f2f2f2;
`

const StyledCancelbutton = styled(CancelButton)`
  background: #fff;
  color: #000;
  &:hover,
  &:active,
  &:focus {
    color: #000;
  }
`

export const DeletePromotionConfirmationDialog: FormeDialog<{ record: Member; promotion: BillingPromotion }> = (
  props,
) => {
  const {
    params: { record, promotion },
    open: isOpened,
    onClose,
  } = props

  const updatePromotions = useAction(fetchMemberPromotions)

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const onDelete = () => {
    const params = { id: promotion?.id, data: {}, previousData: { id: promotion?.id } }

    api.common
      .delete(Resources.billingPromotions, params)
      .then(() => {
        updatePromotions(record?.id)
        onDisplaySuccessNotification('Promotion successfully deleted')
      })
      .catch(() => {
        onDisplayErrorNotification('Error deleting promotion. Please refresh and try again.')
      })
    onClose()
  }

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <StyledDialogTitle>Remove</StyledDialogTitle>
      <DialogContent>
        <StyledDialogContext>Are you sure you want to remove this coupon?</StyledDialogContext>
      </DialogContent>
      <StyledDialogActions>
        <StyledCancelbutton onClick={onClose}>Cancel</StyledCancelbutton>
        <DeleteButton onClick={onDelete}>Remove</DeleteButton>
      </StyledDialogActions>
    </Dialog>
  )
}
