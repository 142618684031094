import { SCondition } from '../../types/cond-operators'

export type ResourceListContainerSearchFilter = (
  searchString: string,
) => { [key: string]: string | SCondition } | undefined

export const createRegularSearchFilter =
  (fields: string[]): ResourceListContainerSearchFilter =>
  (searchString) => ({ [`searchBy||${fields.join(',')}`]: searchString.trim() })

export const createFullNameSearchFilter = (firstNameField: string, lastNameField: string) => (searchString: string) => {
  const isSearchingByFullName = /\w+\s+\w+/.test(searchString)
  if (isSearchingByFullName) {
    const [firstName, lastName] = searchString.trim().split(/\s+/)
    return {
      rawFilter: {
        $or: [{ $and: [{ [firstNameField]: { $contL: firstName } }, { [lastNameField]: { $contL: lastName } }] }],
      },
    }
  }
}

export const combineSearchFilters =
  (...filters: ResourceListContainerSearchFilter[]): ResourceListContainerSearchFilter =>
  (searchString: string) => {
    for (const filter of filters) {
      const result = filter(searchString)
      if (result) return result
    }
    throw new Error('No one search filter could be applied')
  }
