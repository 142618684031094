import React from 'react'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'

import { Resources } from '../../../types/permissions-types'
import { getButtonDataQa } from '../../../utils/entities-data-qa-utils'
import ActionsDropdownMenu from '../../../components/actions-dropdown'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import { Button } from '@mui/material'

type TrainersActionsDropdownMenuType = {
  onOpenCreate?: () => void
  onRankingEdit?: () => void
}
function TrainersAdditionalActions(props: TrainersActionsDropdownMenuType) {
  const actionsConfig = []

  const { isCreateAllowed } = useResourcePermissionHandler(Resources.personalTrainers)
  const { isEditAllowed: isRankingEditAllowed } = useResourcePermissionHandler(Resources.trainerPayRate)

  if (isCreateAllowed) {
    actionsConfig.push({
      icon: <AddIcon />,
      title: 'Create',
      dataQa: getButtonDataQa(Resources.personalTrainers, 'create'),
      onClick: () => props.onOpenCreate?.(),
    })
  }

  if (isRankingEditAllowed) {
    actionsConfig.push({
      icon: <EditIcon />,
      title: 'Edit Trainer Ranking',
      dataQa: getButtonDataQa(Resources.personalTrainers, 'ranking'),
      onClick: () => props.onRankingEdit?.(),
    })
  }

  if (actionsConfig.length === 1) {
    const [actionConfig] = actionsConfig

    return (
      <Button startIcon={actionConfig.icon} onClick={actionConfig?.onClick} data-qa={actionConfig.dataQa}>
        {actionConfig.title}
      </Button>
    )
  }

  return <ActionsDropdownMenu actionsConfig={[actionsConfig]} />
}

export default TrainersAdditionalActions
