import { useEffect } from 'react'

import { getErrorMessage } from 'src/utils/errors'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

export const useRequestErrorHandler = (error?: Error) => {
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  useEffect(() => {
    if (error) onDisplayErrorNotification(getErrorMessage(error))
  }, [error, onDisplayErrorNotification])
}
