import api from 'src/api'
import { HttpClient, getRequestParams, RequestError, RequestMethods } from 'src/api/http-client'
import { BundleState, BundleOrderResponceProps, UserBundle } from 'src/api/services/bundles'
import { CloudBundle, Bill, Charge } from 'src/constants/billing-v2-contants'
import { Identifier } from 'src/types/request-entities-types'
import { SubEntities } from '../entities'

enum BillingPeriod {
  monthly = 'monthly',
  biweekly = 'biweekly',
}

const billingV2Api = (baseUrl: string, httpClient: HttpClient) => ({
  getUserBundle: async (user_id: Identifier): Promise<(CloudBundle & Pick<UserBundle, 'state'>)[]> => {
    const bundles = (await httpClient(`${baseUrl}/bundles/user/${user_id}`)) as CloudBundle[]
    return bundles.map((b) => ({
      ...b,
      state: BundleState.sentToBilling,
    }))
  },
  getBundlesByName: (order_name: string): Promise<BundleOrderResponceProps> => {
    return httpClient(`${baseUrl}/bundles/order/${order_name}`)
  },
  putBundlesFromOrder: (order_id: string, user_id: string): Promise<any> => {
    return httpClient(`${baseUrl}/bundles/order/${user_id}/${order_id}`, {
      method: RequestMethods.post,
    })
  },
  getUserBillEstimation: async (user_id: Identifier, period: string): Promise<Bill> => {
    const bill = period
      ? ((await httpClient(`${baseUrl}/bills/user/${user_id}?period=${period}`)) as Bill)
      : ((await httpClient(`${baseUrl}/bills/user/${user_id}`)) as Bill)
    return bill
  },

  getUserBillingSchedule: async (user_id: Identifier): Promise<any> => {
    const schedule = await api.entities
      .getSubEntityListWithFilterOnly(SubEntities.billingSchedule, {
        filter: {
          'user_id||$eq': user_id,
        },
      })
      .then((res) => {
        return res
      })
      .catch((error: RequestError) => {
        return error
      })
    return schedule
  },

  updateUserBillingSchedule: async (
    user_id: Identifier,
    period: string,
    due_date: string,
    anchorDay: number,
  ): Promise<void> => {
    const params = getRequestParams({
      method: 'PATCH',
      body: JSON.stringify({ due_date: due_date, monthly_anchor_day: anchorDay }),
    })
    await httpClient(`${baseUrl}/billing-schedule/${user_id}/${period}/`, params)
  },

  getUserCharges: async (): Promise<any> => {
    const params = getRequestParams({ method: 'GET' })
    await httpClient(`${baseUrl}/charges`, params)
  },

  updateUserCharges: async (charge_id: Identifier, charge: Partial<Charge>): Promise<void> => {
    const params = getRequestParams({ method: 'PATCH', body: JSON.stringify(charge) })
    await httpClient(`${baseUrl}/charges/${charge_id}/`, params)
  },

  createUserCharges: async (charge: Partial<Charge>): Promise<void> => {
    const params = getRequestParams({ method: 'POST', body: JSON.stringify(charge) })
    await httpClient(`${baseUrl}/charges/`, params)
  },

  triggerUserBiWeeklyBilling: async (user_id: Identifier): Promise<void> => {
    const params = getRequestParams({ method: 'POST' })
    await httpClient(`${baseUrl}/bills/user/${user_id}/${BillingPeriod.biweekly}`, params)
  },
})

export default billingV2Api
