import React, { useReducer } from 'react'
import { Theme, responsiveFontSizes, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { lightTheme, darkTheme } from '../theme/app-theme'
import { CssBaseline } from '@mui/material'

type ThemeContextProviderProps = {
  children: React.ReactNode
}

function ThemeContextProvider({ children }: ThemeContextProviderProps) {
  const [useDefaultTheme] = useReducer((theme) => !theme, true)

  const muiTheme: Theme = useDefaultTheme ? lightTheme : darkTheme
  const theme = responsiveFontSizes(muiTheme)

  return (
    <CssBaseline>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </CssBaseline>
  )
}

export default ThemeContextProvider
