import React from 'react'
import { TableSortLabel, TableCell } from '@mui/material'
import styled from '@emotion/styled'

import { AccessibleTableHeaderCellProps } from './acessible-table-types'
import AccessibleTableHeaderFilter from './accessible-table-header-filter'

const StyledCell = styled(TableCell)`
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: #f5f5f5;
  border-top: 2px solid #808080;
  border-bottom: 2px solid #808080;
  color: #000000;
`

const Content = styled.div`
  display: flex;
  align-items: center;

  &,
  svg {
    font-size: 14px;
  }

  > svg {
    margin-left: 8px;
  }

  > span + svg {
    margin-left: 0px;
  }
`

function AccessibleTableHeaderCell({
  order,
  orderBy,
  onColumnClick,
  tableDataList,
  filterState,
  onFilterChange,
  column,
}: AccessibleTableHeaderCellProps) {
  const { key, title, orderByField, filter } = column

  return (
    <StyledCell key={key} sortDirection={orderBy === orderByField ? order : false}>
      <Content>
        {orderByField ? (
          <TableSortLabel
            onClick={() => {
              if (orderByField) onColumnClick(orderByField)
            }}
            direction={order}
            active={orderBy === orderByField}
          >
            {title}
          </TableSortLabel>
        ) : (
          title
        )}
        {filter && (
          <AccessibleTableHeaderFilter
            column={column}
            tableDataList={tableDataList}
            filterValues={filterState[key] || []}
            onFilterChange={onFilterChange}
          />
        )}
      </Content>
    </StyledCell>
  )
}

export default AccessibleTableHeaderCell
