import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'
import { Resources } from '../../types/permissions-types'
import { GetListParams } from '../../types/request-entities-types'
import { setResourceList, setResourceListError, startFetchingResourceList } from './resource-list-reducer'

type ResourceListDataProps = {
  params: GetListParams
  resource: Resources
  apiResource?: (resource: Resources, params?: Partial<GetListParams> | undefined) => Promise<any>
}

export const fetchResourceList =
  ({ resource, params, apiResource }: ResourceListDataProps) =>
  (dispatch: Dispatch) => {
    dispatch(startFetchingResourceList())

    const fetchFunc = apiResource || api.common.getList

    fetchFunc(resource, params)
      .then((response) => {
        const payload = Array.isArray(response)
          ? { resourceList: response, totalRows: response.length }
          : { resourceList: response.data, totalRows: response.total }

        dispatch(setResourceList(payload))
      })
      .catch((error) => dispatch(setResourceListError(error)))
  }
