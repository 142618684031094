import { API } from 'src/config'
import httpClient, { RequestMethods } from 'src/api/http-client'

type DeviceMessage = { userId: string; deviceId: string; message: string }

export const requestSendingDeviceMessages = (messages: DeviceMessage[]): Promise<unknown> =>
  httpClient(`${API.cloud.replace('v1', 'v2')}/firebase-message/send`, {
    method: RequestMethods.post,
    body: JSON.stringify(messages),
  })
