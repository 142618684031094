import React, { useEffect, useMemo, useState } from 'react'
import { Input, Modal, useWatchAll } from 'src/antd-components'
import { Resources } from 'src/types/permissions-types'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { FormeDialog } from 'src/hooks/useDialog'
import { Select, notification, Form } from 'antd'
import { useRequest } from 'src/hooks/use-request'
import { requestMembershipClassList } from 'src/api/services/cloud/memberships/requestMembershipClassList'
import { requestSwitchMembership } from 'src/api/services/cloud/memberships/requestSwitchMembership'
import {
  BillingDetail,
  BillingTarget,
  CommercialMembershipBusinessType,
  MembershipClassType,
} from 'src/api/services/cloud/memberships/types'

type FormData = {
  membershipClass: string
  businessType?: CommercialMembershipBusinessType | undefined
  businessName?: string | undefined
  billing: BillingDetail
}

const errorNotification = (text = 'Please try again later') =>
  notification.error({
    message: 'Something went wrong',
    description: text,
  })

// add back after cloud can accept
// const businessTypeOptions = Object.keys(CommercialMembershipBusinessType).map((value) => ({
//   value,
//   label: value.charAt(0).toLocaleUpperCase() + value.slice(1),
// }))

const billingTargetOptions = [
  { value: 'self', label: 'Self' },
  { value: 'sponsored', label: 'Sponsored' },
  { value: 'external', label: 'External' },
]

export const SwitchMembershipDialog: FormeDialog<{ record: Member }> = ({ params: { record }, open, onClose }) => {
  const [form] = Form.useForm<FormData>()
  const initialFormData = useMemo(
    () => ({
      membershipClass: '',
      businessType: undefined,
      businessName: undefined,
      billing: {
        target: BillingTarget.self,
      },
    }),
    [],
  )
  const [loading, setLoading] = useState(false)
  const [membershipClassType, setMembershipClassType] = useState<MembershipClassType | undefined>(undefined)
  const { data: membershipClassList, fetch: fetchMembershipClassList } = useRequest(requestMembershipClassList)
  const { membershipClass, businessType, businessName, billing } = useWatchAll(form, initialFormData)

  useEffect(() => {
    fetchMembershipClassList()
  }, [fetchMembershipClassList])

  const { isEditAllowed } = useResourcePermissionHandler(Resources.membershipSwitch)

  const options = membershipClassList?.data.map(({ id, name }) => ({ value: id, label: name })) || []

  return (
    <Modal
      title="Switch Membership"
      open={open}
      onCancel={() => !loading && onClose()}
      onOk={async () => {
        try {
          setLoading(true)
          await requestSwitchMembership(
            record.id,
            membershipClass!,
            membershipClassType === MembershipClassType.commercial ? businessType : undefined,
            membershipClassType === MembershipClassType.commercial ? businessName : undefined,
            billing,
          )

          notification.success({
            message: 'Membership switched',
            description: 'It will take some time for system to update',
          })
        } catch (err: any) {
          errorNotification(err?.response?.__error__)
        } finally {
          setLoading(false)
          onClose()
        }
      }}
      cancelText="Cancel"
      okText="OK"
      okButtonProps={{ danger: true, disabled: !isEditAllowed || !membershipClass, loading }}
    >
      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item label="Membership Class" name="membershipClass">
          <Select
            style={{ width: 200 }}
            options={options}
            onChange={(id) => {
              const clazz = membershipClassList?.data.find((c) => c.id === id)
              if (clazz) setMembershipClassType(clazz.type)
            }}
          />
        </Form.Item>
        {/* <Form.Item label="Business Name" name="businessName">
          <Input disabled={membershipClassType !== MembershipClassType.commercial} />
        </Form.Item>
        <Form.Item label="Business Type" name="businessType">
          <Select options={businessTypeOptions} disabled={membershipClassType !== MembershipClassType.commercial} />
        </Form.Item> */}
        <Form.Item label="Billing Target" name={['billing', 'target']}>
          <Select options={billingTargetOptions} defaultValue="self" />
        </Form.Item>
        <Form.Item label="Sponsor ID" name={['billing', 'sponsor_id']}>
          <Input disabled={billing?.target !== BillingTarget.sponsored ? true : false} />
        </Form.Item>
        <Form.Item label="External ID" name={['billing', 'external_id']}>
          <Input disabled={billing?.target !== BillingTarget.external ? true : false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
