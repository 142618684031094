import { getClientTimezone } from 'src/api/utils'

export const getFormattedDate = (
  dateString: string,
  locale = 'en-US',
  options?: Intl.DateTimeFormatOptions,
): string | null => {
  if (dateString === null) return dateString

  const localeDateOptions =
    options ||
    ({
      timeZone: getClientTimezone() ?? undefined,
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    } as Intl.DateTimeFormatOptions)
  return new Date(dateString).toLocaleDateString(locale, localeDateOptions)
}

export const formatPromotionDate = (dateString: string | null): string => {
  if (!dateString) {
    return ''
  }

  const promotionDate = new Date(dateString)
  const month = promotionDate.toLocaleString('en-US', { month: 'short' })
  const day = promotionDate.getDate()
  const year = promotionDate.toLocaleString('en-US', { year: '2-digit' })
  return `${month} ${day}/${year}`
}
