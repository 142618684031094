import React from 'react'
import { useSelector } from 'react-redux'
import { getMemberInvites, getMemberInvitesError, getMemberInvitesLoading } from 'src/redux/member/member-selectors'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { PageSectionTable } from '../components/ui-kit'
import TableSectionContainer from '../components/table-section-container'

type ResourceInvitesTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
}

function ResourceInvitesTable(props: ResourceInvitesTableProps) {
  const { title, resource, columns, emptyDataMessage } = props
  const tableId = `${resource}-table`

  const data = useSelector(getMemberInvites)
  const error = useSelector(getMemberInvitesError)
  const isLoading = useSelector(getMemberInvitesLoading)

  return (
    <TableSectionContainer title={title}>
      <PageSectionTable
        id={tableId}
        columns={columns}
        resource={resource}
        errorOnDataLoad={error}
        isLoading={isLoading}
        data={data || []}
        emptyDataMessage={emptyDataMessage}
      />
    </TableSectionContainer>
  )
}

export default ResourceInvitesTable
