import React from 'react'
import { TpiWorkflowState } from 'src/constants/tpi-constants'
import TPIsPage from '../tpis'

const TPIWorkflowsConfirmingList = () => {
  const filterParams = {
    [`workflow_state||$eq`]: TpiWorkflowState.confirming,
  }
  return <TPIsPage filterParams={filterParams} />
}

export default TPIWorkflowsConfirmingList
