import React, { useEffect, useState } from 'react'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import { Resources } from '../../types/permissions-types'
import { MembershipStatus } from '../../constants/membership-constants'
import { TableRow } from '@mui/material'
import { TableCell } from './styles'
import SelectInput from '../../components/inputs/select-input'
import { getFieldDataQa } from '../../utils/entities-data-qa-utils'
import { requestMembershipUpdate } from '../../api/services/cloud/memberships/requestMembershipUpdate'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { getIsOwner, getMembership } from '../../redux/member/member-selectors'
import { useSelector } from 'react-redux'

export function Status() {
  const membership = useSelector(getMembership)
  const isOwner = useSelector(getIsOwner)
  const [membershipStatus, setMembershipStatus] = useState(membership?.status || '')
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  useEffect(() => {
    membership && setMembershipStatus(membership?.status)
  }, [membership])

  const { isEditAllowed: isMembershipStatusEditAllowed } = useResourcePermissionHandler(Resources.membershipStatus)
  const statusOptions = Object.values(MembershipStatus).map((id) => ({ label: id, id }))
  const onStatusChange = (status: string) => {
    if (!membership?.id) return

    const oldStatus = membershipStatus
    setMembershipStatus(status as MembershipStatus)
    requestMembershipUpdate(membership.id, { status } as { status: MembershipStatus })
      .catch(() => {
        onDisplayErrorNotification('Error changing status. Please refresh and try again.')
        setMembershipStatus(oldStatus)
      })
      .then(() => {
        window.location.reload()
      })
  }

  return (
    <TableRow>
      <TableCell>Status</TableCell>
      <TableCell>
        <SelectInput
          disabled={!isMembershipStatusEditAllowed || !isOwner}
          id={getFieldDataQa(Resources.membership, '/select/membership-status')}
          value={membershipStatus}
          placeholder="Status"
          sx={{
            width: '350px',
            maxWidth: '100%',
            height: 40,
            maxHeight: 40,
            marginBottom: 2,
            color: membershipStatus === 'expired' ? 'red' : null,
            border: membershipStatus === 'expired' ? '1px solid red' : null,
          }}
          choices={statusOptions}
          onSelect={onStatusChange}
        />
      </TableCell>
    </TableRow>
  )
}
