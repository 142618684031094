import React from 'react'
import { TableHead, TableRow } from '@mui/material'

import { AccessibleTableHeaderProps } from './acessible-table-types'
import AccessibleTableHeaderCell from './accessible-table-header-cell'

function AccessibleTableHeader({ columns, ...rest }: AccessibleTableHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <AccessibleTableHeaderCell key={column.key} column={column} {...rest} />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default AccessibleTableHeader
