import { RootState } from '../store'
import { RequestError } from '../../api/http-client'
import { MemberState } from './member-reducer'
import { Member } from '../../constants/member-constants'
import { Studio } from '../../api/services/cloud/studios/types'
import { PersonalWorkout } from '../../constants/personal-workout-constants'
import { BillingPromotion } from '../../constants/billing-promotion-constants'
import { MemberProfile } from 'src/types/member-profile'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import { Bill, BillingSchedule, Charge, Coupon } from 'src/constants/billing-v2-contants'
import { MembershipV2 } from '../../api/services/cloud/memberships/types'

export const getMemberState = (state: RootState): MemberState => state.member

export const getIsMemberLoading = (state: RootState): boolean => getMemberState(state).isRecordLoading
export const getMemberRecord = (state: RootState): Member | null => getMemberState(state).record
export const getMemberError = (state: RootState): RequestError | null => getMemberState(state).recordError

export const getIsMemberStudiosLoading = (state: RootState): boolean => getMemberState(state).isStudiosLoading
export const getStudiosError = (state: RootState): null | RequestError => getMemberState(state).studiosError
export const getStudios = (state: RootState): Studio[] => getMemberState(state).studios

export const getIsMemberIncurredChargesLoading = (state: RootState): boolean =>
  getMemberState(state).isIncurredChargesLoading
export const getIncurredChargesError = (state: RootState): null | RequestError =>
  getMemberState(state).incurredChargesError
export const getIncurredCharges = (state: RootState): Bill | null => getMemberState(state).incurredCharges

export const getIsMemberSessionsLoading = (state: RootState): boolean => getMemberState(state).isSessionsLoading
export const getIsSessionsError = (state: RootState): null | RequestError => getMemberState(state).sessionsError
export const getSessions = (state: RootState): PersonalWorkout[] => getMemberState(state).sessions

export const getIsMemberPromotionsLoading = (state: RootState): boolean => getMemberState(state).isPromotionsLoading
export const getIsPromotionsError = (state: RootState): null | RequestError => getMemberState(state).promotionsError
export const getPromotions = (state: RootState): BillingPromotion[] => getMemberState(state).promotions

export const getIsMemberProfilesLoading = (state: RootState): boolean => getMemberState(state).isProfilesLoading
export const getMemberProfiles = (state: RootState): MemberProfile[] => getMemberState(state).profiles
export const getMemberProfilesError = (state: RootState): RequestError | null => getMemberState(state).profilesError

export const getProfileMembershipId = (state: RootState): string | null => getMemberState(state).profileMembershipId

export const getIsMemberTrainersLoading = (state: RootState): boolean => getMemberState(state).isTrainersLoading
export const getMemberTrainers = (state: RootState): PersonalTrainer[] | null => getMemberState(state).trainers
export const getMemberTrainersError = (state: RootState): RequestError | null => getMemberState(state).trainersError

export const getIsAllChargesByUserLoading = (state: RootState): boolean => getMemberState(state).isChargesLoading
export const getAllChargesByUserError = (state: RootState): RequestError | null => getMemberState(state).chargesError
export const getAllChargesByUser = (state: RootState): Charge[] => getMemberState(state).charges

export const getIsAllCouponsByUserLoading = (state: RootState): boolean => getMemberState(state).isCouponsLoading
export const getAllCouponsByUserError = (state: RootState): RequestError | null => getMemberState(state).couponsError
export const getAllCouponsByUser = (state: RootState): Coupon[] => getMemberState(state).coupons

export const getEstimatedBillLoading = (state: RootState): boolean => getMemberState(state).isEstimatedBillLoading
export const getEstimatedBillError = (state: RootState): RequestError | null => getMemberState(state).estimatedBillError
export const getEstimatedBill = (state: RootState): number | null => getMemberState(state).estimatedBill

export const getMemberBillingSchedule = (state: RootState): BillingSchedule[] | null =>
  getMemberState(state).billingSchedule
export const getMemberBillingScheduleError = (state: RootState): RequestError | null =>
  getMemberState(state).billingScheduleError
export const getIsMemberBillingScheduleLoading = (state: RootState): boolean =>
  getMemberState(state).isBillingScheduleLoading

export const getHistoryWorkout = (state: RootState): PersonalWorkout[] => getMemberState(state).historyWorkout
export const getHistoryWorkoutError = (state: RootState): RequestError | null =>
  getMemberState(state).historyWorkoutError
export const getIsHistoryWorkoutLoading = (state: RootState): boolean => getMemberState(state).isHistoryWorkoutLoading

export const getCPSSchedule = (state: RootState): PersonalWorkout[] => getMemberState(state).cpsSchedule
export const getCPSScheduleError = (state: RootState): RequestError | null => getMemberState(state).cpsScheduleError
export const getIsCPSScheduleLoading = (state: RootState): boolean => getMemberState(state).isCPSScheduleLoading

export const getMemberInvites = (state: RootState): PersonalWorkout[] => getMemberState(state).memberInvites
export const getMemberInvitesError = (state: RootState): RequestError | null => getMemberState(state).memberInvitesError
export const getMemberInvitesLoading = (state: RootState): boolean => getMemberState(state).isMemberInvitesLoading

export const getMembership = (state: RootState): MembershipV2 | null => getMemberState(state).membership

export const getIsOwner = (state: RootState): boolean | undefined => {
  const profile = state.member.profiles.find(({ userId }) => state.member.record?.id === userId)

  return profile?.owner
}
