import { GridSize, useMediaQuery, useTheme } from '@mui/material'

type UseMediaQueryHandler = {
  gridSize: GridSize
  breakpoint: Breakpoints
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
  isXl: boolean
}

type GetActiveBreakpointProps = {
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
}

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

const gridSizeMap = {
  [Breakpoints.xs]: 12,
  [Breakpoints.sm]: 8,
  [Breakpoints.md]: 4,
  [Breakpoints.lg]: 3,
  [Breakpoints.xl]: 2,
} as Record<Breakpoints, GridSize>

const getActiveBreakpoint = ({ isXs, isSm, isMd, isLg }: GetActiveBreakpointProps): Breakpoints => {
  if (isXs) return Breakpoints.xs
  if (isSm) return Breakpoints.sm
  if (isMd) return Breakpoints.md
  if (isLg) return Breakpoints.lg
  return Breakpoints.xl
}

export const useMediaQueryHandler = (): UseMediaQueryHandler => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down(Breakpoints.xs))
  const isSm = useMediaQuery(theme.breakpoints.down(Breakpoints.sm))
  const isMd = useMediaQuery(theme.breakpoints.down(Breakpoints.md))
  const isLg = useMediaQuery(theme.breakpoints.down(Breakpoints.lg))
  const isXl = useMediaQuery(theme.breakpoints.down(Breakpoints.xl))

  const breakpoint = getActiveBreakpoint({ isXs, isSm, isMd, isLg })
  const gridSize: GridSize = gridSizeMap[breakpoint]

  return { gridSize, breakpoint, isXs, isSm, isMd, isLg, isXl }
}
