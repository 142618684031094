import httpClient from '../../../http-client'
import { API } from '../../../../config'

export type OrderEvent = {
  id: number
  order_id: string //actually, it's shopify order name
  event_type: 'RESCHEDULE' | 'Schedule' | 'UNSCHEDULED' | 'INSTALLATION' | 'INSTALL' | string //TODO-eb: make the type more precise
  event_date: string
  original_scheduled_date: string | null
  scheduled_date: string | null
  status: 'NEW' | 'INPROGRESS' | 'COMPLETED' | string //TODO-eb: make the type more precise
  response_payload: Record<string, string> //TODO-eb: make the type more precise
  created_at: string
  updated_at: string
}

export const requestEventsLog = (params?: { status?: string; order_id?: string }): Promise<OrderEvent[]> =>
  httpClient(`${API.orders}/track`, { params })
