import React, { useState } from 'react'
import { Modal } from 'src/antd-components'
import { Resources } from 'src/types/permissions-types'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { FormeDialog } from 'src/hooks/useDialog'
import { Checkbox, notification } from 'antd'
import { requestCancelMembership } from 'src/api/services/cloud/memberships/requestMembershipStateChanges'

const errorNotification = (text = 'Please try again later') =>
  notification.error({
    message: 'Something went wrong',
    description: text,
  })

export const CancelMembershipDialog: FormeDialog<{ record: Member }> = ({ params: { record }, open, onClose }) => {
  const [immediately, setImmediately] = useState(false)
  const [loading, setLoading] = useState(false)

  const { isDeleteAllowed } = useResourcePermissionHandler(Resources.membership)

  return (
    <Modal
      title="Cancel Membership"
      open={open}
      onCancel={() => !loading && onClose()}
      onOk={async () => {
        try {
          setLoading(true)
          const response = await requestCancelMembership(record.id, immediately)

          if (response.success) {
            notification.success({
              message: 'Membership cancelled',
              description: 'It will take some time for system to update',
            })
          } else {
            errorNotification()
          }
        } catch (err: any) {
          errorNotification(err?.response?.__error__)
        } finally {
          setLoading(false)
          onClose()
        }
      }}
      cancelText="Keep Membership"
      okText="Cancel Membership"
      okButtonProps={{ danger: true, disabled: !isDeleteAllowed, loading }}
    >
      <Checkbox checked={immediately} onChange={(e) => setImmediately(e.target.checked)}>
        Immediately
      </Checkbox>
    </Modal>
  )
}
