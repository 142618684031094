import React from 'react'
import { TableRow } from '@mui/material'
import { TableCell } from './styles'
import { DatePicker } from 'antd'
import moment from 'moment-timezone'
import { requestMembershipUpdate } from '../../api/services/cloud/memberships/requestMembershipUpdate'
import { MembershipV2 } from '../../api/services/cloud/memberships/types'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { useSelector } from 'react-redux'
import { getIsOwner, getMembership } from '../../redux/member/member-selectors'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import { Resources } from '../../types/permissions-types'
import { getUniformDateTime } from '../../utils/uniformDateDisplay'
import { ABSENT_VALUE_PLACEHOLDER } from '../../components/accessible-table/accessible-table-cell'

export const ExpiryDateRow = () => {
  const membership = useSelector(getMembership)
  const isOwner = useSelector(getIsOwner)
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const { isEditAllowed: isExpiryEditAllowed } = useResourcePermissionHandler(Resources.membershipExpiry)

  const onMembershipChange = (data: Partial<MembershipV2>) => {
    membership?.id &&
      requestMembershipUpdate(membership.id, data).catch(() => {
        onDisplayErrorNotification('Error changing field. Please refresh and try again.')
      })
  }

  const editable = isExpiryEditAllowed && isOwner && membership?.status === 'active'

  return (
    <TableRow>
      <TableCell>Expiry Date</TableCell>
      <TableCell>
        {editable ? (
          <DatePicker
            defaultValue={membership?.expiry_date ? moment(membership.expiry_date) : undefined}
            onChange={(date) => {
              const value = date?.toDate().toISOString() || null

              onMembershipChange({ expiry_date: value })
            }}
          />
        ) : membership?.expiry_date ? (
          getUniformDateTime(membership.expiry_date)
        ) : (
          ABSENT_VALUE_PLACEHOLDER
        )}
      </TableCell>
    </TableRow>
  )
}
