import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CardContent, Grid } from '@mui/material'

import { useRequest } from 'src/hooks/use-request'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'
import { requestStudio } from 'src/api/services/cloud/studios/requestStudio'
import { requestProfileWithMembership } from 'src/api/services/cloud/memberships/requestProfileMembership'
import { Studio } from 'src/api/services/cloud/studios/types'

import { MembershipCardContent } from './MembershipCardContent'
import { StudioCardContent } from './StudioCardContent'
import { MetadataCardContent } from './MetadataCardContent'
import styled from '@emotion/styled'
import { withPermissions } from 'src/hocs/withPermissions'
import { Resources } from 'src/types/permissions-types'

const Title = styled.span`
  font-size: 60px;
  font-style: normal;
  font-weight: 300;
  line-height: 60px;
`

function StudioPage() {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading, error } = useRequest(requestStudio, [id])
  useRequestErrorHandler(error)

  const studioState = useState<Studio>()
  const [studio, setStudio] = studioState
  useEffect(() => {
    setStudio(data)
  }, [setStudio, data])

  const profileQuery = useRequest(requestProfileWithMembership, studio?.profile_id ? [studio.profile_id] : undefined)
  useRequestErrorHandler(profileQuery.error)

  return (
    <Grid container spacing={2} style={{ padding: '20px 0' }}>
      <Grid item xs={12}>
        <Title>{studio?.id}</Title>
      </Grid>
      <Grid item xs={3}>
        <CardContent>
          <StudioCardContent studioState={studioState} isLoading={isLoading} membershipQuery={profileQuery} />
        </CardContent>
      </Grid>
      <Grid item xs={9}>
        <CardContent>
          <MembershipCardContent studioDataIsLoading={isLoading} membershipQuery={profileQuery} />
        </CardContent>
        <CardContent>
          <MetadataCardContent studioId={id} />
        </CardContent>
      </Grid>
    </Grid>
  )
}

export default withPermissions(StudioPage, Resources.studios)
