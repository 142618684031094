import { useEffect, useState } from 'react'
import { useAuthHandler } from './use-auth-handler'
import { getResourcesPermissionsMap, ResourcesPermissionsMap } from '../utils/permission-utils'
import { getPermissions, savePermissions } from '../utils/auth-utils'
import { USER_PERMISSIONS_KEY } from '../config'

const initialResourcesPermissionsMap = getResourcesPermissionsMap([])

export const useResourcesPermissionsMap = () => {
  const { isLoading, isAuthenticated, user } = useAuthHandler()
  const [isReady, setIsReady] = useState(false)
  const [resourcesPermissionsMap, setResourcesPermissionsMap] =
    useState<ResourcesPermissionsMap>(initialResourcesPermissionsMap)

  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      const permissions = user[USER_PERMISSIONS_KEY]
      const savedPermissions = getPermissions()
      if (!savedPermissions && permissions) savePermissions(permissions)
      setResourcesPermissionsMap(getResourcesPermissionsMap(permissions))
      setIsReady(true)
    }
  }, [isAuthenticated, isLoading, user])

  return { isReady, resourcesPermissionsMap, isAuthenticated }
}
