import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'
import { createStructuredSelector } from 'reselect'
import { useSelector } from 'react-redux'

import { useRequest } from 'src/hooks/use-request'
import { getComparator, stableSort } from 'src/utils/sort-utils'
import { formatBillRateSession } from 'src/utils/format-bill-rate'

import api from '../../../api'
import AccessibleTable from '../../../components/accessible-table/accessible-table'
import TableSectionContainer from '../../../components/table-section-container'
import { Identifier } from '../../../types/request-entities-types'
import { Resources } from '../../../types/permissions-types'
import { ColumnItem } from '../../../components/accessible-table/acessible-table-types'
import { getFieldDataQa } from '../../../utils/entities-data-qa-utils'

import { Order } from '../../../types/common-types'
import { PersonalWorkout } from '../../../constants/personal-workout-constants'
import { getIsMemberSessionsLoading, getIsSessionsError, getSessions } from '../../../redux/member/member-selectors'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import { BundleState, UserBundle } from 'src/api/services/bundles'
import BundleAccordion from './bundles-info-accordion'
import { EMPTY_DATA_MAIN_MESSAGE } from 'src/constants/messages'

type MemberOverviewTabProps = {
  recordId: Identifier
  memberId: string
  sessionsTableColumns: ColumnItem[]
  incurredChargesTableColumns: ColumnItem[]
  setIsOneOnOneLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export function sortByActive(item1: Pick<UserBundle, 'state'>, item2: Pick<UserBundle, 'state'>) {
  if (item1.state === BundleState.active && item2.state === BundleState.active) {
    return 0
  }
  if (item2.state === BundleState.active) {
    return 1
  }
  return -1
}

const IncurredChargesTitle = styled.div`
  font-size: 14px;
  margin: 10px 20px;
  opacity: 0.5;
`
const IncurredChargesAmount = styled.div`
  font-size: 16px;
`
const MainMessage = styled.span`
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 400;
  text-align: center;
`

const selectSesssionsState = createStructuredSelector({
  isSessionsLoading: getIsMemberSessionsLoading,
  sessionsError: getIsSessionsError,
  sessions: getSessions,
})

function MemberOneOnOneTab(props: MemberOverviewTabProps) {
  const { recordId, incurredChargesTableColumns, sessionsTableColumns, setIsOneOnOneLoading } = props

  const { isReadAllowed: isIncurredChargesReadAllowed } = useResourcePermissionHandler(
    Resources.oneOnOneIncurredCharges,
  )
  const { isReadAllowed: isSessionsReadAllowed } = useResourcePermissionHandler(Resources.memberSessions)
  const { sessions, isSessionsLoading } = useSelector(selectSesssionsState)

  const [sessionsList, setSessionsList] = useState<PersonalWorkout[]>([])
  const [showAllSessions, setShowAllSessions] = useState(false)

  const updateShowAllSessions = () => setShowAllSessions(!showAllSessions)
  const sortList = (list: any, sortBy: string, sortDirection: Order): any[] =>
    stableSort(list || [], getComparator(sortDirection, sortBy))

  const { data: cloudBundles, isLoading: isCloudBundlesLoading } = useRequest(api.billingV2.getUserBundle, [recordId])
  const { data: userBills, isLoading: isBillsLoading } = useRequest(api.billingV2.getUserBillEstimation, [
    recordId,
    'biweekly',
  ])

  useEffect(() => {
    setIsOneOnOneLoading(isSessionsLoading || isCloudBundlesLoading || isBillsLoading)
  }, [setIsOneOnOneLoading, isSessionsLoading, isCloudBundlesLoading, isBillsLoading])

  const sessionData = useMemo(
    () => (showAllSessions ? sessionsList : [...sessionsList.slice(0, 5)]),
    [sessionsList, showAllSessions],
  )

  useEffect(() => {
    const sortedSessions = sortList(formatBillRateSession(sessions?.map((session) => session)), 'date', Order.desc)
    setSessionsList(sortedSessions)
  }, [sessions, setSessionsList])
  const userBillsFilteredData = userBills?.line_items?.filter((li) => li.charge?.source === 'personalTraining')

  return (
    <>
      <TableSectionContainer withDivider title="Bundles">
        {cloudBundles && <BundleAccordion bundlesList={cloudBundles} />}
        {!cloudBundles?.length && <MainMessage>{EMPTY_DATA_MAIN_MESSAGE}</MainMessage>}
      </TableSectionContainer>

      {isIncurredChargesReadAllowed && (
        <TableSectionContainer title="Incurred Charges">
          <Grid container style={{ display: 'flex', textAlign: 'center' }}>
            <Grid item xs={2}>
              <IncurredChargesTitle>Balance</IncurredChargesTitle>
              <IncurredChargesAmount>${userBills?.total || 0.0}</IncurredChargesAmount>
              <IncurredChargesTitle>Sessions</IncurredChargesTitle>
              <IncurredChargesAmount>{userBills?.line_items?.length || 0}</IncurredChargesAmount>
            </Grid>

            <Grid item xs={10}>
              <AccessibleTable
                id={getFieldDataQa(Resources.bills, 'user_id')}
                sortBy="date"
                sortByDirection={Order.desc}
                resource={Resources.bills}
                columns={incurredChargesTableColumns}
                data={userBillsFilteredData || []}
              />
            </Grid>
          </Grid>
        </TableSectionContainer>
      )}

      {isSessionsReadAllowed && (
        <TableSectionContainer
          title="Sessions"
          withShowAllToggleButton
          showAll={showAllSessions}
          onShowAllClick={updateShowAllSessions}
        >
          <AccessibleTable
            id={getFieldDataQa(Resources.members, 'member_sessions')}
            sortBy="date"
            sortByDirection={Order.desc}
            resource={Resources.members}
            columns={sessionsTableColumns}
            data={sessionData}
          />
        </TableSectionContainer>
      )}
    </>
  )
}

export default MemberOneOnOneTab
