import styled from '@emotion/styled'
import { TableCell } from '@mui/material'

export const GreyHeaderTableCell = styled(TableCell)`
  border-top: 1px solid ${(_) => _.theme.palette.grey[600]};
  border-bottom: 1px solid ${(_) => _.theme.palette.grey[600]};
  padding-top: 9px;
  padding-bottom: 9px;
  color: black;
  background-color: ${(_) => _.theme.palette.grey[100]};
`
