import React, { useEffect, useState } from 'react'

import api from '../api'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { RequestError } from '../api/http-client'
import { PageSectionTable } from '../components/ui-kit'
import { EntitiesSubUrls, SubUrlPlacements } from '../api/services/entities'
import TableSectionContainer from '../components/table-section-container'
import useIsMounted from '../hooks/use-is-mounted'
import { getErrorMessage } from '../utils/errors'
import useSnackbarNotifications from '../hooks/use-snackbar-notifications'

type ResourceEntityTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  entitySubUrl: EntitiesSubUrls
  subUrlPlacements?: SubUrlPlacements
  emptyDataMessage?: string
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

type DataListItem = Record<any, string> & {
  id: Identifier
}

type ResponseState = {
  error: null | string
  isLoading: boolean
  dataList: DataListItem[]
}

function ResourceEntityTable(props: ResourceEntityTableProps) {
  const { title, resource, recordId, columns, entitySubUrl, subUrlPlacements, emptyDataMessage, setIsLoading } = props
  const tableId = `${resource}-${entitySubUrl}-table`

  const isMounted = useIsMounted()
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const [responseState, setResponseState] = useState<ResponseState>({ error: null, isLoading: false, dataList: [] })

  useEffect(() => {
    const setState = (nextStateProps: Record<string, any>): void => {
      isMounted && setResponseState((prevState) => ({ ...prevState, ...nextStateProps }))
    }
    setState({ isLoading: true, error: null })
    setIsLoading?.(true)
    api.entities
      .getResourceEntity(resource, recordId, entitySubUrl, subUrlPlacements)
      .then((response: DataListItem[]) => {
        setIsLoading?.(false)
        return setState({ error: null, isLoading: false, dataList: response ?? [] })
      })
      .catch((error: RequestError) => {
        setIsLoading?.(false)
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
        return setState({ error: errorMessage, isLoading: false })
      })
  }, [isMounted, resource, recordId, entitySubUrl, subUrlPlacements, onDisplayErrorNotification, setIsLoading])

  return (
    <TableSectionContainer title={title}>
      <PageSectionTable
        id={tableId}
        columns={columns}
        resource={resource}
        errorMessage={responseState.error}
        isLoading={responseState.isLoading}
        data={responseState.dataList}
        sortBy="id"
        emptyDataMessage={emptyDataMessage}
      />
    </TableSectionContainer>
  )
}

export default ResourceEntityTable
