import * as React from 'react'
import { useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styled from '@emotion/styled'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'

import { NOOP } from '../../constants/common-constants'
import CancelButton from '../buttons/cancel-buttons'
import SuccessButton from '../buttons/success-button'
import DeleteButton from '../buttons/delete-button'
import { Breakpoints } from '../../hooks/use-media-query-handler'
import { mainColor } from '../../constants/styles-constants'

type BasicDialogProps = {
  title: string
  cancelTitle: string
  acceptTitle: string
  deleteTitle?: string
  isOpened: boolean
  children: React.ReactNode
  className?: string
  maxDialogWidth?: Breakpoints
  confirmationMessage?: string
  withDeleteButton?: boolean
  isAcceptDisabled?: boolean
  isDeleteDisabled?: boolean
  onAccept: (params: any) => void
  onCloseDialog: () => void
  onDelete?: () => void
  style?: React.CSSProperties | undefined
}

const StyledTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  flex: 0 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 15px 30px;
`
const StyledDialogContent = styled(DialogContent)`
  padding: 30px;
`

const StyledDialogDeleteButton = styled(DeleteButton)`
  border: 1px solid ${mainColor};
  height: 38px;
  padding: 2px 24px;
`

const StyledDialogCancelButton = styled(CancelButton)`
  border: 1px solid ${mainColor};
  height: 38px;
  padding: 2px 24px;
`

const StyledDialogSuccessButton = styled(SuccessButton)`
  border: 1px solid ${mainColor};
  height: 38px;
  padding: 2px 24px;
`
const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const TooltipMessage = styled.span`
  line-height: 30px;
  margin-right: 10px;
  font-size: 14px;
`

const TooltipIcon = styled(DeleteIcon)`
  cursor: pointer;
`

const StyledDialogActions = styled(DialogActions)`
  background: #f2f2f2;
`

const defaultConfirmationMessage = 'Are you sure you want to delete this image?'

function BasicDialog(props: BasicDialogProps) {
  const {
    isOpened,
    title,
    cancelTitle,
    isAcceptDisabled = false,
    isDeleteDisabled = false,
    withDeleteButton = false,
    maxDialogWidth = Breakpoints.xl,
    acceptTitle,
    deleteTitle = 'Delete',
    confirmationMessage = defaultConfirmationMessage,
    className,
    style,
    children,
    onAccept,
    onDelete = NOOP,
    onCloseDialog,
  } = props

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const [isDeleteTooltipOpened, setIsDeleteTooltipOpened] = useState(false)

  useEffect(() => () => setIsDeleteTooltipOpened(false), [])

  return (
    <Dialog
      open={isOpened}
      fullScreen={fullScreen}
      onClose={onCloseDialog}
      scroll="body"
      style={style}
      maxWidth={maxDialogWidth}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className={className}
    >
      <StyledTitle id="scroll-dialog-title">{title}</StyledTitle>

      <StyledDialogContent dividers style={{ overflow: 'hidden' }}>
        {children}
      </StyledDialogContent>

      <StyledDialogActions>
        <StyledDialogCancelButton onClick={onCloseDialog}>{cancelTitle}</StyledDialogCancelButton>
        {withDeleteButton && (
          <Tooltip
            arrow
            placement="top-end"
            open={isDeleteTooltipOpened}
            onClose={() => setIsDeleteTooltipOpened(false)}
            title={
              <TooltipContainer>
                <TooltipMessage>{confirmationMessage}</TooltipMessage>
                <TooltipIcon onMouseDown={onDelete} />
              </TooltipContainer>
            }
          >
            <StyledDialogDeleteButton disabled={isDeleteDisabled} onClick={() => setIsDeleteTooltipOpened(true)}>
              {deleteTitle}
            </StyledDialogDeleteButton>
          </Tooltip>
        )}
        <StyledDialogSuccessButton disabled={isAcceptDisabled} onClick={onAccept}>
          {acceptTitle}
        </StyledDialogSuccessButton>
      </StyledDialogActions>
    </Dialog>
  )
}

export default BasicDialog
