import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import CardActions from '@mui/material/CardActions'
import withStyles from '@mui/styles/withStyles'
import createStyles from '@mui/styles/createStyles'
import { LOCK_CONTAINER_ID } from '../../config'

const styles = () => createStyles({ button: { width: '100%' } })

function LoginPage() {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    loginWithRedirect()
  })

  return <CardActions id={LOCK_CONTAINER_ID} />
}

export default withStyles(styles)(LoginPage)
