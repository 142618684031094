import React, { useState } from 'react'
import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { Member } from 'src/constants/member-constants'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { ColumnsType, Modal, Search, Space, Table, Typography, checkmarkColumn } from 'src/antd-components'

import { FormeDialog } from 'src/hooks/useDialog'
import { BundleOrderResponceProps, UserBundle } from 'src/api/services/bundles'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { getErrorMessage } from 'src/utils/errors'
import { RequestError } from 'src/api/http-client'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from 'src/constants/messages'

type FormData = {
  name: string
  bundles: BundleOrderResponceProps
}

export const AddBundlesDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isCreateAllowed: isCreateBundleAllowed } = useResourcePermissionHandler(Resources.addBundle)
  const [loading, setLoading] = useState<boolean>(false)
  const [inputValues, setInputValues] = useState<FormData>({
    name: '',
    bundles: {
      id: '',
      bundles: [],
      customer: {},
      items: [
        {
          amount: 0,
          name: '',
          sku: '',
        },
      ],
    },
  })
  const isFormValid = true
  const onChangeField = (field: string, value: string) => setInputValues({ ...inputValues, [field]: value })

  const onSearch = () => {
    setLoading(true)
    api.billingV2
      .getBundlesByName(inputValues.name)
      .then((value: BundleOrderResponceProps) => {
        setInputValues({ ...inputValues, ['bundles']: value })
        setLoading(false)
      })
      .catch((error: RequestError) => {
        onDisplayErrorNotification(`Error searching bundles.  ${getErrorMessage(error)}`)
        setLoading(false)
      })
  }

  const onCreate = () => {
    if (isCreateBundleAllowed) {
      setLoading(true)
      api.billingV2
        .putBundlesFromOrder(inputValues.bundles.id, record.id)
        .then(() => {
          onDisplaySuccessNotification(`Bundle has been successfully added`)
          onClose()
          setLoading(false)
        })
        .catch((error: RequestError) => {
          setLoading(false)
          onDisplayErrorNotification(`Error creating bundle.  ${getErrorMessage(error)}`)
        })
    } else {
      onDisplayErrorNotification(`${LIMITED_PERMISSIONS_MAIN_MESSAGE}. ${LIMITED_PERMISSIONS_SECONDARY_MESSAGE}.`)
    }
  }

  const clearFormData = () => {
    setInputValues({ name: '', bundles: { id: '', bundles: [], customer: {}, items: [] } })
  }
  const { name, bundles } = inputValues

  const bundleColumns: ColumnsType<UserBundle> = [
    { key: 'sku', dataIndex: 'sku', title: 'SKU' },
    { key: 'display_name', dataIndex: 'display_name', title: 'Name', width: 160 },
    { key: 'bundle_price', dataIndex: 'bundle_price', title: 'Bundle Price($)' },
    { key: 'sessions_count', dataIndex: 'sessions_count', title: 'Session Count' },
    { key: 'unit_price', dataIndex: 'unit_price', title: 'Unit Price($)' },
    { key: 'is_recurring', dataIndex: 'is_recurring', title: 'Recurring', render: checkmarkColumn },
  ]

  return (
    <Modal
      title="Add bundle"
      okText="Add"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid || loading }}
      onOk={onCreate}
      width={800}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Search
          placeholder="Shopify Order Name"
          enterButton
          onSearch={onSearch}
          loading={loading}
          value={name}
          onChange={(e) => onChangeField('name', e.target.value)}
        />
        {bundles?.customer?.name && (
          <div>
            <Typography.Title level={5}>Customer:</Typography.Title>
            <Space direction="vertical">
              <Typography.Text copyable>{bundles.customer.name}</Typography.Text>
              <Typography.Link copyable href={`mailto:${bundles.customer.email}`}>
                {bundles.customer.email}
              </Typography.Link>
            </Space>
          </div>
        )}
        <div>
          {!!bundles?.bundles?.length && (
            <Table<UserBundle>
              columns={bundleColumns}
              dataSource={bundles.bundles}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          )}
        </div>
      </Space>
    </Modal>
  )
}
