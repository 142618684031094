import * as React from 'react'
import { useCallback, useState } from 'react'
import moment from 'moment-timezone'

import { PREFERRED_TIMEZONE } from 'src/constants/common-constants'
import { FormeDialog } from 'src/hooks/useDialog'
import { useStoredStringState } from 'src/utils/hooks/useStoredStringState'

import { Modal, Select } from 'src/antd-components'

type SelectTimezoneDialogProps = {
  cancelButtonText?: string
  successButtonText?: string
  onClose: () => void
}

const timezones = moment.tz.names().map((name: string) => ({
  value: name,
  label: `${name} (GMT${moment.tz(name).format('Z')})`,
}))

const SelectTimezoneDialog: FormeDialog<SelectTimezoneDialogProps> = ({
  params: { cancelButtonText = 'Cancel', successButtonText = 'Save', onClose },
  open: isOpened,
  onClose: onCancel,
}) => {
  const [storedTimezone, setStoredTimezone] = useStoredStringState(PREFERRED_TIMEZONE)
  const [inputValue, setInputValue] = useState(storedTimezone)

  const onSave = useCallback(() => {
    setStoredTimezone(inputValue)
    onClose()
  }, [inputValue, onClose, setStoredTimezone])

  return (
    <Modal
      title="Time Zone"
      open={isOpened}
      okText={successButtonText}
      cancelText={cancelButtonText}
      onOk={onSave}
      onCancel={onCancel}
    >
      <Select
        placeholder="Select timezone"
        options={timezones}
        value={inputValue}
        onChange={setInputValue}
        style={{ width: '100%' }}
      />
    </Modal>
  )
}

export default SelectTimezoneDialog
