import React from 'react'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import styled from '@emotion/styled'
import { routing } from 'src/boot/routing'
import { NavLink } from 'react-router-dom'

const NavContainer = styled(Box)`
  width: 100%;
  min-height: 100%;
  max-width: 240px;
  border-right: 1px solid ${(_) => _.theme.palette.divider};
`

const routes: {
  path: any
  label: string
}[] = [
  {
    path: routing.tpiworkflows.pattern,
    label: 'Interested',
  },
  {
    path: routing.contactedTPIs.pattern,
    label: 'Contacted',
  },
  {
    path: routing.confirmingTPIs.pattern,
    label: 'Emailed',
  },
  {
    path: routing.activatingTPIs.pattern,
    label: 'In progress',
  },
  {
    path: routing.activeTPIs.pattern,
    label: 'Configuration',
  },
  {
    path: routing.trainersAssignedTPIs.pattern,
    label: 'Complete',
  },
  {
    path: routing.rejectedTPIs.pattern,
    label: 'Rejected',
  },
]

const NavListButton = ({ link, text }: { link: string; text: string }) => {
  const isSelected = `${window.location.pathname}${window.location.search}` === link
  return (
    <ListItemButton component={NavLink} to={link} selected={isSelected}>
      <ListItemText primary={text} />
    </ListItemButton>
  )
}

export const NavList = () => (
  <NavContainer>
    <List component="nav" aria-label="secondary mailbox folders">
      {routes.map((route) => (
        <NavListButton key={route.label} link={route.path} text={route.label} />
      ))}
    </List>
  </NavContainer>
)
