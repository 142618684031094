import { Dispatch } from '@reduxjs/toolkit'

import api from '../../api'
import { EntitiesSubUrls, SubEntities, SubUrlPlacements } from '../../api/services/entities'
import httpClient, { RequestError } from '../../api/http-client'
import { Resources } from '../../types/permissions-types'
import {
  startFetchingMember,
  setMemberRecord,
  setMemberError,
  startFetchingStudios,
  setStudios,
  setStudiosError,
  startFetchingIncurredCharges,
  setIncurredCharges,
  setIncurredChargesError,
  startFetchingSessions,
  setSessions,
  setSessionsError,
  startFetchingPromotions,
  setPromotions,
  setPromotionsError,
  startFetchingProfiles,
  setProfiles,
  setProfilesError,
  setProfileMembershipId,
  startFetchingTrainers,
  setTrainers,
  setTrainersError,
  setMemberAllCharges,
  setMemberAllChargesError,
  startFetchingAllMemberCharges,
  setEstimatedBill,
  setEstimatedBillError,
  startFetchingEstimatedBill,
  startFetchingBillingSchedule,
  setBillingSchedule,
  setBillingScheduleError,
  startFetchingHistoryWorkout,
  setHistoryWorkoutError,
  setHistoryWorkout,
  startFetchingCPSSchedule,
  setCPSScheduleError,
  setCPSSchedule,
  startFetchingAllMemberCoupons,
  setMemberAllCoupons,
  setMemberAllCouponsError,
  startFetchingMemberInvites,
  setMemberInvites,
  setMemberInvitesError,
  setMembership,
} from './member-reducer'
import { formatSessionData } from '../../utils/data-transformation-utils'
import { API } from 'src/config'
import { MemberProfile, MembershipProfile } from 'src/types/member-profile'
import { BillLineItem } from 'src/constants/billing-v2-contants'
import { PersonalWorkout } from 'src/constants/personal-workout-constants'
import { Identifier } from 'src/types/request-entities-types'

export const fetchMemberRecord = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingMember())

  api.common
    .getOne(Resources.members, { id: recordId })
    .then(({ data: record }) => dispatch(setMemberRecord(record)))
    .catch((error: RequestError) => dispatch(setMemberError(error)))
}

export const fetchMemberStudios = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingStudios())

  api.entities
    .getResourceEntity(Resources.studios, recordId, EntitiesSubUrls.byUser, SubUrlPlacements.beforeId)
    .then((studios) => dispatch(setStudios(studios)))
    .catch((error: RequestError) => dispatch(setStudiosError(error)))
}

export const fetchMemberIncurredCharges = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingIncurredCharges())
  api.entities
    .getResourceEntity(Resources.bills, recordId, EntitiesSubUrls.user, SubUrlPlacements.beforeId)
    .then((incurredCharges) =>
      dispatch(
        setIncurredCharges(incurredCharges.line_items.filter((li: BillLineItem) => li?.charge?.period === 'biweekly')),
      ),
    )
    .catch((error: RequestError) => dispatch(setIncurredChargesError(error)))
}

export const fetchAllMemberCharges =
  (recordId: Identifier, status?: string, nextBillingDate?: Date, period?: string) => (dispatch: Dispatch) => {
    dispatch(startFetchingAllMemberCharges())

    api.entities
      .getSubEntityListWithFilterOnly(SubEntities.memberAllCharges, {
        filter: {
          'user_id||$eq': recordId,
          'status||$eq': status,
          'due_date||$lte': nextBillingDate,
          'period||$eq': period,
        },
      })
      .then(({ data: memberCharges }) => dispatch(setMemberAllCharges(memberCharges)))
      .catch((error: RequestError) => dispatch(setMemberAllChargesError(error)))
  }

export const fetchAllMemberCoupons = (recordId: Identifier) => (dispatch: Dispatch) => {
  dispatch(startFetchingAllMemberCoupons())

  api.entities
    .getSubEntityListWithFilterOnly(SubEntities.coupons, {
      filter: {
        'user_id||$eq': recordId,
      },
    })
    .then(({ data: coupons }) => dispatch(setMemberAllCoupons(coupons)))
    .catch((error: RequestError) => dispatch(setMemberAllCouponsError(error)))
}

export const fetchEstimatedBill = (recordId: Identifier, period: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingEstimatedBill())

  api.billingV2
    .getUserBillEstimation(recordId, period)
    .then((bill) => dispatch(setEstimatedBill(bill.total)))
    .catch((err: RequestError) => dispatch(setEstimatedBillError(err)))
}

export const fetchMemberSessions = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingSessions())

  api.entities
    .getSubEntityListWithFilterOnly(SubEntities.personalWorkouts, {
      filter: {
        'client_id||$eq': recordId,
      },
    })
    .then(({ data: sessions }) => {
      const sessionDataFormatted = formatSessionData(sessions)
      dispatch(setSessions(sessionDataFormatted))
    })
    .catch((error: RequestError) => dispatch(setSessionsError(error)))
}

export const fetchMemberPromotions = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingPromotions())
  const params = {
    filter: { 'user_id||$eq': recordId, 'promotion.promotion_class||$eq': 'membership' },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'DESC' },
  }

  api.common
    .getList(Resources.billingPromotions, params)
    .then(({ data: promotions }) => {
      dispatch(setPromotions(promotions))
    })
    .catch((error: RequestError) => dispatch(setPromotionsError(error)))
}

export const fetchMemberProfiles = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingProfiles())
  api.common
    .getList(Resources.profile, {
      filter: {
        [`userId||$eq`]: recordId,
      },
    })
    .then(async ({ data: profiles }) => {
      const results = profiles.map(async (profile: MembershipProfile) => {
        if (profile.membershipId) {
          dispatch(setProfileMembershipId(profile.membershipId))
          profile.membership && dispatch(setMembership(profile.membership))
          const allUsersProfiles = await httpClient(
            `${API.cloud.replace('v1', 'v2')}/${Resources.membership}/${profile.membershipId}/${
              EntitiesSubUrls.profiles
            }`,
          )
          return allUsersProfiles
        }
        return []
      })
      const res = await Promise.all(results)
      dispatch(setProfiles(res.flat() as MemberProfile[]))
    })
    .catch((error: RequestError) => dispatch(setProfilesError(error)))
}

export const fetchMemberTrainers = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingTrainers())
  api.entities
    .getResourceEntity(Resources.members, recordId, EntitiesSubUrls.trainers, SubUrlPlacements.afterId)
    .then((assignedTrainers) => dispatch(setTrainers(assignedTrainers)))
    .catch((error: RequestError) => dispatch(setTrainersError(error)))
}

export const fetchMemberBillingSchedule = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingBillingSchedule())
  api.common
    .getList(Resources.billingSchedule, {
      filter: {
        [`user_id||$in`]: [recordId],
      },
    })
    .then((schedule) => dispatch(setBillingSchedule(schedule)))
    .catch((error: RequestError) => dispatch(setBillingScheduleError(error)))
}

export const fetchMemberHistoryWorkout = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingHistoryWorkout())

  api.common
    .getList(Resources.cpsHistory, {
      filter: {
        [`user_id||$eq`]: recordId,
      },
    })
    .then(({ data: historyWorkout }) => {
      dispatch(setHistoryWorkout(historyWorkout as PersonalWorkout[]))
    })
    .catch((error: RequestError) => dispatch(setHistoryWorkoutError(error)))
}

export const fetchMemberCPSSchedule = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingCPSSchedule())

  api.common
    .getList(Resources.cpsSchedule, {
      filter: {
        [`participants.user_id||$eq`]: recordId,
      },
    })
    .then(({ data: cpsSchedule }) => {
      dispatch(setCPSSchedule(cpsSchedule as PersonalWorkout[]))
    })
    .catch((error: RequestError) => dispatch(setCPSScheduleError(error)))
}

export const fetchMemberCharges = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingHistoryWorkout())

  api.common
    .getList(Resources.cpsHistory, {
      filter: {
        [`user_id||$eq`]: recordId,
      },
    })
    .then(({ data: historyWorkout }) => {
      dispatch(setHistoryWorkout(historyWorkout as PersonalWorkout[]))
    })
    .catch((error: RequestError) => dispatch(setHistoryWorkoutError(error)))
}

export const fetchMemberInvites = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingMemberInvites())

  api.common
    .getList(Resources.invites, {
      filter: {
        [`user_id||$eq`]: recordId,
      },
    })
    .then(({ data: invites }) => {
      dispatch(setMemberInvites(invites))
    })
    .catch((error: RequestError) => dispatch(setMemberInvitesError(error)))
}
