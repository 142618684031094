import { Studio } from 'src/api/services/cloud/studios/types'
import { ArrayElement } from 'src/utils/advanced-types'

const membershipStatuses = ['future', 'active', 'expired', 'will_expire', 'paused', 'suspended'] as const

const sevenDigitalPlanCodes = [
  'forme-beta-free',
  'forme-free-trial',
  'forme-gratis-retail',
  'forme-monthly',
  'forme-commercial-monthly',
  'forme-commercial-annual',
  'forme-extended',
] as const

const membershipBillingCadences = ['never', 'monthly', 'annual', 'triannual'] as const

export type MembershipStatus = ArrayElement<typeof membershipStatuses>
export type SevenDigitalPlanCode = ArrayElement<typeof sevenDigitalPlanCodes>
export type MembershipBillingCadence = ArrayElement<typeof membershipBillingCadences>

export type MembershipContentFeature = {
  id: string
  content_plan?: ContentPlan
  expire_date?: string
  feature: {
    id: string
    name: string
  }
}

export type ContentPlan = {
  id: string
  name: string
  updated_date: string
  created_date: string
}

export type Membership = {
  id: string
  membership_status: MembershipStatus
  start_date: string | null
  valid_through_date: string | null
  created_date: string
  updated_date: string
  seven_digital_expiry_date: string | null
  stripe_subscription_id: string | null
  billing_cycle_anchor: string | null
  seven_digital_plan_code: SevenDigitalPlanCode | null
  membership_billing_cadence: MembershipBillingCadence
  content_plan_id: string
  content_plan: ContentPlan
  studios: Studio[]
  content_features: MembershipContentFeature[]
}

export enum MembershipClassClient {
  ios_phone = 'ios_phone',
  ios_tablet = 'ios_tablet',
  android_phone = 'android_phone',
  android_tablet = 'android_tablet',
  studio = 'studio',
  studio_lift = 'studio_lift',
  web = 'web',
}

export enum MembershipClassFeature {
  PT = 'PT',
  TPI = 'TPI',
  VOD = 'VOD',
  CPS = 'CPS',
  ASSESSMENTS = 'ASSESSMENTS',
  DEFAULT = '.default',
}

export enum MembershipClassType {
  free = 'free',
  standard = 'standard',
  bridge = 'bridge',
  bundle = 'bundle',
  commercial = 'commercial',
  retail = 'retail',
  corporate = 'corporate',
}

export type MembershipСlass = {
  created_date: string
  updated_date: string
  id: string
  name: string
  allowedClients: MembershipClassClient[]
  coveredProfiles: number
  coveredDevices: number
  type: MembershipClassType
  features: MembershipClassFeature[]
  isInternal: boolean
  content_features?: MembershipContentFeature[]
}

export type CommercialDetail = {
  business_name?: string
  business_type?: CommercialMembershipBusinessType
  master_pin?: string
}

export type BillingDetail = {
  target: BillingTarget
  sponsor_id?: string
  external_id?: string
}

export type MembershipV2 = {
  created_date: string
  updated_date: string
  id: string
  membershipClassId: string
  status: MembershipStatus
  class: MembershipСlass
  expiry_date: string | null
  commercial_detail: null | CommercialDetail
  membership_billing: null | BillingDetail
}

export type MemberInviteRequest = {
  memberEmail: string
  firstName: string
  lastName: string
  membershipClassId: string
  trainerId: string
  accountType: AccountType
  commercial: null | CommercialDetail
  billing: null | BillingDetail
}

export type CompleteInviteRequest = {
  userId: string
}

export type SetPasswordRequest = {
  userId: string
  password: string
}

export type InviteGuestRequest = {
  owner: boolean
  email: string
  lastName: string
  firstName: string
}

export enum AccountType {
  emailPassword = 'emailPassword',
  emailOnly = 'emailOnly',
  sms = 'sms',
}

export type MembershipExtended = {
  nextBillingDate?: Date
  avatar?: string
}

export type MembershipFeature = {
  created_date: string
  updated_date: string
  id: string
  auth0_id: string
  name: string
  requires_cc: false
}

export enum CommercialMembershipBusinessType {
  'Hospitality' = 'Hospitality',
  'Gym' = 'Gym',
  'Country Club' = 'Country Club',
  'Distributor' = 'Distributor',
  'Gym Consultant' = 'Gym Consultant',
  'Offices' = 'Offices',
  'Residential/Multifamily' = 'Residential/Multifamily',
}

export enum BillingTarget {
  'self' = 'self',
  'sponsored' = 'sponsored',
  'external' = 'external',
}
