import { AMOUNT_DEFAULT } from 'src/config'
import { PersonalWorkout } from 'src/constants/personal-workout-constants'

export const formatBillRateSession = (sessions: PersonalWorkout[]) => {
  return sessions.map((data: PersonalWorkout) => {
    const result: PersonalWorkout = { ...data }
    if (data.workout_type === 'Assessment') {
      result.bill_rate = data && data.duration ? (data.duration / 30) * AMOUNT_DEFAULT : 0
    }
    return result
  })
}
