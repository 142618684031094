import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Chip,
  IconButton,
  InputAdornment,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Collapse,
  Stack,
} from '@mui/material'
import { Search, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import styled from '@emotion/styled'

import { IOrder } from 'src/api/services/orders/types'
import api from 'src/api'
import { useDebouncedCallback } from 'src/hooks/use-debounced-callback'
import { useRequest } from 'src/hooks/use-request'
import { routing } from 'src/boot/routing'
import EditIcon from '@mui/icons-material/Edit'
import { EventsLogTable } from './EventsLogTable'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

const SearchBox = ({ onChange }: { onChange: (value: string) => void }) => {
  const onChangeInternal = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value), 300)
  return (
    <TextField
      fullWidth
      variant="outlined"
      sx={{
        width: 300,
      }}
      size="small"
      placeholder="Search..."
      onChange={onChangeInternal}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  )
}

interface IColumn {
  key: string
  title: string
  cellProps: Record<string, unknown>
  Component: FC<IOrder>
}

const columns: IColumn[] = [
  {
    key: 'shopify_order_name',
    title: 'Order name',
    cellProps: { colSpan: 2 },
    Component: ({ name }) => <div>{name}</div>,
  },
  {
    key: 'member',
    title: 'Member',
    cellProps: {},
    Component: ({ email, address }) => (
      <div>
        <div>
          {address.first_name} {address.last_name}
        </div>
        <div>{email}</div>
      </div>
    ),
  },
  {
    key: 'status',
    title: 'Status',
    cellProps: {},
    Component: ({ status }) => <Chip label={status} size="small" color={status === 'Draft' ? 'info' : 'success'} />,
  },
  {
    key: 'created_at',
    title: 'Created date',
    cellProps: {},
    Component: ({ created_at }) => <div>{new Date(created_at).toLocaleDateString()}</div>,
  },
  {
    key: 'scheduled_date',
    title: 'Scheduled date',
    cellProps: {},
    Component: ({ scheduled_date }) => (
      <div>{scheduled_date ? new Date(scheduled_date).toLocaleDateString(undefined, { timeZone: 'UTC' }) : ''}</div>
    ),
  },
  {
    key: 'type',
    title: 'Type',
    cellProps: {},
    Component: ({ type }) => <Chip label={type} size="small" color="success" />,
  },
  {
    key: 'edit-link',
    title: '',
    cellProps: {},
    Component: ({ id, name }) => (
      <Link
        to={routing.editOrder.generatePath({
          id: String(id),
          shopify_order_name: name,
        })}
      >
        <IconButton aria-label="delete">
          <EditIcon />
        </IconButton>
      </Link>
    ),
  },
]

const OrdersTableRow = ({ row }: { row: IOrder }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <React.Fragment key={row.name}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen((state) => !state)}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {columns.map((column) => (
          <TableCell key={column.key}>
            <column.Component {...row} />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={isOpen} timeout="auto" mountOnEnter>
            <EventsLogTable shopifyOrderName={row.name} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const OrdersTable = ({ rows }: { rows: IOrder[] }) => (
  <Table stickyHeader>
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column.key} {...column.cellProps}>
            {column.title}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <OrdersTableRow key={row.name} row={row} />
      ))}
    </TableBody>
  </Table>
)

const RootStack = styled(Stack)`
  height: 100%;
`

const TableWrapperStack = styled(Stack)`
  margin-top: 16px;
  overflow-y: auto;
`

const createSearchFilter =
  (searchPhrase: string) =>
  (order: IOrder): boolean => {
    const lowerCasedSearchPhrase = searchPhrase.toLowerCase()
    if (!lowerCasedSearchPhrase) return true

    const isSearchingByFullName = /\w+\s+\w+/.test(lowerCasedSearchPhrase)
    if (isSearchingByFullName) {
      const addressFirstName = order.address.first_name?.toLowerCase() ?? ''
      const addressLastName = order.address.last_name?.toLowerCase() ?? ''
      const [firstName, lastName] = lowerCasedSearchPhrase.trim().split(/\s+/)
      return (
        (addressFirstName.startsWith(firstName) && addressLastName.startsWith(lastName)) ||
        (addressFirstName.startsWith(lastName) && addressLastName.startsWith(firstName))
      )
    }

    return [order.email, order.name, order.address.first_name, order.address.last_name, order.address.phone].some(
      (field) => field?.toLowerCase().includes(lowerCasedSearchPhrase),
    )
  }

export const Orders = () => {
  const [searchPhrase, setSearchPhrase] = useState('')
  const { data, isLoading, error } = useRequest(api.orders.getOrders, [])

  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const rows = useMemo(() => {
    return data
      ?.filter(createSearchFilter(searchPhrase))
      ?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
  }, [data, searchPhrase])

  useEffect(() => {
    if (error) onDisplayErrorNotification(error.message)
  }, [error, onDisplayErrorNotification])

  return (
    <RootStack direction="column">
      <SearchBox onChange={setSearchPhrase} />
      {isLoading && (
        <div style={{ padding: 24 }}>
          <CircularProgress />
        </div>
      )}
      {rows && (
        <TableWrapperStack direction="column" flex={1}>
          <OrdersTable rows={rows} />
        </TableWrapperStack>
      )}
    </RootStack>
  )
}
