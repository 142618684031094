import { generatePath } from 'react-router-dom'

import { Resources } from 'src/types/permissions-types'
import { PartialOnlyKeys } from 'src/utils/advanced-types'

const createRoute = <T = void>(pattern: string, initialParams?: Partial<T>) => ({
  pattern,
  generatePath: (params: T) => generatePath(pattern, { ...initialParams, ...params }),
})

export type StudioRouteParams = { id: string }
export type PersonalTrainerRouteParams = { id: string }
export type MemberRouteParams = { id: string; tab: 'overview' | '1-on-1' | 'cps' | 'user-charges' }
export type ConciergeRouteParams = { id: string }
export type EditOrderRouteParams = { id: string; shopify_order_name: string }

export const routing = {
  dashboard: createRoute('/'),

  login: createRoute('/login'),
  accessRestricted: createRoute('/restricted'),

  studiosList: createRoute(`/${Resources.studios}`),
  studio: createRoute<StudioRouteParams>(`/${Resources.studios}/:id`),

  knownDevice: createRoute(`/${Resources.knownDevice}`),

  personalTrainers: createRoute(`/${Resources.personalTrainers}`),
  personalTrainer: createRoute<PersonalTrainerRouteParams>(`/${Resources.personalTrainers}/:id`),

  members: createRoute(`/${Resources.members}`),
  member: createRoute<PartialOnlyKeys<MemberRouteParams, 'tab'>>(`/${Resources.members}/:id/:tab`, { tab: 'overview' }),
  employees: createRoute('/employees'),

  // concierges: createRoute(`/${Resources.concierges}`),
  // concierge: createRoute<ConciergeRouteParams>(`/${Resources.concierges}/:id`),

  membershipClass: createRoute(`/${Resources.membershipClass}`),

  personalWorkouts: createRoute(`/${Resources.personalWorkouts}`),

  memberships: createRoute(`/${Resources.membership}`),
  membership: createRoute(`${Resources.membership}/:id`),

  billingPromotions: createRoute(`/${Resources.billingPromotions}`),
  promotions: createRoute(`/${Resources.promotions}`),

  ordersManagementWrapper: createRoute(`/${Resources.orderManagement}`),
  createOrder: createRoute(`/${Resources.orderManagement}/create`),
  ordersList: createRoute(`/${Resources.orderManagement}/orders`),
  editOrder: createRoute<EditOrderRouteParams>(`/${Resources.orderManagement}/orders/:id/:shopify_order_name`),
  productsList: createRoute(`/${Resources.orderManagement}/products`),

  tpiworkflows: createRoute<any>(`/${Resources.tpiWorkflows}`),

  createdTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-created`),
  contactedTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-contacted`),
  activeTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-active`),
  rejectedTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-rejected`),
  confirmingTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-confirming`),
  activatingTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-activating`),
  trainersAssignedTPIs: createRoute<any>(`/${Resources.tpiWorkflows}-trainersAssigned`),
}
