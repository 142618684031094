import React from 'react'
import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'

const Root = styled.div`
  height: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`

export default () => (
  <Root>
    <StyledCircularProgress size={50} />
  </Root>
)
