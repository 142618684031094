import { styled, Button } from '@mui/material'
import { disabledButtonColor, errorColor, secondaryColor } from '../../constants/styles-constants'

const DeleteButton = styled(Button)({
  padding: 4,
  paddingLeft: 8,
  paddingRight: 8,
  borderRadius: 8,
  marginRight: 4,
  marginLeft: 4,
  color: secondaryColor,
  backgroundColor: errorColor,
  textTransform: 'capitalize',
  '&:hover, &:active, &:focus': {
    color: secondaryColor,
    backgroundColor: errorColor,
  },
  '&:disabled': {
    color: secondaryColor,
    backgroundColor: disabledButtonColor,
  },
})

export default DeleteButton
