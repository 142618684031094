import api from 'src/api'
import { Resources } from 'src/types/permissions-types'

import { Studio } from './types'

export const requestStudioUpdate = (data: {
  id: Studio['id']
  roles?: Studio['roles']
  profile_id?: string | null
}): Promise<Studio> =>
  api.common.update(Resources.studios, { id: data.id, data, previousData: { id: data.id } }).then((_) => _.data)
