import React, { memo, useState } from 'react'
import { nanoid } from 'nanoid'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material'
import { IOrderFormItemPart } from './types'
import { useCachedProducts } from '../../useCachedProducts'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { OrderItemLayout } from '../order-item-layout'

interface IOrderPartItemsProps {
  items: IOrderFormItemPart[]
  onChange?: (items: IOrderFormItemPart[]) => void
}

export const OrderPartItems = memo((props: IOrderPartItemsProps) => {
  const { items, onChange } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedPart, setSelectedPart] = useState<string>('')
  const globalParts = useCachedProducts() ?? []

  const onDelete = onChange ? (key: string) => onChange(items.filter((item) => item.key !== key)) : undefined

  const onAdd = onChange
    ? () => {
        const item = globalParts.find((_) => _.id === Number(selectedPart))
        if (item) {
          onChange([...items, { key: nanoid(), sku: item.sku, part: item.part }])
        }
      }
    : undefined

  return (
    <OrderItemLayout title="Order Summary">
      <Table size="small" sx={{ td: { padding: 0 }, th: { paddingLeft: 0, color: (_) => _.palette.text.secondary } }}>
        <TableHead>
          <TableRow>
            <TableCell>Part #</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>SKU</TableCell>
            {onDelete && <TableCell width="20px" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(({ key, part, sku }) => {
            const globalPart = globalParts.find((g) => g.part === part && g.sku === sku)
            if (!globalPart) return null
            return (
              <TableRow key={key}>
                <TableCell>{part}</TableCell>
                <TableCell>{globalPart.description}</TableCell>
                <TableCell>1</TableCell>
                <TableCell>{sku}</TableCell>
                {onDelete && (
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => onDelete(key)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
        {onAdd && (
          <TableFooter>
            <TableRow>
              <TableCell sx={{ border: 'none' }}>
                <Button onClick={() => setModalVisible(true)} sx={{ marginLeft: '-6px', marginTop: '10px' }}>
                  Add Part
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
      <Dialog
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Add part to order</DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ marginTop: 2 }}>
            <InputLabel id="part-select-label">Part</InputLabel>
            <Select
              labelId="part-select-label"
              value={selectedPart}
              label="Part"
              onChange={(ev) => setSelectedPart(ev.target.value)}
              placeholder="Choose part"
            >
              {globalParts.map((part) => (
                <MenuItem value={part.id} key={part.id}>
                  [{part.part}] {part.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalVisible(false)}>Close</Button>
          <Button onClick={onAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </OrderItemLayout>
  )
})
