import { Dispatch } from '@reduxjs/toolkit'

import api from '../../api'
import { Resources } from '../../types/permissions-types'
import { RequestError } from '../../api/http-client'
import { SubEntities } from '../../api/services/entities'
import { formatSessionData } from '../../utils/data-transformation-utils'
import {
  setPersonalTrainerError,
  setPersonalTrainerRecord,
  startFetchingPersonalTrainer,
  startFetchingSessions,
  setSessions,
  setSessionsError,
  startFetchingAssignedMembers,
  setAssignedMembers,
  setAssignedMembersError,
} from './personal-trainer-reducer'

export const fetchPersonalTrainerRecord = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingPersonalTrainer())

  api.common
    .getOne(Resources.personalTrainers, { id: recordId })
    .then(({ data: record }) => dispatch(setPersonalTrainerRecord(record)))
    .catch((error: RequestError) => dispatch(setPersonalTrainerError(error)))
}

export const fetchPersonalTrainerAssignedMembers = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingAssignedMembers())

  api.common
    .getOne(Resources.personalTrainers, { id: recordId, subUrl: SubEntities.assignedMembers })
    .then((assignedMembers) => dispatch(setAssignedMembers(assignedMembers)))
    .catch((error: RequestError) => dispatch(setAssignedMembersError(error)))
}

export const fetchPersonalTrainerSessions = (recordId: string) => (dispatch: Dispatch) => {
  dispatch(startFetchingSessions())

  api.entities
    .getSubEntityListWithFilterOnly(SubEntities.personalWorkouts, {
      filter: {
        'trainer_id||$eq': recordId,
      },
    })
    .then(({ data: sessions }) => {
      const sessionDataFormatted = formatSessionData(sessions)
      dispatch(setSessions(sessionDataFormatted))
    })
    .catch((error: RequestError) => dispatch(setSessionsError(error)))
}
