import styled from '@emotion/styled'

import AccessibleTable from 'src/components/accessible-table/accessible-table'
import TextInput from 'src/components/inputs/text-input'
import NumberInput from 'src/components/inputs/number-input'

import { secondaryColor } from '../constants/styles-constants'

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: ${secondaryColor};
`

export const PageTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.03em;
  color: #000000;
`

export const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 64px;
  word-break: break-all;
`

export const PageSectionTable = styled(AccessibleTable)`
  max-height: 430px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 15px;
`

export const PageSectionTableMaxHeight = styled(AccessibleTable)`
  max-height: 800px;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 15px;
`

export const FormContainer = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 50px;
`

export const TextInputWithMargin = styled(TextInput)`
  margin-top: 15px;
  margin-bottom: 15px;
`

export const NumberInputWithMargin = styled(NumberInput)`
  margin-top: 15px;
  margin-bottom: 15px;
`
