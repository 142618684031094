import React, { useEffect } from 'react'

import { ColumnItem } from '../../../components/accessible-table/acessible-table-types'

import { fetchMemberCPSSchedule, fetchMemberHistoryWorkout } from 'src/redux/member/member-actions'
import { useDispatch } from 'react-redux'
import { Resources } from 'src/types/permissions-types'
import ResourceCPSHistoryTable from 'src/containers/resource-cps-history-table'
import ResourceCPSScheduleTable from 'src/containers/resource-cps-schedule-table'

type MemberCPSTabProps = {
  recordId: string
  cpsScheduleTableColumns: ColumnItem[]
  cpsHistoryTableColumns: ColumnItem[]
}

function MemberCPSTab(props: MemberCPSTabProps) {
  const { recordId, cpsScheduleTableColumns, cpsHistoryTableColumns } = props
  const dispatch = useDispatch()

  useEffect(() => {
    recordId && dispatch(fetchMemberHistoryWorkout(recordId))
  }, [recordId, dispatch])

  useEffect(() => {
    recordId && dispatch(fetchMemberCPSSchedule(recordId))
  }, [recordId, dispatch])

  return (
    <>
      <ResourceCPSScheduleTable
        title="CPS Schedule"
        columns={cpsScheduleTableColumns}
        recordId={recordId ?? ''}
        resource={Resources.cpsSchedule}
        emptyDataMessage="Not available"
      />
      <ResourceCPSHistoryTable
        title="CPS History"
        columns={cpsHistoryTableColumns}
        recordId={recordId ?? ''}
        resource={Resources.cpsHistory}
        emptyDataMessage="Not available"
      />
    </>
  )
}

export default MemberCPSTab
