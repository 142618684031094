import React, { useMemo } from 'react'

import { routing } from 'src/boot/routing'
import { Resources } from 'src/types/permissions-types'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import {
  CurrencyCell,
  DataCell,
  DateCell,
  SessionIdCell,
} from '../../components/accessible-table/accessible-table-cell'

export const useSessionTableColumns = (): ColumnItem[] => {
  const { isReadAllowed: isMemberReadAllowed } = useResourcePermissionHandler(Resources.members)
  return useMemo(
    (): ColumnItem[] => [
      { key: 'date', title: 'Date', fields: ['date'], render: DateCell, orderByField: 'date' },
      {
        key: 'workout_category',
        title: 'Category',
        fields: ['workout_category'],
        render: DataCell,
        orderByField: 'workout_category',
      },
      { key: 'workout_type', title: 'Type', fields: ['workout_type'], render: DataCell, orderByField: 'workout_type' },
      {
        key: 'id',
        title: 'Session #',
        fields: ['id'],
        render: SessionIdCell,
        orderByField: 'id',
      },
      { key: 'duration', title: 'Duration', fields: ['duration'], render: DataCell, orderByField: 'duration' },
      {
        key: 'session_status',
        title: 'Ses. Status',
        fields: ['workout_status'],
        render: DataCell,
        orderByField: 'workout_status',
      },
      {
        key: 'payment_status',
        title: 'Pay Status',
        fields: ['payment_status'],
        render: DataCell,
        orderByField: 'payment_status',
      },
      {
        key: 'member',
        title: 'Member',
        fields: ['client_name'],
        render: DataCell,
        orderByField: 'client_name',
        link: isMemberReadAllowed
          ? (row: { client_user_id: string }) => {
              return routing.member.generatePath({ id: row.client_user_id })
            }
          : undefined,
      },
      {
        key: 'client_agora_id',
        title: 'Member Agora Id',
        fields: ['client_agora_id'],
        render: DataCell,
        orderByField: 'client_agora_id',
      },
      {
        key: 'billing_rate',
        title: 'Bill Rate',
        fields: ['bill_rate'],
        render: (props) => <CurrencyCell {...props} />,
        orderByField: 'bill_rate',
      },
    ],
    [isMemberReadAllowed],
  )
}
