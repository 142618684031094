import React, { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'
import { Form, Modal, Select, useWatchAll, Input } from 'src/antd-components'
import { Resources } from 'src/types/permissions-types'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import Loader from 'src/components/loader'
import { FormeDialog } from 'src/hooks/useDialog'
import { useRequest } from 'src/hooks/use-request'
import { requestMembershipClassList } from 'src/api/services/cloud/memberships/requestMembershipClassList'
import { sendMembershipInvite } from 'src/api/services/cloud/memberships/sendMemberInvite'
import { AccountType, BillingTarget, CommercialMembershipBusinessType } from 'src/api/services/cloud/memberships/types'

type FormData = {
  first_name: string
  last_name: string
  membershipClassId: string
  member_email: string
  trainer_id: string
  account_type: string
  commercial: null | {
    business_name?: string
    business_type?: CommercialMembershipBusinessType
  }
  billing: {
    target: BillingTarget
    external_id?: string
    sponsor_id?: string
  }
}

const accountTypeOptions = [
  { value: AccountType.emailOnly, label: 'Email only' },
  { value: AccountType.emailPassword, label: 'Email/Password' },
  { value: AccountType.sms, label: 'sms' },
]

const businessTypeOptions = Object.entries(CommercialMembershipBusinessType).map((value) => ({
  value: value[0],
  label: value[1],
}))

const billingTargetOptions = [
  { value: 'self', label: 'Self' },
  { value: 'sponsored', label: 'Sponsored' },
  { value: 'external', label: 'External' },
]

export const SendMembershipInviteDialog: FormeDialog = (props) => {
  const { open: isOpened, onClose } = props
  const { isLoading } = useResourcePermissionHandler(Resources.memberships)

  const { data: membershipClassList, fetch: fetchMembershipClassList } = useRequest(requestMembershipClassList)
  const { fetch: sendMemberInvite } = useRequest(sendMembershipInvite)

  const initialFormData = useMemo(
    () => ({
      membershipClassId: '',
      member_email: '',
      first_name: '',
      last_name: '',
      trainer_id: '',
      account_type: 'emailPassword',
      commercial: null,
      billing: { target: BillingTarget.self },
    }),
    [],
  )

  const [form] = Form.useForm<FormData>()
  const { membershipClassId, member_email, first_name, last_name, trainer_id, account_type, commercial, billing } =
    useWatchAll(form, initialFormData)

  const clearFormData = () => {
    form.resetFields()
  }

  const isFormValid = membershipClassId && account_type
  const hasChanges = useMemo(() => {
    return !isEqual(initialFormData, { membershipClassId })
  }, [initialFormData, membershipClassId])

  const onAdd = async () => {
    const membershipClass = membershipClassList?.data.find(({ id }) => membershipClassId === id)

    await sendMemberInvite({
      membershipClassId: membershipClassId,
      memberEmail: member_email,
      firstName: first_name,
      lastName: last_name,
      trainerId: trainer_id,
      accountType: account_type as AccountType,
      commercial: membershipClass?.type === 'commercial' ? { ...commercial, master_pin: '0000' } : null,
      billing: {
        target: billing.target,
        sponsor_id: billing.target === BillingTarget.sponsored ? billing.sponsor_id : undefined,
        external_id: billing.target === BillingTarget.external ? billing.external_id : undefined,
      },
    })
    onClose()
  }

  const membershipClassOptions = useMemo(() => {
    return membershipClassList?.data?.map(({ id, name }) => ({ value: id, label: name }))
  }, [membershipClassList])

  useEffect(() => {
    fetchMembershipClassList()
  }, [fetchMembershipClassList])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal
      title="Send New Invite"
      cancelText="Cancel"
      okText="Send"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid || !hasChanges }}
      onOk={onAdd}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item name="membershipClassId" label="Select Membership Type">
          <Select options={membershipClassOptions} />
        </Form.Item>
        <Form.Item label="Guest Email" name="member_email">
          <Input type="email" />
        </Form.Item>
        <Form.Item label="First Name" name="first_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Business Name" name={['commercial', 'business_name']}>
          <Input />
        </Form.Item>
        <Form.Item label="Business Type" name={['commercial', 'business_type']}>
          <Select options={businessTypeOptions} />
        </Form.Item>
        <Form.Item label="Trainer Id" name="trainer_id">
          <Input allowedChars={/^[0-9 ]*$/} maxLength={3} />
        </Form.Item>
        <Form.Item label="Account Type" name="account_type">
          <Select options={accountTypeOptions} />
        </Form.Item>
        <Form.Item label="Billing Target" name={['billing', 'target']}>
          <Select options={billingTargetOptions} defaultValue="self" />
        </Form.Item>
        <Form.Item label="Sponsor ID" name={['billing', 'sponsor_id']}>
          <Input disabled={billing.target !== BillingTarget.sponsored ? true : false} />
        </Form.Item>
        <Form.Item label="External ID" name={['billing', 'external_id']}>
          <Input disabled={billing.target !== BillingTarget.external ? true : false} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
