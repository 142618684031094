import { useEffect, useState } from 'react'

// DEPRECATED
// storing raw string is very inflexible
// use useStoredState hook instead
export function useStoredStringState(key: string) {
  const product = useState(() => localStorage.getItem(key))
  const [state] = product

  useEffect(() => {
    if (state !== null) {
      localStorage.setItem(key, state)
    }
  }, [key, state])

  return product
}
