import React from 'react'
import { Resources } from '../types/permissions-types'
import CropFreeIcon from '@mui/icons-material/CropFree'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import AssignmentIcon from '@mui/icons-material/Assignment'
import UserIcon from '@mui/icons-material/People'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import GroupsIcon from '@mui/icons-material/Groups'
import ReceiptIcon from '@mui/icons-material/Receipt'
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import PhoneIcon from '@mui/icons-material/Phone'
import SendIcon from '@mui/icons-material/Send'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PostAddIcon from '@mui/icons-material/PostAdd'

export type ResourceConfig = {
  name: Resources
  label: string
  icon: React.FunctionComponent
  isReadAllowed: boolean
  isCreateAllowed: boolean
  isDeleteAllowed: boolean
  isUpdateAllowed: boolean
}

export const resourcesNamesMap = {
  [Resources.personalTrainers]: 'Personal Trainers',
  [Resources.paymentPeriods]: 'Payment Periods',
  [Resources.personalWorkouts]: 'Personal Workouts',
  [Resources.members]: 'Members',
  [Resources.profile]: 'Profile',
  [Resources.memberships]: 'Memberships',
  [Resources.membership]: 'Memberships',
  [Resources.studios]: 'Studios',
  [Resources.knownDevice]: 'Known Devices',
  [Resources.billingPromotions]: 'Billing Promotions',
  [Resources.promotions]: 'Promotions',
  [Resources.concierges]: 'Concierges',
  [Resources.failedPayments]: 'Failed Payments',
  [Resources.trainerBillingGroup]: 'Trainer Billing Group',
  [Resources.trainerMember]: 'Trainer Member',
  [Resources.trainerSession]: 'Trainer Session',
  [Resources.memberEmergencyInfo]: 'Member Emergency Contact Info',
  [Resources.oneOnOneIncurredCharges]: '1-on-1 Incurred Charges',
  [Resources.oneOnOneInvoices]: '1-on-1 Invoices',
  [Resources.memberSessions]: 'Member Sessions',
  [Resources.conciergeMember]: 'Concierge Member',
  [Resources.triggerInvoicing]: 'Trigger Invoicing',
  [Resources.addBundle]: 'Add Bundle',
  [Resources.orderManagement]: 'Order Management',
  [Resources.trainerPayRate]: 'Trainer Pay Rate',
  [Resources.userRoles]: 'User Roles',
  [Resources.contentPlans]: 'Content Plans',
  [Resources.tpiWorkflows]: 'TPI',
  [Resources.trainerLevel]: 'Trainer Level',
  [Resources.deviceMessage]: 'Device messages',
  [Resources.personalData]: 'Personal Data',
  [Resources.memberInvite]: 'Member Invite',
  [Resources.trainers]: 'Trainers',
  [Resources.bundles]: 'Bundles',
  [Resources.billingSchedule]: 'Billing Schedule',
  [Resources.bills]: 'Bills',
  [Resources.cpsHistory]: 'Workout CPS History',
  [Resources.cpsSchedule]: 'CPS Schedule',
  [Resources.charge]: 'Charge',
  [Resources.coupons]: 'Coupons',
  [Resources.invites]: 'Invites',
  [Resources.linking]: 'Linking',
  [Resources.membershipSwitch]: 'Membership Switch',
  [Resources.membershipStatus]: 'Membership Status',
  [Resources.trainerFeatures]: 'Membership Features',
  [Resources.membershipClass]: 'Membership Classes',
  [Resources.membershipExpiry]: 'Membership Expiry',
  [Resources.password]: 'Password',
}

export const resourcesIconsMap = {
  [Resources.personalTrainers]: PermIdentityIcon,
  [Resources.paymentPeriods]: AssignmentIcon,
  [Resources.personalWorkouts]: PermIdentityIcon,
  [Resources.members]: UserIcon,
  [Resources.profile]: UserIcon,
  [Resources.memberships]: AssignmentIcon,
  [Resources.membership]: AssignmentIcon,
  [Resources.studios]: CropFreeIcon,
  [Resources.knownDevice]: CropFreeIcon,
  [Resources.billingPromotions]: MoneyOffIcon,
  [Resources.promotions]: ConfirmationNumberIcon,
  [Resources.concierges]: AssignmentIcon,
  [Resources.failedPayments]: PermIdentityIcon,
  [Resources.trainerBillingGroup]: GroupsIcon,
  [Resources.trainerMember]: PermIdentityIcon,
  [Resources.trainerSession]: FitnessCenterIcon,
  [Resources.memberEmergencyInfo]: PhoneIcon,
  [Resources.oneOnOneIncurredCharges]: AccountBalanceWalletIcon,
  [Resources.oneOnOneInvoices]: ReceiptIcon,
  [Resources.memberSessions]: FitnessCenterIcon,
  [Resources.conciergeMember]: AssignmentIcon,
  [Resources.triggerInvoicing]: SendIcon,
  [Resources.addBundle]: AssignmentIcon,
  [Resources.orderManagement]: AssignmentIcon,
  [Resources.trainerPayRate]: AttachMoneyIcon,
  [Resources.userRoles]: UserIcon,
  [Resources.contentPlans]: UserIcon,
  [Resources.tpiWorkflows]: AssignmentIcon,
  [Resources.trainerLevel]: AssignmentIcon,
  [Resources.deviceMessage]: AssignmentIcon,
  [Resources.personalData]: PermIdentityIcon,
  [Resources.memberInvite]: AssignmentIcon,
  [Resources.trainers]: UserIcon,
  [Resources.bundles]: PostAddIcon,
  [Resources.billingSchedule]: ReceiptIcon,
  [Resources.bills]: ReceiptIcon,
  [Resources.cpsHistory]: PermIdentityIcon,
  [Resources.cpsSchedule]: PermIdentityIcon,
  [Resources.charge]: AccountBalanceWalletIcon,
  [Resources.coupons]: AccountBalanceWalletIcon,
  [Resources.invites]: UserIcon,
  [Resources.linking]: UserIcon,
  [Resources.membershipSwitch]: UserIcon,
  [Resources.membershipStatus]: UserIcon,
  [Resources.trainerFeatures]: UserIcon,
  [Resources.membershipClass]: AssignmentIcon,
  [Resources.membershipExpiry]: AssignmentIcon,
  [Resources.password]: UserIcon,
}

export const resourcesList = Object.values(Resources)

export const resourcesConfig: ResourceConfig[] = resourcesList.map((resource) => ({
  name: resource,
  icon: resourcesIconsMap[resource],
  label: resourcesNamesMap[resource],
  isReadAllowed: false,
  isCreateAllowed: false,
  isDeleteAllowed: false,
  isUpdateAllowed: false,
}))
