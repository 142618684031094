import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'

import api from '../../../api'
import { Member } from '../../../constants/member-constants'
import { Resources } from '../../../types/permissions-types'
import { FormContainer } from '../../../components/ui-kit'
import { RequestError } from '../../../api/http-client'
import { getErrorMessage } from '../../../utils/errors'
import { ResourceListState } from '../../../hooks/use-resource-list-data'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import Loader from '../../../components/loader'
import { routing } from '../../../boot/routing'
import {
  combineSearchFilters,
  createFullNameSearchFilter,
  createRegularSearchFilter,
} from '../../../containers/resource-list-container/filters'
import { Modal, Search, Table, ColumnsType } from 'src/antd-components'
import { RowSelectionType } from 'antd/lib/table/interface'

type CreatePersonalTrainerDialogProps = {
  isOpened: boolean
  resourceListState?: ResourceListState
  onClose: () => void
}

const StyledFormContainer = styled(FormContainer)`
  max-height: 500px;
  overflow: hidden;
`

const StyledLabel = styled.h6`
  margin: 0;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`

const StyledTable = styled(Table)`
  margin-top: 25px;
` as unknown as typeof Table

const scroll = { y: 425 }

const clientsTableColumns: ColumnsType<Member> = [
  {
    key: 'name',
    title: 'Name',
    render: (_, member) => `${member.first_name} ${member.last_name}`,
    sorter: (first, second) => first.first_name.localeCompare(second.first_name),
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    sorter: (first, second) => first.email.localeCompare(second.email),
  },
]

const searchFilter = combineSearchFilters(
  createFullNameSearchFilter('first_name', 'last_name'),
  createRegularSearchFilter(['first_name', 'last_name', 'email']),
)

function CreatePersonalTrainerDialog(props: CreatePersonalTrainerDialogProps) {
  const { isOpened, onClose } = props

  const history = useHistory()

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)

  const [searchInputValue, setSearchInput] = useState<string>('')
  const [clientsList, setClientsList] = useState<Member[]>([])
  const [selectedClientId, setClientId] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const rowSelection = useMemo(
    () => ({
      type: 'radio' as RowSelectionType,
      onSelect: ({ id }: Member) => setClientId(id),
    }),
    [],
  )
  const dataSource = useMemo(() => clientsList.map((client) => ({ ...client, key: client.id })), [clientsList])

  const selectedClient = clientsList.find((client) => client.id === selectedClientId)

  const clearFormData = () => {
    setSearchInput('')
    setClientId('')
  }

  useEffect(() => {
    if (isEditAllowed && isOpened) {
      api.common
        .getList(Resources.members, {
          pagination: { page: 1, perPage: 100 },
          filter: searchFilter(searchInputValue),
          sort: { field: 'email', order: 'ASC' },
        })
        .then(({ data: clients }: any) => setClientsList(clients))
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    }
  }, [isOpened, isEditAllowed, searchInputValue, onDisplayErrorNotification])

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed) {
    return null
  }

  const onCreatePersonalTrainer = () => {
    if (!selectedClient) return

    const data = {
      user_id: selectedClientId,
    }

    const params = { data, withReload: true }

    setLoading(true)

    api.common
      .create(Resources.personalTrainers, params)
      .then(({ data: newPersonalTrainer }) => {
        onClose()
        onDisplaySuccessNotification('Personal trainer was successfully created')
        history.push(routing.personalTrainer.generatePath({ id: newPersonalTrainer.id }))
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        if (errorMessage === `personal trainer with user_id:"${selectedClient.id}" already exists`) {
          onDisplayErrorNotification(`personal trainer with email:"${selectedClient.email}" already exists`)
        } else {
          onDisplayErrorNotification(errorMessage)
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      title="Create Personal Trainer"
      width={700}
      cancelText="Cancel"
      okText="Create"
      open={isOpened}
      onOk={onCreatePersonalTrainer}
      okButtonProps={{ disabled: !selectedClient || loading, loading }}
      cancelButtonProps={{ disabled: loading }}
      onCancel={() => {
        if (loading) return

        clearFormData()
        onClose()
      }}
    >
      <StyledFormContainer>
        <StyledLabel>Member List</StyledLabel>
        <Search
          id="searchInput"
          value={searchInputValue}
          allowClear
          placeholder="Search member by name or email..."
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <StyledTable<Member>
          id="clientsList"
          rowSelection={rowSelection}
          columns={clientsTableColumns}
          dataSource={dataSource}
          pagination={false}
          size="middle"
          scroll={scroll}
        />
      </StyledFormContainer>
    </Modal>
  )
}

export default CreatePersonalTrainerDialog
