import React, { ChangeEvent, ComponentProps } from 'react'
import { Input as LibInput } from 'antd'
import { Overwrite } from 'src/utils/advanced-types'

type InputProps = Overwrite<
  ComponentProps<typeof LibInput>,
  {
    onChange?: (value: string) => void
    allowedChars?: RegExp
  }
>

export function Input({ allowedChars, onChange: _onChange, ...rest }: InputProps) {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (allowedChars && !e.target.value.match(allowedChars)) {
      return
    }

    _onChange?.(e.target.value)
  }

  return <LibInput onChange={onChange} {...rest} />
}

const { Group, Search, TextArea, Password } = LibInput
export { Group, Search, TextArea, Password }
