import { Resources } from '../types/permissions-types'
import { useResourcesPermissionsMap } from './use-permissions-handler'

type UseResourcePermissionHandler = {
  isLoading: boolean
  isAuthenticated: boolean
  isReadAllowed: boolean
  isEditAllowed: boolean
  isCreateAllowed: boolean
  isDeleteAllowed: boolean
}

export const useResourcePermissionHandler = (resource: Resources): UseResourcePermissionHandler => {
  const { isReady, resourcesPermissionsMap, isAuthenticated } = useResourcesPermissionsMap()
  const {
    isReadAllowed,
    isUpdateAllowed: isEditAllowed,
    isCreateAllowed,
    isDeleteAllowed,
  } = resourcesPermissionsMap[resource] || {}

  return { isLoading: !isReady, isAuthenticated, isReadAllowed, isEditAllowed, isCreateAllowed, isDeleteAllowed }
}
