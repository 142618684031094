import { PersonalTrainerState } from './personal-trainer-reducer'
import { RequestError } from '../../api/http-client'
import { PersonalTrainer, PersonalTrainerAssignedMembers } from '../../constants/personal-trainer-constants'
import { RootState } from '../store'
import { PersonalWorkout } from '../../constants/personal-workout-constants'

export const getPersonalTrainerState = (state: RootState): PersonalTrainerState => state.personalTrainer

export const getIsPersonalTrainerLoading = (state: RootState): boolean => getPersonalTrainerState(state).isLoading
export const getPersonalTrainerError = (state: RootState): null | RequestError => getPersonalTrainerState(state).error
export const getPersonalTrainerRecord = (state: RootState): PersonalTrainer | null =>
  getPersonalTrainerState(state).record

export const getIsPersonalTrainerAssignedMembersLoading = (state: RootState): boolean =>
  getPersonalTrainerState(state).isAssignedMembersLoading
export const getPersonalTrainerAssignedMembersError = (state: RootState): null | RequestError =>
  getPersonalTrainerState(state).assignedMembersError
export const getPersonalTrainerAssignedMembers = (state: RootState): PersonalTrainerAssignedMembers[] =>
  getPersonalTrainerState(state).assignedMembers

export const getIsPersonalTrainerSessionsLoading = (state: RootState): boolean =>
  getPersonalTrainerState(state).isSessionsLoading
export const getIsPersonalTrainerSessionsError = (state: RootState): null | RequestError =>
  getPersonalTrainerState(state).sessionsError
export const getPersonalTrainerSessions = (state: RootState): PersonalWorkout[] =>
  getPersonalTrainerState(state).sessions
