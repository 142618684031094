import React, { useEffect, useMemo } from 'react'

import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { useAction } from '../../../hooks/use-actions'
import { RequestError } from '../../../api/http-client'
import { PersonalTrainer } from '../../../constants/personal-trainer-constants'
import { getErrorMessage } from '../../../utils/errors'
import { setPersonalTrainerRecord } from '../../../redux/personal-trainer/personal-trainer-reducer'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import Loader from '../../../components/loader'
import { FormeDialog } from '../../../hooks/useDialog'
import { Input, Form, Select, Modal, useWatchAll, TextArea } from 'src/antd-components'
import { requestTrainerConfigs } from 'src/api/services/cloud/trainers/trainerConfig'
import { useRequest } from 'src/hooks/use-request'

export type PayRates = {
  oldPayRate: number
  currentPayRate: number
  newPayRate: number
  newPayRateStartDate: string
  newPayRateStartDateFormatted: string
}

type EditPersonalTrainerSpecsProps = {
  record: PersonalTrainer
}

type FormData = {
  full_name: ''
  active: boolean
  calendly_id: ''
  biography: ''
  certifications: any
  specializations: []
  coaching_styles: []
}

const EditPersonalTrainerSpecsDialog: FormeDialog<EditPersonalTrainerSpecsProps> = ({
  params: { record },
  open: isOpened,
  onClose,
}) => {
  const [form] = Form.useForm()

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)
  const { isEditAllowed: isTrainerFeaturesEditAllowed } = useResourcePermissionHandler(Resources.trainerFeatures)

  const onUpdatePersonalTrainerRecord = useAction(setPersonalTrainerRecord)

  const { data: trainerConfigs, fetch: fetchTrainerConfigsList } = useRequest(requestTrainerConfigs)
  const statusListOptions = [
    { value: true, label: 'true' },
    { value: false, label: 'false' },
  ]
  const specializationsListOptions = useMemo(() => {
    return trainerConfigs?.personal_trainer_specializations?.map((spec) => ({ value: spec, label: spec }))
  }, [trainerConfigs])
  const coachingStylesListOptions = useMemo(() => {
    return trainerConfigs?.personal_trainer_coaching_style?.map((style) => ({ value: style, label: style }))
  }, [trainerConfigs])
  const initialTrainerSpecs = {
    full_name: `${record?.user?.first_name} ${record?.user?.last_name}`,
    active: record?.active,
    calendly_id: record?.calendly_id,
    biography: record?.biography ?? '',
    certifications: record?.certifications?.length ? JSON.stringify(record.certifications) : [],
    specializations: record?.specializations,
    coaching_styles: record?.coaching_styles,
  }

  const { active, calendly_id, biography, certifications, specializations, coaching_styles }: FormData = useWatchAll(
    form,
    initialTrainerSpecs,
  )

  const clearFormData = () => {
    form.resetFields()
  }

  const trainerSpecsChanged =
    active !== record?.active ||
    calendly_id !== record?.calendly_id ||
    biography !== record?.biography ||
    certifications !== JSON.stringify(record?.certifications) ||
    JSON.stringify(specializations) !== JSON.stringify(record?.specializations) ||
    JSON.stringify(coaching_styles) !== JSON.stringify(record?.coaching_styles)
  useEffect(() => {
    fetchTrainerConfigsList()
  }, [fetchTrainerConfigsList])

  const isFormValid = trainerSpecsChanged

  const onEdit = () => {
    if (!record?.user?.id || !isFormValid) return

    if (trainerSpecsChanged) {
      const dataToSend = {
        active,
        calendly_id: calendly_id?.length ? calendly_id : null,
        biography,
        specializations,
        certifications: certifications?.length ? JSON.parse(certifications) : [],
        coaching_styles,
      }
      const params = { id: record?.id, data: dataToSend, previousData: { id: record?.id } }
      api.common
        .update(Resources.personalTrainers, params)
        .then(({ data: trainer }) => {
          onUpdatePersonalTrainerRecord({ ...trainer })
          form.setFieldsValue({ ...dataToSend, certifications: certifications })
          onDisplaySuccessNotification('Personal trainer was successfully edited')
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    }
    clearFormData()
    onClose()
  }

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed) {
    return null
  }

  return (
    <Modal
      title="Edit trainer specs"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{
        disabled: !isFormValid,
      }}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialTrainerSpecs} labelAlign="right" layout="vertical">
        <Form.Item name="full_name" label="Name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={50} disabled />
        </Form.Item>
        {isTrainerFeaturesEditAllowed && (
          <>
            <Form.Item name="active" label="Active">
              <Select options={statusListOptions} value={record.active} />
            </Form.Item>
            <Form.Item name="calendly_id" label="Calendly Id">
              <Input maxLength={36} minLength={36} disabled={record.active} />
            </Form.Item>
            <Form.Item name="biography" label="Biography">
              <TextArea />
            </Form.Item>
            <Form.Item
              tooltip='Example: ["KB Athletics", "Pn2"] or [ ] for none'
              name="certifications"
              label="Certifications"
            >
              <Input />
            </Form.Item>
            <Form.Item name="coaching_styles" label="Coaching Styles">
              <Select options={coachingStylesListOptions} mode="multiple" />
            </Form.Item>
            <Form.Item name="specializations" label="Specializations">
              <Select options={specializationsListOptions} mode="multiple" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default EditPersonalTrainerSpecsDialog
