import httpClient, { RequestMethods } from 'src/api/http-client'
import { API } from 'src/config'

export const requestCancelMembership = (id: string, immediate: boolean): Promise<{ success: boolean }> => {
  const data = {
    options: {
      forceRestoreMembershipPeriod: '5m',
    },
  }

  return httpClient(`${API.cloud}/members/${id}/cancel-membership`, {
    method: RequestMethods.post,
    body: immediate ? JSON.stringify(data) : '',
  })
}

export const requestUncancelMembership = (id: string): Promise<{ success: boolean }> => {
  return httpClient(`${API.cloud}/members/${id}/uncancel-membership`, {
    method: RequestMethods.post,
  })
}

export const requestRestoreMembership = (
  id: string,
  data: { monthlyBillingDate: Date },
): Promise<{ success: boolean }> => {
  return httpClient(`${API.cloud}/members/${id}/restore-membership`, {
    method: RequestMethods.post,
    body: JSON.stringify(data),
  })
}

export const requestPauseMembership = (id: string): Promise<{ success: boolean }> => {
  return httpClient(`${API.cloud}/members/${id}/pause-membership`, {
    method: RequestMethods.post,
  })
}
