import httpClient, { RequestMethods } from 'src/api/http-client'
import { API } from 'src/config'
import { BillingDetail, CommercialMembershipBusinessType } from './types'

export const requestSwitchMembership = (
  userId: string,
  classId: string,
  businessType?: CommercialMembershipBusinessType,
  businessName?: string,
  billing?: BillingDetail,
): Promise<any> =>
  httpClient(`${API.cloud}/workflow/start?skipValidation=true`, {
    method: RequestMethods.post,
    body: JSON.stringify({
      prefix: 'switchMembership',
      id: userId,
      args: {
        userId,
        newMembershipClassId: classId,
        businessName,
        businessType,
        billing,
      },
    }),
  })
