import React, { useState } from 'react'
import { Modal } from 'antd'
import httpClient, { RequestMethods } from 'src/api/http-client'
import { API } from 'src/config'
import { Input } from '../../antd-components'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'

type KnownDevicesAddModalProps = {
  open: boolean
  onClose: () => void
}

export function KnownDevicesAddModal({ open, onClose }: KnownDevicesAddModalProps) {
  const [value, setValue] = useState('')
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  return (
    <Modal
      title="Add Known Device"
      open={open}
      onCancel={onClose}
      okButtonProps={{ disabled: !value }}
      onOk={() => {
        httpClient(`${API.cloud}/known-device`, {
          method: RequestMethods.post,
          body: JSON.stringify({ id: value }),
        })
          .then(() => {
            onDisplaySuccessNotification(`Device ${value} added to known`)
            onClose()
          })
          .catch(() => onDisplayErrorNotification('Sorry, something went wrong, please reload the page'))
      }}
    >
      <Input placeholder="Please, enter the ID" onChange={setValue} />
    </Modal>
  )
}
