import { useCallback } from 'react'

import { useRequest } from 'src/hooks/use-request'
import { requestAdminCreation } from 'src/api/services/orders/requestAdminCreation'
import { IOrderItemPart, OrderTypes } from 'src/api/services/orders/types'
import { ShopifyOrder } from 'src/api/services/orders/requestShopifyOrder'
import { IOrderServiceCode } from 'src/api/services/orders/service-codes/requestServiceCodes'

import { useCachedProducts } from '../../useCachedProducts'
import { useCachedServiceCodes } from '../../useCachedServiceCodes'
import { CreateOrderFormData } from './useFormState'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

export const useCreateOrderRequest = (originalOrder?: ShopifyOrder) => {
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const allParts = useCachedProducts()
  const allCodes = useCachedServiceCodes()
  const { fetch: originalFetch, ...rest } = useRequest(requestAdminCreation)

  const fetch = useCallback(
    (orderType: OrderTypes, orderFormData: CreateOrderFormData) =>
      originalFetch(
        {
          id: Number(orderFormData.name),
          ...originalOrder!,
          address_type: orderFormData.address_type,
          email: orderFormData.email,
          name: orderFormData.name,
          shipping_address: { ...originalOrder!.shipping_address, ...orderFormData.address },
        },
        orderFormData.orderInfo,
        orderType,
        orderFormData.parts
          .map((p) => allParts!.find((globalPart) => globalPart.sku === p.sku && globalPart.part === p.part))
          .filter(Boolean) as IOrderItemPart[],
        orderFormData.service_codes
          .map((p) => allCodes!.find((globalCode) => globalCode.code === p.code))
          .filter(Boolean) as IOrderServiceCode[],
      ).catch((err) => {
        onDisplayErrorNotification(err.response.message || err.message || 'Create draft order exception')
      }),
    [allCodes, allParts, onDisplayErrorNotification, originalFetch, originalOrder],
  )

  return { fetch: allParts && allCodes && originalOrder ? fetch : undefined, ...rest }
}
