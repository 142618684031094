import { useCallback, useEffect, useRef } from 'react'
import { debounce } from 'lodash'
import useIsMounted from './use-is-mounted'

export const useDebouncedCallback = <Args extends any[]>(
  cb: (...args: Args) => void,
  delay: number,
): ((...args: Args) => void) => {
  const inputsRef = useRef({ cb, delay })
  const isMounted = useIsMounted()
  useEffect(() => {
    inputsRef.current = { cb, delay }
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce((...args) => {
      if (inputsRef.current.delay === delay && isMounted.current) {
        inputsRef.current.cb(...args)
      }
    }, delay),
    [delay],
  )
}
