import React, { ReactNode } from 'react'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import { store } from '../redux/store'
import ThemeContextProvider from './theme'
import LocalizationContextProvider from './localization'

type AppProviderProps = {
  children: ReactNode
}

function AppProviders({ children }: AppProviderProps) {
  return (
    <Provider store={store}>
      <LocalizationContextProvider>
        <ThemeContextProvider>
          <SnackbarProvider maxSnack={5}>{children}</SnackbarProvider>
        </ThemeContextProvider>
      </LocalizationContextProvider>
    </Provider>
  )
}

export default AppProviders
