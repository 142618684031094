import React, { useEffect, useState } from 'react'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { PageSectionTableMaxHeight } from '../components/ui-kit'
import TableSectionContainer from '../components/table-section-container'
import { useAction } from 'src/hooks/use-actions'
import { fetchAllMemberCharges } from 'src/redux/member/member-actions'
import { useSelector } from 'react-redux'
import {
  getAllChargesByUser,
  getAllChargesByUserError,
  getIsAllChargesByUserLoading,
} from 'src/redux/member/member-selectors'
import { Order } from 'src/types/common-types'
import { EditUserChargesDialog } from 'src/pages/members/components/dialogs'
import { useDialog } from '../hooks/useDialog'

type ResourceChargesTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
}

function ResourceChargesTable(props: ResourceChargesTableProps) {
  const { recordId, title, resource, columns, emptyDataMessage } = props
  const tableId = `${resource}-table`

  const onFetchMemberCharges = useAction(fetchAllMemberCharges)
  useEffect(() => {
    recordId && onFetchMemberCharges(recordId)
  }, [recordId, onFetchMemberCharges])

  const currentUserCharges = useSelector(getAllChargesByUser)
  const currentUserChargesError = useSelector(getAllChargesByUserError)
  const currentUserChargesLoading = useSelector(getIsAllChargesByUserLoading)

  const [showAllCharges, setShowAllCharges] = useState(false)

  const addUserChargeDialog = useDialog(EditUserChargesDialog)

  return (
    <TableSectionContainer
      title={title}
      withShowAllToggleButton={currentUserCharges!.length > 10}
      onShowAllClick={() => setShowAllCharges(!showAllCharges)}
      showAll={showAllCharges}
      onButtonClick={() => addUserChargeDialog.show({ user_id: recordId })}
      buttonTitle="Add"
    >
      <PageSectionTableMaxHeight
        id={tableId}
        columns={columns}
        resource={resource}
        errorOnDataLoad={currentUserChargesError}
        isLoading={currentUserChargesLoading}
        data={currentUserCharges}
        sortBy="status"
        sortByDirection={Order.asc}
        emptyDataMessage={emptyDataMessage}
        withPagination={!showAllCharges}
        page={0}
        rowsPerPage={showAllCharges ? 0 : 10}
        showPager={false}
      />
      {addUserChargeDialog.node}
    </TableSectionContainer>
  )
}

export default ResourceChargesTable
