import React, { useRef, useState } from 'react'
import { Resources } from 'src/types/permissions-types'
import ResourceListContainer from 'src/containers/resource-list-container'
import { createRegularSearchFilter } from 'src/containers/resource-list-container/filters'
import { DataCell } from 'src/components/accessible-table/accessible-table-cell'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { MembershipClassDrawer } from './membership-class-drawer'

const membershipClassesColumns: ColumnItem[] = [
  {
    key: 'name',
    title: 'Membership Name',
    fields: ['name'],
    render: DataCell,
    orderByField: 'name',
  },
  {
    key: 'type',
    title: 'Membership Type',
    fields: ['type'],
    render: DataCell,
    orderByField: 'type',
  },
  {
    key: 'coveredProfiles',
    title: 'Max.profiles',
    fields: ['coveredProfiles'],
    render: DataCell,
    orderByField: 'coveredProfiles',
  },
  {
    key: 'coveredDevices',
    title: 'Devices',
    fields: ['coveredDevices'],
    render: DataCell,
    orderByField: 'coveredDevices',
  },
  {
    key: 'features',
    title: 'Features',
    fields: ['features'],
    render: DataCell,
    formatter: (value) => value?.split(',').join(', ') || '',
    orderByField: 'features',
  },
  {
    key: 'allowedClients',
    title: 'Clients',
    fields: ['allowedClients'],
    render: DataCell,
    formatter: (value) => value?.split(',').join(', ') || '',
    orderByField: 'allowedClients',
  },
  {
    key: 'price',
    title: 'Price',
    fields: ['chargeAmount'],
    render: DataCell,
    formatter: (value) => (value ? `$${value}` : ''),
    orderByField: 'chargeAmount',
  },
]

const searchFilter = createRegularSearchFilter(['name'])

function MembershipClassesList() {
  const resourceListRef = useRef<{ fetch: () => void }>()
  const [open, setOpen] = useState(false)
  const [record, setRecord] = useState(null)

  return (
    <>
      <ResourceListContainer
        apiRef={resourceListRef}
        searchFilter={searchFilter}
        searchFilterPlaceholder="Search by class name..."
        columns={membershipClassesColumns}
        withCreate
        withClickableRows
        onRowClick={(data) => {
          setRecord(data)
          setOpen(true)
        }}
        onCreate={() => setOpen(true)}
        resource={Resources.membershipClass}
      />
      <MembershipClassDrawer
        open={open}
        onClose={() => {
          setOpen(false)
          setRecord(null)
        }}
        onReload={() => {
          resourceListRef.current?.fetch()
        }}
        record={record}
      />
    </>
  )
}

export default MembershipClassesList
