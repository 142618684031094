import React from 'react'
import styled from '@emotion/styled'
import { PeopleAltOutlined } from '@mui/icons-material'
import SvgIcon from '@mui/material/SvgIcon'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { mainColor } from 'src/constants/styles-constants'
import { routing } from 'src/boot/routing'

const Heading = styled.h1`
  font-size: 48px;
  font-weight: 400;
  padding-bottom: 44px;
  margin: 0;
`

const Paragraph = styled.p`
  font-size: 20px;
  color: ${mainColor};
  font-weight: 500;
  padding-bottom: 16px;
  margin: 0;
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px;
`

const CardContent = styled.div`
  margin: 12px;
  background: #ebeeff;
  height: 250px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 4px 4px 16px rgba(27, 17, 112, 0.16);
  border-radius: 6px;
`

const CardIcon = styled.div`
  color: ${mainColor};
`

const CardTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const Card = ({ link, icon: Icon, title }: { link: string; icon: typeof SvgIcon; title: string }) => (
  <Link to={link} style={{ textDecoration: 'none', color: 'black' }}>
    <CardContent>
      <CardIcon>
        <Icon sx={{ fontSize: 44 }} />
      </CardIcon>
      <CardTitle>{title}</CardTitle>
    </CardContent>
  </Link>
)

export const Dashboard = () => {
  const { user } = useAuth0()
  return user ? (
    <div>
      <Heading>{user.given_name}, Welcome to Dash</Heading>
      <Paragraph>Most Viewed</Paragraph>
      <Cards>
        <Card link={routing.members.generatePath()} icon={PeopleAltOutlined} title="Members" />
        <Card link={routing.personalTrainers.generatePath()} icon={PeopleAltOutlined} title="Personal Trainers" />
        {/* Remove Concierges functionality from Dash
        <Card link={routing.concierges.generatePath()} icon={PeopleAltOutlined} title="Concierges" /> */}
      </Cards>
    </div>
  ) : null
}
