import styled from '@emotion/styled'
import React, { useMemo, useState } from 'react'
import { Input } from '../../../antd-components'
import { useActionsConfig } from '../useActionsConfig'
import { DialogsController } from '../useDialogsController'
import { useDebouncedCallback } from '../../../hooks/use-debounced-callback'
import { MembersTable, memberBaseColumns, searchFilter } from './members-table'
import { Popover } from 'antd'
import { Resources } from '../../../types/permissions-types'
import ActionsDropdownMenu from '../../../components/actions-dropdown'
import { CloseOutlined } from '@ant-design/icons'

const AdditionalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const SearchInput = styled(Input)`
  height: 32px;
  width: 300px !important;
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
`

const StyledCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;
`

const MembersTableContainer = styled.div`
  width: 50vw;
  height: 70vh;
  margin-top: 12px;

  .MuiPaper-root {
    height: 100%;
    overscroll-behavior: contain;

    > div {
      padding: 0;
    }
  }
`

type MemberAdditionalActionsProps = {
  dialogsController: DialogsController
}

export function MemberAdditionalActions({ dialogsController }: MemberAdditionalActionsProps) {
  const [searchValue, setSearchValue] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')

  const actionsConfig = useActionsConfig(dialogsController)
  const onSearchChangeDebounced = useDebouncedCallback(setSearchValue, 400)
  const filterApiResource = useMemo(
    () => searchFilter(searchValue) as { [key: string]: string } | undefined,
    [searchValue],
  )

  return (
    <AdditionalActionsContainer>
      <Popover
        content={
          <>
            <CloseContainer>
              <StyledCloseOutlined
                onClick={() => {
                  setSearchInputValue('')
                  setSearchValue('')
                }}
              />
            </CloseContainer>
            <MembersTableContainer onScroll={(e) => e.stopPropagation()}>
              <MembersTable
                searchFilter={null}
                searchFilterPlaceholder=""
                columns={memberBaseColumns}
                withRefresh={false}
                filterApiResource={filterApiResource}
                onRowClick={({ id }) => {
                  window.open(`/${Resources.members}/${id}/overview`, '_blank')
                }}
              />
            </MembersTableContainer>
          </>
        }
        placement="leftBottom"
        open={!!searchValue}
      >
        <SearchInput
          value={searchInputValue}
          placeholder="Search"
          onChange={(v) => {
            setSearchInputValue(v)
            onSearchChangeDebounced(v)
          }}
          allowClear
        />
      </Popover>
      <ActionsDropdownMenu actionsConfig={actionsConfig} />
    </AdditionalActionsContainer>
  )
}
