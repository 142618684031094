import React, { memo, ReactElement, useMemo } from 'react'
import { Autocomplete, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { nanoid } from 'nanoid'
import { AddressType, OrderAddress } from 'src/api/services/orders/types'
import { OrderItemLayout } from '../../order-item-layout'
import usStates from './us-states.json'

interface IAddressProps {
  address: OrderAddress
  addressType: AddressType
  onAddressChange?: (value: Partial<OrderAddress>) => void
  onAddressTypeChange?: (value: AddressType) => void
}

const StateInput = ({
  value,
  onChange,
  disabled,
}: {
  value?: string
  onChange: (value: string) => void
  disabled?: boolean
}) => {
  const options = useMemo(() => usStates.map(([id, label]) => ({ id, label })), [])
  return (
    <Autocomplete
      disablePortal
      value={options.find((_) => _.id === value) ?? null}
      options={options}
      onChange={(e, val) => val && onChange(val.id)}
      renderInput={(params) => <TextField {...params} label="State" required />}
      fullWidth
      size="small"
      disabled={disabled}
    />
  )
}

const AddressTypeDropdown = ({
  value,
  onChange,
  disabled,
}: {
  value?: AddressType
  onChange: (value: AddressType) => void
  disabled?: boolean
}) => {
  const id = useMemo(() => nanoid(), [])
  return (
    <FormControl fullWidth size="small">
      <InputLabel id={id}>Address type</InputLabel>
      <Select
        labelId={id}
        label="Address type"
        value={value}
        onChange={(e) => onChange(e.target.value as AddressType)}
        disabled={disabled}
      >
        <MenuItem value="Residence">Residence</MenuItem>
        <MenuItem value="Business">Business</MenuItem>
      </Select>
    </FormControl>
  )
}

export const Address = memo((props: IAddressProps): ReactElement<IAddressProps> => {
  const { address, addressType, onAddressChange, onAddressTypeChange } = props
  const { address1, address2, city, province_code, zip } = address
  return (
    <OrderItemLayout
      title="Shipping Address"
      subTitle={`${address1}, ${address2 ? `${address2}, ` : ''} ${city}, ${province_code} ${zip}`}
    >
      <Grid container rowSpacing={2} spacing={1} sx={{ paddingTop: '20px' }}>
        <Grid item xs={4}>
          <AddressTypeDropdown
            disabled={!onAddressTypeChange}
            value={addressType}
            onChange={(value) => onAddressTypeChange?.(value)}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            disabled={!onAddressChange}
            required
            fullWidth
            size="small"
            label="Address"
            onChange={(ev) => onAddressChange?.({ address1: ev.target.value ?? '' })}
            value={address1 ?? ''}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={!onAddressChange}
            required
            fullWidth
            size="small"
            label="City"
            onChange={(ev) => onAddressChange?.({ city: ev.target.value ?? '' })}
            value={city ?? ''}
          />
        </Grid>
        <Grid item xs={4}>
          <StateInput
            disabled={!onAddressChange}
            value={province_code ?? undefined}
            onChange={(value) => onAddressChange?.({ province_code: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={!onAddressChange}
            required
            fullWidth
            size="small"
            label="ZIP"
            onChange={(ev) => onAddressChange?.({ zip: (ev.target.value ?? '').replace(/\D/g, '') })}
            value={zip ?? ''}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">* Please note: This form does not validate address/code/zip fields.</Typography>
        </Grid>
      </Grid>
    </OrderItemLayout>
  )
})
