import { HttpClient, RequestMethods } from 'src/api/http-client'
import { IOrder, IOrderInfo, IProduct } from './types'
import { IOrderFormItemPart, IOrderFormServiceCode } from 'src/pages/orders/components/order-form/types'

export type UpdateOrderRequestData = {
  orderInfo?: IOrderInfo
  service_codes?: IOrderFormServiceCode[]
  parts?: IOrderFormItemPart[]
}

const ordersApi = (baseUrl: string, httpClient: HttpClient) => ({
  syncProductsWithGoogleDoc: () => httpClient(`${baseUrl}/product/sync`),
  getProducts: (): Promise<IProduct[]> => httpClient(`${baseUrl}/product`),
  getOrders: (params?: { shopify_order_name?: string }): Promise<IOrder[]> =>
    httpClient(`${baseUrl}/v2/order`, { params }),
  getOrderById: (shopify_order_name: string, id: string): Promise<IOrder> => {
    const res = httpClient(`${baseUrl}/v2/order?shopify_order_name=${shopify_order_name}`).then(
      (response: IOrder[]) => {
        return response.filter((order) => String(order.id) === id)[0]
      },
    )
    return res
  },
  getOrdersByEmail: (email: string): Promise<IOrder[]> =>
    httpClient(`${baseUrl}/v2/order?email=${encodeURIComponent(email)}`),
  sendSchedule: (id: number): Promise<IOrder[]> =>
    httpClient(`${baseUrl}/order/${id}/send_schedule_email`, { method: RequestMethods.post }),
  updateOrder: (id: number, data: UpdateOrderRequestData): Promise<IOrder> =>
    httpClient(`${baseUrl}/order/${id}`, { method: RequestMethods.put, body: JSON.stringify(data) }),
  sendOrder: (id: number): Promise<IOrder> =>
    httpClient(`${baseUrl}/order/${id}/send`, { method: RequestMethods.post }),
  request: (path: string, params?: Record<string, any>) => httpClient(`${baseUrl}/${path}`, params),
})

export default ordersApi
