import React, { useEffect } from 'react'
import { Coupon } from '../../constants/billing-v2-contants'
import { Form, Modal } from 'antd'
import ResourceCouponsForm from './resource-coupons-form'

type ResourceCouponsModalProps = {
  selected: Coupon | null
  visible: boolean
  onClose: () => void
  onUpdate: (data: Partial<Coupon>) => void
  onCreate: (data: Partial<Coupon>) => void
}

export default function ResourceCouponsModal({
  visible,
  onClose,
  selected,
  onUpdate,
  onCreate,
}: ResourceCouponsModalProps) {
  const [form] = Form.useForm()

  useEffect(() => {
    visible ? form.setFieldsValue(selected) : form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Coupon Form"
      onOk={() => {
        const data = form.getFieldsValue()
        selected ? onUpdate(data) : onCreate(data)
      }}
      destroyOnClose
    >
      <ResourceCouponsForm form={form} />
    </Modal>
  )
}
