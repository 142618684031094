import React, { useState, useEffect } from 'react'
import ResourceProfilesTable from 'src/containers/resource-profiles-table'
import { Resources } from '../../../types/permissions-types'
import { ColumnItem } from '../../../components/accessible-table/acessible-table-types'
import { EntitiesSubUrls, SubUrlPlacements } from '../../../api/services/entities'
import ResourceEntityTable from '../../../containers/resource-entity-table'
import ResourceMembershipInfoContainer from '../../../containers/ResourceMembershipInfoContainer'
import ResourcePromotionInfoContainer from '../../../containers/resource-promotion-info-container'
import { Member } from '../../../constants/member-constants'
import {
  getIsMemberProfilesLoading,
  getIsMemberTrainersLoading,
  getIsAllChargesByUserLoading,
  getIsMemberBillingScheduleLoading,
} from 'src/redux/member/member-selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchMemberProfiles,
  fetchMemberTrainers,
  fetchMemberBillingSchedule,
  fetchMemberInvites,
} from 'src/redux/member/member-actions'

import ResourceTrainersTable from 'src/containers/resource-trainers-table'
import { useAction } from 'src/hooks/use-actions'
import ResourceBillingScheduleTable from 'src/containers/resource-billing-schedule-info-container'
import ResourceInvitesTable from '../../../containers/resource-invites-table'

type MemberOverviewTabProps = {
  record: Member | null
  promotionsTableColumns: ColumnItem[]
  hardwareTableColumns: ColumnItem[]
  profileTableColumns: ColumnItem[]
  trainerTableColumns: ColumnItem[]
  billingScheduleTableColumns: ColumnItem[]
  personalTrainersTableColumns: ColumnItem[]
  memberInvitesTableColumns: ColumnItem[]
  isPromotionsReadAllowed: boolean
  isPromotionsCreateAllowed: boolean
  isStudiosReadAllowed: boolean
  isProfilesReadAllowed: boolean
  isPersonalTrainersReadAllowed: boolean
  isBillingScheduleReadAllowed: boolean
  showCreatePromotionDialog: () => void
  setIsOverviewLoading: React.Dispatch<React.SetStateAction<boolean>>
}

function MemberOverviewTab(props: MemberOverviewTabProps) {
  const {
    record,
    promotionsTableColumns,
    hardwareTableColumns,
    profileTableColumns,
    trainerTableColumns,
    billingScheduleTableColumns,
    memberInvitesTableColumns,
    isPromotionsReadAllowed,
    isPromotionsCreateAllowed,
    isBillingScheduleReadAllowed,
    showCreatePromotionDialog,
    setIsOverviewLoading,
  } = props

  const isMemberChargesLoading = useSelector(getIsAllChargesByUserLoading)
  const dispatch = useDispatch()
  const [isPromotionsLoading, setIsPromotionsLoading] = useState(false)
  const [isHardwareLoading, setIsHardwareLoading] = useState(false)
  const isPersonalTrainersLoading = useSelector(getIsMemberTrainersLoading)
  const isBillingScheduleLoading = useSelector(getIsMemberBillingScheduleLoading)
  const isProfilesLoading = useSelector(getIsMemberProfilesLoading)

  useEffect(() => {
    record?.id && dispatch(fetchMemberProfiles(record.id))
  }, [record?.id, dispatch])

  const onFetchTrainers = useAction(fetchMemberTrainers)
  useEffect(() => {
    onFetchTrainers(record?.id || '')
  }, [record?.id, onFetchTrainers])

  const onFetchBillingSchedule = useAction(fetchMemberBillingSchedule)
  useEffect(() => {
    onFetchBillingSchedule(record?.id || '')
  }, [record?.id, onFetchBillingSchedule])

  const onFetchInvites = useAction(fetchMemberInvites)
  useEffect(() => {
    onFetchInvites(record?.id || '')
  }, [record?.id, onFetchInvites])

  useEffect(() => {
    setIsOverviewLoading(
      isHardwareLoading ||
        isPromotionsLoading ||
        isProfilesLoading ||
        isPersonalTrainersLoading ||
        isBillingScheduleLoading ||
        isMemberChargesLoading,
    )
  }, [
    isHardwareLoading,
    isPromotionsLoading,
    setIsOverviewLoading,
    isProfilesLoading,
    isPersonalTrainersLoading,
    isBillingScheduleLoading,
    isMemberChargesLoading,
  ])

  return (
    <>
      <ResourceMembershipInfoContainer />

      {isBillingScheduleReadAllowed && (
        <ResourceBillingScheduleTable
          title="Billing Schedule"
          columns={billingScheduleTableColumns}
          recordId={record?.id ?? ''}
          resource={Resources.billingSchedule}
          emptyDataMessage="Not available"
          isLoading={isBillingScheduleLoading}
        />
      )}

      <ResourceProfilesTable
        title="Profiles"
        columns={profileTableColumns}
        recordId={record?.id ?? ''}
        resource={Resources.profile}
        emptyDataMessage="Not available"
        isLoading={isProfilesLoading}
      />

      <ResourceTrainersTable
        title="Assigned Trainers"
        columns={trainerTableColumns}
        recordId={record?.id ?? ''}
        resource={Resources.personalTrainers}
        emptyDataMessage="Not available"
        isLoading={isPersonalTrainersLoading}
      />

      {isPromotionsReadAllowed && (
        <ResourcePromotionInfoContainer
          record={record}
          setIsLoading={setIsPromotionsLoading}
          promotionsTableColumns={promotionsTableColumns}
          isPromotionsCreateAllowed={isPromotionsCreateAllowed}
          showCreatePromotionDialog={showCreatePromotionDialog}
        />
      )}

      <ResourceEntityTable
        setIsLoading={setIsHardwareLoading}
        title="Hardware"
        entitySubUrl={EntitiesSubUrls.byUser}
        subUrlPlacements={SubUrlPlacements.beforeId}
        recordId={record?.id ?? ''}
        resource={Resources.studios}
        columns={hardwareTableColumns}
      />

      <ResourceInvitesTable
        title="Invites"
        columns={memberInvitesTableColumns}
        recordId={record?.id ?? ''}
        resource={Resources.invites}
        emptyDataMessage="Not available"
      />
    </>
  )
}

export default MemberOverviewTab
