export enum InputVariant {
  labeled = 'filled',
  filled = 'filled',
  outlined = 'outlined',
  standard = 'standard',
}

export enum InputSize {
  small = 'small',
  medium = 'medium',
}
