import { ResourceListState } from './resource-list-reducer'
import { RequestError } from '../../api/http-client'
import { RootState } from '../store'

export const getResourceListState = (state: RootState): ResourceListState => state.resourceList

export const getIsResourceListLoading = (state: RootState): boolean => getResourceListState(state).isLoading
export const getResourceListError = (state: RootState): null | RequestError => getResourceListState(state).error
export const getResourceList = (state: RootState): Record<string, any>[] => getResourceListState(state).resourceList
export const getResourceListTotalRows = (state: RootState): number => getResourceListState(state).totalRows
