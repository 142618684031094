import { ABSENT_VALUE_PLACEHOLDER, DataCell, DateCell } from '../../components/accessible-table/accessible-table-cell'
import { ColumnItem } from '../../components/accessible-table/acessible-table-types'

export const membershipsColumns: ColumnItem[] = [
  {
    key: 'business_name',
    title: 'Business Name',
    fields: ['commercial_detail.business_name'],
    render: DataCell,
    orderByField: 'commercial_detail.business_name',
  },
  {
    key: 'business_type',
    title: 'Business Type',
    fields: ['commercial_detail.business_type'],
    render: DataCell,
    orderByField: 'commercial_detail.business_type',
  },
  {
    key: 'email',
    title: 'Email',
    render: DataCell,
    getCellValue: ({ rowData }) => {
      const ownerProfile = rowData.profiles.find(({ role }: { role: string }) => role === 'owner')

      return ownerProfile?.fullUser.email || ABSENT_VALUE_PLACEHOLDER
    },
  },
  {
    key: 'name',
    title: 'Name',
    render: DataCell,
    getCellValue: ({ rowData }) => {
      const ownerProfile = rowData.profiles.find(({ role }: { role: string }) => role === 'owner')
      if (!ownerProfile) return ABSENT_VALUE_PLACEHOLDER

      const { first_name, last_name } = ownerProfile.fullUser

      return `${first_name} ${last_name}`
    },
  },
  {
    key: 'status',
    title: 'Status',
    fields: ['status'],
    render: DataCell,
    orderByField: 'status',
    filter: true,
  },
  {
    key: 'expiry_date',
    title: 'Expiry date',
    fields: ['expiry_date'],
    render: DateCell,
    orderByField: 'expiry_date',
  },
  {
    key: 'guests_count',
    title: 'Guests Count',
    fields: ['profiles'],
    render: DataCell,
    getCellValue: ({ rowData }) => {
      const guestProfiles = rowData.profiles.filter(({ role }: { role: string }) => role === 'guest')
      return guestProfiles.length
    },
  },
]
