import React from 'react'
import { useSelector } from 'react-redux'
import { getMemberProfiles, getMemberProfilesError } from 'src/redux/member/member-selectors'
import { Resources } from '../types/permissions-types'
import { Identifier } from '../types/request-entities-types'
import { ColumnItem } from '../components/accessible-table/acessible-table-types'
import { PageSectionTable } from '../components/ui-kit'
import TableSectionContainer from '../components/table-section-container'

type ResourceProfilesTableProps = {
  recordId: Identifier
  resource: Resources
  columns: ColumnItem[]
  title: string
  emptyDataMessage?: string
  isLoading: boolean
}

function ResourceProfilesTable(props: ResourceProfilesTableProps) {
  const { title, resource, isLoading, columns, emptyDataMessage } = props
  const tableId = `${resource}-table`

  const profiles = useSelector(getMemberProfiles)
  const profilesError = useSelector(getMemberProfilesError)

  return (
    <TableSectionContainer title={title}>
      <PageSectionTable
        id={tableId}
        columns={columns}
        resource={resource}
        errorOnDataLoad={profilesError}
        isLoading={isLoading}
        data={profiles || []}
        sortBy="id"
        emptyDataMessage={emptyDataMessage}
      />
    </TableSectionContainer>
  )
}

export default ResourceProfilesTable
