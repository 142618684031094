import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

type LocalizationContextProviderProps = {
  children: React.ReactNode
}

function LocalizationContextProvider({ children }: LocalizationContextProviderProps) {
  return <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
}

export default LocalizationContextProvider
