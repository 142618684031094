import { API } from '../config'
import commonApi from './services/cloud'
import entitiesApi from './services/entities'
import filesApi from './services/files'
import invoicesApi from './services/invoices'
import ordersApi from './services/orders'
import httpClient from './http-client'
import tpiApi from './services/tpi'
import billingV2 from './services/cloud/billing.v2'
import trainersApi from './services/cloud/trainers/trainersApi'

export default {
  files: filesApi(API.cloud, httpClient),
  common: commonApi(API.cloud, httpClient),
  entities: entitiesApi(API.cloud, httpClient),
  invoices: invoicesApi(API.invoices, httpClient),
  billingV2: billingV2(API.cloud, httpClient),
  orders: ordersApi(API.orders, httpClient),
  tpi: tpiApi(API.tpi, httpClient),
  cloud: {
    trainers: trainersApi(API.cloud, httpClient),
  },
}
