import { CondOperator, QuerySort, RequestQueryBuilder, SCondition } from '@nestjsx/crud-request'

import { Resources } from '../../types/permissions-types'
import { HttpClient, RequestMethods } from '../http-client'
import { getTransformedDataForGetOneApiRequest, transformDataList } from '../../utils/data-transformation-utils'
import { composeSearch, countDiff, mergeEncodedQueries, paramsSerializer } from '../utils'
import {
  CreateParams,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from '../../types/request-entities-types'

const apiV2 = [Resources.membership]

const getResourceUrl = (resource: Resources, baseUrl: string) => {
  const isApiV2 = apiV2.includes(resource)
  const _baseUrl = isApiV2 ? baseUrl.replace('v1', 'v2') : baseUrl
  return `${_baseUrl}/${resource}`
}

const commonApi = (baseUrl: string, httpClient: HttpClient) => ({
  getList: (resource: Resources, params?: Partial<GetListParams>) => {
    const { sort, pagination, join } = params ?? {}
    const { q: queryParams, ...filter } = params?.filter || {}
    const encodedQueryParams = paramsSerializer(queryParams)

    let queryBuilder = RequestQueryBuilder.create(filter ? { search: composeSearch(filter) } : {})
    if (pagination) {
      const { page, perPage } = pagination
      queryBuilder = queryBuilder
        .setLimit(perPage)
        .setPage(page)
        .setOffset((page - 1) * perPage)
    }
    if (sort) {
      queryBuilder = queryBuilder.sortBy(sort as QuerySort)
    }

    if (join) {
      join.forEach((j) => queryBuilder.setJoin([j]))
    }

    const query = mergeEncodedQueries(encodedQueryParams, queryBuilder.query())
    const requestUrl = `${getResourceUrl(resource, baseUrl)}?${query}`
    return httpClient(requestUrl).then((response) => {
      if (pagination) {
        const { data, total } = response
        return { data: transformDataList(resource, data), total }
      }
      return response
    })
  },

  getOne: (resource: Resources, params: GetOneParams & { subUrl?: string }) => {
    const { id, subUrl } = params
    const url = `${getResourceUrl(resource, baseUrl)}/${id}`
    const requestUrl = subUrl ? `${url}/${subUrl}` : url

    return httpClient(requestUrl).then((response) => {
      if (subUrl) return response
      return getTransformedDataForGetOneApiRequest(resource, response)
    })
  },

  getMany: (resource: Resources, params: GetManyParams) => {
    const filterParams = { field: 'id', operator: CondOperator.IN, value: `${params.ids}` }
    const query = RequestQueryBuilder.create().setFilter(filterParams).query()
    const requestUrl = `${getResourceUrl(resource, baseUrl)}?${query}`

    return httpClient(requestUrl)
  },

  getManyReference: (resource: Resources, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination
    const { q: queryParams, ...otherFilters } = params.filter || {}
    const search: SCondition = composeSearch(otherFilters)
    search.$and?.push({ [params.target]: { [CondOperator.EQUALS]: params.id } })

    const encodedQueryParams = paramsSerializer(queryParams)
    const encodedQueryFilter = RequestQueryBuilder.create({ search })
      .sortBy(params.sort as QuerySort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query()

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
    const requestUrl = `${getResourceUrl(resource, baseUrl)}?${query}`

    return httpClient(requestUrl)
  },

  create: async (resource: Resources, params: CreateParams & { id?: string; subUrl?: string }) => {
    const { data, subUrl } = params
    const url = `${getResourceUrl(resource, baseUrl)}`
    const requestUrl = subUrl ? `${url}/${subUrl}` : url

    return httpClient(requestUrl, {
      method: RequestMethods.post,
      body: JSON.stringify(data),
    }).then((response) => ({ data: response }))
  },

  update: (resource: Resources, params: UpdateParams & { subUrl?: string }) => {
    const { id, subUrl } = params
    const url = `${getResourceUrl(resource, baseUrl)}/${id}`
    const requestUrl = subUrl ? `${url}/${subUrl}` : url

    const data = countDiff(params.data, params.previousData)
    return httpClient(requestUrl, {
      method: RequestMethods.patch,
      body: JSON.stringify(data),
    }).then((response) => getTransformedDataForGetOneApiRequest(resource, response))
  },

  updateMany: (resource: Resources, params: UpdateManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${getResourceUrl(resource, baseUrl)}/${id}`, {
          method: RequestMethods.put,
          body: JSON.stringify(params.data),
        }),
      ),
    ),

  delete: (resource: Resources, params: DeleteParams & { subUrl?: string }) => {
    const { id, subUrl } = params
    const url = `${getResourceUrl(resource, baseUrl)}/${id}`
    const requestUrl = subUrl ? `${url}/${subUrl}` : url

    return httpClient(requestUrl, {
      method: RequestMethods.delete,
    }).then((response) => ({ data: { ...response, id } }))
  },

  deleteMany: (resource: Resources, params: DeleteManyParams) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${getResourceUrl(resource, baseUrl)}/${id}`, {
          method: RequestMethods.delete,
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json) })),
})

export default commonApi
