import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  getIsMemberProfilesLoading,
  getMemberProfiles,
  getMemberProfilesError,
  getMemberRecord,
  getMembership,
  getStudios,
} from 'src/redux/member/member-selectors'
import { getUniformDateTime } from 'src/utils/uniformDateDisplay'
import TableSectionContainer from 'src/components/table-section-container'
import { EMPTY_DATA_MAIN_MESSAGE } from 'src/constants/messages'
import { Stack, TableBody, TableHead, TableRow, CircularProgress } from '@mui/material'
import { mainColor } from 'src/constants/styles-constants'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'

import FeaturedVideoRoundedIcon from '@mui/icons-material/FeaturedVideoRounded'
import { createStructuredSelector } from 'reselect'
import Typography from 'antd/lib/typography'
import { CommercialSection } from './CommercialSection'
import { TableCell, Table } from './styles'
import { ContentPlans } from './ContentPlans'
import { Note } from './Note'
import { Status } from './Status'
import { UpcomingCharge } from './UpcomingCharge'
import { ExpiryDateRow } from './ExpiryDateRow'
import { BillingTarget } from 'src/api/services/cloud/memberships/types'

const Container = styled.div`
  margin-bottom: 15px;
`
const HeaderCellContent = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  gap: 8px;
`
const StyledIcon = styled(FeaturedVideoRoundedIcon)`
  width: 30px !important;
  height: 30px !important;
  color: ${mainColor};
`

const MainMessage = styled.span`
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 400;
  text-align: center;
`

const selectProfileState = createStructuredSelector({
  isLoadingProfiles: getIsMemberProfilesLoading,
  errorProfiles: getMemberProfilesError,
  profilesList: getMemberProfiles,
})
const ResourceMembershipInfoContainer = () => {
  const memberRecord = useSelector(getMemberRecord)
  const memberStudios = useSelector(getStudios)
  const membership = useSelector(getMembership)

  const studioIds = useMemo(() => {
    const ids = Object.assign({})
    memberStudios.every((studio, index) => {
      ids[studio?.profile_id || index] = studio.id
    })
    return ids
  }, [memberStudios])

  // TODO: get rid of profiles, they are not relevant for this component (at least now)
  // use only membership from Redux instead
  const { isLoadingProfiles, errorProfiles, profilesList } = useSelector(selectProfileState)
  const activeProfile = profilesList.filter((profile) => profile.userId === memberRecord?.id)[0]

  const membership_id = membership?.id
  const membership_type = membership?.class?.name
  const membership_start_date = membership?.created_date
  const membership_billing_target = membership?.membership_billing?.target
  const membership_billing_sponsor_id = membership?.membership_billing?.sponsor_id
  const membership_billing_external_id = membership?.membership_billing?.external_id

  if (errorProfiles) {
    useRequestErrorHandler(errorProfiles)
  }

  return (
    <Container>
      <TableSectionContainer withDividerThin title="Memberships">
        {!activeProfile && !studioIds?.length && <MainMessage>{EMPTY_DATA_MAIN_MESSAGE}</MainMessage>}
      </TableSectionContainer>
      {activeProfile && membership && (
        <Table key={`membership-${membership_id}`}>
          <TableHead>
            <TableRow>
              <TableCell>
                <HeaderCellContent>
                  <StyledIcon />
                  Membership
                </HeaderCellContent>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Membership ID</TableCell>
              <TableCell>
                <Typography.Text code copyable>
                  {membership_id}
                </Typography.Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>{membership_type}</TableCell>
            </TableRow>
            <Status />
            <TableRow>
              <TableCell>Start</TableCell>
              <TableCell>{membership_start_date ? getUniformDateTime(membership_start_date) : '-'}</TableCell>
            </TableRow>
            <CommercialSection />
            <ExpiryDateRow />
            <ContentPlans />
            <UpcomingCharge />
            <TableRow>
              <TableCell>Billing Target</TableCell>
              <TableCell>{membership_billing_target || 'Self'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sponsor/External ID</TableCell>
              <TableCell>
                <Typography.Text
                  code={
                    membership_billing_target === BillingTarget.sponsored ||
                    membership_billing_target === BillingTarget.external
                  }
                  copyable={
                    membership_billing_target === BillingTarget.sponsored ||
                    membership_billing_target === BillingTarget.external
                  }
                >
                  {membership_billing_target === BillingTarget.sponsored
                    ? membership_billing_sponsor_id
                    : membership_billing_target === BillingTarget.external
                    ? membership_billing_external_id
                    : undefined}
                </Typography.Text>
              </TableCell>
            </TableRow>
            <Note />
          </TableBody>
        </Table>
      )}
      <Stack alignItems="center">
        {isLoadingProfiles && <CircularProgress />}
        {!activeProfile && <div>No membership</div>}
      </Stack>
    </Container>
  )
}

export default ResourceMembershipInfoContainer
