import { Member } from '../../constants/member-constants'
import { RequestError } from '../../api/http-client'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Studio } from '../../api/services/cloud/studios/types'
import { PersonalWorkout } from '../../constants/personal-workout-constants'
import { BillingPromotion } from '../../constants/billing-promotion-constants'
import { MemberProfile } from 'src/types/member-profile'
import { PersonalTrainer } from 'src/constants/personal-trainer-constants'
import { Bill, BillingSchedule, Charge, Coupon } from 'src/constants/billing-v2-contants'
import { MembershipV2 } from '../../api/services/cloud/memberships/types'

export interface MemberState {
  isRecordLoading: boolean
  recordError: null | RequestError
  record: Member | null
  isStudiosLoading: boolean
  studiosError: null | RequestError
  studios: Studio[]
  isIncurredChargesLoading: boolean
  incurredChargesError: null | RequestError
  incurredCharges: Bill | null
  isSessionsLoading: boolean
  sessionsError: null | RequestError
  sessions: PersonalWorkout[]
  isPromotionsLoading: boolean
  promotionsError: null | RequestError
  promotions: BillingPromotion[]
  isProfilesLoading: boolean
  profiles: MemberProfile[]
  profilesError: null | RequestError
  profileMembershipId: null | string
  isTrainersLoading: boolean
  trainers: PersonalTrainer[]
  trainersError: null | RequestError
  charges: Charge[]
  isChargesLoading: boolean
  chargesError: null | RequestError
  isCouponsLoading: boolean
  couponsError: null | RequestError
  coupons: Coupon[]
  estimatedBill: number | null
  isEstimatedBillLoading: boolean
  estimatedBillError: null | RequestError
  isBillingScheduleLoading: boolean
  billingSchedule: BillingSchedule[]
  billingScheduleError: null | RequestError
  isHistoryWorkoutLoading: boolean
  historyWorkout: PersonalWorkout[]
  historyWorkoutError: null | RequestError
  isCPSScheduleLoading: boolean
  cpsSchedule: PersonalWorkout[]
  cpsScheduleError: null | RequestError
  isMemberInvitesLoading: boolean
  memberInvitesError: null | RequestError
  memberInvites: any[]
  membership: MembershipV2 | null
}
export type TPIStateProps = {
  id: string
  workflow_state: string
}
export interface TPIState {
  record: TPIStateProps | null
}

const initialState: MemberState = {
  isRecordLoading: false,
  recordError: null,
  record: null,
  isStudiosLoading: false,
  studiosError: null,
  studios: [],
  isIncurredChargesLoading: false,
  incurredChargesError: null,
  incurredCharges: null,
  isSessionsLoading: false,
  sessionsError: null,
  sessions: [],
  isPromotionsLoading: false,
  promotionsError: null,
  promotions: [],
  isProfilesLoading: false,
  profiles: [],
  profileMembershipId: null,
  profilesError: null,
  isTrainersLoading: false,
  trainers: [],
  trainersError: null,
  isChargesLoading: false,
  charges: [],
  chargesError: null,
  isCouponsLoading: false,
  couponsError: null,
  coupons: [],
  isEstimatedBillLoading: false,
  estimatedBill: null,
  estimatedBillError: null,
  isBillingScheduleLoading: false,
  billingSchedule: [],
  billingScheduleError: null,
  historyWorkout: [],
  isHistoryWorkoutLoading: false,
  historyWorkoutError: null,
  cpsSchedule: [],
  isCPSScheduleLoading: false,
  cpsScheduleError: null,
  memberInvites: [],
  isMemberInvitesLoading: false,
  memberInvitesError: null,
  membership: null,
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    startFetchingAllMemberCharges: (state) => {
      state.isChargesLoading = true
      state.chargesError = null
    },
    setMemberAllCharges: (state, action: PayloadAction<Charge[]>) => {
      state.isChargesLoading = false
      state.chargesError = null
      state.charges = action.payload
    },
    setMemberAllChargesError: (state, action: PayloadAction<RequestError>) => {
      state.chargesError = action.payload
      state.isChargesLoading = false
      state.charges = []
    },
    startFetchingAllMemberCoupons: (state) => {
      state.isCouponsLoading = true
      state.couponsError = null
    },
    setMemberAllCoupons: (state, action: PayloadAction<Coupon[]>) => {
      state.isCouponsLoading = false
      state.couponsError = null
      state.coupons = action.payload
    },
    setMemberAllCouponsError: (state, action: PayloadAction<RequestError>) => {
      state.couponsError = action.payload
      state.isCouponsLoading = false
      state.coupons = []
    },
    startFetchingEstimatedBill: (state) => {
      state.isEstimatedBillLoading = true
      state.chargesError = null
    },
    setEstimatedBill: (state, action: PayloadAction<number>) => {
      state.isEstimatedBillLoading = false
      state.estimatedBillError = null
      state.estimatedBill = action.payload
    },
    setEstimatedBillError: (state, action: PayloadAction<RequestError>) => {
      state.estimatedBillError = action.payload
      state.isEstimatedBillLoading = false
      state.estimatedBill = null
    },
    startFetchingMember: (state) => {
      state.isRecordLoading = true
      state.recordError = null
    },
    setMemberRecord: (state, action: PayloadAction<Member>) => {
      state.isRecordLoading = false
      state.recordError = null
      state.record = action.payload
    },
    setMemberError: (state, action: PayloadAction<RequestError>) => {
      state.recordError = action.payload
      state.isRecordLoading = false
      state.record = null
    },
    startFetchingStudios: (state) => {
      state.isStudiosLoading = true
      state.studiosError = null
    },
    setStudios: (state, action: PayloadAction<Studio[]>) => {
      state.isStudiosLoading = false
      state.studiosError = null
      state.studios = action.payload
    },
    setStudiosError: (state, action: PayloadAction<RequestError>) => {
      state.studiosError = action.payload
      state.isStudiosLoading = false
      state.studios = []
    },
    startFetchingIncurredCharges: (state) => {
      state.isIncurredChargesLoading = true
      state.incurredChargesError = null
    },
    setIncurredCharges: (state, action: PayloadAction<Bill>) => {
      state.isIncurredChargesLoading = false
      state.incurredChargesError = null
      state.incurredCharges = action.payload
    },
    setIncurredChargesError: (state, action: PayloadAction<RequestError>) => {
      state.incurredChargesError = action.payload
      state.isIncurredChargesLoading = false
      state.incurredCharges = null
    },
    startFetchingSessions: (state) => {
      state.isSessionsLoading = true
      state.sessionsError = null
    },
    setSessions: (state, action: PayloadAction<PersonalWorkout[]>) => {
      state.isSessionsLoading = false
      state.sessionsError = null
      state.sessions = action.payload
    },
    setSessionsError: (state, action: PayloadAction<RequestError>) => {
      state.sessionsError = action.payload
      state.isSessionsLoading = false
      state.sessions = []
    },
    startFetchingPromotions: (state) => {
      state.isPromotionsLoading = true
      state.promotionsError = null
    },
    setPromotions: (state, action: PayloadAction<BillingPromotion[]>) => {
      state.isPromotionsLoading = false
      state.promotionsError = null
      state.promotions = action.payload
    },
    setPromotionsError: (state, action: PayloadAction<RequestError>) => {
      state.promotionsError = action.payload
      state.isPromotionsLoading = false
      state.promotions = []
    },
    startFetchingProfiles: (state) => {
      state.isProfilesLoading = true
    },
    setProfiles: (state, action: PayloadAction<MemberProfile[]>) => {
      state.isProfilesLoading = false
      state.profilesError = null
      state.profiles = action.payload
    },
    setProfileMembershipId: (state, action: PayloadAction<string | null>) => {
      state.profileMembershipId = action.payload
    },
    setProfilesError: (state, action: PayloadAction<RequestError>) => {
      state.profilesError = action.payload
      state.isProfilesLoading = false
      state.profiles = []
    },
    startFetchingTrainers: (state) => {
      state.isTrainersLoading = true
    },
    setTrainers: (state, action: PayloadAction<PersonalTrainer[]>) => {
      state.isTrainersLoading = false
      state.trainersError = null
      state.trainers = action.payload
    },
    setTrainersError: (state, action: PayloadAction<RequestError>) => {
      state.trainersError = action.payload
      state.isTrainersLoading = false
      state.trainers = []
    },
    startFetchingBillingSchedule: (state) => {
      state.isBillingScheduleLoading = true
    },
    setBillingSchedule: (state, action: PayloadAction<BillingSchedule[]>) => {
      state.isBillingScheduleLoading = false
      state.billingScheduleError = null
      state.billingSchedule = action.payload
    },
    setBillingScheduleError: (state, action: PayloadAction<RequestError>) => {
      state.billingScheduleError = action.payload
      state.isBillingScheduleLoading = false
      state.billingSchedule = []
    },
    startFetchingHistoryWorkout: (state) => {
      state.isHistoryWorkoutLoading = true
    },
    setHistoryWorkout: (state, action: PayloadAction<any[]>) => {
      state.isHistoryWorkoutLoading = false
      state.historyWorkoutError = null
      state.historyWorkout = action.payload
    },
    setHistoryWorkoutError: (state, action: PayloadAction<RequestError>) => {
      state.historyWorkoutError = action.payload
      state.isHistoryWorkoutLoading = false
      state.historyWorkout = []
    },
    startFetchingCPSSchedule: (state) => {
      state.isHistoryWorkoutLoading = true
    },
    setCPSSchedule: (state, action: PayloadAction<any[]>) => {
      state.isCPSScheduleLoading = false
      state.cpsScheduleError = null
      state.cpsSchedule = action.payload
    },
    setCPSScheduleError: (state, action: PayloadAction<RequestError>) => {
      state.cpsScheduleError = action.payload
      state.isCPSScheduleLoading = false
      state.cpsSchedule = []
    },
    startFetchingMemberInvites: (state) => {
      state.isMemberInvitesLoading = true
    },
    setMemberInvites: (state, action: PayloadAction<any[]>) => {
      state.memberInvites = action.payload
      state.isMemberInvitesLoading = false
      state.memberInvitesError = null
    },
    setMemberInvitesError: (state, action: PayloadAction<any>) => {
      state.memberInvites = []
      state.isMemberInvitesLoading = false
      state.memberInvitesError = action.payload
    },
    setMembership: (state, action: PayloadAction<MembershipV2>) => {
      state.membership = action.payload
    },
    clearRecordData: (state) => {
      state.isRecordLoading = false
      state.recordError = null
      state.record = null
      state.isStudiosLoading = false
      state.studiosError = null
      state.studios = []
      state.isIncurredChargesLoading = false
      state.incurredChargesError = null
      state.incurredCharges = null
      state.isSessionsLoading = false
      state.sessionsError = null
      state.sessions = []
      state.isPromotionsLoading = false
      state.promotionsError = null
      state.promotions = []
      state.isProfilesLoading = false
      state.profiles = []
      state.profilesError = null
      state.profileMembershipId = null
      state.trainersError = null
      state.isTrainersLoading = false
      state.trainers = []
      state.charges = []
      state.chargesError = null
      state.isChargesLoading = false
      state.billingSchedule = []
      state.isBillingScheduleLoading = false
      state.billingScheduleError = null
      state.historyWorkoutError = null
      state.isHistoryWorkoutLoading = false
      state.historyWorkout = []
      state.cpsScheduleError = null
      state.isCPSScheduleLoading = false
      state.cpsSchedule = []
      state.membership = null
    },
  },
})

export const {
  startFetchingMember,
  setMemberRecord,
  setMemberError,
  startFetchingStudios,
  setStudios,
  setStudiosError,
  startFetchingIncurredCharges,
  setIncurredCharges,
  setIncurredChargesError,
  startFetchingSessions,
  setSessions,
  setSessionsError,
  clearRecordData,
  startFetchingPromotions,
  setPromotions,
  setPromotionsError,
  startFetchingProfiles,
  setProfiles,
  setProfilesError,
  setProfileMembershipId,
  setTrainers,
  setTrainersError,
  startFetchingTrainers,
  setMemberAllCharges,
  setMemberAllChargesError,
  startFetchingAllMemberCharges,
  startFetchingAllMemberCoupons,
  setMemberAllCoupons,
  setMemberAllCouponsError,
  setEstimatedBill,
  setEstimatedBillError,
  startFetchingEstimatedBill,
  setBillingSchedule,
  setBillingScheduleError,
  startFetchingBillingSchedule,
  setHistoryWorkout,
  setHistoryWorkoutError,
  startFetchingHistoryWorkout,
  setCPSSchedule,
  setCPSScheduleError,
  startFetchingCPSSchedule,
  startFetchingMemberInvites,
  setMemberInvites,
  setMemberInvitesError,
  setMembership,
} = memberSlice.actions

export default memberSlice.reducer
