import React, { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'

import api from '../../../api'
import { Member } from '../../../constants/member-constants'
import { Resources } from '../../../types/permissions-types'
import { useAction } from '../../../hooks/use-actions'
import { BillingGroup } from '../../../types/billing-groups'
import { RequestError } from '../../../api/http-client'
import { getFieldDataQa } from '../../../utils/entities-data-qa-utils'
import { getErrorMessage } from '../../../utils/errors'
import { PersonalTrainer, PersonalTrainerAssignedMembers } from '../../../constants/personal-trainer-constants'
import { setAssignedMembers } from '../../../redux/personal-trainer/personal-trainer-reducer'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import { FormeDialog } from '../../../hooks/useDialog'
import {
  combineSearchFilters,
  createFullNameSearchFilter,
  createRegularSearchFilter,
} from '../../../containers/resource-list-container/filters'
import { Modal, Search, Select, Table, ColumnsType } from 'src/antd-components'
import { RowSelectionType } from 'antd/lib/table/interface'

type AssignClientToPersonalTrainer = {
  record?: PersonalTrainer
  billingGroups: BillingGroup[]
  defaultBillingGroupId: string
  personalTrainerId: string
  assignedMembers: PersonalTrainerAssignedMembers[]
}

const StyledLabel = styled.h6`
  margin: 0;
  padding-bottom: 15px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
`

const Section = styled.div`
  padding-bottom: 60px;
`

const StyledTableWrapp = styled.div`
  margin-top: 15px;
`

const scroll = { y: 480 }

const clientsTableColumns: ColumnsType<Member> = [
  {
    key: 'name',
    title: 'Name',
    render: (_, member) => `${member.first_name} ${member.last_name}`,
    sorter: (first, second) => first.first_name.localeCompare(second.first_name),
  },
  {
    key: 'email',
    title: 'Email',
    dataIndex: 'email',
    sorter: (first, second) => first.email.localeCompare(second.email),
  },
]

const searchFilter = combineSearchFilters(
  createFullNameSearchFilter('first_name', 'last_name'),
  createRegularSearchFilter(['first_name', 'last_name', 'email']),
)

const AssignClientToPersonalTrainerDialog: FormeDialog<AssignClientToPersonalTrainer> = (props) => {
  const {
    params: { personalTrainerId, defaultBillingGroupId, billingGroups, assignedMembers },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)

  const onUpdateAssignedMembersRecord = useAction(setAssignedMembers)

  const billingGroupsChoices = billingGroups.map((item) => {
    const { id, name, rate_30m, rate_60m } = item
    const isDefaultGroup = id === defaultBillingGroupId
    const commonLabel = `${name} ($${rate_30m} / $${rate_60m})`
    const label = `${commonLabel}${isDefaultGroup ? ' (Default)' : ''}`
    return { value: id, label }
  })

  const [searchInputValue, setSearchInput] = useState('')
  const [clientsList, setClientsList] = useState<Member[]>([])
  const [selectedClientId, setClientId] = useState<string>('')
  const [billingGroupId, setBillingGroupId] = useState(defaultBillingGroupId)
  const [loading, setLoading] = useState(false)

  const rowSelection = useMemo(
    () => ({
      type: 'radio' as RowSelectionType,
      onSelect: ({ id }: Member) => setClientId(id),
    }),
    [],
  )
  const dataSource = useMemo(() => clientsList.map((client) => ({ ...client, key: client.id })), [clientsList])

  const selectedBillingGroup = billingGroups.find(({ id }) => id === billingGroupId)
  const isFormDataValid = selectedClientId && billingGroupId

  const clearFormData = () => {
    setSearchInput('')
    setClientId('')
    setBillingGroupId(defaultBillingGroupId)
  }

  useEffect(() => {
    if (isEditAllowed) {
      api.common
        .getList(Resources.members, {
          pagination: { page: 1, perPage: 100 },
          filter: searchFilter(searchInputValue),
          sort: { field: 'email', order: 'ASC' },
        })
        .then(({ data: clients }: any) => setClientsList(clients))
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    }
  }, [isEditAllowed, searchInputValue, onDisplayErrorNotification])

  useEffect(() => {
    setBillingGroupId(defaultBillingGroupId)
  }, [defaultBillingGroupId])

  if (!isEditAllowed) {
    return null
  }

  const onAssignClient = () => {
    const params = {
      data: { user_id: selectedClientId, billing_group_id: billingGroupId },
      subUrl: `${personalTrainerId}/clients/${selectedClientId}/${billingGroupId}`,
    }

    setLoading(true)

    api.common
      .create(Resources.personalTrainers, params)
      .then((response) => {
        const newClient = {
          user_id: response.data?.id,
          first_name: response.data?.first_name || '',
          last_name: response.data?.last_name || '',
          email: response.data?.email || '',
          personal_trainer_billing_group_id: selectedBillingGroup?.id || null,
          billing_group_name: selectedBillingGroup?.name || null,
          assigned_date: new Date(),
        }
        onUpdateAssignedMembersRecord([...assignedMembers, newClient])
        onDisplaySuccessNotification('Client was successfully assigned')
        clearFormData()
        onClose()
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      title="Assign Member"
      cancelText="Cancel"
      okText="Assign"
      open={isOpened}
      okButtonProps={{ disabled: !isFormDataValid || loading, loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={onAssignClient}
      onCancel={() => {
        if (loading) return

        clearFormData()
        onClose()
      }}
      width={700}
    >
      <Section>
        <StyledLabel>Billing Group</StyledLabel>
        <Select
          id={getFieldDataQa(Resources.personalTrainers, 'select/billingGroup')}
          value={billingGroupId}
          placeholder="Most of My Clients (Default)"
          options={billingGroupsChoices}
          onChange={setBillingGroupId}
        />
      </Section>

      <StyledLabel>Member List</StyledLabel>
      <Search
        id="searchInput"
        allowClear
        placeholder="Search member by name or email..."
        value={searchInputValue}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <StyledTableWrapp>
        <Table<Member>
          id="clientsList"
          rowSelection={rowSelection}
          columns={clientsTableColumns}
          dataSource={dataSource}
          pagination={false}
          size="middle"
          scroll={scroll}
        />
      </StyledTableWrapp>
    </Modal>
  )
}

export default AssignClientToPersonalTrainerDialog
