import React from 'react'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Resources } from '../../../types/permissions-types'
import { PageSectionHeader, PageTitle } from '../../../components/ui-kit'
import { getFieldDataQa } from '../../../utils/entities-data-qa-utils'
import { getFormattedDate } from '../../../utils/date-utils'
import TextInput from '../../../components/inputs/text-input'
import TableSectionContainer from '../../../components/table-section-container'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import { getUniformDateTime } from '../../../utils/uniformDateDisplay'
import { InputVariant } from 'src/components/inputs/inputs-types'
import Avatar from 'antd/lib/avatar'
import { getStripeLink, getAuth0Link } from 'src/config'

type MemberSideSectionProps = {
  resource: Resources
  record: Record<string, any>
  onOpenEditMediaDialog: () => void
  onOpenEditMemberDetailsDialog: () => void
  onOpenEditEmergencyInformationDialog: () => void
}

const Divider = styled.div`
  margin-bottom: 25px;
  border-bottom: solid 1px #e7e6e6;
  padding-bottom: 10px;
`

const StyledTextInput = styled(TextInput)`
  > .MuiInputBase-root {
    margin-bottom: 7px;
  }
  display: isPersonalDataReadAllowed ? block : none
`

function MemberSideSection(props: MemberSideSectionProps) {
  const {
    record,
    record: {
      emergency_address,
      emergency_contact_first_name,
      emergency_contact_last_name,
      emergency_contact_phone,
      emergency_contact_relationship,
    },
    onOpenEditMediaDialog,
    onOpenEditMemberDetailsDialog,
    onOpenEditEmergencyInformationDialog,
  } = props

  const { isEditAllowed } = useResourcePermissionHandler(Resources.members)
  const { isReadAllowed: isEmergencyContactReadAllowed, isEditAllowed: isEmergencyContactEditAllowed } =
    useResourcePermissionHandler(Resources.memberEmergencyInfo)

  const phoneFormatted =
    emergency_contact_phone.length === 10
      ? `(${emergency_contact_phone.slice(0, 3)}) ${emergency_contact_phone.slice(
          3,
          6,
        )}-${emergency_contact_phone.slice(6)}`
      : emergency_contact_phone
  const relationshipFormatted = emergency_contact_relationship
    ? `${emergency_contact_relationship[0].toUpperCase()}${emergency_contact_relationship.slice(1)}`
    : emergency_contact_relationship
  const dateFormatted = getFormattedDate(record.created_date, 'en-US', { year: 'numeric', month: 'long' })

  const { isReadAllowed: isPersonalDataReadAllowed } = useResourcePermissionHandler(Resources.personalData)

  return (
    <Grid item xs={3}>
      <PageSectionHeader>
        <PageTitle data-qa={getFieldDataQa(Resources.members, 'email')}>{record.email}</PageTitle>
      </PageSectionHeader>
      <TableSectionContainer
        withDivider
        title="Member Details"
        buttonTitle={isEditAllowed ? 'Edit' : undefined}
        onButtonClick={onOpenEditMemberDetailsDialog}
      >
        <StyledTextInput
          readOnly
          variant={InputVariant.labeled}
          label="Name"
          value={`${record.first_name} ${record.last_name}`}
          id={getFieldDataQa(Resources.members, 'name')}
        />
        <StyledTextInput topLabel="Email" value={record.email} id="email" copyable variant={InputVariant.standard} />
        <StyledTextInput
          topLabel="ID"
          value={record.id}
          id="id"
          copyable
          link={`${getAuth0Link}users/${record.id}`}
          variant={InputVariant.standard}
        />
        {record?.stripe_customer_id && (
          <StyledTextInput
            topLabel="Stripe ID"
            value={record.stripe_customer_id}
            id="stripe ID"
            copyable
            link={`${getStripeLink}${record.stripe_customer_id}`}
            variant={InputVariant.standard}
          />
        )}
        <StyledTextInput
          readOnly
          variant={InputVariant.standard}
          label="Agora ID"
          value={record?.agora_int_uid ?? ''}
          id={getFieldDataQa(Resources.members, 'agora_int_uid')}
        />
        {isPersonalDataReadAllowed && (
          <>
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Height"
              value={record.height ? `${record.height}in.` : 'N/A'}
              id={getFieldDataQa(Resources.members, 'height')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Weight"
              value={record.weight ? `${record.weight}lbs.` : 'N/A'}
              id={getFieldDataQa(Resources.members, 'weight')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Gender"
              value={record.gender || ''}
              id={getFieldDataQa(Resources.members, 'gender')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Date Of Birth"
              value={record.date_of_birth ? record.date_of_birth : 'N/A'}
              id={getFieldDataQa(Resources.members, 'date_of_birth')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Zip Code"
              value={record.zip_code || 'N/A'}
              id={getFieldDataQa(Resources.members, 'zip_code')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Timezone"
              value={record.timezone || 'N/A'}
              id={getFieldDataQa(Resources.members, 'timezone')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Member Since"
              value={dateFormatted || 'N/A'}
              id={getFieldDataQa(Resources.members, 'created_date')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Created"
              id={getFieldDataQa(Resources.members, 'created_date')}
              value={record?.created_date ? getUniformDateTime(record.created_date) : 'N/A'}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Updated"
              id={getFieldDataQa(Resources.members, 'updated_date')}
              value={record?.updated_date ? getUniformDateTime(record.updated_date) : 'N/A'}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Roles"
              value={record?.roles?.join(', ') || ''}
              id={getFieldDataQa(Resources.members, 'roles')}
            />
            <StyledTextInput
              readOnly
              variant={InputVariant.standard}
              label="Phone"
              value={record.phone || 'N/A'}
              id={getFieldDataQa(Resources.members, 'phone')}
            />
          </>
        )}
      </TableSectionContainer>
      <TableSectionContainer
        withDivider
        title="Headshot"
        buttonTitle="Edit"
        withAction={isEditAllowed}
        onButtonClick={onOpenEditMediaDialog}
      >
        <Avatar
          alt={`${record.first_name} ${record.last_name} avatar`}
          src={record.image}
          shape="circle"
          style={{ width: 250, height: 250 }}
        >
          {record.first_name?.[0]?.toUpperCase()}
          {record.last_name?.[0]?.toUpperCase()}
        </Avatar>
      </TableSectionContainer>
      {isEmergencyContactReadAllowed && (
        <TableSectionContainer
          withDivider
          title="Emergency Information"
          buttonTitle={isEmergencyContactEditAllowed ? 'Edit' : undefined}
          onButtonClick={onOpenEditEmergencyInformationDialog}
        >
          <StyledTextInput
            readOnly
            variant={InputVariant.standard}
            label="Member Address"
            value={emergency_address || 'N/A'}
            id={getFieldDataQa(Resources.members, 'emergency_address')}
          />
          <Divider />
          <StyledTextInput
            readOnly
            variant={InputVariant.standard}
            label="Emergency Contact"
            value={`${emergency_contact_first_name} ${emergency_contact_last_name}`.trim() || 'N/A'}
            id={getFieldDataQa(Resources.members, 'emergency_contact_name')}
          />
          <StyledTextInput
            readOnly
            variant={InputVariant.standard}
            label="Relationship"
            value={relationshipFormatted || 'N/A'}
            id={getFieldDataQa(Resources.members, 'emergency_contact_relationship')}
          />
          <StyledTextInput
            readOnly
            variant={InputVariant.standard}
            label="Phone Number"
            value={phoneFormatted || 'N/A'}
            id={getFieldDataQa(Resources.members, 'emergency_contact_phone')}
          />
        </TableSectionContainer>
      )}
    </Grid>
  )
}

export default MemberSideSection
