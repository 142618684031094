import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { Query } from 'src/hooks/use-request'
import { routing } from 'src/boot/routing'
import styled from '@emotion/styled'
import FeaturedVideoRoundedIcon from '@mui/icons-material/FeaturedVideoRounded'
import { titleCellBackground, mainColor } from 'src/constants/styles-constants'
import { MembershipProfile } from 'src/types/member-profile'

const StyledTableCell = styled(TableCell)`
  &:first-of-type {
    width: 250px;
    background-color: ${titleCellBackground};
  }
`

const HeaderCellContent = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
  gap: 8px;
`

const StyledIcon = styled(FeaturedVideoRoundedIcon)`
  width: 30px !important;
  height: 30px !important;
  color: ${mainColor};
`

export const MembershipCardContent = ({
  studioDataIsLoading,
  membershipQuery,
}: {
  studioDataIsLoading: boolean
  membershipQuery: Query<[id: string], MembershipProfile>
}) => {
  const { data, isLoading } = membershipQuery
  const memTypeV2 = useMemo(
    () => ({
      membershipType: data?.membership?.class?.name || '-',
      membershipStatus: data?.membership?.status,
    }),
    [data],
  )

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <HeaderCellContent>
                <StyledIcon />
                Membership
              </HeaderCellContent>
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.fullUser && (
              <TableRow>
                <StyledTableCell>{data.owner ? 'Owner' : 'Guest Proifle'}</StyledTableCell>
                <StyledTableCell>
                  <Link to={routing.member.generatePath({ id: data.fullUser.id })}>
                    {data.fullUser.first_name} {data.fullUser.last_name}
                  </Link>
                </StyledTableCell>
              </TableRow>
            )}
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell>{data.id}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>{memTypeV2.membershipType}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>{memTypeV2.membershipStatus}</StyledTableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Stack alignItems="center" style={{ paddingTop: 20 }}>
        {(studioDataIsLoading || isLoading) && <CircularProgress />}
        {!data && <div>No membership</div>}
      </Stack>
    </div>
  )
}
