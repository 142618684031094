import React, { useCallback, useMemo, useState } from 'react'

import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { useAction } from 'src/hooks/use-actions'
import { setMemberRecord } from 'src/redux/member/member-reducer'
import { Resources } from 'src/types/permissions-types'
import { FormeDialog } from 'src/hooks/useDialog'
import { Member } from 'src/constants/member-constants'
import api from 'src/api'

import EditEntityMediaDialog from 'src/components/dialogs/edit-entity-media-dialog'

export const EditMemberMediaDialog: FormeDialog<{ record: Member }> = ({ params: { record }, open, onClose }) => {
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const onUpdateMemberRecord = useAction(setMemberRecord)
  const [currentMemberRecord, setCurrentMemberRecord] = useState<Member | null>(record ?? null)

  const getMemberRecord = useCallback(() => {
    if (!record.id) return
    return api.common
      .getOne(Resources.members, { id: record.id })
      .catch(() => onDisplayErrorNotification('Sorry, something went wrong, please reload the page'))
  }, [record.id, onDisplayErrorNotification])

  const onUploadFileCallBack = useCallback(async () => {
    const { data: nextMemberRecord } = await getMemberRecord()
    onUpdateMemberRecord(nextMemberRecord)
    setCurrentMemberRecord(nextMemberRecord)
  }, [getMemberRecord, onUpdateMemberRecord])

  const onDeleteFileCallBack = useCallback(() => {
    const newRecord = { ...record, image: null }
    onUpdateMemberRecord(newRecord)
    setCurrentMemberRecord(newRecord)
  }, [record, onUpdateMemberRecord])

  const params = useMemo(
    () => ({
      title: 'Edit Member Media',
      resource: Resources.members,
      resourceItemId: record.id,
      avatar: currentMemberRecord?.image,
      onDeleteFileCallBack,
      onUploadFileCallBack,
    }),
    [record, currentMemberRecord, onDeleteFileCallBack, onUploadFileCallBack],
  )

  return <EditEntityMediaDialog params={params} open={open} onClose={onClose} />
}
