import { createTheme, Theme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'

import { mainColor, secondaryColor } from '../constants/styles-constants'

const theme: ThemeOptions = {
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
  },
}

export const lightTheme: Theme = createTheme({
  ...theme,
  palette: {
    mode: 'light',
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      paper: secondaryColor,
    },
    text: {
      primary: '#000',
    },
  },
})

export const darkTheme: Theme = createTheme({
  ...theme,
  palette: {
    mode: 'dark',
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
})
