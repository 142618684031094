export enum MembershipType {
  b2c = 'b2c',
  b2b = 'b2b',
  internal = 'internal',
  retail = 'retail',
}

export enum MembershipStatus {
  future = 'future',
  active = 'active',
  willExpire = 'will_expire',
  expired = 'expired',
  paused = 'paused',
  suspended = 'suspended',
}

export enum MembershipBillingCadences {
  month = 'monthly',
  triannual = 'triannual',
  annual = 'annual',
  never = 'never',
}

export const statusChoicesList = [
  { id: MembershipStatus.future, name: 'Future' },
  { id: MembershipStatus.active, name: 'Active' },
  { id: MembershipStatus.willExpire, name: 'Will expire' },
  { id: MembershipStatus.expired, name: 'Expired' },
]

export const categoryChoicesList = [
  { id: MembershipType.b2c, name: 'B2C' },
  { id: MembershipType.b2b, name: 'B2B' },
  { id: MembershipType.internal, name: 'Internal' },
  { id: MembershipType.retail, name: 'Retail' },
]

export const membershipFieldsAllowedForEdit = [
  'id',
  'category',
  'membership_type',
  'membership_status',
  'created_date',
  'start_date',
  'valid_through_date',
  'seven_digital_expiry_date',
  'studios',
]

export enum MebeshipContentFeatureType {
  tpi = 'tpi',
}

export const ContentFeatureNames = {
  [MebeshipContentFeatureType.tpi]: 'TPI',
}
