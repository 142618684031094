import React, { ReactNode } from 'react'

import api from '../../api'
import { Resources } from '../../types/permissions-types'
import { useAction } from '../../hooks/use-actions'
import { RequestError } from '../../api/http-client'
import { PersonalTrainer, PersonalTrainerAssignedMembers } from '../../constants/personal-trainer-constants'
import { getErrorMessage } from '../../utils/errors'
import { setAssignedMembers } from '../../redux/personal-trainer/personal-trainer-reducer'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { PersonalWorkout } from '../../constants/personal-workout-constants'
import { useDialog } from '../../hooks/useDialog'
import AssignClientToPersonalTrainerDialog from './components/assign-client-to-personal-trainer-dialog'
import EditPersonalTrainerDetailsDialog, { PayRates } from './components/edit-personal-trainer-details-dialog'
import CreatePersonalTrainerBillingGroupDialog from './components/CreatePersonalTrainerBillingGroupDialog'
import { EditTrainerMediaDialog } from './components/EditTrainerMediaDialog'
import EditAssignedClientBillingGroupDialog from './components/edit-assigned-client-billing-group-dialog'
import EditTrainerSessionDetailsDialog from './components/edit-trainer-session-details'
import EditPersonalTrainerSpecsDialog from './components/edit-personal-trainer-specs-dialog'

import ConfirmationDialog from '../../components/dialogs/confirmation-dialog'

export type DialogsController = {
  dialogNodes: ReactNode
  onOpenEditTrainerMediaDialog: (isHeadImage: boolean) => void
  onOpenCreatePersonalTrainerBillingGroup: () => void
  onOpenEditPersonalTrainerDetailsDialog: () => void
  onOpenAssignMemberDialog: () => void
  onEditAssignedClient: (rowData: { [key: string]: any }) => void
  onOpenEditSessionDialog: (rowData: { [key: string]: any }) => void
  onOpenRemoveAssignedMemberDialog: (data: { [key: string]: any }) => void
  onOpenEditPersonalTrainerSpecsDialog: () => void
}

export const useDialogsController = (
  record: PersonalTrainer | null,
  assignedMembers: PersonalTrainerAssignedMembers[],
  payRates: PayRates,
  requestRatesUpdate: (id: string) => void,
): DialogsController => {
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const onUpdateAssignedMembersRecord = useAction(setAssignedMembers)

  const assignClientToPersonalTrainerDialog = useDialog(AssignClientToPersonalTrainerDialog)
  const editPersonalTrainerDetailsDialog = useDialog(EditPersonalTrainerDetailsDialog)
  const editPersonalTrainerSpecsDialog = useDialog(EditPersonalTrainerSpecsDialog)
  const createPersonalTrainerBillingGroupDialog = useDialog(CreatePersonalTrainerBillingGroupDialog)
  const editTrainerMediaDialog = useDialog(EditTrainerMediaDialog)
  const editAssignedClientBillingGroupDialog = useDialog(EditAssignedClientBillingGroupDialog)
  const editTrainerSessionDetailsDialog = useDialog(EditTrainerSessionDetailsDialog)
  const confirmationDialog = useDialog(ConfirmationDialog)

  const dialogNodes = (
    <>
      {assignClientToPersonalTrainerDialog.node}
      {editPersonalTrainerDetailsDialog.node}
      {editPersonalTrainerSpecsDialog.node}
      {createPersonalTrainerBillingGroupDialog.node}
      {editTrainerMediaDialog.node}
      {editAssignedClientBillingGroupDialog.node}
      {editTrainerSessionDetailsDialog.node}
      {confirmationDialog.node}
    </>
  )

  const onOpenEditTrainerMediaDialog = (isHeadImage: boolean) => {
    if (record) {
      editTrainerMediaDialog.show({ record, isHeadImage })
    }
  }

  const onOpenCreatePersonalTrainerBillingGroup = () => {
    if (record) {
      createPersonalTrainerBillingGroupDialog.show({ record })
    }
  }

  const onOpenEditPersonalTrainerDetailsDialog = () => {
    if (record) {
      editPersonalTrainerDetailsDialog.show({
        record,
        payRates,
        onSuccess: requestRatesUpdate,
      })
    }
  }

  const onOpenEditPersonalTrainerSpecsDialog = () => {
    if (record) {
      editPersonalTrainerSpecsDialog.show({ record })
    }
  }

  const onOpenAssignMemberDialog = () => {
    const billingGroups = record?.billing_groups ?? []
    if (!billingGroups.length)
      return onDisplayErrorNotification(
        'You must create a billing group before assigning a member to a personal trainer.',
      )
    if (record) {
      assignClientToPersonalTrainerDialog.show({
        record,
        assignedMembers,
        personalTrainerId: record.id,
        billingGroups: record.billing_groups,
        defaultBillingGroupId: record.default_billing_group_id || '',
      })
    }
  }

  const onEditAssignedClient = (rowData: { [key: string]: any }) => {
    if (!record) return
    editAssignedClientBillingGroupDialog.show({
      record,
      assignedMembers,
      personalTrainerId: record.id,
      assignedClient: rowData as PersonalTrainerAssignedMembers,
      billingGroups: record.billing_groups,
    })
  }

  const onOpenEditSessionDialog = (rowData: { [key: string]: any }) => {
    if (!record) return
    editTrainerSessionDetailsDialog.show({
      record,
      session: rowData as PersonalWorkout,
    })
  }

  const onRemoveAssignedClient = (assignedClientToRemove: { [key: string]: any }) => {
    if (!record || !assignedClientToRemove) return
    const { user_id: clientId } = assignedClientToRemove
    const params = { id: record.id, subUrl: `clients/${clientId}`, previousData: { id: clientId } }

    api.common
      .delete(Resources.personalTrainers, params)
      .then(() => {
        const nextRecord = assignedMembers.filter((item) => item.user_id !== clientId)
        onUpdateAssignedMembersRecord(nextRecord)
        onDisplaySuccessNotification('Assigned client was successfully removed')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
    confirmationDialog.hide()
  }

  const onOpenRemoveAssignedMemberDialog = (data: { [key: string]: any }) =>
    confirmationDialog.show({
      title: 'Remove assigned member',
      children: 'Are you sure you want to delete this assigned member?',
      onOk: () => onRemoveAssignedClient(data),
    })

  return {
    dialogNodes,
    onOpenEditTrainerMediaDialog,
    onOpenAssignMemberDialog,
    onOpenCreatePersonalTrainerBillingGroup,
    onOpenEditPersonalTrainerDetailsDialog,
    onEditAssignedClient,
    onOpenEditSessionDialog,
    onOpenRemoveAssignedMemberDialog,
    onOpenEditPersonalTrainerSpecsDialog,
  }
}
