import * as React from 'react'
import styled from '@emotion/styled'
import { Divider, Menu, MenuItem, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { ButtonDataQa } from '../utils/entities-data-qa-utils'
import ActionDropdownButton from './buttons/action-dropdown-button'
import { mainColor } from '../constants/styles-constants'

export type ActionItemConfig = {
  title: string
  dataQa: ButtonDataQa
  disabled?: boolean
  onClick: (data?: { [key: string]: any }) => void
}

type ActionsDropdownMenuType = {
  title?: string
  actionsConfig: ActionItemConfig[][]
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const MenuButton = styled(ActionDropdownButton)`
  border-radius: 6px;
`

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 6px;
    margin-top: 5px;
    min-width: 200px;
  }
  .MuiList-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  padding-top: 4px;
  padding-bottom: 4px;
  color: ${mainColor};
`

const StyledDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`

function ActionsDropdownMenu(props: ActionsDropdownMenuType) {
  const { title = 'Actions', actionsConfig } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const onClose = () => setAnchorEl(null)
  const onActionDropdownClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)

  const populatedGroups = actionsConfig.filter((_) => _.length > 0)

  if (populatedGroups.length === 0) {
    return null
  }

  return (
    <Container>
      <MenuButton
        disableRipple
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={onActionDropdownClick}
        size="small"
      >
        {title}
      </MenuButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={onClose}
      >
        {populatedGroups.map((group, groupIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={groupIndex}>
            {group.map(({ dataQa: actionDataQa, title: actionTitle, disabled, onClick: onActionClick }) => (
              <StyledMenuItem
                key={actionDataQa}
                data-qa={actionDataQa}
                disabled={disabled}
                onClick={() => {
                  onActionClick()
                  onClose()
                }}
              >
                <Typography variant="body2" fontWeight="500">
                  {actionTitle}
                </Typography>
              </StyledMenuItem>
            ))}
            {groupIndex < populatedGroups.length - 1 && <StyledDivider />}
          </React.Fragment>
        ))}
      </StyledMenu>
    </Container>
  )
}

export default ActionsDropdownMenu
