import React, { useState } from 'react'
import { Studio, StudioRole, studioRoles } from 'src/api/services/cloud/studios/types'
import { useRequest } from 'src/hooks/use-request'
import { requestStudioUpdate } from 'src/api/services/cloud/studios/requestStudioUpdate'
import { useRequestErrorHandler } from 'src/hooks/useRequestErrorHandler'
import { requestStudioDeregistration } from 'src/api/services/cloud/studios/requestStudioDeregistration'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { Modal, Select, Button } from 'src/antd-components'
import styled from '@emotion/styled'

const studioRolesValueLabel = studioRoles.map((role) => ({ value: role, label: role }))

const Label = styled.div`
  font-size: 16px;
`
const NotRegisteredLabel = styled.div`
  font-weight: 500;
  text-transform: uppercase;
  color: #b3b3b3;
`
const Wrapper = styled.div`
  margin-top: 16px;
`

type StudioEditModalProps = {
  studio: Studio
  onStudioChange: (studio: Studio) => void
  open: boolean
  onClose: () => void
}

export const StudioEditModal = ({ studio, onStudioChange, open, onClose }: StudioEditModalProps) => {
  const [selectedRoles, setSelectedRoles] = useState(studio.roles)
  const [hasChanges, setHasChanges] = useState(false)
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const {
    fetch: studioUpdateRequestFetch,
    isLoading: studioUpdateRequestIsLoading,
    error: studioUpdateRequestError,
  } = useRequest(requestStudioUpdate)
  useRequestErrorHandler(studioUpdateRequestError)

  const {
    fetch: studioDeregistrationRequestFetch,
    isLoading: studioDeregistrationRequestIsLoading,
    error: studioDeregistrationRequestError,
  } = useRequest(requestStudioDeregistration)
  useRequestErrorHandler(studioDeregistrationRequestError)

  const isUpdating = studioUpdateRequestIsLoading || studioDeregistrationRequestIsLoading

  const onRolesDropdownChange = (roles: StudioRole[]) => {
    setHasChanges(true)
    setSelectedRoles(roles)
  }

  const handleClose = () => {
    setSelectedRoles(studio.roles)
    onClose()
  }

  const onUnregisterButtonClick = async () => {
    try {
      const data = await studioDeregistrationRequestFetch(studio!.id)
      onStudioChange(data)
      onDisplaySuccessNotification('Studio has been successfully unregistered')
    } catch (e) {
      onDisplayErrorNotification((e as any).errorMessage)
    }
  }

  const onRemoveOwnershipButtonClick = async () => {
    try {
      const newStudio = { ...studio, profile_id: null } as Studio
      const data = await studioUpdateRequestFetch(newStudio)
      onStudioChange(data)
      onClose()
      onDisplaySuccessNotification('Ownership successfully removed')
    } catch (e) {
      onDisplayErrorNotification((e as any).errorMessage)
    } finally {
      setHasChanges(false)
    }
  }

  const onSaveButtonClick = async () => {
    try {
      const newStudio = { ...studio, roles: selectedRoles } as Studio
      const data = await studioUpdateRequestFetch(newStudio)
      onStudioChange(data)
      onClose()
      onDisplaySuccessNotification('Studio has been successfully updated')
    } catch (e) {
      onDisplayErrorNotification((e as any).errorMessage)
    } finally {
      setHasChanges(false)
    }
  }

  return (
    <Modal
      open={open}
      title="Edit Studio Info"
      cancelText="Cancel"
      onCancel={handleClose}
      okText="Save"
      onOk={onSaveButtonClick}
      okButtonProps={{
        disabled: !hasChanges || isUpdating,
        loading: isUpdating,
      }}
      width={520}
    >
      <Label>Roles</Label>
      <Select
        value={selectedRoles}
        onChange={onRolesDropdownChange}
        mode="multiple"
        options={studioRolesValueLabel}
        showArrow
        style={{ width: 470, marginBottom: 16 }}
      />

      <Wrapper>
        {studio.has_actual_keys ? (
          <Button danger style={{ width: 120 }} disabled={isUpdating} onClick={onUnregisterButtonClick}>
            Deregister
          </Button>
        ) : (
          <NotRegisteredLabel>The studio is not registered</NotRegisteredLabel>
        )}
      </Wrapper>
      <Wrapper>
        {studio.profile_id ? (
          <Button danger style={{ width: 160 }} disabled={isUpdating} onClick={onRemoveOwnershipButtonClick}>
            Remove Ownership
          </Button>
        ) : (
          <NotRegisteredLabel>The studio is not owned</NotRegisteredLabel>
        )}
      </Wrapper>
    </Modal>
  )
}
