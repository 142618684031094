import React from 'react'
import { TableHead, TableRow } from '@mui/material'
import { Table, TableCell } from './styles'
import { getFormattedDate } from '../../utils/date-utils'
import { useSelector } from 'react-redux'
import { getMemberProfiles, getMemberRecord, getMembership } from '../../redux/member/member-selectors'

export function ContentPlans() {
  const profiles = useSelector(getMemberProfiles)
  const memberRecord = useSelector(getMemberRecord)
  const activeProfile = profiles.filter((profile) => profile.userId === memberRecord?.id)[0]
  const membership = useSelector(getMembership)

  const content_features = activeProfile.membership.class.content_features

  return content_features?.length ? (
    <TableRow>
      <TableCell>Content Plans</TableCell>
      <TableCell>
        <Table key={`membership-${membership?.id}-content-features`}>
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell>Content Plan</TableCell>
              <TableCell>Expires</TableCell>
            </TableRow>
          </TableHead>
          {content_features
            .filter((f) => f.content_plan)
            .map((cf) => (
              <TableRow key={`membership-content-feature-${cf.id}`}>
                <TableCell>{cf.feature.name}</TableCell>
                <TableCell>{cf.content_plan?.name}</TableCell>
                <TableCell>{cf.expire_date ? getFormattedDate(cf.expire_date) : '-'}</TableCell>
              </TableRow>
            ))}
        </Table>
      </TableCell>
    </TableRow>
  ) : null
}
