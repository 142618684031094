export class RequestCancellationError extends Error {
  message = 'Request has been cancelled.'
}

export const makePromiseCancelable = <T>(originalPromise: Promise<T>) => {
  let isCancelled = false
  const promise = new Promise<T>((resolve, reject) =>
    originalPromise
      .then((val) => (isCancelled ? reject(new RequestCancellationError()) : resolve(val)))
      .catch((error) => (isCancelled ? reject(new RequestCancellationError()) : reject(error))),
  )
  const cancel = () => (isCancelled = true)
  return { promise, cancel }
}
