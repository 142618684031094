import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { mainColor, secondaryColor } from '../../constants/styles-constants'

const ActionDropdownButton = styled(Button)({
  boxShadow: 'none',
  padding: '6px 12px',
  border: '1px solid',
  color: secondaryColor,
  backgroundColor: mainColor,
  borderColor: secondaryColor,
  fontSize: 14,
  lineHeight: '16px',
  textTransform: 'capitalize',
  minWidth: 'unset',
  '&:hover, &:active, &:focus': {
    boxShadow: 'none',
    border: '1px solid',
    backgroundColor: mainColor,
    borderColor: secondaryColor,
  },
})

export default ActionDropdownButton
