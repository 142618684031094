import { Resources, Verbs } from '../types/permissions-types'
import { ResourceConfig, resourcesConfig } from '../constants/resources-constants'
import { pagesPermissionsMap } from 'src/constants/permissions-constants'

export type ResourcesPermissionsMap = Record<Resources, ResourceConfig>

export const getResourcesPermissionsMap = (permissionsList: string[] = []): ResourcesPermissionsMap => {
  const resourcesPermissionsMap = permissionsList.reduce((acc, permission) => {
    const [action, resource] = permission.split(':') as [Verbs, Resources]
    const resourceActions = acc[resource] || []
    return { ...acc, [resource]: [...resourceActions, action] }
  }, {} as Record<Resources, Verbs[]>)

  return resourcesConfig.reduce((acc, resourceConfig) => {
    const mapForPermissionNames = pagesPermissionsMap[resourceConfig.name] || []

    const [, resource] = mapForPermissionNames[Verbs.read].split(':') as [Verbs, Resources]
    const resourcePermissions = resourcesPermissionsMap[resource] || []
    const isReadAllowed = resourcePermissions.includes(Verbs.read)
    const isCreateAllowed = resourcePermissions.includes(Verbs.create)
    const isDeleteAllowed = resourcePermissions.includes(Verbs.delete)
    const isUpdateAllowed = resourcePermissions.includes(Verbs.update)

    const resourceItemConfig = {
      ...resourceConfig,
      // read permissions are sometimes left out of the JWT token if create, delete, or update are already present
      isReadAllowed: isReadAllowed || isCreateAllowed || isDeleteAllowed || isUpdateAllowed,
      isCreateAllowed,
      isDeleteAllowed,
      isUpdateAllowed,
    }

    return { ...acc, [resourceConfig.name]: resourceItemConfig }
  }, {} as ResourcesPermissionsMap)
}
