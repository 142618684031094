import { useCallback, useState } from 'react'

import { OrderFormData } from '../../components/order-form/types'

export type CreateOrderFormData = OrderFormData & { name: string }

const initialOrderFormState: CreateOrderFormData = {
  name: '',
  email: '',
  address_type: 'Residence',
  address: {
    first_name: '',
    last_name: '',
    phone: '',
    zip: '',
    city: '',
    address1: '',
    province_code: '',
    country_code: 'US',
  },
  type: '',
  orderInfo: {},
  service_codes: [],
  parts: [],
}

export const useFormState = () => {
  const [formData, setFormData] = useState<CreateOrderFormData>(initialOrderFormState)

  const setFormDataPart = useCallback(
    (newValue: Partial<CreateOrderFormData>) => setFormData((state) => ({ ...state!, ...newValue })),
    [],
  )

  const resetFormState = useCallback(() => setFormData(initialOrderFormState), [])

  return { formData, setFormData, setFormDataPart, resetFormState }
}
