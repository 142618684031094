import React from 'react'
import { Resources } from '../types/permissions-types'
import { useResourcePermissionHandler } from '../hooks/use-resource-permission-handler'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../constants/messages'
import StubPage from '../components/stub-page'

/**
 * Returns the component depending on user rermissions
 * @param {Component} Component - React component
 * @param {Rerouces} resource - Resource type
 * @returns {Component} - component with actions
 */

export function withPermissions<T>(
  Component: React.FunctionComponent<T>,
  resource: Resources,
): React.FunctionComponent<T> {
  const wrapped = (props: T) => {
    const { isReadAllowed } = useResourcePermissionHandler(resource)

    if (!isReadAllowed) {
      return (
        <StubPage
          mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE}
          secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE}
        />
      )
    }

    return <Component {...props} />
  }

  wrapped.displayName = `withCurrentUserAction(${Component.displayName || Component.name})`

  return wrapped
}
