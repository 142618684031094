import React from 'react'
import { DragRow } from './drag-row'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Table } from 'antd'
import type { TableProps } from 'antd/es/table'

type DragTableProps<T> = {
  moveRow: (dragIndex: number, hoverIndex: number) => void
} & TableProps<T>

export function DragTable<T extends Record<string, unknown>>({ moveRow, ...props }: DragTableProps<T>) {
  const components = {
    body: {
      row: DragRow,
    },
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        {...props}
        components={components}
        onRow={(_, index) => {
          const attr = {
            index,
            moveRow,
          }
          return attr as React.HTMLAttributes<any>
        }}
        pagination={false}
      />
    </DndProvider>
  )
}
