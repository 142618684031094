import React from 'react'

import { Modal, Space, Typography } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'
import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import Loader from 'src/components/loader'

import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { FormeDialog } from 'src/hooks/useDialog'
import { BillingSchedule } from 'src/constants/billing-v2-contants'
import { DatePicker, DatePickerProps, Divider } from 'antd'
import moment from 'moment'

export const EditBillingScheduleDialog: FormeDialog<{ schedule: BillingSchedule }> = (props) => {
  const {
    params: { schedule },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.billingSchedule)
  let updatedDueDate = schedule.due_date
  let updatedAnchorDay = schedule.monthly_anchor_day
  let isFormValid = true
  const updateSchedule = async () => {
    try {
      await api.billingV2
        .updateUserBillingSchedule(schedule.user_id, schedule.period, updatedDueDate, updatedAnchorDay)
        .then(() => {
          setTimeout(() => window.location.reload(), 300)
          onDisplaySuccessNotification('Schedule was successfully updated')
          onClose()
        })
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
    }
  }

  const { fetch: onEditMemberSchedule, isLoading: isSubmitting } = useRequest(updateSchedule)

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed || schedule.status === 'cancelled') {
    return null
  }

  const onOk = (value: DatePickerProps['value']) => {
    updatedDueDate = value?.toISOString() ?? updatedDueDate
    updatedAnchorDay = moment(updatedDueDate).date()
    isFormValid = true
  }

  const onChange = (value: any) => {
    isFormValid = false
    onOk(value)
  }

  return (
    <Modal
      style={{ zIndex: '1 !important' }}
      title="Edit Schedule"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid }}
      onOk={onEditMemberSchedule}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <Typography.Paragraph>
        User id:{' '}
        <Typography.Text code copyable>
          {schedule?.user_id}
        </Typography.Text>
      </Typography.Paragraph>
      <Divider />
      <Typography.Paragraph>
        Period: <Typography.Text>{schedule?.period}</Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph>
        Current Due Date: <Typography.Text>{schedule?.due_date}</Typography.Text>
        <Space direction="vertical" size={12} title="Set Due Date to: ">
          <DatePicker showTime onChange={onChange} onOk={onOk} placeholder="Set Due Date to: " />
        </Space>
      </Typography.Paragraph>

      <Typography.Paragraph style={{ marginTop: '1rem' }}>
        Current Monthly Anchor Day: <Typography.Text>{schedule?.monthly_anchor_day}</Typography.Text>
      </Typography.Paragraph>
    </Modal>
  )
}
