import React, { useRef } from 'react'
import { AddressType, OrderAddress } from 'src/api/services/orders/types'
import { Grid, TextField } from '@mui/material'
import { OrderItemLayout } from '../order-item-layout'
import { Account } from './account'
import { Address } from './address'
import { OrderPartItems } from './order-part-items'
import { OrderServices } from './order-services'
import { Info } from './info'
import { IOrderFormItemPart, IOrderFormServiceCode, OrderFormData } from './types'

type Props = {
  data: OrderFormData
  onChange: (data: Partial<OrderFormData>) => void
  onValidationChange?: (isValid: boolean) => void
  userBioEditable?: boolean
  partItemsEditable?: boolean
  serviceCodesEditable?: boolean
}

export const OrderForm = ({
  data,
  onChange,
  onValidationChange,
  userBioEditable = false,
  partItemsEditable = true,
  serviceCodesEditable = true,
}: Props) => {
  const { email, address_type, address, orderInfo, parts, service_codes: serviceCodes } = data
  const formRef = useRef<HTMLFormElement | null>(null)
  const reportValidity = () => onValidationChange?.(Boolean(formRef.current?.reportValidity()))
  const setRef = (ref: HTMLFormElement | null) => {
    formRef.current = ref
    setTimeout(reportValidity)
  }

  const onAddressChange = userBioEditable
    ? (newValue: Partial<OrderAddress>) => onChange({ address: { ...address, ...newValue } })
    : undefined

  const onEmailChange = userBioEditable ? (newValue: string) => onChange({ email: newValue }) : undefined

  const onAddressTypeChange = userBioEditable
    ? (newValue: AddressType) => onChange({ address_type: newValue })
    : undefined

  const onPartsChange = partItemsEditable
    ? (newValue: IOrderFormItemPart[]) => onChange({ parts: newValue })
    : undefined

  const onServiceCodesChange = serviceCodesEditable
    ? (newValue: IOrderFormServiceCode[]) => onChange({ service_codes: newValue })
    : undefined

  const onInfoChange = (newValue: any) => onChange({ orderInfo: { ...orderInfo, additionalInfo: newValue } })

  const tableItems: { key: string; value: string }[] = []
  Object.entries(orderInfo.survey || {}).forEach(([key, value]) => {
    tableItems.push({ key, value })
  })
  return (
    <form ref={setRef} onChange={reportValidity}>
      <Account address={address} email={email} onAddressChange={onAddressChange} onEmailChange={onEmailChange} />
      <Address
        address={address}
        addressType={address_type}
        onAddressChange={onAddressChange}
        onAddressTypeChange={onAddressTypeChange}
      />
      <OrderPartItems items={parts} onChange={onPartsChange} />
      <OrderServices items={serviceCodes} onChange={onServiceCodesChange} />
      <Info defaultValue={orderInfo.additionalInfo || ''} onChange={onInfoChange} />
      {tableItems.length ? (
        <OrderItemLayout title="Additional Questions">
          <Grid container spacing={1} rowSpacing={2} sx={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
              {tableItems.map((table: any) => {
                return (
                  <TextField
                    title={table.value.question}
                    key={'table-' + table.key}
                    fullWidth
                    label={table.value.question}
                    multiline
                    rows={4}
                    sx={{ marginBottom: '10px' }}
                    disabled
                    defaultValue={table.value.answer}
                  />
                )
              })}
            </Grid>
          </Grid>
        </OrderItemLayout>
      ) : null}
    </form>
  )
}
