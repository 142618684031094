import React, { memo } from 'react'

import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../../constants/messages'
import Loader from '../../components/loader'
import StubPage from '../../components/stub-page'
import ResourceListContent, { ResourceListContentProps } from './resource-list-content'

function ResourceListContainer(props: ResourceListContentProps) {
  const { resource, withCreate = false } = props

  const {
    isLoading: isPermissionLoading,
    isAuthenticated,
    isReadAllowed,
    isCreateAllowed,
  } = useResourcePermissionHandler(resource)

  if (isPermissionLoading) {
    return <Loader />
  }

  if (!isAuthenticated) {
    return null
  }

  if (!isReadAllowed) {
    return (
      <StubPage
        mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE}
        secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE}
      />
    )
  }

  return <ResourceListContent {...props} withCreate={withCreate && isCreateAllowed} />
}

export default memo(ResourceListContainer)
