import { Col, DatePicker, Form, FormProps, InputNumber, Row, Select } from 'antd'
import React from 'react'
import { CouponChargeKind, CouponSourceType, CouponStatus } from '../../constants/billing-v2-contants'
import moment from 'moment-timezone'

const toOptions = (_enum: Record<string, unknown>) => Object.keys(_enum).map((value) => ({ value, label: value }))

const statusOptions = toOptions(CouponStatus)
const chargeKindOptions = toOptions(CouponChargeKind)
const sourceTypeOptions = toOptions(CouponSourceType)

export default function ResourceCouponsForm(props: FormProps) {
  return (
    <Form {...props} layout="vertical">
      <Row>
        <Col span={10}>
          <Form.Item name="status" label="Status">
            <Select options={statusOptions} />
          </Form.Item>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item
            getValueFromEvent={(value: Date) => value?.toISOString()}
            getValueProps={(value?: string) => ({ value: value ? moment(value) : value })}
            name="expire_date"
            label="Expire Date"
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item name="charge_kind" label="Charge Kind">
            <Select options={chargeKindOptions} />
          </Form.Item>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name="source_type" label="Source Type">
            <Select options={sourceTypeOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Form.Item name="fixed_price" label="Price">
            <InputNumber min={0} onChange={() => props.form?.setFieldValue('discount_percent', 0)} />
          </Form.Item>
        </Col>
        <Col span={10} offset={2}>
          <Form.Item name="discount_percent" label="Discount (%)">
            <InputNumber min={0} max={100} onChange={() => props.form?.setFieldValue('fixed_price', 0)} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
