import * as React from 'react'
import { TableCell } from '@mui/material'
import { withPermissions } from 'src/hocs/withPermissions'
import { Resources } from 'src/types/permissions-types'
import { PageContainer } from 'src/components/ui-kit'
import ResourceListContainer from 'src/containers/resource-list-container'
import { ColumnItem } from 'src/components/accessible-table/acessible-table-types'
import { getUniformDateTime } from 'src/utils/uniformDateDisplay'
import { createRegularSearchFilter } from 'src/containers/resource-list-container/filters'
import { Order } from 'src/types/common-types'
import { BooleanYesNoCell, DataCell } from '../../../components/accessible-table/accessible-table-cell'

const columns: ColumnItem[] = [
  {
    key: 'id',
    title: 'ID',
    fields: ['id'],
    render: DataCell,
  },
  {
    key: 'product_type',
    title: 'Product type',
    fields: ['type'],
    render: DataCell,
    orderByField: 'product_type',
  },
  {
    key: 'roles',
    title: 'Roles',
    fields: ['roles'],
    render: DataCell,
    orderByField: 'roles',
  },
  {
    key: 'lift_id',
    title: 'Lift ID',
    fields: ['lift_id'],
    render: DataCell,
    orderByField: 'lift_id',
  },
  {
    key: 'lift_only_mode',
    title: 'Lift Only Mode',
    fields: ['lift_only_mode'],
    render: BooleanYesNoCell,
    orderByField: 'lift_only_mode',
  },
  {
    key: 'has_actual_keys',
    title: 'Registered',
    fields: ['has_actual_keys'],
    render: BooleanYesNoCell,
    orderByField: 'has_actual_keys',
  },
  {
    key: 'install_date',
    title: 'Install date',
    render: ({ rowData: { install_date } }) => (
      <TableCell>{install_date ? getUniformDateTime(install_date) : ''}</TableCell>
    ),
    orderByField: 'install_date',
  },
  {
    key: 'created_date',
    title: 'Created date',
    render: ({ rowData: { created_date } }) => <TableCell>{getUniformDateTime(created_date)}</TableCell>,
  },
]

function StudiosListPage() {
  const searchFilter = createRegularSearchFilter(['id'])

  return (
    <PageContainer>
      <ResourceListContainer
        withClickableRows
        searchFilter={searchFilter}
        searchFilterPlaceholder="Search by ID..."
        columns={columns}
        resource={Resources.studios}
        sortField="install_date"
        sortDirection={Order.desc}
      />
    </PageContainer>
  )
}

export default withPermissions(StudiosListPage, Resources.studios)
