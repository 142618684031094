import { styled, Button } from '@mui/material'
import { mainColor, secondaryColor } from '../../constants/styles-constants'

const TextButton = styled(Button)({
  boxShadow: 'none',
  padding: '5px',
  paddingRight: 0,
  borderRadius: 0,
  borderBottom: '1px solid',
  borderColor: secondaryColor,
  backgroundColor: secondaryColor,
  color: mainColor,
  fontSize: 14,
  lineHeight: '16px',
  minWidth: 'unset',
  textTransform: 'capitalize',
  '&:hover, &:active, &:focus': {
    borderColor: mainColor,
    backgroundColor: secondaryColor,
  },
})

export default TextButton
