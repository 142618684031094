import React, { memo } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from '@emotion/styled'

interface IOrderItemLayout {
  title: string
  subTitle?: string
  children: React.ReactNode
}

const Accordion = styled(MuiAccordion)`
  box-shadow: none;
  margin-bottom: 20px;
  &:before {
    display: none;
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  border-bottom: 1px solid ${(_) => _.theme.palette.grey.A400};
  min-height: 0;
  height: 36px;
  padding: 0;
`

const Title = styled(Typography)`
  flex-shrink: 0;
  padding-right: 8px;
  font-weight: bold;
`

const Subtitle = styled(Typography)`
  color: ${(_) => _.theme.palette.text.secondary};
`

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0 0 50px;
`

function OrderItemLayout({ title, subTitle, children }: IOrderItemLayout) {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Title>{title}</Title>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default memo(OrderItemLayout)
