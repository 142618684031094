import React from 'react'
import { Grid } from '@mui/material'
import styled from '@emotion/styled'
import { createStructuredSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { MemberRouteParams } from 'src/boot/routing'

import { Resources } from '../../types/permissions-types'
import useTabsConfig from './useTabsConfig'
import MemberSideSection from './components/member-side-section'
import ResourceRecordContainer from '../../containers/resource-record-container'
import PageTabSectionContainer from '../../containers/page-tab-section-container'
import Loader from '../../components/loader'
import { useMemberData } from './use-member-data'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'
import StubPage from '../../components/stub-page'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../../constants/messages'
import { getIsMemberLoading, getMemberRecord } from '../../redux/member/member-selectors'
import { useDialogsController } from './useDialogsController'
import { MemberAdditionalActions } from './components/member-additional-actions'

const StyledGrid = styled(Grid)`
  width: 100%;
`

const selectMemberState = createStructuredSelector({
  isLoading: getIsMemberLoading,
  record: getMemberRecord,
})

function MemberView() {
  const { isReadAllowed } = useResourcePermissionHandler(Resources.members)

  const { id: recordId } = useParams<MemberRouteParams>()
  const { isLoading, record } = useSelector(selectMemberState)

  useMemberData(recordId)

  const dialogsController = useDialogsController(record)

  const { showEditMemberDetailsDialog, showEditMemberMediaDialog, showEditEmergencyContactInfoDialog, dialogNodes } =
    dialogsController

  const tabsContentConfig = useTabsConfig(record, dialogsController)

  if (!record) return null

  if (isLoading) {
    return <Loader />
  }

  if (!isReadAllowed) {
    return (
      <StubPage
        mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE}
        secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE}
      />
    )
  }

  return (
    <StyledGrid container>
      <ResourceRecordContainer record={record} resource={Resources.members}>
        <Grid container spacing={4}>
          <MemberSideSection
            record={record}
            resource={Resources.members}
            onOpenEditMediaDialog={showEditMemberMediaDialog}
            onOpenEditMemberDetailsDialog={showEditMemberDetailsDialog}
            onOpenEditEmergencyInformationDialog={showEditEmergencyContactInfoDialog}
          />
          <PageTabSectionContainer
            tabsConfig={tabsContentConfig}
            headerAdditionalActions={<MemberAdditionalActions dialogsController={dialogsController} />}
          />
        </Grid>
      </ResourceRecordContainer>
      {dialogNodes}
    </StyledGrid>
  )
}

export default MemberView
