import React, { memo, ReactElement } from 'react'
import { Grid, TextField } from '@mui/material'

import { OrderAddress } from 'src/api/services/orders/types'

import { OrderItemLayout } from '../order-item-layout'

interface IAccountProps {
  address: OrderAddress
  email: string
  onAddressChange?: (value: Partial<OrderAddress>) => void
  onEmailChange?: (value: string) => void
}

export const Account = memo((props: IAccountProps): ReactElement<IAccountProps> => {
  const { address, email, onAddressChange, onEmailChange } = props
  const { first_name, last_name, phone } = address

  return (
    <OrderItemLayout title="Account" subTitle={`${first_name} ${last_name}`}>
      <Grid container spacing={1} rowSpacing={2} sx={{ paddingTop: '20px' }}>
        <Grid item xs={6}>
          <TextField
            onChange={(ev) => onAddressChange?.({ first_name: ev.target.value })}
            label="First name"
            value={first_name ?? ''}
            error={first_name?.trim().length === 0}
            required
            fullWidth
            size="small"
            disabled={!onAddressChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(ev) => onAddressChange?.({ last_name: ev.target.value })}
            label="Last name"
            value={last_name ?? ''}
            error={last_name?.trim().length === 0}
            required
            fullWidth
            size="small"
            disabled={!onAddressChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="email"
            onChange={(ev) => onEmailChange?.(ev.target.value)}
            label="Email"
            value={email ?? ''}
            error={email?.trim().length === 0}
            required
            fullWidth
            size="small"
            disabled={!onEmailChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(ev) => onAddressChange?.({ phone: ev.target.value.replace(/[^\+\d\s]/g, '') })}
            label="Phone"
            value={phone ?? ''}
            error={phone?.trim().length === 0}
            required
            fullWidth
            size="small"
            disabled={!onAddressChange}
          />
        </Grid>
      </Grid>
    </OrderItemLayout>
  )
})
