import { PagePermissions, PagesPermissions, Permission, Resources, Verbs } from '../types/permissions-types'

const getPermissionName = (verb: Verbs, resource: Resources): Permission => `${verb}:${resource}`

const getPagePermissionsMap = (pageResource: Resources): PagePermissions =>
  Object.values(Verbs).reduce(
    (acc: PagePermissions, verbAction: Verbs) => ({
      ...acc,
      [verbAction]: getPermissionName(verbAction, pageResource),
    }),
    {},
  )

export const pagesPermissionsMap: PagesPermissions = {
  ...Object.values(Resources).reduce((acc, resource: Resources) => {
    acc[resource] = getPagePermissionsMap(resource)
    return acc
  }, {} as PagesPermissions),
  [Resources.membership]: getPagePermissionsMap(Resources.memberships),
  [Resources.cpsHistory]: getPagePermissionsMap(Resources.memberSessions),
  [Resources.cpsSchedule]: getPagePermissionsMap(Resources.memberSessions),

  // temporary solution since there are no dedicated rights to access known-device
  // TODO: delete this line in future
  [Resources.knownDevice]: getPagePermissionsMap(Resources.studios),
}
