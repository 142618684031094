import React, { ChangeEvent } from 'react'
import { InputLabel, TextField, styled } from '@mui/material'
import { InputSize, InputVariant } from './inputs-types'

type NumberInputProps = {
  id: string
  value: number
  label?: string
  topLabel?: string
  fullWidth?: boolean
  prefix?: string
  size?: InputSize
  max: number
  variant?: InputVariant
  className?: string
  onChange: (inputValue: number) => void
  thin?: boolean
}

const SimpleTextField = styled(TextField, {
  shouldForwardProp: (propName) => propName !== 'thin',
})<{ thin?: boolean }>(({ thin }) => ({
  '> .MuiInput-underline:before': {
    display: 'none',
  },
  '> .MuiInput-underline:after': {
    display: 'none',
  },
  '> .MuiInputBase-root': {
    marginBottom: thin ? 0 : 20,
  },
  '> .MuiInputBase-root > input': {
    padding: thin ? '5px 9px' : undefined,
    fontSize: thin ? '0.875rem' : undefined,
  },
  '.MuiOutlinedInput-notchedOutline': { borderWidth: thin ? '1px !important' : undefined },
}))

function NumberInput(props: NumberInputProps) {
  const {
    id,
    value,
    label,
    topLabel,
    prefix,
    className,
    max,
    variant = InputVariant.labeled,
    size = InputSize.small,
    fullWidth = true,
    onChange,
    thin,
  } = props
  const isLabeledInput = variant === InputVariant.labeled
  const labelId = `${id}/label`

  const getInputValue = (inputString: string) => {
    if (!prefix) return +inputString
    const [, valueStr] = inputString.split(prefix)
    return +valueStr
  }

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = getInputValue(e.target.value)
    if (isNaN(inputValue) || inputValue > max) return
    onChange(inputValue)
  }

  return (
    <>
      {topLabel && <InputLabel id={labelId}>{topLabel}</InputLabel>}
      <SimpleTextField
        label={label}
        className={isLabeledInput ? '' : className}
        fullWidth={fullWidth}
        value={`${prefix}${value}`}
        data-qa={id}
        name="numberformat"
        size={size}
        variant={isLabeledInput ? InputVariant.standard : variant}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onChange={onChangeInput}
        thin={thin}
      />
    </>
  )
}

export default NumberInput
