import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, Form, Row, Typography } from 'antd'
import styled from '@emotion/styled'
import { Input, InputNumber, Select } from 'src/antd-components'
import {
  MembershipClassClient,
  MembershipClassFeature,
  MembershipClassType,
} from '../../api/services/cloud/memberships/types'
import api from '../../api'
import { Resources } from '../../types/permissions-types'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import { useResourcePermissionHandler } from '../../hooks/use-resource-permission-handler'

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button + button {
    margin-left: 8px;
  }
`

const IdContainer = styled.div`
  margin-bottom: 12px;

  span + span {
    margin-left: 8px;
  }
`

const enumToOptions = (data: Record<string, unknown>) => Object.values(data).map((value) => ({ label: value, value }))

const featureOptions = enumToOptions(MembershipClassFeature)
const clientOptions = enumToOptions(MembershipClassClient)
const typeOptions = enumToOptions(MembershipClassType)

export function MembershipClassDrawer({
  open,
  onClose,
  onReload,
  record,
}: {
  open: boolean
  onClose: () => void
  onReload: () => void
  record: any
}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isDeleteAllowed, isEditAllowed } = useResourcePermissionHandler(Resources.membershipClass)

  const isNew = !record
  const title = isNew ? 'Create New' : `Edit ${record.name}`

  useEffect(() => {
    record ? form.setFieldsValue(record) : form.resetFields()
  }, [record, form])

  const onSave = async () => {
    const data = form.getFieldsValue()
    setLoading(true)
    try {
      const request = isNew
        ? api.common.create(Resources.membershipClass, { data })
        : api.common.update(Resources.membershipClass, { id: record.id, data, previousData: record })
      await request
      onDisplaySuccessNotification(isNew ? 'Membership class was created' : 'Membership class was updated')
      onReload()
      onClose()
    } catch (err) {
      onDisplayErrorNotification('Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const onDelete = async () => {
    setDeleting(true)
    try {
      await api.common.delete(Resources.membershipClass, { id: record.id })
      onDisplaySuccessNotification('Membership class was deleted')
      onReload()
      onClose()
    } catch (err) {
      onDisplayErrorNotification('Something went wrong')
    } finally {
      setDeleting(false)
    }
  }

  const drawerTitle = (
    <Title>
      <div>{title}</div>
      {isEditAllowed ? (
        <div>
          <Button onClick={onClose} disabled={loading || deleting}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSave} loading={loading} disabled={deleting}>
            Save
          </Button>
        </div>
      ) : null}
    </Title>
  )

  return (
    <Drawer width={600} open={open} onClose={onClose} title={drawerTitle}>
      <Form form={form} layout="vertical" initialValues={record}>
        {record?.id ? (
          <IdContainer>
            <span>ID:</span>
            <Typography.Text copyable>{record.id}</Typography.Text>
          </IdContainer>
        ) : null}
        <Row>
          <Col span={14}>
            <Form.Item label="Membership Name" name="name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Max.profiles" name="coveredProfiles">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={4} offset={1}>
            <Form.Item label="Devices" name="coveredDevices">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item label="Type" name="type">
              <Select options={typeOptions} disabled={!isNew} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item label="Price" name="chargeAmount">
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Features" name="features">
          <Select mode="multiple" options={featureOptions} />
        </Form.Item>
        <Form.Item label="Clients" name="allowedClients">
          <Select mode="multiple" options={clientOptions} />
        </Form.Item>
      </Form>
      {!isNew && isDeleteAllowed ? (
        <Button danger type="primary" loading={deleting} disabled={loading} onClick={onDelete}>
          Delete
        </Button>
      ) : null}
    </Drawer>
  )
}
