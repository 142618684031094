import React from 'react'

import { Form, Modal, Password, useWatchAll } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'

import { RequestError } from 'src/api/http-client'
import Loader from 'src/components/loader'
import { Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from 'src/types/permissions-types'
import { getErrorMessage } from 'src/utils/errors'

import { sendSetPassword } from 'src/api/services/cloud/memberships/sendSetPassword'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { FormeDialog } from 'src/hooks/useDialog'

type FormData = {
  password: string
}

export const SetPasswordDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading } = useResourcePermissionHandler(Resources.personalData)

  const [form] = Form.useForm<FormData>()
  const inputValues = useWatchAll(form, { password: '' })
  const { password } = inputValues

  const isFormDataValid = password

  const setPassword = async () => {
    try {
      await sendSetPassword({ userId: record.id, password })

      onDisplaySuccessNotification('Password successfully set')
      onClose()
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
    }
  }

  const { fetch: onSetPassword, isLoading: isSubmitting } = useRequest(setPassword)

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal
      title="Set Password"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormDataValid }}
      onOk={onSetPassword}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <Form form={form} labelAlign="right" layout="vertical">
        <Form.Item label="Password" name="password">
          <Password />
        </Form.Item>
      </Form>
    </Modal>
  )
}
