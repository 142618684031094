import { TpiWorkflowState } from 'src/constants/tpi-constants'

export enum ContentStatus {
  created = 'created',
  unpublished = 'unpublished',
  published = 'published',
  archived = 'archived',
}

export const contentStatusChoicesList = [
  { id: ContentStatus.created, name: 'Created' },
  { id: ContentStatus.unpublished, name: 'Unpublished' },
  { id: ContentStatus.published, name: 'Published' },
  { id: ContentStatus.archived, name: 'Archived' },
]

export const NOOP = (): void => {
  //
}

export const PREFERRED_TIMEZONE = 'preferredTimezone'

export const WORKFLOWS_TPI = [
  { id: TpiWorkflowState.created, label: 'Interested' },
  { id: TpiWorkflowState.contacted, label: 'Contacted' },
  { id: TpiWorkflowState.confirming, label: 'Emailed' },
  { id: TpiWorkflowState.activating, label: 'In progress' },
  { id: TpiWorkflowState.active, label: 'Configuration' },
  { id: TpiWorkflowState.trainerAssigned, label: 'Complete' },
  { id: TpiWorkflowState.rejected, label: 'Rejected' },
]

export const NO_STRIPE_CUSTOMER_ID = 'NO_STRIPE_CUSTOMER_ID'
