import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import errorIcon from 'src/theme/icons/error.png'
import okIcon from 'src/theme/icons/ok.png'
import { UserBundle } from 'src/api/services/bundles'
import { getBundleData } from './utils/get-bundle-data'
import { isNumber } from 'lodash'

const BundlesContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`
const BundleDataContainer = styled.div`
  padding-left: 10px;
  margin-right: 10px;
  flex-grow: 2;
  display: grid;
`

const SubTitleContainer = styled.span`
  opacity: 50%;
  margin-top: 5px;
  margin-bottom: 40px;
`

const SessionContainer = styled.div`
  flex-grow: 1;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f5f5f5;
  display: grid;
`

interface BundleDataProps {
  data: UserBundle
}

function BundleData({ data }: BundleDataProps) {
  const bundle = useMemo(() => getBundleData(data), [data])

  return (
    <BundlesContainer>
      <BundleDataContainer>
        {bundle.sku && <span>{bundle.sku}</span>}
        {bundle.unitPrice && <span>{bundle.unitPrice}</span>}
        {bundle.bundlePrice && <span>{bundle.bundlePrice}</span>}
        {bundle.consumedAt && <span>{bundle.consumedAt}</span>}
        {bundle.sessionsCount && <span>{bundle.sessionsCount}</span>}
        {bundle.consumedSessions && <span>{bundle.consumedSessions}</span>}
        {bundle.installmentPayed && <span>{bundle.installmentPayed}</span>}
      </BundleDataContainer>
      <SessionContainer>
        <img src={errorIcon} style={{ margin: 5 }} />
        <SubTitleContainer>Used Credit</SubTitleContainer>
        {isNumber(bundle.consumedSessionsInt) && <span>{bundle.consumedSessionsInt}</span>}
      </SessionContainer>
      <SessionContainer>
        <img src={okIcon} style={{ margin: 5 }} />
        <SubTitleContainer>Available Credits</SubTitleContainer>
        {isNumber(bundle.availableSessionsint) && <span>{bundle.availableSessionsint || 0}</span>}
      </SessionContainer>
    </BundlesContainer>
  )
}

export default BundleData
