export const LIMITED_PERMISSIONS_MAIN_MESSAGE = 'Sorry, your permissions are limited'
export const LIMITED_PERMISSIONS_SECONDARY_MESSAGE = 'Please contact your administrator to extend your rights'
export const SOME_ERROR_MAIN_MESSAGE = 'Sorry, something went wrong'
export const SOME_ERROR_SECONDARY_MESSAGE = 'Please try to reload page'
export const WRONG_RESOURCES_URL_MAIN_MESSAGE = 'You have opened a non-existent page'
export const WRONG_RESOURCES_URL_SECONDARY_MESSAGE = 'Please, use the navigation menu'
export const WRONG_RESOURCES_URL_BUTTON_BLOCK_MESSAGE = 'Or click the button below to open the most similar page'
export const EMPTY_DATA_MAIN_MESSAGE = "Looks like you don't have any data yet"
export const PROMOTIONS_EMPTY_DATA_MESSAGE = 'There are no active promotions for this user'
export const CREATE_PROMOTION_INSTALL_DATE_MESSAGE =
  "Install date will be automatically added based on the customer's existing selection"
