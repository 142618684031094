import React, { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import api from 'src/api'
import { RequestError } from 'src/api/http-client'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

export const SyncButton = ({ onSuccess }: { onSuccess: () => void }) => {
  const [loading, setLoading] = useState(false)
  const { onDisplayErrorNotification } = useSnackbarNotifications()

  const sync = () => {
    setLoading(true)
    api.orders
      .syncProductsWithGoogleDoc()
      .then(onSuccess)
      .catch((err: RequestError) => onDisplayErrorNotification(err.message))
      .finally(() => setLoading(false))
  }

  return (
    <LoadingButton onClick={sync} loading={loading} variant="contained">
      Sync with Google Spreadsheet
    </LoadingButton>
  )
}
