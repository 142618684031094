import React, { useMemo } from 'react'
import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { sortBy } from 'lodash'

import { useRequest } from 'src/hooks/use-request'
import { requestEventsLog } from 'src/api/services/orders/events/requestEventsLog'

const Container = styled(Paper)`
  background-color: ${(_) => _.theme.palette.grey[100]};
  padding: 22px 30px;
`

const Row = styled(Box)`
  padding: 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${(_) => _.theme.palette.grey[400]};
  }
`

export const EventsLog = ({ shopifyOrderName }: { shopifyOrderName: string }) => {
  const { data, isLoading } = useRequest(requestEventsLog, [{ order_id: shopifyOrderName }])
  const events = useMemo(() => sortBy(data ?? [], (_) => new Date(_.event_date)).reverse(), [data])
  return (
    <Container elevation={0}>
      <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
        Events Log
      </Typography>
      <Divider sx={{ borderColor: (_) => _.palette.grey[500] }} />
      <Box>
        {isLoading && (
          <Box sx={{ textAlign: 'center', paddingTop: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {events.map((event) => (
          <Row key={event.id}>
            <Typography sx={{ color: (_) => _.palette.grey[500] }}>{event.event_date}</Typography>
            <Typography>{event.event_type}</Typography>
          </Row>
        ))}
      </Box>
    </Container>
  )
}
