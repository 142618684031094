import React, { memo, ReactElement, useState } from 'react'
import { nanoid } from 'nanoid'
import {
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Select,
  InputLabel,
} from '@mui/material'
import { IOrderFormServiceCode } from './types'
import { useCachedServiceCodes } from '../../useCachedServiceCodes'
import { OrderItemLayout } from '../order-item-layout'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

interface IOrderServicesProps {
  items: IOrderFormServiceCode[]
  onChange?: (items: IOrderFormServiceCode[]) => void
}

const StyledProducts = styled.div`
  font-size: 12px;
  color: #999;
`

export const OrderServices = memo((props: IOrderServicesProps): ReactElement<IOrderServicesProps> => {
  const { items, onChange } = props
  const globalCodes = useCachedServiceCodes() ?? []
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCode, setSelectedCode] = useState('')

  const onAdd = onChange
    ? () => {
        const item = globalCodes.find((code) => code.code === selectedCode)
        if (!item) throw new Error(`Global code "${selectedCode}" not found`)
        onChange([...items, { key: nanoid(), code: item.code }])
      }
    : undefined

  const onDelete = onChange ? (key: string) => onChange(items.filter((item) => item.key !== key)) : undefined

  return (
    <OrderItemLayout title="Service Codes" subTitle="">
      <Table
        size="small"
        sx={{ td: { padding: 0, height: '52px' }, th: { paddingLeft: 0, color: (_) => _.palette.text.secondary } }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Description</TableCell>
            {onDelete && <TableCell width="20px" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(({ key, code }) => {
            const globalCode = globalCodes.find((g) => g.code === code)
            if (!globalCode) return null
            return (
              <TableRow key={key}>
                <TableCell>{code}</TableCell>
                <TableCell>
                  {globalCode.description}
                  <StyledProducts>{globalCode.product.join(', ')}</StyledProducts>
                </TableCell>
                {onDelete && (
                  <TableCell>
                    <IconButton aria-label="delete" onClick={() => onDelete(key)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {onAdd && (
        <Button sx={{ marginLeft: '-6px', marginTop: '10px' }} onClick={() => setModalVisible(true)}>
          Add service code
        </Button>
      )}
      <Dialog
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle>Add service code</DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ marginTop: 2 }}>
            <InputLabel id="code-select-label">Code</InputLabel>
            <Select
              labelId="code-select-label"
              value={selectedCode}
              label="Code"
              onChange={(ev) => setSelectedCode(ev.target.value)}
            >
              {globalCodes.map((serviceCode) => (
                <MenuItem value={serviceCode.code} key={serviceCode.code}>
                  [{serviceCode.code}] {serviceCode.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalVisible(false)}>Close</Button>
          <Button onClick={onAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </OrderItemLayout>
  )
})
