import React from 'react'

import { Form, Input, Modal, Select, useWatchAll } from 'src/antd-components'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { emergencyContactRelationshipChoices, Member } from 'src/constants/member-constants'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { useAction } from 'src/hooks/use-actions'
import { setMemberRecord } from 'src/redux/member/member-reducer'
import { FormeDialog } from 'src/hooks/useDialog'

type FormData = {
  emergency_address: string
  emergency_contact_relationship: string
  emergency_contact_first_name: string
  emergency_contact_last_name: string
  emergency_contact_phone: string
}

export const EditEmergencyContactInfoDialog: FormeDialog<{ record: Member; phoneLength: number }> = (props) => {
  const {
    params: { record, phoneLength },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isEditAllowed } = useResourcePermissionHandler(Resources.members)

  const onUpdateMemberRecord = useAction(setMemberRecord)

  const initialEmergencyContactInfo = {
    emergency_address: record.emergency_address,
    emergency_contact_relationship: record.emergency_contact_relationship,
    emergency_contact_first_name: record.emergency_contact_first_name,
    emergency_contact_last_name: record.emergency_contact_last_name,
    emergency_contact_phone: record.emergency_contact_phone,
  }

  const [form] = Form.useForm<FormData>()
  const inputValues = useWatchAll(form, initialEmergencyContactInfo)

  const {
    emergency_address,
    emergency_contact_relationship,
    emergency_contact_first_name,
    emergency_contact_last_name,
    emergency_contact_phone,
  } = inputValues

  const clearFormData = () => {
    form.resetFields()
  }

  const isFormValid =
    emergency_address.trim() &&
    emergency_contact_relationship &&
    emergency_contact_first_name.trim() &&
    emergency_contact_last_name.trim() &&
    emergency_contact_phone &&
    (emergency_address.trim() !== record.emergency_address ||
      emergency_contact_relationship !== record.emergency_contact_relationship ||
      emergency_contact_first_name.trim() !== record.emergency_contact_first_name ||
      emergency_contact_last_name.trim() !== record.emergency_contact_last_name ||
      emergency_contact_phone !== record.emergency_contact_phone) &&
    (emergency_contact_phone.length === phoneLength || emergency_contact_phone === '911')

  const onEdit = () => {
    const data = {
      emergency_address: emergency_address.trim(),
      emergency_contact_relationship,
      emergency_contact_first_name: emergency_contact_first_name.trim(),
      emergency_contact_last_name: emergency_contact_last_name.trim(),
      emergency_contact_phone,
    }
    const params = { id: record.id, data, previousData: { id: record.id } }

    api.common
      .update(Resources.members, params)
      .then(({ data: updatedMember }) => {
        onUpdateMemberRecord(updatedMember)
        onDisplaySuccessNotification('Member was successfully updated')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
    onClose()
  }

  if (!isEditAllowed) {
    return null
  }

  return (
    <Modal
      title="Edit emergency contact information"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid }}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialEmergencyContactInfo} labelAlign="right" layout="vertical">
        <Form.Item label="Member Address" name="emergency_address">
          <Input allowedChars={/^[a-zA-Z0-9., ]*$/} maxLength={100} />
        </Form.Item>
        <Form.Item label="Contact Relationship" name="emergency_contact_relationship">
          <Select options={emergencyContactRelationshipChoices.map(({ id, label }) => ({ value: id, label }))} />
        </Form.Item>
        <Form.Item label="Contact First Name" name="emergency_contact_first_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Contact Last Name" name="emergency_contact_last_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Contact Phone Number (10 digit)" name="emergency_contact_phone">
          <Input allowedChars={/^[0-9]*$/} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
