import { PillColors } from 'src/components/text-pill'

export type PersonalWorkout = {
  id: string
  client_id: string
  date: string
  duration: number
  workout_status: WorkoutStatus
  billing_group_id: string
  workout_category: string
  workout_type: PersonalWorkoutType
  billing_status: BillingStatus
  payment_status: PaymentStatus
  client_name: string
  client_user_id: string
  bill_rate: number
  personal_session_id: string
  trainer_name: string
  trainer_id: number
}

export enum WorkoutCategory {
  Live1on1 = 'live1on1',
  TPI = 'tpi',
}

export const workoutCategoriesCaptions: Record<string, string> = {
  [WorkoutCategory.Live1on1]: 'Live 1:1',
  [WorkoutCategory.TPI]: 'TPI',
}

export type WorkoutCategories = {
  caption: string
  color: PillColors
}

export const workoutCategories: Record<string, WorkoutCategories> = {
  [WorkoutCategory.Live1on1]: { caption: 'Live 1:1', color: PillColors.blue },
  [WorkoutCategory.TPI]: { caption: 'TPI', color: PillColors.pink },
}

export enum WorkoutStatus {
  Planned = 'Planned',
  Completed = 'Completed',
  UnableToComplete = 'Unable to Complete',
  NoShow = 'No Show',
  LateCancel = 'Late Cancel',
  // keeping option "Other" below in this enum but removing from personalWorkoutStatusChoices as we don't currently support this option as a session status but may at a later date
  Other = 'Other',
  Error = 'Error',
  Cancelled = 'Cancelled',
}

export const personalWorkoutStatusChoices = [
  { id: WorkoutStatus.Planned, label: 'Planned' },
  { id: WorkoutStatus.Completed, label: 'Completed' },
  { id: WorkoutStatus.Cancelled, label: 'Cancelled' },
  { id: WorkoutStatus.UnableToComplete, label: 'Unable to Complete' },
  { id: WorkoutStatus.NoShow, label: 'No Show' },
  { id: WorkoutStatus.LateCancel, label: 'Late Cancel' },
]

export enum PersonalWorkoutType {
  Workout = 'Workout',
  Assessment = 'Assessment',
}

export enum BillingStatus {
  Billable = 'Billable',
  NotBillable = 'Not Billable',
  Invoiced = 'Invoiced',
  Comped = 'Comped',
  Credited = 'Credited',
  Error = 'Error',
  'Trainer Paid' = 'Trainer Paid',
}

export const billingStatusChoices = [
  { id: BillingStatus.Comped, label: 'Comped' },
  { id: BillingStatus.Billable, label: 'Billable' },
  { id: BillingStatus.NotBillable, label: 'Not Billable' },
  { id: BillingStatus.Invoiced, label: 'Invoiced' },
]

export enum PaymentStatus {
  Payable = 'Payable',
  NotPayable = 'Not Payable',
  TBD = 'TBD',
  Paid = 'Paid',
}

export enum SessionDuration {
  ThirtyMinutes = '30 Minutes',
  SixtyMinutes = '60 Minutes',
}

export const personalWorkoutTypeChoices = [
  { id: PersonalWorkoutType.Workout, name: 'Workout' },
  { id: PersonalWorkoutType.Assessment, name: 'Assessment' },
]

export const personalWorkoutBillingStatusChoices = [
  { id: BillingStatus.Billable, label: 'Billable' },
  { id: BillingStatus.NotBillable, label: 'Not Billable' },
  { id: BillingStatus.Invoiced, label: 'Invoiced' },
  { id: BillingStatus.Comped, label: 'Comped' },
  { id: BillingStatus.Credited, label: 'Credited' },
]

export const personalWorkoutDurationChoices = [
  { id: SessionDuration.ThirtyMinutes, label: '30 Mins' },
  { id: SessionDuration.SixtyMinutes, label: '60 Mins' },
]

export const personalWorkoutFieldsAllowedForEdit = ['workout_status']
