import { Member } from '../constants/member-constants'
import { Resources } from '../types/permissions-types'
import { BillingGroup } from '../types/billing-groups'
import { MembershipBillingCadences } from '../constants/membership-constants'
import { getFormattedDate } from './date-utils'
import { DEFAULT_TRAINER_PAY_RATE, PayRateResponse, PersonalTrainer } from '../constants/personal-trainer-constants'
import { Order } from '../types/common-types'
import { getComparator, stableSort } from './sort-utils'
import { PayRateProps } from 'src/pages/personal-trainer/personal-trainer-view'
import { PersonalWorkout, workoutCategoriesCaptions } from 'src/constants/personal-workout-constants'
import { Bill } from 'src/constants/billing-v2-contants'

export enum CurrencyFormats {
  enUs = 'en-US',
}

export const getTransformedDataForGetOneApiRequest = (resource: string, data: any) => {
  switch (resource) {
    /**
     *
     */
    case Resources.personalTrainers: {
      const billingGroups = (data?.billing_groups ?? []).map(
        (item: BillingGroup): BillingGroup => ({
          ...item,
          rate_30m: item.rate_30m / 100,
          rate_60m: item.rate_60m / 100,
        }),
      )
      const clients = (data?.clients ?? []).map(
        (
          client: Omit<Member, 'personal_trainer_billing_groups'> & {
            personal_trainer_billing_group: BillingGroup
            personal_trainer_billing_groups: { id: string }[]
          },
        ): Member => {
          const personalTrainerBillingGroups = (client?.personal_trainer_billing_groups ?? []).reduce(
            (acc: BillingGroup[], item) => {
              const billingGroup = billingGroups.find(({ id }: BillingGroup) => id === item.id)
              if (!billingGroup) return acc
              return [...acc, billingGroup]
            },
            [],
          )
          const [personalTrainerBillingGroup] = personalTrainerBillingGroups
          return {
            ...client,
            personal_trainer_billing_group: personalTrainerBillingGroup,
            personal_trainer_billing_groups: personalTrainerBillingGroups,
          }
        },
      )
      return { data: { ...data, billing_groups: billingGroups, clients } }
    }
    /**
     *
     */
    case Resources.personalWorkouts: {
      const clients = (data?.personal_workout_session_clients ?? []).map((item: any) => item.user)
      return { data: { ...data, clients } }
    }
    /**
     *
     */
    default: {
      return { data }
    }
  }
}

export const transformDataList = (resource: Resources, dataList: any) => {
  switch (resource) {
    /**
     *
     */
    case Resources.personalTrainers: {
      return dataList.map((trainer: PersonalTrainer) => {
        const currentDate = new Date()

        const currentPayRates = trainer.pay_rates.filter((rate) => {
          const startDate = new Date(rate.start_date)
          const endDate = rate.end_date ? new Date(rate.end_date) : null
          const isRateCurrentBasedOnDates =
            startDate <= currentDate && ((endDate && endDate > currentDate) || endDate === null)
          return isRateCurrentBasedOnDates
        })

        let current_pay_rate = DEFAULT_TRAINER_PAY_RATE
        if (currentPayRates.length > 0) {
          current_pay_rate = currentPayRates[0]?.pay_rate
        }

        return { ...trainer, current_pay_rate }
      })
    }
    /**
     *
     */
    default: {
      return dataList
    }
  }
}

export const getIncurredChargesStatistics = (charges: Bill) => {
  const chargesTotalCount = charges.line_items?.length
  const chargesTotalAmount = charges.total

  return { chargesTotalCount, chargesTotalAmount }
}

export const formatSessionData = (sessions: PersonalWorkout[]): PersonalWorkout[] => {
  return sessions.map((session) => ({
    ...session,
    bill_rate: session.bill_rate / 100,
    workout_category: workoutCategoriesCaptions[session.workout_category],
  }))
}

export const formatCurrencyNumber = (
  number: number,
  numberFormat: CurrencyFormats = CurrencyFormats.enUs,
  options: Intl.NumberFormatOptions = { style: 'currency', currency: 'USD', maximumFractionDigits: 0 },
) => {
  const internationalNumberFormat = new Intl.NumberFormat(numberFormat, options)
  return internationalNumberFormat.format(number)
}

export const getNextInvoiceMessageForMembership = (
  billingCadence: MembershipBillingCadences,
  nextInvoiceDate: string,
  nextInvoiceAmount: number,
): string => {
  if (billingCadence === 'never') return 'No future invoice due'

  const cadenceFormatted = billingCadence === MembershipBillingCadences.month ? 'monthly' : billingCadence
  const dateFormatted = getFormattedDate(nextInvoiceDate, 'en-US', { month: 'short', day: 'numeric' })
  const amountFormatted = formatCurrencyNumber(nextInvoiceAmount, CurrencyFormats.enUs, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  return `Billing ${cadenceFormatted}ly — next invoice on ${dateFormatted} for ${amountFormatted}`
}

export const capitalizeWord = (word: string): string => {
  return `${word[0].toUpperCase()}${word.slice(1)}`
}

export const convertDecimalToPercent = (decimal: number): string => {
  return `${decimal * 100}%`
}

// cloud pay rate response always sends back currentPayRate + most recent previous one (if exists) and most near future one (if exists)
// since most prev and most near future are optional (there may be no prev or future rates), rates need to be extracted based on dates
export const transformPayRateResponse = (payRates: PayRateResponse[]): PayRateProps => {
  const currentDate = new Date()
  const ratesSortedByStartDate = stableSort(payRates || [], getComparator(Order.asc, 'start_date'))

  const currentRateIndex = ratesSortedByStartDate.findIndex((rate) => {
    const startDate = new Date(rate?.start_date)
    const endDate = rate?.end_date ? new Date(rate?.end_date) : null
    return startDate <= currentDate && (endDate === null || endDate > currentDate)
  })

  const oldRate = ratesSortedByStartDate?.[currentRateIndex - 1]
  const currentRate = ratesSortedByStartDate?.[currentRateIndex]
  const newRate = ratesSortedByStartDate?.[currentRateIndex + 1]

  const extractedRates = {
    oldPayRate: oldRate?.pay_rate ?? 0,
    currentPayRate: currentRate?.pay_rate ?? 0,
    newPayRate: newRate?.pay_rate ?? 0,
    newPayRateStartDate: newRate?.start_date ?? '',
    newPayRateStartDateFormatted: newRate?.start_date
      ? getFormattedDate(newRate?.start_date, 'en-US', {
          timeZone: 'UTC',
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })!
      : '',
  }

  return extractedRates
}
