import React, { useMemo } from 'react'
import { isEqual, xor } from 'lodash'

import { Form, Input, Modal, Select, useWatchAll } from 'src/antd-components'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { useAction } from 'src/hooks/use-actions'
import { RequestError } from 'src/api/http-client'
import { Member, UserRole } from 'src/constants/member-constants'
import { getErrorMessage } from 'src/utils/errors'
import { setMemberRecord } from 'src/redux/member/member-reducer'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import Loader from 'src/components/loader'
import { FormeDialog } from 'src/hooks/useDialog'
import { Checkbox } from 'antd'
import styled from '@emotion/styled'

type FormData = {
  first_name: string
  last_name: string
  roles: UserRole[]
  require_cc: boolean
}

const CheckboxFormItem = styled(Form.Item)`
  .ant-form-vertical &.ant-form-item {
    .ant-form-item-row {
      flex-direction: row;
    }

    .ant-form-item-label {
      display: flex;
      padding: 0 8px 0 0;
    }

    .ant-form-item-control {
      width: auto;
    }
  }
`

export const EditMemberDetailsDialog: FormeDialog<{ record: Member }> = (props) => {
  const {
    params: { record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed: isEditMemberAllowed } = useResourcePermissionHandler(Resources.members)
  const { isEditAllowed: isEditUserRolesAllowed } = useResourcePermissionHandler(Resources.userRoles)
  const { isEditAllowed: isEditRequireCCAllowed } = useResourcePermissionHandler(Resources.charge)

  const onUpdateMemberRecord = useAction(setMemberRecord)

  const initialFormData = useMemo(
    () => ({
      first_name: record.first_name,
      last_name: record.last_name,
      roles: record.roles,
      require_cc: record.require_cc,
    }),
    [record],
  )

  const [form] = Form.useForm<FormData>()
  const { first_name, last_name, roles, require_cc } = useWatchAll(form, initialFormData)

  const clearFormData = () => {
    form.resetFields()
  }

  // so we check each field whether it is valid or it was not edited
  // because we should not block editing first name if last name is already invalid
  // because of external (e.g. Cloud or Studio internal logic) reasons
  const isFormValid =
    (!!first_name.trim() || initialFormData.first_name === first_name) &&
    (!!last_name.trim() || initialFormData.last_name === last_name) &&
    (roles.length || isEqual(initialFormData.roles, roles))

  const hasChanges = useMemo(() => {
    return !isEqual(initialFormData, { first_name: first_name.trim(), last_name: last_name.trim(), roles, require_cc })
  }, [initialFormData, first_name, last_name, roles, require_cc])

  const onEdit = () => {
    const data = {
      first_name: first_name.trim(),
      last_name: last_name.trim(),
      roles: xor(record.roles, roles).length > 0 ? roles : undefined,
      require_cc,
    }
    const params = { id: record.id, data, previousData: { id: record.id } }

    api.common
      .update(Resources.members, params)
      .then(({ data: updatedMember }) => {
        onUpdateMemberRecord(updatedMember)
        onDisplaySuccessNotification('Member was successfully edited')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
    onClose()
  }

  if (isLoading) {
    return <Loader />
  }

  if (!isEditMemberAllowed) {
    return null
  }

  const availableRoles = Object.values(UserRole).map((_) => ({ value: _, label: _ }))

  return (
    <Modal
      title="Edit member details"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid || !hasChanges }}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item label="First Name" name="first_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Last Name" name="last_name">
          <Input allowedChars={/^[a-zA-Z ]*$/} maxLength={30} />
        </Form.Item>
        <Form.Item label="Roles" name="roles" hidden={!isEditUserRolesAllowed}>
          <Select mode="multiple" allowClear options={availableRoles} />
        </Form.Item>
        <CheckboxFormItem label="Require CC" name="require_cc" hidden={!isEditRequireCCAllowed} valuePropName="checked">
          <Checkbox />
        </CheckboxFormItem>
      </Form>
    </Modal>
  )
}
