import { WorkoutStatus, BillingStatus, PaymentStatus } from '../constants/personal-workout-constants'

export const isSessionAvailableForEdit = (
  sessionStatus: WorkoutStatus,
  billingStatus: BillingStatus,
  payStatus: PaymentStatus,
): boolean => {
  if (billingStatus === BillingStatus.Invoiced || payStatus === PaymentStatus.Paid) {
    return false
  }
  return true
}

export const isPromotionDeleteAvailable = (consumed: boolean) => !consumed
