import React, { useState } from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { ReactState } from 'src/utils/react'
import { Query } from 'src/hooks/use-request'
import { Studio } from 'src/api/services/cloud/studios/types'
import { getUniformDateTime } from 'src/utils/uniformDateDisplay'
import TableSectionContainer from 'src/components/table-section-container'
import TextInput from 'src/components/inputs/text-input'
import { Resources } from 'src/types/permissions-types'
import { getFieldDataQa } from 'src/utils/entities-data-qa-utils'
import { StudioEditModal } from './StudioEditModal'
import { DeviceMessagesMenu } from './DeviceMessagesMenu'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { MembershipProfile } from 'src/types/member-profile'

export const StudioCardContent = ({
  studioState,
  isLoading,
  membershipQuery,
}: {
  studioState: ReactState<Studio | undefined>
  isLoading: boolean
  membershipQuery: Query<[id: string], MembershipProfile>
}) => {
  const [studio, setStudio] = studioState
  const [modalVisible, setModalVisible] = useState(false)

  const { isEditAllowed } = useResourcePermissionHandler(Resources.studios)

  return (
    <TableSectionContainer
      withDivider
      title="Studio Info"
      buttonTitle="Edit"
      withAction={isEditAllowed}
      onButtonClick={() => setModalVisible(true)}
    >
      {studio && (
        <>
          <TextInput readOnly label="ID" value={studio.id || ''} id={getFieldDataQa(Resources.studios, 'id')} />
          <TextInput
            readOnly
            label="Lift ID"
            value={studio.lift_id || '-'}
            id={getFieldDataQa(Resources.studios, 'lift_id')}
          />
          <TextInput
            readOnly
            label="Lift registration date"
            value={studio.lift_registration_date ? getUniformDateTime(studio.lift_registration_date) : '-'}
            id={getFieldDataQa(Resources.studios, 'lift_registration_date')}
          />
          <TextInput
            readOnly
            label="Product type"
            value={studio.product_type || '-'}
            id={getFieldDataQa(Resources.studios, 'product_type')}
          />
          {studio.roles.length ? (
            <TextInput
              readOnly
              label="Roles"
              value={studio.roles?.join(', ') || ''}
              id={getFieldDataQa(Resources.studios, 'roles')}
            />
          ) : null}
          <TextInput
            readOnly
            label="Create date"
            value={getUniformDateTime(studio.created_date)}
            id={getFieldDataQa(Resources.studios, 'create_date')}
          />
          <TextInput
            readOnly
            label="Install date"
            value={studio.install_date ? getUniformDateTime(studio.install_date) : '-'}
            id={getFieldDataQa(Resources.studios, 'install_date')}
          />
        </>
      )}
      {studio && isEditAllowed && <DeviceMessagesMenu membershipQuery={membershipQuery} />}
      {isLoading && (
        <Stack alignItems="center" style={{ paddingTop: 20 }}>
          <CircularProgress />
        </Stack>
      )}
      {studio && (
        <StudioEditModal
          studio={studio}
          onStudioChange={setStudio}
          open={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      )}
    </TableSectionContainer>
  )
}
