import React, { useEffect, useMemo } from 'react'

import { Form, Modal, Select, useWatchAll } from 'src/antd-components'

import api from 'src/api'
import { Resources } from 'src/types/permissions-types'
import { useAction } from 'src/hooks/use-actions'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import Loader from 'src/components/loader'
import { FormeDialog } from 'src/hooks/useDialog'
import { requestMembershipFeatureList } from 'src/api/services/cloud/memberships/requestMembershipFeature'
import { useRequest } from 'src/hooks/use-request'
import { fetchMemberTrainers } from 'src/redux/member/member-actions'

type FormData = {
  feature: string | null
}

export const EditAssignedTrainersDialog: FormeDialog<{ record: any; trainer: any }> = (props) => {
  const {
    params: { trainer, record },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed: isEditMemberAllowed } = useResourcePermissionHandler(Resources.members)
  const { data: membershipFeaturesList, fetch: fetchMembershipFeatureList } = useRequest(requestMembershipFeatureList)
  const onUpdateTrainerRecord = useAction(fetchMemberTrainers)

  const initialFormData = useMemo(
    () => ({
      feature: '',
    }),
    [],
  )

  const [form] = Form.useForm<FormData>()
  const { feature } = useWatchAll(form, initialFormData)

  const clearFormData = () => {
    form.resetFields()
  }

  const hasChanges = true

  const onEdit = () => {
    const data = feature
      ? {
          billing_group_id: trainer.personal_trainer_billing_group_id,
          feature_id: feature,
        }
      : {
          billing_group_id: trainer.personal_trainer_billing_group_id,
          feature_id: null,
        }
    const params = {
      id: trainer.trainer_id,
      data,
      previousData: { id: trainer.trainer_id },
      subUrl: `clients/${record.id}`,
    }
    // update trainer clients here
    api.common
      .update(Resources.personalTrainers, params)
      .then(() => {
        onUpdateTrainerRecord(record.id)
        onDisplaySuccessNotification('Member Trainer was successfully updated')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
    onClose()
  }

  useEffect(() => {
    fetchMembershipFeatureList()
  }, [fetchMembershipFeatureList])

  if (isLoading) {
    return <Loader />
  }

  if (!isEditMemberAllowed) {
    return null
  }

  const membershipFeaturesSelectOptions = membershipFeaturesList?.data?.map((_) => ({
    value: _.id,
    label: _.name,
  }))

  return (
    <Modal
      title="Edit member trainer details"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !hasChanges }}
      onOk={onEdit}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
    >
      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item label="Set Feature" name="feature" hidden={false}>
          <Select allowClear options={membershipFeaturesSelectOptions} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
