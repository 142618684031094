import { useMemo } from 'react'

import { Resources } from 'src/types/permissions-types'
import { getButtonDataQa } from 'src/utils/entities-data-qa-utils'
import { ActionItemConfig } from 'src/components/actions-dropdown'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'

import { DialogsController } from './useDialogsController'

export const useActionsConfig = (dialogsController: DialogsController): ActionItemConfig[][] => {
  const {
    onOpenEditTrainerMediaDialog,
    onOpenEditPersonalTrainerDetailsDialog,
    onOpenCreatePersonalTrainerBillingGroup,
    onOpenAssignMemberDialog,
  } = dialogsController

  const { isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)
  const { isCreateAllowed: isBillingGroupsCreateAllowed } = useResourcePermissionHandler(Resources.trainerBillingGroup)
  const { isCreateAllowed: isAssignedMembersCreateAllowed } = useResourcePermissionHandler(Resources.trainerMember)

  return useMemo(() => {
    const editingGroup: ActionItemConfig[] = []
    if (isEditAllowed) {
      editingGroup.push({
        title: 'Edit trainer details',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'editTrainerDetails'),
        onClick: onOpenEditPersonalTrainerDetailsDialog,
      })
      editingGroup.push({
        title: 'Edit media',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'editMedia'),
        onClick: () => onOpenEditTrainerMediaDialog(false),
      })
    }

    const addingGroup: ActionItemConfig[] = []
    if (isBillingGroupsCreateAllowed) {
      addingGroup.push({
        title: 'Add billing group',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'addBillingGroup'),
        onClick: onOpenCreatePersonalTrainerBillingGroup,
      })
    }

    if (isAssignedMembersCreateAllowed) {
      addingGroup.push({
        title: 'Assign member',
        dataQa: getButtonDataQa(Resources.personalTrainers, 'assignMember'),
        onClick: onOpenAssignMemberDialog,
      })
    }

    return [addingGroup, editingGroup]
  }, [
    isAssignedMembersCreateAllowed,
    isBillingGroupsCreateAllowed,
    isEditAllowed,
    onOpenAssignMemberDialog,
    onOpenCreatePersonalTrainerBillingGroup,
    onOpenEditTrainerMediaDialog,
    onOpenEditPersonalTrainerDetailsDialog,
  ])
}
