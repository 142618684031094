import React from 'react'
import { Resources } from '../../types/permissions-types'
import ResourceListContainer from '../../containers/resource-list-container'
import {
  AvatarCell,
  DataCell,
  DataDropCell,
  DateCell,
  getCellValue,
} from '../../components/accessible-table/accessible-table-cell'
import { ColumnItem } from '../../components/accessible-table/acessible-table-types'
import api from 'src/api'
import styled from '@emotion/styled'
import { WORKFLOWS_TPI } from 'src/constants/common-constants'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'
import { RequestError } from 'src/api/http-client'
import { getErrorMessage } from 'src/utils/errors'
import { TpiWorkflowState } from 'src/constants/tpi-constants'
import { routing } from 'src/boot/routing'
import { getClientTimezone } from 'src/api/utils'

const apiResource = api.tpi.getList

const StyledTD = styled.td`
  border-bottom: 1px solid #e0e0e0;
`
const TPIWorkflowsList = ({ filterParams }: { filterParams?: { [key: string]: string } }) => {
  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const onChangeTPIWorkflow = (value: TpiWorkflowState, tpiRecord: Record<string, any>) => {
    if (tpiRecord?.id) {
      api.tpi
        .updateTPIWorkflow(tpiRecord?.id, { workflow_state: value })
        .then(() => {
          onDisplaySuccessNotification('TPI workflow state has been updated')
        })
        .catch((error: RequestError) => {
          const errorMessage = getErrorMessage(error)
          onDisplayErrorNotification(errorMessage)
        })
    } else {
      onDisplayErrorNotification('Failed: ID is null')
    }
  }

  const workflowsColumns: ColumnItem[] = [
    { key: 'image', title: 'Avatar', fields: ['image'], render: AvatarCell },
    {
      key: 'name',
      title: 'Name',
      fields: ['first_name', 'last_name'],
      render: DataCell,
      orderByField: 'first_name',
      link: (row: { user_id: string }) => routing.member.generatePath({ id: row.user_id }),
    },
    { key: 'email', title: 'Email', fields: ['email'], render: DataCell, orderByField: 'email' },
    {
      key: 'workflow_state',
      title: 'Workflow State',
      fields: ['workflow_state'],
      render: (props) => {
        let namedCellValue = '-'
        let resultChoices = WORKFLOWS_TPI
        switch (getCellValue(props)) {
          case TpiWorkflowState.confirming:
            resultChoices = []
            namedCellValue = 'Emailed'
            break
          case TpiWorkflowState.trainerAssigned:
            resultChoices = []
            namedCellValue = 'Complete'
            break
          case TpiWorkflowState.activating:
            resultChoices = []
            namedCellValue = 'In progress'
            break
          case TpiWorkflowState.active: {
            namedCellValue = 'Configuration'
            resultChoices = resultChoices.filter((data) => TpiWorkflowState.trainerAssigned === data.id)
            break
          }
          case TpiWorkflowState.rejected: {
            namedCellValue = 'Rejected'
            resultChoices = []
            break
          }
          case TpiWorkflowState.contacted: {
            namedCellValue = 'Contacted'
            resultChoices = resultChoices.filter((data) =>
              [TpiWorkflowState.rejected, TpiWorkflowState.confirming].includes(data.id),
            )
            break
          }
          case TpiWorkflowState.created: {
            namedCellValue = 'Interested'
            resultChoices = resultChoices.filter((data) =>
              [TpiWorkflowState.contacted, TpiWorkflowState.rejected].includes(data.id),
            )
            break
          }
          default:
            resultChoices = []
        }
        return (
          <>
            {resultChoices.length === 0 && <StyledTD>{namedCellValue}</StyledTD>}
            {resultChoices.length > 0 && (
              <DataDropCell
                dropData={resultChoices}
                onSelect={(value: TpiWorkflowState) => {
                  onChangeTPIWorkflow(value, props.rowData)
                }}
                {...props}
              />
            )}
          </>
        )
      },
      orderByField: 'workflow_state',
    },
    {
      key: 'updated_date',
      title: 'Updated',
      fields: ['updated_date'],
      render: (props) => (
        <DateCell
          options={{ year: 'numeric', month: 'long', day: 'numeric', timeZone: getClientTimezone() ?? undefined }}
          {...props}
        />
      ),
      orderByField: 'updated_date',
    },
  ]

  if (!filterParams) {
    filterParams = {}
  }
  return (
    <ResourceListContainer
      withCreate={false}
      withClickableRows={false}
      searchFilter={undefined}
      searchFilterPlaceholder="Search by name or email..."
      columns={workflowsColumns}
      resource={Resources.tpiWorkflows}
      apiResource={apiResource}
      filterApiResource={filterParams || {}}
    />
  )
}

export default TPIWorkflowsList
