import React from 'react'
import ReactDOM from 'react-dom'

import App from './boot/app'
import AuthContextProvider from './context-providers/auth'
import { ErrorBoundary } from './utils/components/error-boundary'
import { ErrorFallback } from './components/error-fallback'

import 'antd/dist/antd.css'
import './index.css'

ReactDOM.render(
  <ErrorBoundary ErrorFallback={ErrorFallback}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
)
