import React from 'react'
import { TpiWorkflowState } from 'src/constants/tpi-constants'
import TPIsPage from '../tpis'

const TPIWorkflowsActivatingList = () => {
  const filterParams = {
    [`workflow_state||$eq`]: TpiWorkflowState.activating,
  }
  return <TPIsPage filterParams={filterParams} />
}

export default TPIWorkflowsActivatingList
