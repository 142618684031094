import React, { useMemo } from 'react'
import styled from '@emotion/styled'

import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { useAction } from '../../../hooks/use-actions'
import { FormContainer } from '../../../components/ui-kit'
import { BillingGroup } from '../../../types/billing-groups'
import { RequestError } from '../../../api/http-client'
import { PersonalTrainer } from '../../../constants/personal-trainer-constants'
import { getErrorMessage } from '../../../utils/errors'
import { setPersonalTrainerRecord } from '../../../redux/personal-trainer/personal-trainer-reducer'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import Loader from '../../../components/loader'
import { FormeDialog } from '../../../hooks/useDialog'
import { InputNumber, Modal, Input, Form, useWatchAll } from 'src/antd-components'

type CreatePersonalTrainerBillingGroup = {
  record?: PersonalTrainer
}

const StyledFormContainer = styled(FormContainer)`
  width: 494px;
`

type FormData = {
  name: string
  rate_30m: number
  rate_60m: number
}

const DEFAULT_VALUES: FormData = {
  name: '',
  rate_30m: 0,
  rate_60m: 0,
}

// const dataQa = {
//   billingGroupName: getFieldDataQa(Resources.personalTrainers, '/create-billing-group/name'),
//   rate_30m: getFieldDataQa(Resources.personalTrainers, '/create-billing-group/rate_30m'),
//   rate_60m: getFieldDataQa(Resources.personalTrainers, '/create-billing-group/rate_60m'),
// }

const defaultRecord = { id: '', billing_groups: [], default_billing_group_id: null }

const CreatePersonalTrainerBillingGroupDialog: FormeDialog<CreatePersonalTrainerBillingGroup> = (props) => {
  const {
    params: { record = defaultRecord },
    open: isOpened,
    onClose,
  } = props
  const { id: personalTrainerId, billing_groups: billingGroups = [] as BillingGroup[] } = record

  const [form] = Form.useForm<FormData>()

  const { name: billingGroupName, rate_30m, rate_60m } = useWatchAll(form, DEFAULT_VALUES)

  const onUpdatePersonalTrainerRecord = useAction(setPersonalTrainerRecord)

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()

  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalTrainers)

  const freeBillingGroup = billingGroups.find((item: BillingGroup) => item.rate_30m === 0 && item.rate_60m === 0)
  const isFreeBillingGroupExist = !!freeBillingGroup

  const clearFormData = () => {
    form.resetFields()
  }

  const isFormDataValid = useMemo(() => {
    const isBillingGroupNameValid = !!billingGroupName.trim()
    const isFreeBillingGroup = rate_30m === 0 && rate_60m === 0
    const isRatesFulfilled = rate_30m > 0 && rate_60m > 0
    const isRatesAllowed = isRatesFulfilled || (isFreeBillingGroup && !isFreeBillingGroupExist)
    const isBillingGroupNameAlreadyExists = billingGroups.some(
      (billingGroup) => billingGroup.name === billingGroupName.trim(),
    )
    return isBillingGroupNameValid && isRatesAllowed && !isBillingGroupNameAlreadyExists
  }, [isFreeBillingGroupExist, billingGroups, billingGroupName, rate_30m, rate_60m])

  const onAddButtonPress = () => {
    const params = {
      data: {
        name: billingGroupName.trim(),
        rate_30m: rate_30m * 100,
        rate_60m: rate_60m * 100,
      },
      subUrl: `${personalTrainerId}/billing-groups`,
    }

    api.common
      .create(Resources.personalTrainers, params)
      .then(({ data: billingGroup }) => {
        const updatedRecord = record.default_billing_group_id
          ? record
          : { ...record, default_billing_group_id: billingGroup.id }
        const newBillingGroup = {
          ...billingGroup,
          rate_30m: billingGroup.rate_30m / 100,
          rate_60m: billingGroup.rate_60m / 100,
        }
        const nextRecord = {
          ...updatedRecord,
          billing_groups: [...updatedRecord.billing_groups, newBillingGroup],
        } as PersonalTrainer
        onUpdatePersonalTrainerRecord(nextRecord)
        onDisplaySuccessNotification('Billing group was successfully created')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })
  }

  const onAccept = () => {
    onAddButtonPress()
    clearFormData()
    onClose()
  }

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed) {
    return null
  }

  return (
    <Modal
      title="Add new billing group"
      cancelText="Cancel"
      okText="Add"
      open={isOpened}
      okButtonProps={{
        disabled: !isFormDataValid,
      }}
      onOk={onAccept}
      onCancel={() => {
        clearFormData()
        onClose()
      }}
      width={545}
    >
      <StyledFormContainer>
        <Form form={form} initialValues={DEFAULT_VALUES} labelAlign="right" layout="vertical">
          <Form.Item label="Name" name="name">
            <Input allowedChars={/^[a-zA-Z0-9- ]*$/} maxLength={30} />
          </Form.Item>

          <Form.Item label="30 Minute Bill Rate" name="rate_30m">
            <InputNumber min={0} max={21474836} prefix="$" />
          </Form.Item>
          <Form.Item label="60 Minute Bill Rate" name="rate_60m">
            <InputNumber min={0} max={21474836} prefix="$" />
          </Form.Item>
        </Form>
      </StyledFormContainer>
    </Modal>
  )
}

export default CreatePersonalTrainerBillingGroupDialog
