import React, { ReactNode } from 'react'
import { LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { format } from 'date-fns'

import { OrderEvent, requestEventsLog } from 'src/api/services/orders/events/requestEventsLog'
import { useRequest } from 'src/hooks/use-request'
import { getClientTimezone } from 'src/api/utils'

const formatDateTime = (date: string) => {
  const timezonedDate = new Date(date).toLocaleString('en-US', { timeZone: getClientTimezone() ?? undefined })
  return format(new Date(timezonedDate), 'yyyy-MM-dd, h:mmaaa')
}

type Column = {
  key: string
  title?: string
  render: (props: OrderEvent) => ReactNode
}

const columns: Column[] = [
  {
    key: 'id',
    title: 'ID',
    render: (_) => _.id,
  },
  {
    key: 'event_type',
    title: 'Event type',
    render: (_) => _.event_type,
  },
  {
    key: 'event_date',
    title: 'Event date',
    render: (_) => _.event_date,
  },
  {
    key: 'original_scheduled_date',
    title: 'Original scheduled date',
    render: (_) => _.original_scheduled_date,
  },
  {
    key: 'scheduled_date',
    title: 'Scheduled date',
    render: (_) => _.scheduled_date,
  },
  {
    key: 'status',
    title: 'Status',
    render: (_) => _.status,
  },
  {
    key: 'created_at',
    title: 'Created at',
    render: (_) => formatDateTime(_.created_at),
  },
  {
    key: 'updated_at',
    title: 'Updated at',
    render: (_) => formatDateTime(_.updated_at),
  },
]

export const EventsLogTable = ({ shopifyOrderName }: { shopifyOrderName: string }) => {
  const { data, isLoading } = useRequest(requestEventsLog, [{ order_id: shopifyOrderName }])

  if (isLoading) return <LinearProgress />
  if (!data) return null
  if (data.length === 0) return <Typography>No events found</Typography>

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell key={column.key}>{column.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((event) => (
          <TableRow key={event.id}>
            {columns.map((column) => (
              <TableCell key={column.key} sx={{ borderBottom: 'none' }}>
                {column.render(event)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
