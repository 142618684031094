import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { clearRecordData } from '../../redux/member/member-reducer'
import { useAction } from '../../hooks/use-actions'
import { getMemberError } from '../../redux/member/member-selectors'
import { useResourcePermissionHandler } from 'src/hooks/use-resource-permission-handler'
import { Resources } from '../../types/permissions-types'
import { usePreviousValue } from '../../hooks/use-previous-value'
import { getErrorMessage } from '../../utils/errors'
import useSnackbarNotifications from '../../hooks/use-snackbar-notifications'
import {
  fetchMemberIncurredCharges,
  fetchMemberPromotions,
  fetchMemberRecord,
  fetchMemberSessions,
  fetchMemberStudios,
} from '../../redux/member/member-actions'

export const useMemberData = (recordId?: string) => {
  const memberError = useSelector(getMemberError)
  const onFetchMemberRecord = useAction(fetchMemberRecord)
  const onFetchStudios = useAction(fetchMemberStudios)
  const onFetchIncurredCharges = useAction(fetchMemberIncurredCharges)
  const onFetchSessions = useAction(fetchMemberSessions)
  const onFetchPromotions = useAction(fetchMemberPromotions)
  const onClearRecordData = useAction(clearRecordData)

  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const { isReadAllowed } = useResourcePermissionHandler(Resources.members)

  const prevIsReadAllowed = usePreviousValue(isReadAllowed)

  useEffect(() => {
    const isReadAllowedChanged = isReadAllowed !== prevIsReadAllowed

    if (recordId && isReadAllowed && isReadAllowedChanged) {
      onFetchMemberRecord(recordId)
      onFetchStudios(recordId)
      onFetchIncurredCharges(recordId)
      onFetchSessions(recordId)
      onFetchPromotions(recordId)
    }
  }, [
    recordId,
    isReadAllowed,
    prevIsReadAllowed,
    onFetchMemberRecord,
    onFetchStudios,
    onFetchIncurredCharges,
    onFetchSessions,
    onFetchPromotions,
  ])

  useEffect(() => {
    if (!!memberError) {
      onDisplayErrorNotification(getErrorMessage(memberError))
    }
  }, [memberError, onDisplayErrorNotification])

  useEffect(() => () => onClearRecordData(), [onClearRecordData])
}
