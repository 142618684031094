import * as React from 'react'
import { Accordion, AccordionSummary, AccordionDetails as MuiAccordionDetails, styled } from '@mui/material'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { UserBundle } from 'src/api/services/bundles'
import BundleData from './components/bundle-data'

interface BundleDataProps {
  bundlesList: UserBundle[]
}

const Title = styled(Typography)`
  flex-shrink: 0;
  width: 80%;
`

const Subtitle = styled(Typography)`
  color: ${(_) => _.theme.palette.text.secondary};
  margin-left: 50px;
`

const AccordionDetails = styled(MuiAccordionDetails)`
  color: ${(_) => _.theme.palette.text.secondary};
`

export default function BundleAccordion({ bundlesList }: BundleDataProps) {
  const [expanded, setExpanded] = React.useState<number | 0>(0)

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : 0)
  }

  return (
    <div>
      {bundlesList
        .filter(({ available_sessions }) => available_sessions)
        .map((data: UserBundle) => {
          return (
            <Accordion
              key={data.id}
              expanded={expanded === data.id}
              onChange={handleChange(data.id)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Title>{data.display_name}</Title>
                <Subtitle>{data.state}</Subtitle>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <BundleData data={data} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
    </div>
  )
}
