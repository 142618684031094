import { useEffect, useMemo } from 'react'
import { nanoid } from 'nanoid'

import { useRequest } from 'src/hooks/use-request'
import { requestServiceCodes, IOrderServiceCode } from 'src/api/services/orders/service-codes/requestServiceCodes'

const cache: { value?: IOrderServiceCode[] } = {}

export const useCachedServiceCodes = () => {
  const { data = cache.value, fetch } = useRequest(requestServiceCodes)
  useEffect(() => {
    if (!data) {
      fetch().then((response) => (cache.value = response))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return useMemo(() => data?.map((item) => ({ ...item, key: nanoid() })), [data])
}
