import React, { useEffect, useMemo } from 'react'
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import styled from '@emotion/styled'

import { IProduct } from 'src/api/services/orders/types'
import api from 'src/api'
import { useRequest } from 'src/hooks/use-request'
import { SyncButton } from './sync-button'
import { Order } from 'src/types/common-types'
import { getComparator, stableSort } from 'src/utils/sort-utils'

import { GreyHeaderTableCell } from '../../../../components/GreyHeaderTableCell'
import useSnackbarNotifications from 'src/hooks/use-snackbar-notifications'

const columns: { field: keyof IProduct; title: string }[] = [
  { field: 'sku', title: 'SKU' },
  { field: 'part', title: 'Part' },
  { field: 'description', title: 'Description' },
  { field: 'dimUOM', title: 'Dim UOM' },
  { field: 'length', title: 'Length(in.)' },
  { field: 'width', title: 'Width(in.)' },
  { field: 'height', title: 'Height(in.)' },
  { field: 'weightUOM', title: 'Weight UOM' },
  { field: 'weight', title: 'Weight(lbs.)' },
]

const ProductsTable = ({ rows }: { rows: IProduct[] }) => (
  <Table stickyHeader>
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <GreyHeaderTableCell key={column.field}>{column.title}</GreyHeaderTableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          {columns.map((column) => (
            <TableCell key={column.field}>{row[column.field]}</TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

const RootStack = styled(Stack)`
  height: 100%;
`

const TableWrapperStack = styled(Stack)`
  margin-top: 16px;
  overflow-y: auto;
`

export const Products = () => {
  const { onDisplayErrorNotification } = useSnackbarNotifications()
  const { data, error, fetch } = useRequest(api.orders.getProducts, [])

  const tableDataList = useMemo(() => stableSort(data || [], getComparator(Order.asc, 'sku')), [data])

  useEffect(() => {
    if (error) onDisplayErrorNotification(error.message)
  }, [error, onDisplayErrorNotification])

  return (
    <RootStack direction="column">
      <div>
        <SyncButton onSuccess={fetch} />
      </div>
      {data && (
        <TableWrapperStack direction="column" flex={1}>
          <ProductsTable rows={tableDataList} />
        </TableWrapperStack>
      )}
    </RootStack>
  )
}
