import React, { useEffect, useMemo, useState } from 'react'

import { Divider, Form, Modal, Select, Skeleton, Typography, toAntOptions, useWatchAll } from 'src/antd-components'
import { useRequest } from 'src/hooks/use-request'

import api from '../../../../api'
import { Member } from '../../../../constants/member-constants'
import { Resources } from '../../../../types/permissions-types'
import { useAction } from '../../../../hooks/use-actions'
import { RequestError } from '../../../../api/http-client'
import { getErrorMessage } from '../../../../utils/errors'
import { useResourcePermissionHandler } from '../../../../hooks/use-resource-permission-handler'
import Loader from '../../../../components/loader'

import useSnackbarNotifications from '../../../../hooks/use-snackbar-notifications'
import { fetchMemberIncurredCharges, fetchMemberSessions } from '../../../../redux/member/member-actions'
import {
  BillingStatus,
  billingStatusChoices,
  PaymentStatus,
  PersonalWorkout,
  personalWorkoutStatusChoices,
} from '../../../../constants/personal-workout-constants'
import { FormeDialog } from '../../../../hooks/useDialog'
import { isSessionAvailableForEdit } from 'src/utils/edit-utils'
import { BillingGroup } from 'src/types/billing-groups'

type FormData = {
  sessionStatus: string
  billingStatus: string
  billingGroupId: string
}

const personalWorkoutStatusOptions = toAntOptions(personalWorkoutStatusChoices)

export const EditMemberSessionDetailsDialog: FormeDialog<{ record: Member; session: PersonalWorkout }> = (props) => {
  const {
    params: { record, session },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalWorkouts)
  const sessionIsPaid = useMemo(
    () => !isSessionAvailableForEdit(session.workout_status, session.billing_status, session.payment_status),
    [session.billing_status, session.payment_status, session.workout_status],
  )

  const sessionChangingIsDisable = sessionIsPaid
  const initialFormData = {
    sessionStatus: session?.workout_status,
    billingStatus: session?.billing_status,
    billingGroupId: session?.billing_group_id,
  }
  const [form] = Form.useForm<FormData>()
  const sessionDetails = useWatchAll(form, initialFormData)

  const [initValues, setInitValues] = useState<{
    workout_status: string
    billing_status: string
    billing_group_id: string
  }>({
    workout_status: session?.workout_status.toString(),
    billing_status: session?.billing_status,
    billing_group_id: session?.billing_group_id,
  })

  const {
    data: sessionGroups,
    isLoading: isLoadingSessionGroups,
    error: errorSessionGroups,
    fetch: fetchSessionGroups,
  } = useRequest(() =>
    api.common
      .getOne(Resources.personalTrainers, { id: session.trainer_id })
      .then((resp) => resp.data.billing_groups.map((item: BillingGroup) => ({ value: item.id, label: item.name }))),
  )

  useEffect(() => {
    if (errorSessionGroups) {
      onDisplayErrorNotification('Sorry, something went wrong, please reload the page')
    }
  }, [errorSessionGroups, onDisplayErrorNotification])

  const updateSessions = useAction(fetchMemberSessions)
  const updateIncurredCharges = useAction(fetchMemberIncurredCharges)

  const billingStatusOptions = useMemo(() => {
    return toAntOptions(
      sessionIsPaid
        ? billingStatusChoices.filter(({ id }) => id === sessionDetails.billingStatus)
        : billingStatusChoices.filter(({ id }) => id !== BillingStatus.Invoiced),
    )
  }, [sessionDetails.billingStatus, sessionIsPaid])

  const isAvailableSessionGroups = useMemo(
    () => session.billing_status !== BillingStatus.Invoiced && session.payment_status !== PaymentStatus.Paid,
    [session],
  )

  let isFormValid =
    (sessionDetails?.sessionStatus && sessionDetails.sessionStatus !== session?.workout_status) ||
    (sessionDetails?.billingStatus && sessionDetails.billingStatus !== session?.billing_status)
  if (isAvailableSessionGroups) {
    isFormValid = isFormValid || initValues.billing_group_id !== sessionDetails.billingGroupId
  }

  const updatePersonalWorkout = async () => {
    try {
      await api.common.update(Resources.personalWorkouts, {
        id: session.id,
        data: {
          workout_status: sessionDetails.sessionStatus,
          billing_status: sessionDetails.billingStatus ?? session.billing_status,
          billing_group_id: sessionDetails.billingGroupId,
        },
        previousData: {
          ...initValues,
          id: session.id,
        },
      })
      updateSessions(record.id)
      updateIncurredCharges(record.id)

      onDisplaySuccessNotification('Session was successfully updated')
      onClose()
    } catch (err: unknown) {
      const errorMessage = getErrorMessage(err as RequestError)
      onDisplayErrorNotification(errorMessage)
      updateSessions(record.id)
      updateIncurredCharges(record.id)
    }
  }

  const { fetch: onEditMemberSession, isLoading: isSubmitting } = useRequest(updatePersonalWorkout)

  useEffect(() => {
    if (isOpened && session?.id) {
      if (isAvailableSessionGroups) {
        fetchSessionGroups()
      }

      setInitValues({ ...initValues, billing_group_id: session.billing_group_id })

      form.setFieldsValue({
        sessionStatus: session.workout_status,
        billingStatus: session.billing_status,
        billingGroupId: session.billing_group_id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, session, onDisplayErrorNotification])

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed || !session?.id) {
    return null
  }

  return (
    <Modal
      title="Edit session details"
      cancelText="Cancel"
      okText="Save"
      open={isOpened}
      okButtonProps={{ disabled: !isFormValid }}
      onOk={onEditMemberSession}
      onCancel={onClose}
      confirmLoading={isSubmitting}
    >
      <Typography.Paragraph>
        Session id:{' '}
        <Typography.Text code copyable>
          {session?.id || ''}
        </Typography.Text>
      </Typography.Paragraph>
      <Divider />

      <Form form={form} initialValues={initialFormData} labelAlign="right" layout="vertical">
        <Form.Item label="Session Status" name="sessionStatus">
          <Select disabled={sessionChangingIsDisable} options={personalWorkoutStatusOptions} />
        </Form.Item>
        <Form.Item label="Billing Status" name="billingStatus">
          <Select options={billingStatusOptions} />
        </Form.Item>

        {isLoadingSessionGroups && <Skeleton active paragraph={{ rows: 1 }} />}

        {isAvailableSessionGroups && (
          <Form.Item label="Billing Group" name="billingGroupId">
            <Select options={sessionGroups} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}
