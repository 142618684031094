export enum BundleSourceType {
  msbundles = 'msbundles',
}

export type CloudBundle = {
  id: number
  consumed_at?: number
  sessions_count: number
  unit_price: number
  bundle_price: number
  category: 'general' | 'tpi'
  display_name: string
  consumed_sessions: number
  available_sessions: number
  installment_payed: number
  charges: Charge[]
  source_id: string
  source_type: BundleSourceType
  sku: string
  is_recurring: boolean
}

export interface Charge {
  /** Should match auth0 permission. */
  features: string[]
  /** recurring, one time, etc */
  type: 'recurring' | 'per_item' | 'one_time'
  /** monthly */
  period: 'monthly' | 'biweekly' | 'annual'
  /** paid, pending, etc */
  status: 'not_paid' | 'pending' | 'paid' | 'failed' | 'cancelled'
  /** $39.00 for monthly hardware membership */
  price: number
  taxable: boolean
  user_id: string
  coupons?: Coupon[] | null
  tax_code?: string
  line_item_title: string
  source: 'monthlyMembership' | 'sessionsBundle' | 'personalTraining' | 'manual'
  source_id: string
  /** @format date-time */
  due_date: string
  id: string
  /** @format date-time */
  created_date: string
  /** @format date-time */
  updated_date: string
  /** @format date-time */
  deleted_date: string
}

export enum CouponStatus {
  active = 'active',
  expired = 'expired',
  pending = 'pending',
  used = 'used',
}

export enum CouponChargeKind {
  monthlyMembership = 'monthlyMembership',
  sessionsBundle = 'sessionsBundle',
  personalTraining = 'personalTraining',
}

export enum CouponSourceType {
  shopifyBundlePayment = 'shopifyBundlePayment',
  sessionsBundle = 'sessionsBundle',
  manualByOperator = 'manualByOperator',
}

export interface Coupon {
  charge_id?: string | null
  charge?: Charge | null
  status: CouponStatus
  charge_kind: CouponChargeKind
  fixed_price?: number | null
  discount_percent?: number | null
  /** @format date-time */
  expire_date?: string | null
  created_by?: string | null
  source_type: CouponSourceType
  user_id: string
  line_item_id?: string | null
  id: string
  /** @format date-time */
  created_date: string
  /** @format date-time */
  updated_date: string
  /** @format date-time */
  deleted_date: string
}

export interface Bill {
  /** invoiced, paid, etc */
  status: 'not_billable' | 'billable' | 'invoiced' | 'paid' | 'failed'
  /**
   * bill can't be created without a due date...
   * @format date-time
   */
  due_date: string
  /** corresponding stripe id */
  external_id?: string | null
  /**
   * corresponding Avalara (Avatax) id
   * can be the same as external id in case of Recurly
   */
  external_tax_id?: string | null
  line_items: BillLineItem[] | null
  /**
   * total amount SUM(line_items[i].total)
   * @default 0
   */
  total: number
  /** @default [] */
  charge_ids: string[]
  id: string
  /** @format date-time */
  created_date: string
  /** @format date-time */
  updated_date: string
  /** @format date-time */
  deleted_date: string
}

export interface BillLineItem {
  title: string
  description?: string | null
  /**
   * $39.00 sub-total is the price before taxes
   * @default 0
   */
  sub_total: number
  /** taxes calculated for (sub-total - discount) if taxable */
  taxes: number
  /**
   * total price: (sub-total - discount) + taxes
   * @default 0
   */
  total: number
  coupons: (Coupon[] | null)[]
  /** invoiced, paid, etc */
  status: 'not_billable' | 'billable' | 'invoiced' | 'paid' | 'failed'
  taxable: boolean
  bill_id: string
  charge_id: string
  charge: Charge | null
  /** corresponding stripe id */
  external_id?: string | null
  /**
   * corresponding Avalara (Avatax) id
   * can be the same as external id in case of Recurly
   */
  external_tax_id?: string | null
  bill: Bill
  id: string
  /** @format date-time */
  created_date: string
  /** @format date-time */
  updated_date: string
  /** @format date-time */
  deleted_date: string
}

export interface BillingSchedule {
  created_date: string
  updated_date: string
  user_id: string
  period: string
  due_date: string
  force_billing_period: any
  status: string
  monthly_anchor_day: number
}
