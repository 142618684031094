import { styled, TextField } from '@mui/material'

const SearchInput = styled(TextField)({
  width: 315,
  maxWidth: 315,
  height: 30,
  maxHeight: 30,
  '> .MuiInputBase-root': {
    width: 315,
    maxWidth: 315,
    height: 30,
    maxHeight: 30,
  },
})

export default SearchInput
