export const mainColor = '#5868CE'
export const secondaryColor = '#FFF'
export const disabledButtonColor = 'grey'
export const errorColor = 'rgb(244, 67, 54)'

export const mainTextColor = '#000'
export const mainOppositeTextColor = '#FFF'

export const titleCellBackground = '#f5f5f5'

export const HEADER_HEIGHT = 60
export const FOOTER_HEIGHT = 30
export const DRAWER_WIDTH = 250
