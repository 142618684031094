import React, { useMemo } from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import styled from '@emotion/styled'

import api from '../api'
import { NOOP } from '../constants/common-constants'
import { Resources } from '../types/permissions-types'
import { PageContainer } from '../components/ui-kit'
import { useResourcePermissionHandler } from '../hooks/use-resource-permission-handler'
import { LIMITED_PERMISSIONS_MAIN_MESSAGE, LIMITED_PERMISSIONS_SECONDARY_MESSAGE } from '../constants/messages'
import StubPage from '../components/stub-page'
import Loader from '../components/loader'

type ResourceRecordContainerProps = {
  record: Record<string, any> | null
  resource: Resources
  children: React.ReactNode
  withToolbar?: boolean
  withCreate?: boolean
  withDelete?: boolean
  onCreate?: () => void
}

const Actions = styled.div`
  display: flex;
  padding: 30px 20px 20px;
  justify-content: flex-end;
`

function ResourceRecordContainer(props: ResourceRecordContainerProps) {
  const { record, resource, children, withCreate, withDelete, onCreate = NOOP } = props

  const { isLoading, isAuthenticated, isReadAllowed } = useResourcePermissionHandler(resource)

  const onDelete = () => {
    if (record?.id) api.common.delete(resource, { id: record.id })
  }

  const hasActions = useMemo(() => withDelete || withCreate, [withCreate, withDelete])

  if (isLoading) {
    return <Loader />
  }

  if (!record || !isAuthenticated) {
    return null
  }

  if (!isReadAllowed) {
    return (
      <StubPage
        mainMessage={LIMITED_PERMISSIONS_MAIN_MESSAGE}
        secondaryMessage={LIMITED_PERMISSIONS_SECONDARY_MESSAGE}
      />
    )
  }

  return (
    <PageContainer>
      {hasActions && (
        <Actions>
          {withDelete && (
            <Button startIcon={<DeleteIcon />} onClick={onDelete}>
              Delete
            </Button>
          )}
          {withCreate && (
            <Button startIcon={<AddIcon />} onClick={onCreate}>
              Create
            </Button>
          )}
        </Actions>
      )}
      {children}
    </PageContainer>
  )
}

export default ResourceRecordContainer
