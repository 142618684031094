import React, { ReactNode, useMemo, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { CssBaseline, Container } from '@mui/material'
import styled from '@emotion/styled'

import { PageContainer } from '../components/ui-kit'
import { useAuthHandler } from '../hooks/use-auth-handler'
import Loader from '../components/loader'
import NavigationDrawer, { COLLAPSED_DRAWER_WIDTH, DRAWER_WIDTH } from './NavigationDrawer'

type LayoutProps = RouteComponentProps & {
  children: ReactNode
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  height: 100%;
`

const StyledContainer = styled(PageContainer.withComponent(Container))`
  max-width: 100% !important;
  margin: 0;
  padding: 0px 24px;
`

const Content = styled.div<{ collapsed?: boolean }>`
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  margin-left: ${(props) => (props.collapsed ? `${COLLAPSED_DRAWER_WIDTH}px` : `${DRAWER_WIDTH}px`)};
`

function BaseLayout(props: LayoutProps) {
  const { children } = props
  const { isLoading } = useAuthHandler()
  const [collapsed, setCollapsed] = useState(false)

  const renderContent = useMemo(() => (isLoading ? <Loader /> : children), [children, isLoading])

  return (
    <Root>
      <CssBaseline />
      <StyledContainer>
        <Main id="main-content">
          <NavigationDrawer collapsed={collapsed} onCollapsedChange={() => setCollapsed(!collapsed)} />
          <Content collapsed={collapsed}>{renderContent}</Content>
        </Main>
      </StyledContainer>
    </Root>
  )
}

export default withRouter(BaseLayout)
