import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import styled from '@emotion/styled'

import api from '../../../api'
import { Resources } from '../../../types/permissions-types'
import { PersonalTrainer } from '../../../constants/personal-trainer-constants'
import { useAction } from '../../../hooks/use-actions'
import { ColumnSection } from '../../../components/ui-kit'
import { RequestError } from '../../../api/http-client'
import { getFieldDataQa } from '../../../utils/entities-data-qa-utils'
import { getErrorMessage } from '../../../utils/errors'
import { useResourcePermissionHandler } from '../../../hooks/use-resource-permission-handler'
import Loader from '../../../components/loader'
import BasicDialog from '../../../components/dialogs/basic-dialog'
import SelectInput from '../../../components/inputs/select-input'
import useSnackbarNotifications from '../../../hooks/use-snackbar-notifications'
import {
  PersonalWorkout,
  personalWorkoutStatusChoices,
  personalWorkoutDurationChoices,
} from '../../../constants/personal-workout-constants'
import { fetchPersonalTrainerSessions } from '../../../redux/personal-trainer/personal-trainer-actions'
import { FormeDialog } from '../../../hooks/useDialog'

type EditTrainerSessionDetailsProps = {
  record: PersonalTrainer
  session: PersonalWorkout
}

type FormData = {
  sessionStatus: string
  duration: number
}

const Container = styled.div`
  overflow: hidden;
`

const Section = styled.div`
  padding-bottom: 30px;
`

const EditTrainerSessionDetailsDialog: FormeDialog<EditTrainerSessionDetailsProps> = (props) => {
  const {
    params: { record, session },
    open: isOpened,
    onClose,
  } = props

  const { onDisplayErrorNotification, onDisplaySuccessNotification } = useSnackbarNotifications()
  const { isLoading, isEditAllowed } = useResourcePermissionHandler(Resources.personalWorkouts)

  const [sessionDetails, setSessionDetails] = useState<FormData>({
    sessionStatus: session?.workout_status,
    duration: session?.duration,
  })

  useEffect(() => {
    if (isOpened && session?.id) {
      setSessionDetails({ sessionStatus: session.workout_status, duration: session.duration })
    }
  }, [isOpened, session, setSessionDetails])

  const updateSessions = useAction(fetchPersonalTrainerSessions)

  const isFormValid = sessionDetails?.sessionStatus && sessionDetails.sessionStatus !== session?.workout_status

  if (isLoading) {
    return <Loader />
  }

  if (!isEditAllowed || !session?.id) {
    return null
  }

  const onChangeField = (field: string, value: string | number) =>
    setSessionDetails({ ...sessionDetails, [field]: value })

  const onEditTrainerSession = () => {
    const params = {
      id: session.id,
      data: {
        workout_status: sessionDetails.sessionStatus,
      },
      previousData: {
        id: session.id,
      },
    }
    api.common
      .update(Resources.personalWorkouts, params)
      .then(() => {
        updateSessions(record.id)
        onDisplaySuccessNotification('Session was successfully updated')
      })
      .catch((error: RequestError) => {
        const errorMessage = getErrorMessage(error)
        onDisplayErrorNotification(errorMessage)
      })

    onClose()
  }

  return (
    <BasicDialog
      title="Edit session details"
      cancelTitle="Cancel"
      acceptTitle="Save"
      isOpened={isOpened}
      isAcceptDisabled={!isFormValid}
      onAccept={onEditTrainerSession}
      onCloseDialog={onClose}
    >
      <Container>
        <Section>
          <Typography gutterBottom variant="h6">
            Session #
          </Typography>
          <ColumnSection>{session?.id || ''}</ColumnSection>
        </Section>

        <Section>
          <Typography gutterBottom variant="h6">
            Session Status
          </Typography>
          <SelectInput
            id={getFieldDataQa(Resources.personalWorkouts, 'select/session-status')}
            value={sessionDetails.sessionStatus}
            choices={personalWorkoutStatusChoices}
            onSelect={(value) => onChangeField('sessionStatus', value)}
          />
        </Section>

        <Section>
          <Typography gutterBottom variant="h6">
            Duration
          </Typography>
          <SelectInput
            id={getFieldDataQa(Resources.personalWorkouts, 'select/session-duration')}
            value={`${sessionDetails.duration} Minutes`}
            choices={personalWorkoutDurationChoices}
            onSelect={(value) => onChangeField('duration', value === '30 Minutes' ? 30 : 60)}
          />
        </Section>
      </Container>
    </BasicDialog>
  )
}

export default EditTrainerSessionDetailsDialog
