import React, { useState, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { Table, TableContainer } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'

import { NOOP } from '../../constants/common-constants'
import { Order } from '../../types/common-types'
import { AccessibleTableProps } from './acessible-table-types'
import AccessibleTableHeader from './accessible-table-header'
import AccessibleTableBody from './accessible-table-body'
import { stableSort, getComparator } from '../../utils/sort-utils'
import { EMPTY_DATA_MAIN_MESSAGE, SOME_ERROR_MAIN_MESSAGE } from '../../constants/messages'
import { getCellValue } from './accessible-table-cell'

const StyledTableContainer = styled(TableContainer)`
  box-shadow: none;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const TableWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
`

const rowsPerPageOptions = [25, 50, 100]

function AccessibleTable(props: AccessibleTableProps) {
  const {
    id,
    resource,
    data,
    withClickableRows = false,
    columns,
    activeRowId,
    page = 0,
    withPagination,
    useHandlersOnSort,
    errorMessage = SOME_ERROR_MAIN_MESSAGE,
    isLoading,
    rowsPerPage = 25,
    sortByDirection = Order.asc,
    sortBy,
    bottomTitle,
    selectedRows = [],
    highlightedRows = [],
    className,
    emptyDataMessage = EMPTY_DATA_MAIN_MESSAGE,
    withEmptyDataActionButton,
    emptyDataActionButtonTitle,
    onEmptyDataActionButtonClick,
    getRowTooltipTitle,
    onRowClick = NOOP,
    onChangePage = NOOP,
    onChangeRowsPerPage = NOOP,
    onChangeSortBy = NOOP,
    onChangeSortByDirection = NOOP,
    onRowHover = NOOP,
    showPager = true,
  } = props

  const [tableOrder, setTableOrder] = useState<Order>(sortByDirection)
  const [tableOrderBy, setTableOrderBy] = useState(sortBy)
  const [filterState, setFilterState] = useState<Record<string, string[]>>({})

  // sorted data (doesn't affect row count)
  const tableDataList = useMemo(() => {
    const nonEmptyData = data || []
    return tableOrderBy && !useHandlersOnSort
      ? stableSort(nonEmptyData, getComparator(tableOrder, tableOrderBy))
      : nonEmptyData
  }, [data, tableOrder, tableOrderBy, useHandlersOnSort])

  // filtered data (before pagination)
  const tableFilteredDataList = useMemo(
    () =>
      tableDataList.filter((rowData) =>
        Object.entries(filterState).every(([key, values]) => {
          const column = columns.find((_column) => _column.key === key)

          if (!column || !column.fields || !values?.length) return true

          const value = getCellValue({ column, rowData })

          return values.includes(value)
        }),
      ),
    [columns, filterState, tableDataList],
  )

  // paginated data (rows to be displayed)
  const paginatedDataList = useMemo(() => {
    const start = page * rowsPerPage
    const end = start + rowsPerPage
    return withPagination ? tableFilteredDataList.slice(start, end) : tableFilteredDataList
  }, [page, rowsPerPage, tableFilteredDataList, withPagination])

  const onColumnClick = useCallback(
    (nextOrderBy: string) => {
      // if using handlers on sort, call those, otherwise handle sort logic in state
      if (useHandlersOnSort && nextOrderBy === sortBy) {
        onChangeSortByDirection(sortByDirection === Order.asc ? Order.desc : Order.asc)
        return
      } else if (!useHandlersOnSort && nextOrderBy === tableOrderBy) {
        setTableOrder(tableOrder === Order.asc ? Order.desc : Order.asc)
        return
      }

      if (useHandlersOnSort) {
        onChangeSortByDirection(Order.asc)
        onChangeSortBy(nextOrderBy)
      } else {
        setTableOrder(Order.asc)
        setTableOrderBy(nextOrderBy)
      }
    },
    [
      useHandlersOnSort,
      sortByDirection,
      sortBy,
      tableOrder,
      tableOrderBy,
      onChangeSortByDirection,
      onChangeSortBy,
      setTableOrder,
      setTableOrderBy,
    ],
  )

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(newPage)
  }

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+e.target.value)
    onChangePage(0)
  }

  if (!data) {
    return null
  }

  return (
    <StyledTableContainer className={className}>
      <TableWrapper>
        <Table stickyHeader aria-label="caption table">
          {bottomTitle && <caption>{bottomTitle}</caption>}
          <AccessibleTableHeader
            order={useHandlersOnSort ? sortByDirection : tableOrder}
            orderBy={useHandlersOnSort ? sortBy : tableOrderBy}
            columns={columns}
            onColumnClick={onColumnClick}
            // we pass all rows to generate proper filter values
            tableDataList={tableDataList}
            filterState={filterState}
            onFilterChange={(key, filterValues) =>
              setFilterState((old) => ({
                ...old,
                [key]: filterValues,
              }))
            }
          />
          <AccessibleTableBody
            id={id}
            resource={resource}
            withClickableRows={withClickableRows}
            // we pass only data which should be actually dispayed
            tableDataList={paginatedDataList}
            columns={columns}
            activeRowId={activeRowId}
            selectedRows={selectedRows}
            highlightedRows={highlightedRows}
            errorMessage={errorMessage}
            emptyDataMessage={emptyDataMessage}
            isLoading={isLoading}
            withEmptyDataActionButton={withEmptyDataActionButton}
            emptyDataActionButtonTitle={emptyDataActionButtonTitle}
            onEmptyDataActionButtonClick={onEmptyDataActionButtonClick}
            getRowTooltipTitle={getRowTooltipTitle}
            onRowHover={onRowHover}
            onRowClick={onRowClick}
          />
        </Table>
      </TableWrapper>
      {withPagination && showPager && (
        <TablePagination
          page={page}
          component="div"
          // we get filtered (not paginated) row count
          count={tableFilteredDataList.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </StyledTableContainer>
  )
}

export default AccessibleTable
