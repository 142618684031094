import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { setAuthData } from '../utils/auth-utils'

export const useAuthHandler = () => {
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = useAuth0()

  const [loading, setLoading] = useState<boolean>(isLoading)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const getAuthData = async () => {
      setLoading(true)

      const tokenClaims = await getIdTokenClaims()
      if (tokenClaims) setAuthData(tokenClaims)

      setToken(tokenClaims?.__raw || null)
      setLoading(false)
    }

    if (!isLoading) {
      if (isAuthenticated) getAuthData()
      if (!isAuthenticated) setLoading(false)
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims, user])

  return { isLoading: loading, isAuthenticated: isAuthenticated && !!token, user, token }
}
