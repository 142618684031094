import jwtDecode from 'jwt-decode'
import { IdToken } from '@auth0/auth0-react'
import { Permission } from '../types/permissions-types'
import { USER_PERMISSIONS_KEY } from '../config'

/**
 *
 */
export const setToken = (idToken: string) => localStorage.setItem('id_token', idToken)
export const getToken = () => localStorage.getItem('id_token')

export const setAccessToken = (accessToken: string) => localStorage.setItem('access_token', accessToken)
export const getAccessToken = () => localStorage.getItem('access_token')

export const setExpiresAt = (expiresAt: string) => localStorage.setItem('expires_at', expiresAt)
export const getExpiresAt = () => localStorage.getItem('expires_at')

export const savePermissions = (permissions: string) => localStorage.setItem('permissions', permissions)
export const getPermissions = () => localStorage.getItem('permissions') || ''

/**
 *
 */
export const clearAuthData = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('id_token')
  localStorage.removeItem('expires_at')
  localStorage.removeItem('permissions')
}

/**
 *
 */
export const isTokenValid = (token: string | null, expiredDate: string | null) => {
  const date = +new Date()
  const isExpired = Number(expiredDate) <= date

  return token && expiredDate && !isExpired
}

/**
 *
 */
export const getIsTokenShouldBeUpdated = (tokenClaims: IdToken) => {
  const token = getToken()
  const expiredDate = getExpiresAt()
  const isValid = isTokenValid(token, expiredDate)
  const newIdToken = tokenClaims?.__raw
  const isTokenChanged = token !== newIdToken

  return !token || !isValid || isTokenChanged
}

/**
 *
 */
export const setAuthData = (tokenClaims: IdToken) => {
  const { __raw: idToken, aud: accessToken, exp: expDate } = tokenClaims
  const shouldUpdateToken = getIsTokenShouldBeUpdated(tokenClaims)

  if (!idToken) return
  if (shouldUpdateToken) setToken(idToken)
  if (accessToken && !getAccessToken()) setAccessToken(accessToken)
  if (expDate && !getExpiresAt()) setExpiresAt(JSON.stringify(expDate * 1000 + new Date().getTime()))
}

/**
 *
 */
export const getPermissionsFromToken = () => {
  const idToken = getToken()
  if (!idToken) return 'Missed auth token'

  const decoded = jwtDecode<{ [USER_PERMISSIONS_KEY]: Permission[] }>(idToken)
  return decoded[USER_PERMISSIONS_KEY]
}
